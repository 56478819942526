// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-list-item-extended_znPcC{height:52px}.sport-event-list-item-extended--loaded_l83TJ{height:auto}.slide-enter-active__zzLY{transition-timing-function:ease-in;transition-duration:.3s}.slide-leave-active_SZOfI{transition-timing-function:cubic-bezier(0,1,.5,1);transition-duration:.3s}.slide-enter-to_Ro7yr,.slide-leave-from_QVWn5{max-height:300px;overflow:hidden}.slide-enter-from_ZezEj,.slide-leave-to_kXQht{max-height:0;overflow:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-list-item-extended": `sport-event-list-item-extended_znPcC`,
	"sport-event-list-item-extended--loaded": `sport-event-list-item-extended--loaded_l83TJ`,
	"slide-enter-active": `slide-enter-active__zzLY`,
	"slide-leave-active": `slide-leave-active_SZOfI`,
	"slide-enter-to": `slide-enter-to_Ro7yr`,
	"slide-leave-from": `slide-leave-from_QVWn5`,
	"slide-enter-from": `slide-enter-from_ZezEj`,
	"slide-leave-to": `slide-leave-to_kXQht`
};
export default ___CSS_LOADER_EXPORT___;
