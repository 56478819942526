// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .payments-iframe_elmcz{width:100%;height:auto;margin:0;border:0}html.app__layout--desktop .payments-iframe-loader_pyV0N{display:flex;flex-direction:column;align-items:center;justify-content:center;min-height:140px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-iframe": `payments-iframe_elmcz`,
	"payments-iframe-loader": `payments-iframe-loader_pyV0N`
};
export default ___CSS_LOADER_EXPORT___;
