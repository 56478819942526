import { isNumber, isString, isObject, isBoolean, isUndefined, bindIsArrayOf, isValidInterface } from '@leon-hub/guards';
import { isCoreMarketRunnerResponse } from './isCoreMarketRunnerResponse';
import { isBetlineSelectionTag } from './isBetlineSelectionTag';
function isMarketSpecifiersResponse(value) {
    if (!isObject(value)) return false;
    const isValidSpecifiers = (key)=>isString(key) && (isString(value[key]) || isNumber(value[key]));
    return Object.keys(value).every(isValidSpecifiers);
}
export const isCoreMarketResponseFieldGuards = {
    id: isNumber,
    marketTypeId: isNumber,
    name: isString,
    runners: bindIsArrayOf(isCoreMarketRunnerResponse),
    open: isBoolean,
    primary: isBoolean,
    cols: isNumber,
    selectionTypes: [
        isUndefined,
        bindIsArrayOf(isBetlineSelectionTag)
    ],
    handicap: [
        isUndefined,
        isString
    ],
    hasZeroMargin: [
        isUndefined,
        isBoolean
    ],
    specifiers: [
        isUndefined,
        isMarketSpecifiersResponse
    ]
};
export function isCoreMarketResponse(value) {
    return isValidInterface(value, isCoreMarketResponseFieldGuards);
}
