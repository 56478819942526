// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-search-section_G6XPj{display:flex;flex-direction:column;gap:0}.casino-search-section__list_u6QBh{padding:0;margin:0;list-style:none}.casino-search-section__list-item_x70vQ{margin-bottom:4px}.casino-search-section__list-item_x70vQ:last-child{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-search-section": `casino-search-section_G6XPj`,
	"casino-search-section__list": `casino-search-section__list_u6QBh`,
	"casino-search-section__list-item": `casino-search-section__list-item_x70vQ`
};
export default ___CSS_LOADER_EXPORT___;
