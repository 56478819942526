// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer_p1qMc{display:flex;flex-direction:column;text-align:center}.timer__label_Yr0yp{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}.timer__time-left_um89G{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"timer": `timer_p1qMc`,
	"timer__label": `timer__label_Yr0yp`,
	"timer__time-left": `timer__time-left_um89G`
};
export default ___CSS_LOADER_EXPORT___;
