// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-loyalty-program-card_gyTrK{position:relative;display:flex;flex-direction:column;align-items:center;padding:16px;cursor:pointer;background-color:var(--Layer1);border-radius:5px}@media(hover:hover)and (pointer:fine){.bonus-loyalty-program-card_gyTrK:hover .bonus-loyalty-program-balance_GO0RD:not(.bonus-loyalty-program-card_gyTrK:hover .bonus-loyalty-program-balance--inactive_UhrJe){background-color:var(--OpacityLayer2)}}.bonus-loyalty-program-card__title_vhCBS{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;width:100%;overflow:hidden;color:var(--TextDefault);text-align:center;text-overflow:ellipsis;white-space:nowrap}.bonus-loyalty-program-card__img-wrapper_AZUsc{display:flex;align-items:center;justify-content:center;width:100px;height:100px;padding:10px;overflow:hidden}.bonus-loyalty-program-card__img_gQGIh{max-width:100%;object-fit:contain;object-position:center}.bonus-loyalty-program-card__placeholder_gD6Ew{width:80px;height:auto}.bonus-loyalty-program-card--locked_e04ws{cursor:auto}.bonus-loyalty-program-card--locked_e04ws .bonus-loyalty-program-card__title_vhCBS{color:var(--TextSecondary)}.bonus-loyalty-program-card--locked_e04ws .bonus-loyalty-program-card__img-wrapper_AZUsc{opacity:.3}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-loyalty-program-card": `bonus-loyalty-program-card_gyTrK`,
	"bonus-loyalty-program-balance": `bonus-loyalty-program-balance_GO0RD`,
	"bonus-loyalty-program-balance--inactive": `bonus-loyalty-program-balance--inactive_UhrJe`,
	"bonus-loyalty-program-card__title": `bonus-loyalty-program-card__title_vhCBS`,
	"bonus-loyalty-program-card__img-wrapper": `bonus-loyalty-program-card__img-wrapper_AZUsc`,
	"bonus-loyalty-program-card__img": `bonus-loyalty-program-card__img_gQGIh`,
	"bonus-loyalty-program-card__placeholder": `bonus-loyalty-program-card__placeholder_gD6Ew`,
	"bonus-loyalty-program-card--locked": `bonus-loyalty-program-card--locked_e04ws`
};
export default ___CSS_LOADER_EXPORT___;
