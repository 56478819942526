// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countries-selector-item__code_BZywj{width:45px;color:var(--TextSecondary)}.list-item__prefix-flag_EQRt9{display:flex;align-items:center;width:100%;padding-right:8px;padding-left:8px}.list-item__prefix-flag-img_x3dKv{width:100%;height:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countries-selector-item__code": `countries-selector-item__code_BZywj`,
	"list-item__prefix-flag": `list-item__prefix-flag_EQRt9`,
	"list-item__prefix-flag-img": `list-item__prefix-flag-img_x3dKv`
};
export default ___CSS_LOADER_EXPORT___;
