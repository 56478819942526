// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address_aNg0i{padding:0;margin:0;border:none}.address__predictions_zp3W6{z-index:5;position:absolute;top:100%;right:0;left:0;margin:-14px 0 0;background-color:var(--Layer0)}.address__predictions-item_xTAFl{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;display:block;width:100%;padding:12px;margin:4px 0 0;color:var(--TextPrimary);text-align:left;cursor:pointer;background:var(--Layer1);border:none;border-radius:5px}.address__predictions-item--preselected_SM9Xk{background:var(--Highlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"address": `address_aNg0i`,
	"address__predictions": `address__predictions_zp3W6`,
	"address__predictions-item": `address__predictions-item_xTAFl`,
	"address__predictions-item--preselected": `address__predictions-item--preselected_SM9Xk`
};
export default ___CSS_LOADER_EXPORT___;
