// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .snack-bar_tE50J{z-index:69;position:fixed;right:16px;bottom:16px;left:16px;display:flex;justify-content:center;max-height:70%}html.app__layout--desktop .snack-bar--wrapper_rmiP5{position:relative;overflow:hidden;border-radius:8px}html.app__layout--desktop .snack-bar--tooltip-index_dFXpv{z-index:72}html.app__layout--desktop .snack-bar--max-z-index_pApHn{z-index:300}html.app__layout--desktop .snack-bar__content_xdrvw{min-width:664px;max-width:664px}@media(min-width:1024px){html.app__layout--desktop .snack-bar__content_xdrvw{max-width:664px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"snack-bar": `snack-bar_tE50J`,
	"snack-bar--wrapper": `snack-bar--wrapper_rmiP5`,
	"snack-bar--tooltip-index": `snack-bar--tooltip-index_dFXpv`,
	"snack-bar--max-z-index": `snack-bar--max-z-index_pApHn`,
	"snack-bar__content": `snack-bar__content_xdrvw`
};
export default ___CSS_LOADER_EXPORT___;
