// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.power-play-badge_pXVAr{display:flex;flex-direction:column;padding:2px 0}.power-play-badge__label_QeIQ1{font-size:9px;font-weight:700;line-height:12px;text-transform:uppercase;letter-spacing:.2px;display:flex;align-items:center;align-self:stretch;justify-content:center;padding:0 2px;background:#9cc6ee;border-radius:2px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"power-play-badge": `power-play-badge_pXVAr`,
	"power-play-badge__label": `power-play-badge__label_QeIQ1`
};
export default ___CSS_LOADER_EXPORT___;
