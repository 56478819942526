// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-input_aicpm{display:inline-flex;padding:0 12px}.payments-input__error_On3le{font-size:11px;font-weight:400;line-height:16px;color:var(--ColorContent5)}.payments-input__balance_uxav1{padding:4px 0}.payments-input__balance-label_Ud_jI{font-size:11px;font-weight:400;line-height:16px;color:var(--ColorContent1a)}.payments-input__balance-value_fEU9Y{font-size:11px;font-weight:500;line-height:16px;padding-left:4px;color:var(--ColorContent2)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-input": `payments-input_aicpm`,
	"payments-input__error": `payments-input__error_On3le`,
	"payments-input__balance": `payments-input__balance_uxav1`,
	"payments-input__balance-label": `payments-input__balance-label_Ud_jI`,
	"payments-input__balance-value": `payments-input__balance-value_fEU9Y`
};
export default ___CSS_LOADER_EXPORT___;
