import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, Teleport as _Teleport, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 2
};
import { ref, toRef, nextTick, onMounted, onBeforeUnmount } from 'vue';
import { PopperPosition, PopperApplyTarget } from './enums';
import { useVPopper } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VPopper',
    props: {
        position: {},
        applyTarget: {},
        modifiers: {},
        hasOffset: {
            type: Boolean
        },
        isFullWidth: {
            type: Boolean
        },
        isFullHeight: {
            type: Boolean
        }
    },
    emits: [
        "show",
        "hide"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { popper, reference, content, showContent, isBodyContentAvailable, destroyPopper, toggle, hide, show, focusReferenceElm } = useVPopper({
            modifiers: toRef(props, 'modifiers', []),
            position: toRef(props, 'position', PopperPosition.BOTTOM),
            applyTarget: toRef(props, 'applyTarget'),
            hasOffset: toRef(props, 'hasOffset', true),
            isFullWidth: toRef(props, 'isFullWidth', false),
            isFullHeight: toRef(props, 'isFullHeight', false)
        }, emit);
        const isMounted = ref(false);
        onMounted(async ()=>{
            // We have to await full layout mount before select components except body
            await nextTick();
            isMounted.value = true;
        });
        onBeforeUnmount(destroyPopper);
        __expose({
            show,
            hide,
            focusReferenceElm
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "popper",
                ref: popper,
                class: _normalizeClass(_ctx.$style['popper'])
            }, [
                _createElementVNode("div", {
                    ref_key: "reference",
                    ref: reference,
                    class: _normalizeClass(_ctx.$style['popper__reference']),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(toggle) && _unref(toggle)(...args);
                    })
                }, [
                    _renderSlot(_ctx.$slots, "reference")
                ], 2),
                _ctx.applyTarget ? _ctx.applyTarget === _unref(PopperApplyTarget).Body || isMounted.value ? (_openBlock(), _createBlock(_Teleport, {
                    key: 1,
                    to: _ctx.applyTarget,
                    disabled: !_unref(isBodyContentAvailable)
                }, [
                    _withDirectives(_createElementVNode("div", {
                        ref_key: "content",
                        ref: content,
                        class: _normalizeClass({
                            [_ctx.$style['popper__content']]: true,
                            [_ctx.$style['popper__content--full-width']]: _ctx.isFullWidth,
                            [_ctx.$style['popper__content--full-height']]: _ctx.isFullHeight
                        })
                    }, [
                        _renderSlot(_ctx.$slots, "default", {
                            hide: _unref(hide),
                            shown: _unref(showContent)
                        })
                    ], 2), [
                        [
                            _vShow,
                            _unref(showContent)
                        ]
                    ])
                ], 8, [
                    "to",
                    "disabled"
                ])) : (_openBlock(), _createElementBlock("div", _hoisted_1)) : _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    ref_key: "content",
                    ref: content,
                    class: _normalizeClass({
                        [_ctx.$style['popper__content']]: true,
                        [_ctx.$style['popper__content--full-width']]: _ctx.isFullWidth,
                        [_ctx.$style['popper__content--full-height']]: _ctx.isFullHeight
                    })
                }, [
                    _renderSlot(_ctx.$slots, "default", {
                        hide: _unref(hide),
                        shown: _unref(showContent)
                    })
                ], 2)), [
                    [
                        _vShow,
                        _unref(showContent)
                    ]
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VPopper'
                ]
            ]);
        };
    }
});
