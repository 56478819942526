// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-list-item__type-name_hHA3E{font-weight:500;display:block;width:100%;height:16px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.payment-list-item__date_si0ET,.payment-list-item__type-name_hHA3E{font-size:12px;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}.payment-list-item__date_si0ET{font-weight:400}.payment-list-item__type-description_LgMjs{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.payment-list-item__status_gxMyc{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:var(--TextPrimary)}.payment-list-item__id_cNRhZ{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}.payment-list-item__withdrawal-block_AcUej{display:flex;align-items:center}.payment-list-item__withdrawal-button_q8XXj{position:relative;margin-left:8px}.payment-list-item__withdrawal-button_q8XXj:after{position:absolute;top:-12px;right:-12px;bottom:-12px;left:-12px;display:block;content:""}.payment-list-item__money_TjWvG{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextPrimary)}.payment-list-item__money--positive_hcSQO{color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-list-item__type-name": `payment-list-item__type-name_hHA3E`,
	"payment-list-item__date": `payment-list-item__date_si0ET`,
	"payment-list-item__type-description": `payment-list-item__type-description_LgMjs`,
	"payment-list-item__status": `payment-list-item__status_gxMyc`,
	"payment-list-item__id": `payment-list-item__id_cNRhZ`,
	"payment-list-item__withdrawal-block": `payment-list-item__withdrawal-block_AcUej`,
	"payment-list-item__withdrawal-button": `payment-list-item__withdrawal-button_q8XXj`,
	"payment-list-item__money": `payment-list-item__money_TjWvG`,
	"payment-list-item__money--positive": `payment-list-item__money--positive_hcSQO`
};
export default ___CSS_LOADER_EXPORT___;
