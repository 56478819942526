// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotions-shared__title_JzL_b{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault);margin:0;text-align:center}html.app__layout--desktop .promotions-shared__title_JzL_b{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.promotions-shared__title--left_kjFbU{text-align:left}.promotions-shared__title--big_LreW8{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.promotion-details-title__wrapper_Ooc4Q{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center;background-color:var(--Layer1)}.promotion-details-title__rate-wrapper_ZWLEo{position:relative}.promotion-details-title__period_gvIgO{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;margin-bottom:12px;padding:0;color:var(--BrandDefault);background-color:#0000;border:none;border-radius:0}.promotion-details-title__period--archived_hyCP_{color:var(--TextSecondary)}.promotion-details-title__name_gBIhK{margin-bottom:16px;text-align:center}.promotion-details-title__pre-text_aEqAa{margin-bottom:24px;font-size:14px;line-height:20px;color:var(--TextPrimary)}.promotion-details-title__status_t9qsT{margin-top:24px;margin-right:auto;margin-left:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotions-shared__title": `promotions-shared__title_JzL_b`,
	"promotions-shared__title--left": `promotions-shared__title--left_kjFbU`,
	"promotions-shared__title--big": `promotions-shared__title--big_LreW8`,
	"promotion-details-title__wrapper": `promotion-details-title__wrapper_Ooc4Q`,
	"promotion-details-title__rate-wrapper": `promotion-details-title__rate-wrapper_ZWLEo`,
	"promotion-details-title__period": `promotion-details-title__period_gvIgO`,
	"promotion-details-title__period--archived": `promotion-details-title__period--archived_hyCP_`,
	"promotion-details-title__name": `promotion-details-title__name_gBIhK`,
	"promotion-details-title__pre-text": `promotion-details-title__pre-text_aEqAa`,
	"promotion-details-title__status": `promotion-details-title__status_t9qsT`
};
export default ___CSS_LOADER_EXPORT___;
