import { FormControlType } from '../../../../enums';
import getWidget from '../../../../utils/getWidget';
export default function getWidgetTypeList(uiSchema) {
    // all properties should be defined in ui.order
    const propertyNames = uiSchema.order ? uiSchema.order : Object.keys(uiSchema.fields ?? {});
    const widgetTypeList = [];
    const captchaWidgets = [];
    for (const name of propertyNames){
        const widget = getWidget(uiSchema, name);
        if (widget === FormControlType.Captcha || widget === FormControlType.CaptchaV3) captchaWidgets.push({
            type: widget,
            field: name
        });
        else widgetTypeList.push({
            type: widget,
            field: name
        });
    }
    return {
        widgetTypeList,
        captchaWidgets
    };
}
