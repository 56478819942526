import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { provide } from 'vue';
import { ScrollbarProvidableContext } from '../enums';
import { useVScrollbarDesktop } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VScrollbarDesktop',
    props: {
        disabled: {
            type: Boolean
        },
        flexFill: {
            type: Boolean
        },
        isDropdown: {
            type: Boolean
        },
        useScrollListener: {
            type: Boolean
        },
        testEl: {},
        handleResizeChange: {
            type: Boolean
        }
    },
    emits: [
        "scroll",
        "reached-bottom"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { testAttrs, element, providableContext, onScroll, scrollTo, scrollTop, scrollDown, scrollRight, scrollUpdate, scrollToElement, recompose } = useVScrollbarDesktop(props, emit);
        provide(ScrollbarProvidableContext.ScrollbarContext, providableContext);
        __expose({
            scrollTo,
            scrollTop,
            scrollDown,
            scrollRight,
            scrollUpdate,
            scrollToElement,
            recompose
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "element",
                ref: element,
                class: _normalizeClass({
                    ['scrollbar']: true,
                    ['scrollbar--flex-fill']: _ctx.flexFill,
                    ['scrollbar--dropdown']: _ctx.isDropdown
                }),
                onWheel: _cache[0] || (_cache[0] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onScroll) && _unref(onScroll)(...args);
                })
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 34)), [
                [
                    _directive_auto_id,
                    'VScrollbarDesktop'
                ],
                [
                    _directive_data_test,
                    _unref(testAttrs)
                ]
            ]);
        };
    }
});
