// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .date-time-filter_vk9aO{z-index:8;position:sticky;display:flex;height:44px;top:-8px}@media(min-width:1280px){html.app__layout--desktop .date-time-filter_vk9aO{top:-8px}}html.app__layout--desktop .custom-range-select_iVj2N{display:flex;flex:1;height:calc(100% + 1px);padding-left:12px;margin:0;background-color:var(--Layer1);border:1px solid var(--Blue);border-radius:5px}html.app__layout--desktop .custom-range-select__content_c9bAx,html.app__layout--desktop .custom-range-select__prefix_q6dhY,html.app__layout--desktop .custom-range-select__suffix_YJDJM{display:flex;align-items:center}html.app__layout--desktop .custom-range-select__prefix_q6dhY{justify-content:flex-start}html.app__layout--desktop .custom-range-select__content_c9bAx{flex:1;padding:0 8px}html.app__layout--desktop .custom-range-select__suffix_YJDJM{justify-content:flex-end}html.app__layout--desktop .custom-range-select__input_HE4DD{padding:0}html.app__layout--desktop .custom-range-select__cancel_uUASS{height:42px}html.app__layout--desktop .custom-range-select__divider_F6Zst{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;padding:0 10px;color:var(--TextSecondary);cursor:default;-webkit-user-select:none;user-select:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date-time-filter": `date-time-filter_vk9aO`,
	"custom-range-select": `custom-range-select_iVj2N`,
	"custom-range-select__content": `custom-range-select__content_c9bAx`,
	"custom-range-select__prefix": `custom-range-select__prefix_q6dhY`,
	"custom-range-select__suffix": `custom-range-select__suffix_YJDJM`,
	"custom-range-select__input": `custom-range-select__input_HE4DD`,
	"custom-range-select__cancel": `custom-range-select__cancel_uUASS`,
	"custom-range-select__divider": `custom-range-select__divider_F6Zst`
};
export default ___CSS_LOADER_EXPORT___;
