// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-game-loader_HHtW7{display:flex;width:100%;height:100%;background-color:var(--Layer1)}.casino-game-loader--dark_y8qjy{background-color:var(--Layer0)}.casino-game-loader__logo_h_Kyx{width:100%;min-width:255px;max-width:275px;margin:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-game-loader": `casino-game-loader_HHtW7`,
	"casino-game-loader--dark": `casino-game-loader--dark_y8qjy`,
	"casino-game-loader__logo": `casino-game-loader__logo_h_Kyx`
};
export default ___CSS_LOADER_EXPORT___;
