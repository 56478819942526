// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.egs-jackpot-amount_AWpsW{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;display:inline-flex;align-items:center;color:var(--TextDefault)}.egs-jackpot-amount--background_H2ZEt{padding:4px 4px 0;background-color:var(--Layer0);border-radius:5px 5px 0 0}.egs-jackpot-amount__currency_EE2wx{min-width:11px;margin:0 8px}.egs-jackpot-amount__value-list_Gh1VC{display:flex}.egs-jackpot-amount__item-value_UNzl5{width:19px;padding:4px;margin-left:2px;background-color:var(--Layer1);border-radius:5px}.egs-jackpot-amount__dot_fUwSq{width:6px;margin:4px 0 4px 2px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"egs-jackpot-amount": `egs-jackpot-amount_AWpsW`,
	"egs-jackpot-amount--background": `egs-jackpot-amount--background_H2ZEt`,
	"egs-jackpot-amount__currency": `egs-jackpot-amount__currency_EE2wx`,
	"egs-jackpot-amount__value-list": `egs-jackpot-amount__value-list_Gh1VC`,
	"egs-jackpot-amount__item-value": `egs-jackpot-amount__item-value_UNzl5`,
	"egs-jackpot-amount__dot": `egs-jackpot-amount__dot_fUwSq`
};
export default ___CSS_LOADER_EXPORT___;
