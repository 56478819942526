import { toRef } from 'vue';
import { bonusCurrency, bonusLocale } from 'web/src/modules/money/consts';
import { shouldReduceMoneyAmount, getCurrencySymbol } from '../utils';
import { useCurrencyStore } from '../store';
export default function useFormatMoney() {
    const currencyStore = useCurrencyStore();
    const currencyLocale = toRef(()=>currencyStore.currencyLocale);
    const currency = toRef(()=>currencyStore.currency);
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return function(amount) {
        let inputOptions = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
        const options = {
            ...inputOptions
        };
        const defaultFractionDigits = Number.isInteger(amount) && 0 !== amount ? 0 : 2;
        const defaultCurrencyDisplayOption = 'code';
        let formattedValue;
        const defaultOptions = {
            locale: currencyLocale.value,
            style: 'currency',
            currency: currency.value,
            currencyDisplay: defaultCurrencyDisplayOption,
            minimumFractionDigits: defaultFractionDigits,
            maximumFractionDigits: defaultFractionDigits
        };
        const originalCurrency = options?.currency ?? defaultOptions.currency;
        let workingOptions = {
            ...options
        };
        if (options?.currency === bonusCurrency || options.currency?.length !== 3) {
            const locale = options?.currency === bonusCurrency ? bonusLocale : currencyLocale.value;
            workingOptions = {
                ...workingOptions,
                currency: 'KHQ',
                locale
            };
        }
        const { locale, ...intlOptions } = workingOptions;
        const formatOptions = {
            ...defaultOptions,
            ...intlOptions
        };
        if (options.hideCurrency) formatOptions.style = 'decimal';
        const { currency: customCurrency } = formatOptions;
        if (options.reduceAmount && shouldReduceMoneyAmount(amount, customCurrency, options.reduceAmount)) {
            const roundedCurrency = Math.round(amount / 1000);
            formattedValue = roundedCurrency.toLocaleString(locale || currencyLocale.value, {
                style: 'decimal'
            });
            return `${formattedValue}K`;
        }
        formattedValue = amount.toLocaleString(locale || currencyLocale.value, formatOptions);
        // For the bonus currency, the currency symbol should always be placed on the right side.
        // However, to maintain consistent visualization, a locale with the currency on the left side is used.
        // Therefore, the following adjustment is necessary.
        if (originalCurrency === bonusCurrency) {
            formattedValue = formattedValue.replace(`${formatOptions.currency}`, '').trim();
            if (!options.hideCurrency) formattedValue = `${formattedValue} ${formatOptions.currency}`;
        }
        if (formatOptions.currencyDisplay === defaultCurrencyDisplayOption && !options.hideCurrency) formattedValue = formattedValue.replace(customCurrency, getCurrencySymbol(originalCurrency));
        return formattedValue;
    };
}
