import { getLocationHost } from '@leon-hub/service-locator-env';
import { init, isLoggableError, browserTracingIntegration, captureConsoleIntegration, extraErrorDataIntegration, browserProfilingIntegration, replayIntegration, getCurrentScope, setUser, setTags } from '@leon-hub/sentry';
import getBaseHeaders from '@leon-hub/api/src/client/getBaseHeaders';
import { IgnoredErrorType } from '@leon-hub/api-sdk';
export function initSentry(app, router, options) {
    const integrations = options.parameters.integrations ?? [];
    const browserOptions = {
        ...options.parameters,
        ignoreErrors: [
            // builtin ignored errors.
            '[webpack-dev-server]',
            ...options.ignoredErrors.map((item)=>item.type === IgnoredErrorType.REGEXP ? new RegExp(item.error) : item.error)
        ]
    };
    if (options.browserTracingEnabled) {
        integrations.push(browserTracingIntegration({
            router
        }));
        if (browserOptions.tracesSampleRate) browserOptions.tracesSampleRate = Number(browserOptions.tracesSampleRate);
    }
    if (options.sessionReplayEnabled) {
        integrations.push(replayIntegration({
            networkDetailAllowUrls: [
                /\/api-1/,
                /\/api-2\//
            ],
            networkRequestHeaders: [
                ...Object.keys(getBaseHeaders()),
                'user-agent'
            ]
        }));
        if (browserOptions.replaysSessionSampleRate) browserOptions.replaysSessionSampleRate = Number(browserOptions.replaysSessionSampleRate);
        if (browserOptions.replaysOnErrorSampleRate) browserOptions.replaysOnErrorSampleRate = Number(browserOptions.replaysOnErrorSampleRate);
    }
    // TODO: not supported in vue yet
    if (options.profilingEnabled) integrations.push(browserProfilingIntegration());
    // if (sentryOptions.profilesSampleRate) {
    //   sentryOptions.profilesSampleRate = Number(sentryOptions.profilesSampleRate);
    // }
    init({
        app,
        ...browserOptions,
        transportOptions: {
            ...browserOptions.transportOptions,
            fetchOptions: {
                ...browserOptions.transportOptions?.fetchOptions,
                priority: 'low'
            }
        },
        release: options.release,
        denyUrls: options.denyUrls,
        // According to docs when logErrors==true, original Vue's logError will be called as well
        // Instead logErrors parameter defines whether original Vue's logWarn will be called but
        // original Vue's logError will be called in any case
        // We don't want to call logError and logWarn since the same log will be duplicated as ERROR and WARN
        logErrors: false,
        integrations: (defaultIntegrations)=>[
                captureConsoleIntegration({
                    levels: options.consoleLogLevels
                }),
                extraErrorDataIntegration(),
                ...integrations,
                // Log UnhandledPromiseRejection manually in useErrorHandler.handleError()
                ...defaultIntegrations.filter((integration)=>'GlobalHandlers' !== integration.name)
            ],
        tracePropagationTargets: [
            'localhost',
            new RegExp(`^https://${getLocationHost()}`),
            /^\//
        ],
        beforeSend (event, hint) {
            if (hint?.originalException && !isLoggableError(hint.originalException, options.ignoredErrors)) return null;
            return event;
        }
    });
    const globalScope = getCurrentScope();
    globalScope.setTag('frame', 'main');
    globalScope.setTag('release', options.release);
    globalScope.setTag('product', options.product);
    globalScope.setTag('skin', options.skin);
    globalScope.setTag('platform', options.platform);
    globalScope.setTag('layout', options.layout);
    globalScope.setTag('modernity', options.modernity);
    return {
        setUser,
        setTags
    };
}
