import { IconName, isIconNameKey } from '@leon-hub/icons';
import getCurrencyAbbrForIcon from './getCurrencyAbbrForIcon';
export default function getCurrencyIcon(currency) {
    const fallbackIcon = IconName.COIN_USD;
    if (!currency) return fallbackIcon;
    const nameByCurrency = `COIN_${getCurrencyAbbrForIcon(currency)}`;
    if (nameByCurrency in IconName) {
        isIconNameKey(nameByCurrency);
        return IconName[nameByCurrency];
    }
    return fallbackIcon;
}
