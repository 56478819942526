// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_tLOsC{display:flex;flex-direction:column;gap:8px;align-items:center;margin:0 auto;text-align:center}.loader__spinner_iCWAR{width:28px;height:28px;padding:4px}.loader__spinner--size-small_fabrU{width:14px;height:14px}.loader__spinner--size-large_DNw0v{width:52px;height:52px}.loader__svg_NAzKb{width:100%;height:100%}.loader__title_pBClO{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal}.loader__hint_bZQDQ{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px}.loader__hint_bZQDQ,.loader__hint--grey_pWLRo{color:var(--TextPrimary)}.loader--margin-small__262f{margin:10px auto}.loader--margin-medium_mRd5M{margin:20px auto}.loader--margin-large_v22yr{margin:40px auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `loader_tLOsC`,
	"loader__spinner": `loader__spinner_iCWAR`,
	"loader__spinner--size-small": `loader__spinner--size-small_fabrU`,
	"loader__spinner--size-large": `loader__spinner--size-large_DNw0v`,
	"loader__svg": `loader__svg_NAzKb`,
	"loader__title": `loader__title_pBClO`,
	"loader__hint": `loader__hint_bZQDQ`,
	"loader__hint--grey": `loader__hint--grey_pWLRo`,
	"loader--margin-small": `loader--margin-small__262f`,
	"loader--margin-medium": `loader--margin-medium_mRd5M`,
	"loader--margin-large": `loader--margin-large_v22yr`
};
export default ___CSS_LOADER_EXPORT___;
