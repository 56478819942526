import { computed } from 'vue';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika/src/constants/index';
import { CasinoIconName } from '@leon-hub/icons';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { PresetName } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron/VJumbotron/enums';
export default function useCasinoLoyaltyProgramCurrentLevelCard(props, emits) {
    const $formatMoney = useFormatMoney();
    const { $translate } = useI18n();
    const { showDialog } = useDialogs();
    const analytics = useAnalytics();
    const currentBalance = computed(()=>$formatMoney(props.currentValue || 0, {
            currency: 'L',
            hideCurrency: true,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }));
    const remainingPoints = computed(()=>$formatMoney(props.total || 0, {
            currency: 'L'
        }));
    const nextLevelI18nProps = computed(()=>({
            nextLevelTitle: props.nextLevelTitle
        }));
    const nextLevel = computed(()=>props.total > props.currentValue ? $translate('WEB2_LOYALTY_PROGRAM_UNTIL_NEXT_LEVEL_TITLE', nextLevelI18nProps).value : '');
    const multiplierLabel = $translate('WEB2_LOYALTY_PROGRAM_MULTIPLIER_TEXT').value;
    function emitClick() {
        emits('show-description', true);
    }
    function onClick() {
        if (props.initLevel) return;
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                loyaltyProgram: 'colorFilledZone'
            }
        });
        emitClick();
    }
    function onGiftClick() {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                loyaltyProgram: 'gift'
            }
        });
        emitClick();
    }
    function clickOnBalance() {
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                loyaltyProgram: 'multiplier'
            }
        });
        showDialog({
            presetName: PresetName.ALERT_SUCCESS,
            options: {
                iconName: CasinoIconName.CASINO_ROULETTE,
                iconKind: JumbotronIconKind.DEFAULT,
                fullHeight: false,
                title: `${multiplierLabel} ${props.multiplier}`,
                confirmMessage: $translate('WEB2_LOYALTY_PROGRAM_MULTIPLIER_ABOUT_TEXT').value,
                buttons: [
                    {
                        label: $translate('WEB2_LOYALTY_PROGRAM_MODAL_REMAINING_BUTTON_TEXT').value
                    }
                ],
                dataTestId: 'multiplier'
            }
        });
    }
    return {
        currentBalance,
        remainingPoints,
        nextLevel,
        multiplierLabel,
        onClick,
        onGiftClick,
        clickOnBalance
    };
}
