// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-button_UvpmO{display:flex;align-items:center;justify-content:center;width:36px;height:44px;padding:0;color:var(--TextSecondary);cursor:pointer;background:#0000;border:none}@media(hover:hover)and (pointer:fine){.input-button_UvpmO:hover{color:var(--TextDefault)}}.input-button--large_RnwtA{width:36px;height:44px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-button": `input-button_UvpmO`,
	"input-button--large": `input-button--large_RnwtA`
};
export default ___CSS_LOADER_EXPORT___;
