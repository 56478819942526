import { defineComponent, h, computed, provide, ref, nextTick, cloneVNode, onMounted, onActivated, onBeforeUnmount, onDeactivated, withDirectives, watch, normalizeClass, useCssModule } from 'vue';
import { ObserveVisibility } from 'vue3-observe-visibility';
import { Timer } from '@leon-hub/utils';
import { useDebounce } from '@leon-hub/debounce';
import { assert } from '@leon-hub/guards';
import { useWindowResize } from '@leon-hub/browser-composables';
import { useSwiperManualActiveSlide } from 'web/src/components/Swiper/VSwiper/composables';
import { VSwipeDirection, VSwiperProvidableKeys } from '../VSwiper/enums';
import { isSlideVisibleInContainer, isSwiperSlideVNode } from '../VSwiper/utils';
function shouldSlideBeVisible(limits, slide) {
    if (slide) {
        const offsetWidth = slide.component?.exposed?.getOffsetWidth?.() || 0;
        const offsetLeft = slide.component?.exposed?.getOffsetLeft?.() || 0;
        return limits.min <= offsetLeft + offsetWidth && limits.max >= offsetLeft;
    }
    return false;
}
const SWIPER_OPTIONS = {
    bounce: {
        minVelocity: 0.3,
        momentumRatio: 800,
        durationRatio: 0.7,
        momentumVelocityRatio: 0.6
    },
    visibility: {
        offsetRatio: 0.3
    },
    navigation: {
        visibleSlideSpeed: 600
    },
    overscroll: {
        durationRatio: 2,
        maxDuration: 1000,
        maxOffsetRatio: 0,
        maxWidth: 0,
        minMouseMove: 10,
        minSwipe: 80
    },
    autoScroll: {
        transitionOffset: 1000,
        durationRatio: 100,
        startTimeout: 600,
        customerSwipingTimeout: 3000
    },
    loop: {
        additionalSlidesCoefficient: 4
    }
};
function getAvailableOverscrollWidth(offsetWidth) {
    const width = offsetWidth * SWIPER_OPTIONS.overscroll.maxOffsetRatio;
    return width > SWIPER_OPTIONS.overscroll.maxWidth ? SWIPER_OPTIONS.overscroll.maxWidth : width;
}
export default defineComponent({
    name: 'VSwiperTranslate',
    props: {
        isAutoScroll: {
            type: Boolean
        },
        isScrollSnapEnabled: {
            type: Boolean
        },
        isBlock: {
            type: Boolean
        },
        isInfiniteLoop: {
            type: Boolean
        },
        isFullHeight: {
            type: Boolean
        },
        isOverflowVisible: {
            type: Boolean
        },
        outerPadding: {
            type: String
        },
        topMargin: {
            type: String
        },
        slideVisibilityRate: {
            type: Number
        },
        // TODO: find better union types for functional component
        // eslint-disable-next-line vue/require-prop-types
        wrapperClass: {},
        // eslint-disable-next-line vue/require-prop-types
        eventContainerClass: {}
    },
    emits: [
        'scroll',
        'mouseenter',
        'mouseleave'
    ],
    // eslint-disable-next-line sonarjs/cognitive-complexity
    setup (props, param) {
        let { emit, slots, expose } = param;
        const $style = useCssModule();
        const className = computed(()=>({
                swiper: true,
                [$style.swiper]: true,
                [$style['swiper--full-height']]: props.isFullHeight,
                [$style['swiper--block']]: props.isBlock,
                [$style['swiper--overflow-visible']]: props.isOverflowVisible,
                [$style[`swiper--outer-padding-${props.outerPadding}`]]: !!props.outerPadding,
                [$style[`swiper--top-margin-${props.topMargin}`]]: !!props.topMargin
            }));
        const swiperState = {
            isSwiping: ref(false),
            slidesCounter: ref(0),
            activeSlideIndex: ref(0),
            isPreviousButtonAllowed: ref(false),
            isNextButtonAllowed: ref(false)
        };
        const stateTranslateX = ref(0);
        const isMarginReached = ref(false);
        const swiperWrapperReference = ref();
        const swiperReference = ref();
        const prependSlidesCounter = ref(0);
        const appendSlidesCounter = ref(0);
        const isSlideToNextSlide = ref(false);
        const slideDragPosStart = ref(0);
        const { manualActiveSlide, manualChangeSlideActiveState } = useSwiperManualActiveSlide();
        let swipeInitData = null;
        let swipeCurrentData = null;
        let isTouchEventStarted = false;
        let overscrollCheckTimeout = 0;
        let isVerticalSwipe = false;
        let startAutoScrollTimeout = 0;
        let customerSwipingAutoScrollTimeout = 0;
        let isAutoScrollStarted = false;
        let isAutoScrollReversed = false;
        let isLoopInitialized = false;
        let isTransitionInProgress = false;
        let visibleSlideIndexes = [];
        let resizeObserver = null;
        let touchIdentifier = null;
        let defaultSlides = slots.default?.({
            manualChangeSlideActiveState
        }) ?? [];
        let prependSlides = preparePrependSlides();
        let appendSlides = prepareAppendSlides();
        function getWrapperProperties() {
            let offsetWidth = 0;
            let scrollWidth = 0;
            let left = 0;
            let paddingLeft = 0;
            let paddingRight = 0;
            if (swiperWrapperReference.value) {
                const boundingRect = swiperWrapperReference.value.getBoundingClientRect();
                offsetWidth = swiperWrapperReference.value.offsetWidth;
                scrollWidth = swiperWrapperReference.value.scrollWidth;
                left = boundingRect.left;
                const styles = window.getComputedStyle(swiperWrapperReference.value, null);
                paddingLeft = parseInt(styles.getPropertyValue('padding-left'), 10);
                paddingRight = parseInt(styles.getPropertyValue('padding-right'), 10);
            }
            return {
                minTranslateX: -scrollWidth + offsetWidth,
                offsetWidth,
                scrollWidth,
                left,
                paddingLeft,
                paddingRight
            };
        }
        // eslint-disable-next-line max-len, unicorn/consistent-function-scoping
        function cloneSwiperSlide(vNode, keySuffix, originalSlidesIndex) {
            return cloneVNode(vNode, {
                key: vNode.key ? `${String(vNode.key)}-${keySuffix}` : void 0,
                originalSlidesIndex
            });
        }
        // eslint-disable-next-line sonarjs/cognitive-complexity
        function preparePrependSlides() {
            const slides = [];
            if (props.isInfiniteLoop && prependSlidesCounter.value > 0) {
                let counter = prependSlidesCounter.value;
                let doLoop = true;
                let loopIndex = 0;
                const slideEntries = getSlides().reverse();
                if (slideEntries.length > 0) do {
                    for (const [index, slide] of slideEntries.entries()){
                        // eslint-disable-next-line max-len
                        slides.push(cloneSwiperSlide(slide, `prepend-${loopIndex}`, slideEntries.length - 1 - index));
                        counter -= 1;
                        if (0 === counter) {
                            doLoop = false;
                            break;
                        }
                    }
                    loopIndex += 1;
                }while (doLoop);
            }
            return slides.reverse();
        }
        // eslint-disable-next-line sonarjs/cognitive-complexity
        function prepareAppendSlides() {
            const slides = [];
            if (props.isInfiniteLoop && appendSlidesCounter.value > 0) {
                let counter = appendSlidesCounter.value;
                let doLoop = true;
                const slideEntries = getSlides();
                let loopIndex = 0;
                if (slideEntries.length > 0) do {
                    for (const [index, slide] of slideEntries.entries()){
                        slides.push(cloneSwiperSlide(slide, `append-${loopIndex}`, index));
                        counter -= 1;
                        if (0 === counter) {
                            doLoop = false;
                            break;
                        }
                    }
                    loopIndex += 1;
                }while (doLoop);
            }
            return slides;
        }
        function getCurrentTranslateX() {
            return swiperWrapperReference.value ? Math.round(new WebKitCSSMatrix(getComputedStyle(swiperWrapperReference.value).transform).m41) : 0;
        }
        function getSlides() {
            const slides = [];
            for (const slide of defaultSlides)if (isSwiperSlideVNode(slide)) slides.push(slide);
            else if (slide.children && Array.isArray(slide.children)) {
                for (const child of slide.children)if (isSwiperSlideVNode(child)) slides.push(child);
            }
            return slides;
        }
        function getAllSlides() {
            return [
                ...prependSlides,
                ...getSlides(),
                ...appendSlides
            ];
        }
        async function calculatePreviousNextButtons() {
            await nextTick();
            const { minTranslateX, scrollWidth, offsetWidth } = getWrapperProperties();
            const currentTranslateX = getCurrentTranslateX();
            const fixInaccuracy = process.env.VUE_APP_OS_WINDOWS ? 2 : 0;
            let isMarginReachedInternal = false;
            let isPreviousButtonAllowed = true;
            let isNextButtonAllowed = true;
            if (currentTranslateX >= 0) {
                isMarginReachedInternal = true;
                isPreviousButtonAllowed = false;
            }
            if (currentTranslateX <= minTranslateX + fixInaccuracy) {
                isMarginReachedInternal = true;
                isNextButtonAllowed = false;
            }
            if (scrollWidth <= offsetWidth) {
                isMarginReachedInternal = true;
                isPreviousButtonAllowed = false;
                isNextButtonAllowed = false;
            }
            isMarginReached.value = isMarginReachedInternal;
            swiperState.isPreviousButtonAllowed.value = isPreviousButtonAllowed;
            swiperState.isNextButtonAllowed.value = isNextButtonAllowed;
        }
        function isSlideInView(slide) {
            const scrollLeft = stateTranslateX.value > 0 ? 0 : Math.abs(stateTranslateX.value);
            const wrapperProperties = getWrapperProperties();
            return isSlideVisibleInContainer(slide.el, scrollLeft, wrapperProperties.offsetWidth, props.slideVisibilityRate);
        }
        function setActiveSlide() {
            let activeSlideIndex = -1;
            const slides = getAllSlides();
            for (const [index, slide] of slides.entries())if (isSlideInView(slide)) {
                activeSlideIndex = slide.props?.originalSlidesIndex === void 0 ? index - prependSlides.length : slide.props.originalSlidesIndex;
                break;
            }
            if (-1 === activeSlideIndex && slides.length > 0) {
                activeSlideIndex = 0;
                setTranslateX(0);
            }
            swiperState.activeSlideIndex.value = activeSlideIndex;
        }
        function getActiveSlideIndex() {
            return swiperState.activeSlideIndex.value;
        }
        function getNumberOfSlides() {
            return swiperState.slidesCounter.value;
        }
        function emitScroll() {
            if (!swiperWrapperReference.value) return;
            const { offsetWidth, scrollWidth } = swiperWrapperReference.value;
            const scrollLeft = stateTranslateX.value > 0 ? 0 : Math.abs(stateTranslateX.value);
            emit('scroll', {
                scrollLeft,
                offsetWidth,
                scrollWidth,
                activeSlide: getActiveSlideIndex()
            });
        }
        function checkOverScroll() {
            const { minTranslateX } = getWrapperProperties();
            let duration = 0;
            let newTranslateX = stateTranslateX.value;
            isSlideToNextSlide.value = false;
            if (stateTranslateX.value > 0) {
                duration = Math.abs(stateTranslateX.value);
                newTranslateX = 0;
            } else if (stateTranslateX.value < minTranslateX) {
                duration = Math.abs(minTranslateX - stateTranslateX.value);
                newTranslateX = minTranslateX;
            }
            duration *= SWIPER_OPTIONS.overscroll.durationRatio;
            if (duration > SWIPER_OPTIONS.overscroll.maxDuration) duration = SWIPER_OPTIONS.overscroll.maxDuration;
            if (newTranslateX !== stateTranslateX.value) setTranslateX(newTranslateX, duration);
        }
        function setSwiperWidth() {
            if (!swiperReference.value) return;
            swiperReference.value.style.width = '100%';
            const { offsetWidth } = swiperReference.value;
            if (offsetWidth % 2 !== 0) {
                const evenWidth = offsetWidth + 1;
                swiperReference.value.style.width = `${evenWidth}px`;
            }
        }
        function setTranslateX(translateX) {
            let duration = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0, withOverScroll = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
            if (!swiperWrapperReference.value) return;
            const { minTranslateX, offsetWidth } = getWrapperProperties();
            let newTranslateX = translateX;
            const maxTranslateX = withOverScroll ? getAvailableOverscrollWidth(offsetWidth) : 0;
            const minCalculatedTranslateX = minTranslateX - maxTranslateX;
            if (newTranslateX >= maxTranslateX) newTranslateX = maxTranslateX;
            else if (newTranslateX <= minCalculatedTranslateX) newTranslateX = minCalculatedTranslateX;
            if (stateTranslateX.value !== newTranslateX) {
                const newDuration = Math.round(duration);
                stateTranslateX.value = newTranslateX;
                swiperWrapperReference.value.style.transitionDuration = `${newDuration}ms`;
                swiperWrapperReference.value.style.transform = `translate3d(${newTranslateX}px, 0px, 0px)`;
                setActiveSlide();
                emitScroll();
                calculatePreviousNextButtons();
                if (newDuration > 0) overscrollCheckTimeout = Timer.setTimeout(()=>{
                    checkOverScroll();
                }, duration);
            }
        }
        function onTransitionRun(event) {
            if (event.target === swiperWrapperReference.value && 'transform' === event.propertyName && swiperWrapperReference.value) {
                isTransitionInProgress = true;
                updateSlidesVisibility();
            }
        }
        async function onTransitionEnd(event) {
            if (event.target === swiperWrapperReference.value && 'transform' === event.propertyName && swiperWrapperReference.value) {
                isTransitionInProgress = false;
                updateSlidesVisibility();
                if (props.isInfiniteLoop) {
                    await nextTick();
                    fixLoopTranslateX();
                }
            }
        }
        function setTranslateXOffset(offset) {
            let duration = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0, withOverScroll = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
            setTranslateX(stateTranslateX.value + offset, duration, withOverScroll);
        }
        function stopBounce() {
            if (overscrollCheckTimeout) {
                Timer.clearTimeout(overscrollCheckTimeout);
                overscrollCheckTimeout = 0;
            }
            if (getWrapperProperties().offsetWidth > 0) setTranslateX(getCurrentTranslateX());
        }
        function isAutoScrollEnabled() {
            return props.isAutoScroll && window.devicePixelRatio >= 2;
        }
        // eslint-disable-next-line sonarjs/cognitive-complexity
        function fixLoopTranslateX(direction) {
            const translateX = getCurrentTranslateX();
            const scrollLeft = translateX > 0 ? 0 : Math.abs(translateX);
            const wrapperProperties = getWrapperProperties();
            const wrapperRightMargin = scrollLeft + wrapperProperties.offsetWidth;
            const slides = getSlides();
            let newTranslateX;
            if (!direction || direction === VSwipeDirection.RIGHT) {
                for (const slide of prependSlides)if (slide.el) {
                    const { offsetWidth, offsetLeft } = slide.el;
                    const offsetRight = offsetLeft + offsetWidth;
                    if (offsetRight > scrollLeft && offsetRight <= wrapperRightMargin) {
                        if (slide.props?.originalSlidesIndex !== void 0) {
                            const originalSlide = slides[slide.props.originalSlidesIndex];
                            if (originalSlide?.el) {
                                const offset = offsetRight - scrollLeft;
                                newTranslateX = -originalSlide.el.offsetLeft - originalSlide.el.offsetWidth + offset;
                            }
                        }
                        break;
                    }
                }
            }
            if (void 0 === newTranslateX && (!direction || direction === VSwipeDirection.LEFT)) {
                for (const slide of appendSlides)if (slide.el) {
                    const { offsetLeft } = slide.el;
                    if (offsetLeft < wrapperRightMargin && offsetLeft >= scrollLeft) {
                        if (slide.props?.originalSlidesIndex !== void 0) {
                            const originalSlide = slides[slide.props.originalSlidesIndex];
                            if (originalSlide?.el) {
                                const offset = wrapperRightMargin - offsetLeft;
                                newTranslateX = -originalSlide.el.offsetLeft + wrapperProperties.offsetWidth - offset;
                            }
                        }
                        break;
                    }
                }
            }
            if (void 0 !== newTranslateX) setTranslateX(newTranslateX);
        }
        async function startAutoScroll() {
            stopAutoScroll();
            if (isAutoScrollEnabled()) {
                if (props.isInfiniteLoop) {
                    await nextTick();
                    fixLoopTranslateX();
                }
                swiperWrapperReference.value;
                isAutoScrollStarted = true;
                swiperWrapperReference.value.style.transitionTimingFunction = 'linear';
                const { minTranslateX } = getWrapperProperties();
                let { transitionOffset } = SWIPER_OPTIONS.autoScroll;
                if (0 === minTranslateX) return;
                transitionOffset = isAutoScrollReversed ? transitionOffset : -transitionOffset;
                if (stateTranslateX.value + transitionOffset <= minTranslateX) transitionOffset = minTranslateX - stateTranslateX.value;
                else if (stateTranslateX.value + transitionOffset >= 0) transitionOffset = -stateTranslateX.value;
                if (0 === transitionOffset) {
                    startAutoScroll();
                    return;
                }
                const duration = Math.abs(transitionOffset) * SWIPER_OPTIONS.autoScroll.durationRatio;
                setTranslateXOffset(transitionOffset, duration, false);
                swiperWrapperReference.value.addEventListener('transitionend', onAutoScrollTransitionEnd);
            }
        }
        function onAutoScrollTransitionEnd(event) {
            if (event.target === swiperWrapperReference.value && 'transform' === event.propertyName && isAutoScrollStarted) startAutoScroll();
        }
        function stopAutoScroll() {
            if (startAutoScrollTimeout) {
                Timer.clearTimeout(startAutoScrollTimeout);
                startAutoScrollTimeout = 0;
            }
            if (customerSwipingAutoScrollTimeout) {
                Timer.clearTimeout(customerSwipingAutoScrollTimeout);
                customerSwipingAutoScrollTimeout = 0;
            }
            if (isAutoScrollStarted) {
                const { minTranslateX, offsetWidth } = getWrapperProperties();
                const currentTranslateX = getCurrentTranslateX();
                if (!props.isInfiniteLoop) {
                    if (currentTranslateX <= minTranslateX) isAutoScrollReversed = true;
                    else if (currentTranslateX >= 0) isAutoScrollReversed = false;
                }
                if (offsetWidth > 0) setTranslateX(currentTranslateX, 0, false);
                swiperWrapperReference.value;
                swiperWrapperReference.value.style.transitionTimingFunction = '';
                isAutoScrollStarted = false;
                swiperWrapperReference.value.removeEventListener('transitionend', onAutoScrollTransitionEnd);
            }
        }
        function customerSwiping() {
            stopAutoScroll();
            if (isAutoScrollEnabled()) customerSwipingAutoScrollTimeout = Timer.setTimeout(()=>{
                startAutoScroll();
            }, SWIPER_OPTIONS.autoScroll.customerSwipingTimeout);
        }
        function updateSwipeData(clientX, clientY, timeStamp) {
            if (isVerticalSwipe) return;
            let direction = swipeInitData?.direction;
            const currentClientX = swipeCurrentData?.clientX || null;
            if (swipeCurrentData && clientX !== currentClientX) direction = clientX > (currentClientX || 0) ? VSwipeDirection.RIGHT : VSwipeDirection.LEFT;
            if (swipeInitData && !swipeInitData.direction) swipeInitData.direction = direction;
            const directionChanged = swipeInitData?.direction !== direction;
            const swipeData = {
                clientY,
                clientX,
                timeStamp,
                direction
            };
            if (!swipeInitData || directionChanged) swipeInitData = swipeData;
            swipeCurrentData = swipeData;
            if (null !== currentClientX && clientX !== currentClientX && swiperState.isSwiping.value) {
                customerSwiping();
                setTranslateXOffset(swipeCurrentData.clientX - currentClientX);
                updateSlidesVisibility();
                fixLoopTranslateX(swipeData.direction);
            }
        }
        function setTouchData(event) {
            const { timeStamp, changedTouches } = event;
            if (!changedTouches.length) return;
            const { clientX, clientY } = changedTouches[0];
            updateSwipeData(clientX, clientY, timeStamp);
        }
        function isCurrentTouch(event) {
            const { changedTouches } = event;
            if (!changedTouches.length || !changedTouches[0] || changedTouches[0].identifier !== touchIdentifier) {
                if (event.cancelable) event.preventDefault();
                return false;
            }
            return true;
        }
        function onTouchStart(event) {
            if (null !== touchIdentifier) {
                event.preventDefault();
                return;
            }
            touchIdentifier = event.changedTouches[0].identifier;
            isTouchEventStarted = true;
            stopBounce();
            setTouchData(event);
        }
        function onTouchMove(event) {
            if (!isCurrentTouch(event)) return;
            if (swiperState.isSwiping.value) {
                if (event.cancelable) event.preventDefault();
            } else if (swipeInitData) {
                const { changedTouches } = event;
                const { clientX, clientY } = changedTouches[0];
                const absX = Math.abs(clientX - swipeInitData.clientX);
                const absY = Math.abs(clientY - swipeInitData.clientY);
                if (absY > absX) isVerticalSwipe = true;
                else swiperState.isSwiping.value = true;
            }
            setTouchData(event);
        }
        function setMouseData(event) {
            updateSwipeData(event.clientX, event.clientY, event.timeStamp);
        }
        // eslint-disable-next-line sonarjs/cognitive-complexity
        function getNewOffsetForSnap(offset, currentTranslateX) {
            let newOffset = offset;
            const translateX = currentTranslateX ?? stateTranslateX.value;
            const slideGap = getSlidesGap();
            const { minTranslateX } = getWrapperProperties();
            const newTranslateX = translateX + offset;
            if (!isAutoScrollStarted && props.isScrollSnapEnabled && translateX > minTranslateX) {
                const scrollLeft = -newTranslateX;
                const slides = getAllSlides();
                for (const [index, slide] of slides.entries())if (slide.el) {
                    const { offsetLeft, offsetWidth } = slide.el;
                    const slideWidth = offsetWidth + (index === slides.length - 1 ? 0 : slideGap);
                    if (scrollLeft >= offsetLeft && scrollLeft < offsetLeft + slideWidth / 2) {
                        newOffset = -offsetLeft - translateX;
                        break;
                    }
                    if (scrollLeft >= offsetLeft + slideWidth / 2 && scrollLeft < offsetLeft + slideWidth) {
                        newOffset = -offsetLeft - slideWidth - translateX;
                        break;
                    }
                }
            }
            return newOffset;
        }
        function getSlidesGap() {
            if (!swiperWrapperReference.value) return 0;
            const { gap } = getComputedStyle(swiperWrapperReference.value);
            const numericValue = parseFloat(gap);
            return Number.isNaN(numericValue) ? 0 : numericValue;
        }
        function getNextSlide() {
            let scrollLeft = getCurrentTranslateX();
            scrollLeft = scrollLeft > 0 ? 0 : Math.abs(scrollLeft);
            for (const item of getSlides())if (item.el) {
                const { offsetLeft } = item.el;
                if (offsetLeft >= scrollLeft) return item;
            }
        }
        function getCurrentSlide() {
            let scrollLeft = getCurrentTranslateX();
            scrollLeft = scrollLeft > 0 ? 0 : Math.abs(scrollLeft);
            for (const item of getSlides())if (item.el) {
                const { offsetLeft, offsetWidth } = item.el;
                if (offsetLeft + offsetWidth >= scrollLeft) return item;
            }
        }
        function checkSnap(translate) {
            const snapTranslateXOffset = getNewOffsetForSnap(0);
            if (0 !== snapTranslateXOffset || translate) {
                const duration = props.isBlock ? 200 : 100;
                setTranslateX(stateTranslateX.value + (translate ?? snapTranslateXOffset), duration);
            }
        }
        // eslint-disable-next-line sonarjs/cognitive-complexity
        function doBounce(swipeVelocity, direction) {
            if (isMarginReached.value) {
                stateTranslateX.value = getCurrentTranslateX();
                return;
            }
            if (swipeVelocity >= SWIPER_OPTIONS.bounce.minVelocity && Math.abs(stateTranslateX.value) >= SWIPER_OPTIONS.overscroll.minSwipe) {
                const wrapperProperties = getWrapperProperties();
                const { offsetWidth, minTranslateX } = wrapperProperties;
                const maxTranslateX = getAvailableOverscrollWidth(offsetWidth);
                let momentumDuration = SWIPER_OPTIONS.bounce.momentumRatio;
                let maxDuration = momentumDuration;
                const velocity = swipeVelocity * SWIPER_OPTIONS.bounce.momentumVelocityRatio;
                let offset = velocity * momentumDuration;
                offset = direction === VSwipeDirection.LEFT ? -offset : offset;
                offset = getNewOffsetForSnap(offset);
                if (props.isBlock) {
                    if (direction === VSwipeDirection.LEFT) {
                        const slide = getNextSlide();
                        if (slide?.el) {
                            offset = -slide.el.offsetLeft - stateTranslateX.value;
                            maxDuration = 800;
                        }
                    } else {
                        const slide = getCurrentSlide();
                        if (slide?.el) {
                            offset = -slide.el.offsetLeft - stateTranslateX.value;
                            maxDuration = 800;
                        }
                    }
                }
                const minOverscrollTranslateX = minTranslateX - maxTranslateX;
                if (stateTranslateX.value + offset < minOverscrollTranslateX) offset = minOverscrollTranslateX - stateTranslateX.value;
                else if (stateTranslateX.value + offset > maxTranslateX) offset = maxTranslateX - stateTranslateX.value;
                momentumDuration = Math.abs(offset / velocity) * SWIPER_OPTIONS.bounce.durationRatio;
                if (momentumDuration > maxDuration) momentumDuration = maxDuration;
                setTranslateXOffset(offset, momentumDuration);
            } else {
                const offset = slideDragPosStart.value - (swipeCurrentData?.clientX || 0);
                setTranslateXOffset(offset, SWIPER_OPTIONS.autoScroll.durationRatio, false);
            }
        }
        function onSwiped(event) {
            if (swiperState.isSwiping.value) {
                if ('touches' in event) setTouchData(event);
                else setMouseData(event);
                checkOverScroll();
                customerSwiping();
                const velocity = Math.abs(swipeCurrentData.clientX - swipeInitData.clientX) / Math.abs(swipeCurrentData.timeStamp - swipeInitData.timeStamp);
                const { direction } = swipeCurrentData;
                doBounce(velocity, direction);
            }
            swipeInitData = null;
            swipeCurrentData = null;
            isVerticalSwipe = false;
            swiperState.isSwiping.value = false;
        }
        function onTouchEnd(event) {
            if (!isCurrentTouch(event) && event.touches.length > 0) return;
            isTouchEventStarted = false;
            touchIdentifier = null;
            onSwiped(event);
        }
        function getSlidesVisibilityLimits() {
            const currentTranslateX = getCurrentTranslateX();
            const newTranslateX = stateTranslateX.value;
            const { offsetWidth } = getWrapperProperties();
            const { offsetRatio } = SWIPER_OPTIONS.visibility;
            const offset = offsetWidth * offsetRatio;
            const fromTranslateX = currentTranslateX > 0 ? 0 : Math.abs(currentTranslateX);
            const toTranslateX = newTranslateX > 0 ? 0 : Math.abs(newTranslateX);
            return {
                min: (toTranslateX > fromTranslateX ? fromTranslateX : toTranslateX) - offset,
                max: (toTranslateX > fromTranslateX ? toTranslateX : fromTranslateX) + offsetWidth + offset
            };
        }
        function setVisibleLoopSlides() {
            for (const [index, slide] of getSlides().entries())slide.component?.exposed?.setVisibility?.(visibleSlideIndexes.includes(index));
            for (const slide of [
                ...prependSlides,
                ...appendSlides
            ]){
                const index = slide.props?.originalSlidesIndex === void 0 ? -1 : slide.props?.originalSlidesIndex;
                slide.component?.exposed?.setVisibility?.(visibleSlideIndexes.includes(index));
            }
        }
        function updateSlidesVisibility() {
            let limits = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : null;
            calculatePreviousNextButtons();
            const newLimits = limits ?? getSlidesVisibilityLimits();
            const slides = getSlides();
            const visibleSlideIndexesInner = [];
            for (const [index, slide] of slides.entries()){
                const isVisible = shouldSlideBeVisible(newLimits, slide);
                if (isVisible) visibleSlideIndexesInner.push(index);
            }
            for (const slide of [
                ...prependSlides,
                ...appendSlides
            ]){
                const isVisible = shouldSlideBeVisible(newLimits, slide);
                if (isVisible) visibleSlideIndexesInner.push(slide.props?.originalSlidesIndex || 0);
            }
            visibleSlideIndexes = visibleSlideIndexesInner;
            setVisibleLoopSlides();
        }
        // eslint-disable-next-line sonarjs/cognitive-complexity
        function getLoopSlidesCounter(slides) {
            const { offsetWidth } = getWrapperProperties();
            let doLoop = true;
            let counter = 0;
            let calculatedWidth = 0;
            if (slides.length > 0) do for (const slide of slides.reverse())if (slide.el) {
                const { offsetWidth: slideOffsetWidth } = slide.el;
                if (calculatedWidth >= offsetWidth * SWIPER_OPTIONS.loop.additionalSlidesCoefficient) {
                    doLoop = false;
                    break;
                }
                calculatedWidth += slideOffsetWidth;
                counter += 1;
            } else doLoop = false;
            while (doLoop);
            return counter;
        }
        async function calculateActiveLoopSlides() {
            let slides = getSlides();
            const { offsetWidth, scrollWidth, paddingLeft } = getWrapperProperties();
            if (offsetWidth < scrollWidth) {
                prependSlidesCounter.value = getLoopSlidesCounter(slides.reverse());
                appendSlidesCounter.value = getLoopSlidesCounter(slides);
                await nextTick();
                slides = getSlides();
                if (!isLoopInitialized && slides.length > 0 && slides[0].el && slides[0].el.offsetLeft > paddingLeft) {
                    setTranslateX(-slides[0].el.offsetLeft + paddingLeft);
                    isLoopInitialized = true;
                }
            } else {
                appendSlidesCounter.value = 0;
                prependSlidesCounter.value = 0;
            }
        }
        function initLoopInner() {
            const slides = getSlides();
            const { offsetWidth, scrollWidth } = getWrapperProperties();
            if (slides.length > 1 && props.isInfiniteLoop && offsetWidth < scrollWidth) {
                if (!isTransitionInProgress) calculateActiveLoopSlides();
            } else isLoopInitialized = false;
        }
        const initLoop = useDebounce(initLoopInner, 200);
        function onSlideToggleInner() {
            updateSlidesVisibility();
            if (!isLoopInitialized) initLoop();
            setActiveSlide();
            swiperState.slidesCounter.value = getSlides().length;
        }
        const onSlideToggle = useDebounce(onSlideToggleInner, 50);
        const onWheelStop = useDebounce((event)=>{
            if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) checkSnap();
        }, 75);
        function onWheel(event) {
            if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
                if (event.cancelable) event.preventDefault();
                customerSwiping();
                setTranslateXOffset(-event.deltaX, 0, false);
                fixLoopTranslateX(event.deltaX > 0 ? VSwipeDirection.LEFT : VSwipeDirection.RIGHT);
                updateSlidesVisibility();
            }
            onWheelStop(event);
        }
        function getSlideOffsetLeft(element) {
            const { left, paddingLeft } = getWrapperProperties();
            return element.getBoundingClientRect().left - left - paddingLeft;
        }
        function getSlideTranslateToRight(element) {
            const slideGap = getSlidesGap();
            return getCurrentTranslateX() - element.getBoundingClientRect().width - slideGap;
        }
        function getSlideTranslateToLeft(element) {
            const slideGap = getSlidesGap();
            return getCurrentTranslateX() + element.getBoundingClientRect().width + slideGap;
        }
        function onMouseMove(event) {
            if (Math.abs(event.clientX - (swipeInitData?.clientX || 0)) > SWIPER_OPTIONS.overscroll.minMouseMove) {
                swiperState.isSwiping.value = true;
                setMouseData(event);
            }
        }
        function onMouseUp(event) {
            onSwiped(event);
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        }
        function onMouseDown(event) {
            if (!isSlideToNextSlide.value) {
                slideDragPosStart.value = event.clientX;
                stopBounce();
                setMouseData(event);
                window.addEventListener('mousemove', onMouseMove);
                window.addEventListener('mouseup', onMouseUp);
            }
        }
        // eslint-disable-next-line sonarjs/cognitive-complexity
        function slideToNextSlide() {
            let indexOffset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : props.isBlock ? 1 : 2;
            if (!isSlideToNextSlide.value) {
                isSlideToNextSlide.value = true;
                const slides = getAllSlides();
                for (const [index, slide] of slides.entries()){
                    const nextIndex = index + indexOffset;
                    if (isSlideInView(slide)) {
                        const nextSlide = slides[nextIndex];
                        if (nextSlide && nextSlide.el) {
                            customerSwiping();
                            if (props.isBlock) {
                                setTranslateX(-getSlideOffsetLeft(nextSlide.el), SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
                                return;
                            }
                            setTranslateX(getSlideTranslateToRight(nextSlide.el), SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
                            return;
                        }
                        break;
                    }
                }
                setTranslateX(0, SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
            }
        }
        function slideToNextVisibleSlide() {
            if (props.isBlock) {
                slideToNextSlide(1);
                return;
            }
            let offset = 0;
            let slideFound = false;
            for (const slide of getAllSlides())if (slide.el) {
                if (isSlideInView(slide)) {
                    slideFound = true;
                    offset = getSlideOffsetLeft(slide.el);
                } else if (slideFound) break;
            }
            customerSwiping();
            setTranslateX(-offset, SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
        }
        // eslint-disable-next-line sonarjs/cognitive-complexity
        function slideToPreviousSlide() {
            let indexOffset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 1;
            if (!isSlideToNextSlide.value) {
                isSlideToNextSlide.value = true;
                const slides = getAllSlides();
                for (const [index, slide] of slides.entries())if (isSlideInView(slide)) {
                    const previousIndex = index - indexOffset;
                    const previousSlide = slides[previousIndex];
                    if (previousSlide && previousSlide.el) {
                        customerSwiping();
                        if (props.isBlock) {
                            setTranslateX(-getSlideOffsetLeft(previousSlide.el), SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
                            return;
                        }
                        setTranslateX(getSlideTranslateToLeft(previousSlide.el), SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
                        return;
                    }
                    break;
                }
                setTranslateX(0, SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
            }
        }
        function slideToPreviousVisibleSlide() {
            if (props.isBlock) {
                slideToPreviousSlide(1);
                return;
            }
            let offset = 0;
            for (const slide of getAllSlides())if (slide.el && isSlideInView(slide)) {
                const { offsetWidth, paddingLeft, paddingRight } = getWrapperProperties();
                offset = getSlideOffsetLeft(slide.el) - offsetWidth + paddingLeft + paddingRight + slide.el.offsetWidth;
                break;
            }
            customerSwiping();
            setTranslateX(-offset, SWIPER_OPTIONS.navigation.visibleSlideSpeed, false);
        }
        function slideToSlide(index, options) {
            const slide = getSlides()[index];
            if (slide && slide.el) {
                customerSwiping();
                const { offsetLeft, offsetWidth: elementOffsetWidth } = slide.el;
                let offset = -offsetLeft;
                if (options?.isCentered) {
                    const { offsetWidth } = getWrapperProperties();
                    if (offsetWidth > elementOffsetWidth) offset += (offsetWidth - elementOffsetWidth) / 2;
                    if (props.isScrollSnapEnabled) offset += getNewOffsetForSnap(0, offset);
                }
                setTranslateX(offset, options?.smooth ? options?.speed ?? SWIPER_OPTIONS.navigation.visibleSlideSpeed : 0, false);
                if (!options?.smooth) updateSlidesVisibility();
            }
        }
        function fixTranslateX() {
            const { minTranslateX } = getWrapperProperties();
            if (minTranslateX > stateTranslateX.value) {
                setTranslateX(minTranslateX);
                updateSlidesVisibility();
            }
        }
        function initResizeObserver() {
            resizeObserver = new MutationObserver(()=>{
                if (stateTranslateX.value === getCurrentTranslateX() && !swiperState.isSwiping.value && !isTouchEventStarted) {
                    checkSnap();
                    fixTranslateX();
                }
            });
            if (swiperWrapperReference.value) resizeObserver.observe(swiperWrapperReference.value, {
                childList: true
            });
        }
        function onWindowResize(param) {
            let { deltaX } = param;
            if (Math.abs(deltaX) > 0) {
                if (props.isBlock) slideToSlide(swiperState.activeSlideIndex.value);
                else {
                    const { offsetWidth, scrollWidth } = getWrapperProperties();
                    setTranslateX(scrollWidth > offsetWidth ? getCurrentTranslateX() : 0);
                }
                setSwiperWidth();
                updateSlidesVisibility();
            }
        }
        function disconnectResizeObserver() {
            if (resizeObserver) {
                resizeObserver.disconnect();
                resizeObserver = null;
            }
        }
        function onComponentActivated() {
            initResizeObserver();
        }
        function onComponentDeactivated() {
            stopAutoScroll();
            stopBounce();
            disconnectResizeObserver();
        }
        function resetScrollPosition() {
            if (props.isInfiniteLoop) {
                isLoopInitialized = false;
                initLoop();
            } else setTranslateX(0);
            updateSlidesVisibility();
        }
        function visibilityChanged(value) {
            if (!isLoopInitialized) initLoop();
            calculatePreviousNextButtons();
            if (value) startAutoScrollTimeout = Timer.setTimeout(startAutoScroll, SWIPER_OPTIONS.autoScroll.startTimeout);
            else stopAutoScroll();
        }
        useWindowResize(onWindowResize);
        onMounted(onComponentActivated);
        onActivated(()=>{
            onComponentActivated();
            if (0 === stateTranslateX.value) resetScrollPosition();
        });
        onBeforeUnmount(onComponentDeactivated);
        onDeactivated(onComponentDeactivated);
        provide(VSwiperProvidableKeys.State, swiperState);
        provide(VSwiperProvidableKeys.OnSlideToggle, onSlideToggle);
        provide(VSwiperProvidableKeys.SlideToNextSlide, slideToNextSlide);
        provide(VSwiperProvidableKeys.SlideToNextVisibleSlide, slideToNextVisibleSlide);
        provide(VSwiperProvidableKeys.SlideToPreviousSlide, slideToPreviousSlide);
        provide(VSwiperProvidableKeys.SlideToPreviousVisibleSlide, slideToPreviousVisibleSlide);
        provide(VSwiperProvidableKeys.SlideToSlide, slideToSlide);
        watch(()=>props.isInfiniteLoop, async (newValue)=>{
            await nextTick();
            appendSlidesCounter.value = 0;
            prependSlidesCounter.value = 0;
            isLoopInitialized = false;
            await nextTick();
            if (newValue) initLoop();
        });
        watch(()=>props.isAutoScroll, async (newValue)=>{
            stopAutoScroll();
            setTranslateX(0);
            if (newValue) {
                await nextTick();
                startAutoScroll();
            }
        });
        expose({
            resetScrollPosition,
            slideToSlide,
            slideToNextSlide,
            slideToPreviousSlide,
            getActiveSlideIndex,
            getNumberOfSlides
        });
        const listeners = {
            onTransitionrun: onTransitionRun,
            onTransitionend: onTransitionEnd
        };
        return ()=>{
            defaultSlides = slots.default?.({
                manualChangeSlideActiveState
            }) ?? [];
            prependSlides = preparePrependSlides();
            appendSlides = prepareAppendSlides();
            return withDirectives(h('div', {
                class: className.value,
                ref: swiperReference,
                onMouseenter: ()=>{
                    emit('mouseenter');
                },
                onMouseleave: ()=>{
                    emit('mouseleave');
                }
            }, [
                ...slots['pagination-header']?.() ?? [],
                h('div', {
                    class: [
                        $style['swiper__event-container'],
                        normalizeClass(props.eventContainerClass) ?? ''
                    ].join(' '),
                    onWheel,
                    onTouchstart: onTouchStart,
                    onTouchmove: onTouchMove,
                    onTouchend: onTouchEnd,
                    onMousedown: onMouseDown
                }, [
                    h('div', {
                        class: [
                            $style.swiper__wrapper,
                            normalizeClass(props.wrapperClass) ?? ''
                        ].join(' '),
                        ref: swiperWrapperReference,
                        ...listeners
                    }, [
                        ...prependSlides,
                        ...defaultSlides,
                        ...appendSlides
                    ])
                ]),
                ...slots['pagination-footer']?.({
                    manualActiveSlide: manualActiveSlide.value
                }) ?? []
            ]), [
                [
                    ObserveVisibility,
                    {
                        callback: visibilityChanged,
                        intersection: {
                            threshold: 0.5
                        }
                    }
                ]
            ]);
        };
    }
});
