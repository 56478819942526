// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .lobby-egs-no-games_df3fs{max-width:375px;padding:32px;margin:0 auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-egs-no-games": `lobby-egs-no-games_df3fs`
};
export default ___CSS_LOADER_EXPORT___;
