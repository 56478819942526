// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-wrapper_hkEHy{position:absolute;z-index:2}.pagination-wrapper-center_SmXbj{bottom:0;left:50%;width:100%;transform:translateX(-50%)}.pagination-wrapper-right_cWwyH{right:8px;bottom:8px;left:auto}.pagination-wrapper-limited_e92VA{max-width:60px;overflow-x:auto;scrollbar-width:none}.pagination_NUoCH{display:flex;gap:0;justify-content:center;width:100%}.pagination--full_WhRJm{position:static;bottom:0}.pagination__item_JvhE9{padding:6px;cursor:pointer}.pagination__item_JvhE9:before{display:block;width:8px;height:8px;content:"";background-color:#ffffff80;border:none;border-radius:50%}.pagination__item--selected_GjFFf:before{width:8px;background-color:var(--White);border-radius:50%}.pagination__button_iSO2f{position:absolute;width:50%;height:100%}.pagination__button--left_g0Rdv{left:0}.pagination__button--right_WiFCx{right:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination-wrapper": `pagination-wrapper_hkEHy`,
	"pagination-wrapper-center": `pagination-wrapper-center_SmXbj`,
	"pagination-wrapper-right": `pagination-wrapper-right_cWwyH`,
	"pagination-wrapper-limited": `pagination-wrapper-limited_e92VA`,
	"pagination": `pagination_NUoCH`,
	"pagination--full": `pagination--full_WhRJm`,
	"pagination__item": `pagination__item_JvhE9`,
	"pagination__item--selected": `pagination__item--selected_GjFFf`,
	"pagination__button": `pagination__button_iSO2f`,
	"pagination__button--left": `pagination__button--left_g0Rdv`,
	"pagination__button--right": `pagination__button--right_WiFCx`
};
export default ___CSS_LOADER_EXPORT___;
