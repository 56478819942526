// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-slide_HoRIz{position:relative;flex-shrink:0;transform:translateZ(0);-webkit-backface-visibility:hidden;backface-visibility:hidden}.swiper-slide_HoRIz a,.swiper-slide_HoRIz div,.swiper-slide_HoRIz img{-webkit-user-select:none;user-select:none;-webkit-user-drag:none}.swiper-slide--hidden_fubFe{visibility:hidden}.swiper-slide--full-width_wS8tQ{width:100%}.swiper-slide__overflow_mqAMU{z-index:10;position:absolute;top:0;right:0;bottom:0;left:0;background-color:#0000}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper-slide": `swiper-slide_HoRIz`,
	"swiper-slide--hidden": `swiper-slide--hidden_fubFe`,
	"swiper-slide--full-width": `swiper-slide--full-width_wS8tQ`,
	"swiper-slide__overflow": `swiper-slide__overflow_mqAMU`
};
export default ___CSS_LOADER_EXPORT___;
