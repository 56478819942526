// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-loyalty-program-balance_Be5MJ{display:inline-flex;column-gap:8px;align-items:center;padding:6px 8px;background-color:var(--Layer0);border-radius:20px}@media(hover:hover)and (pointer:fine){.bonus-loyalty-program-balance_Be5MJ:not(.bonus-loyalty-program-balance--no-hover_RFEDO):hover{cursor:pointer;background-color:var(--OpacityLayer2)}}.bonus-loyalty-program-balance__icon_i1yFy{fill:var(--BrandDefault)}.bonus-loyalty-program-balance__text_J5y0P{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextDefault)}.bonus-loyalty-program-balance--inactive_QsygW .bonus-loyalty-program-balance__icon_i1yFy{fill:var(--TextSecondary)}.bonus-loyalty-program-balance--inactive_QsygW .bonus-loyalty-program-balance__text_J5y0P{color:var(--TextSecondary)}.bonus-loyalty-program-balance--secondary_IRKwt{background-color:var(--OpacityLayer1)}.bonus-loyalty-program-balance--has-icon_Ki0FI{padding-right:10px}.bonus-loyalty-program-balance--is-small_l2E5a{column-gap:2px;padding:2px 6px 2px 4px}.bonus-loyalty-program-balance--is-small_l2E5a .bonus-loyalty-program-balance__text_J5y0P{font-size:11px;font-weight:500;line-height:16px;letter-spacing:-.2px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-loyalty-program-balance": `bonus-loyalty-program-balance_Be5MJ`,
	"bonus-loyalty-program-balance--no-hover": `bonus-loyalty-program-balance--no-hover_RFEDO`,
	"bonus-loyalty-program-balance__icon": `bonus-loyalty-program-balance__icon_i1yFy`,
	"bonus-loyalty-program-balance__text": `bonus-loyalty-program-balance__text_J5y0P`,
	"bonus-loyalty-program-balance--inactive": `bonus-loyalty-program-balance--inactive_QsygW`,
	"bonus-loyalty-program-balance--secondary": `bonus-loyalty-program-balance--secondary_IRKwt`,
	"bonus-loyalty-program-balance--has-icon": `bonus-loyalty-program-balance--has-icon_Ki0FI`,
	"bonus-loyalty-program-balance--is-small": `bonus-loyalty-program-balance--is-small_l2E5a`
};
export default ___CSS_LOADER_EXPORT___;
