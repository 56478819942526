// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popper__reference_oJs7D{cursor:pointer}.popper__content_X5kBj{z-index:200}.popper__content--full-width_r2b0a{width:100%}.popper__content--full-height_XpWa0{height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popper__reference": `popper__reference_oJs7D`,
	"popper__content": `popper__content_X5kBj`,
	"popper__content--full-width": `popper__content--full-width_r2b0a`,
	"popper__content--full-height": `popper__content--full-height_XpWa0`
};
export default ___CSS_LOADER_EXPORT___;
