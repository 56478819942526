import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: /*@__PURE__*/ _normalizeClass([
        'landing-brand'
    ])
};
import VBrand from 'web/src/components/Brand/VBrand/VBrand.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingBrand',
    props: {
        isDark: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(VBrand, {
                    class: _normalizeClass(_ctx.$style['landing-brand__image']),
                    "is-dark": _ctx.isDark
                }, null, 8, [
                    "class",
                    "is-dark"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'LandingBrand'
                ]
            ]);
        };
    }
});
