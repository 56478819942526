import { computed } from 'vue';
import { applyLimitationsTo, getMaxDate, getMinDate } from 'web/src/components/Input/utils';
export function useVDatepicker(props, emit) {
    const { min, max, roundTo } = props;
    const minDateTimestamp = computed(()=>getMinDate(min.value));
    const minDate = computed(()=>minDateTimestamp.value ? new Date(minDateTimestamp.value) : void 0);
    const maxDateTimestamp = computed(()=>getMaxDate(max.value));
    const maxDate = computed(()=>maxDateTimestamp.value ? new Date(maxDateTimestamp.value) : void 0);
    function onChange(value) {
        const timestamp = applyLimitationsTo(+value, minDateTimestamp.value, maxDateTimestamp.value, roundTo.value);
        emit('change', {
            timestamp
        });
    }
    return {
        minDate,
        maxDate,
        onChange
    };
}
