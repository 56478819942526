import { isString } from '@leon-hub/guards';
import { MarketGroupStyleType } from 'web/src/modules/sportline/enums';
function isMarketGroupStyleType(value) {
    return isString(value) && Object.values(MarketGroupStyleType).includes(value);
}
function getMarketGroupStyle(style) {
    return style?.type ? {
        type: isMarketGroupStyleType(style.type) ? style.type : MarketGroupStyleType.DEFAULT
    } : void 0;
}
export function createMarketGroupSpecifiers(group) {
    const id = group.specifier?.specifier;
    return id ? {
        id
    } : void 0;
}
export function createMarketGroup(group) {
    return {
        id: String(group.id),
        name: group.name,
        marketTypeIds: group.marketTypeIds.map(String),
        style: getMarketGroupStyle(group?.style),
        specifier: createMarketGroupSpecifiers(group)
    };
}
export function createMarketGroups(marketGroups) {
    return marketGroups.map(createMarketGroup);
}
