import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives, renderSlot as _renderSlot, createSlots as _createSlots } from "vue";
import { normalizeClass } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VBadge from 'web/src/components/Badge/VBadge/VBadge.vue';
import VTabsButton from 'web/src/components/Tabs/VTabsButton/VTabsButton.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import { TabsType } from './enums';
import { useVTabs } from './composables';
import useSwiperToActiveScroll from '../../Swiper/VSwiper/composables/useSwiperToActiveScroll';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VTabs',
    props: {
        swiperWrapperClass: {},
        isNavigation: {
            type: Boolean
        },
        items: {},
        activeId: {},
        fullWidth: {
            type: Boolean
        },
        type: {
            default: TabsType.NORMAL
        },
        badgeNumber: {
            default: 1
        },
        withGap: {
            type: Boolean
        },
        sTabButtonKind: {},
        single: {
            type: Boolean
        }
    },
    emits: [
        "tab-click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { activeTabIndex, isIconTabs, itemsList, containerRole } = useVTabs(props);
        const { swiper, slideToActive, scrollToSlide } = useSwiperToActiveScroll(activeTabIndex);
        __expose({
            slideToActive,
            scrollToSlide
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VSwiper), {
                ref_key: "swiper",
                ref: swiper,
                class: _normalizeClass({
                    [_ctx.$style['tabs--with-navigation']]: !!_ctx.$slots.button
                }),
                "wrapper-class": _ctx.swiperWrapperClass ? normalizeClass(props.swiperWrapperClass) : {
                    [_ctx.$style['tabs__wrapper']]: true,
                    [_ctx.$style['tabs__wrapper--with-gap']]: _ctx.withGap
                },
                role: _unref(containerRole)
            }, _createSlots({
                default: _withCtx(()=>[
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(itemsList), (param)=>{
                            let { badge, icon, label, active, isPromotionsHighlighted, ...buttonProps } = param;
                            return _openBlock(), _createBlock(VSwiperSlide, {
                                key: buttonProps.id,
                                class: _normalizeClass({
                                    [_ctx.$style['tabs__slide--full-width']]: _ctx.fullWidth
                                })
                            }, {
                                default: _withCtx(()=>[
                                        _withDirectives((_openBlock(), _createBlock(VTabsButton, _mergeProps({
                                            ref_for: true
                                        }, buttonProps, {
                                            class: {
                                                [_ctx.$style['tabs__slide--highlighted']]: isPromotionsHighlighted
                                            },
                                            type: _ctx.type,
                                            active: active,
                                            flexible: _ctx.fullWidth,
                                            "s-tab-button-kind": _ctx.sTabButtonKind,
                                            single: _ctx.single,
                                            onClick: _cache[0] || (_cache[0] = ($event)=>emit('tab-click', $event))
                                        }), {
                                            default: _withCtx(()=>[
                                                    isPromotionsHighlighted ? (_openBlock(), _createElementBlock(_Fragment, {
                                                        key: 0
                                                    }, [
                                                        _createVNode(_unref(VIcon), {
                                                            name: _unref(IconName).PROMOS,
                                                            size: _unref(IconSize).SIZE_16,
                                                            class: _normalizeClass([
                                                                _ctx.$style['tabs__icon'],
                                                                [
                                                                    _ctx.$style['tabs__icon--highlighted']
                                                                ]
                                                            ])
                                                        }, null, 8, [
                                                            "name",
                                                            "size",
                                                            "class"
                                                        ]),
                                                        _createElementVNode("span", null, _toDisplayString(label), 1)
                                                    ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                                                        key: 1
                                                    }, [
                                                        _unref(isIconTabs) ? (_openBlock(), _createBlock(_unref(VIcon), _mergeProps({
                                                            key: 0,
                                                            ref_for: true
                                                        }, icon, {
                                                            class: {
                                                                [_ctx.$style['tabs__icon']]: true,
                                                                [_ctx.$style['tabs__icon--active']]: active
                                                            }
                                                        }), null, 16, [
                                                            "class"
                                                        ])) : (_openBlock(), _createElementBlock(_Fragment, {
                                                            key: 1
                                                        }, [
                                                            _createElementVNode("span", {
                                                                class: _normalizeClass(_ctx.$style['tabs__label'])
                                                            }, _toDisplayString(label), 3),
                                                            badge && 'normal' === _ctx.type ? (_openBlock(), _createBlock(VBadge, _mergeProps({
                                                                key: 0,
                                                                ref_for: true
                                                            }, badge, {
                                                                class: _ctx.$style['tabs__badge']
                                                            }), null, 16, [
                                                                "class"
                                                            ])) : _createCommentVNode("", true)
                                                        ], 64))
                                                    ], 64))
                                                ]),
                                            _: 2
                                        }, 1040, [
                                            "class",
                                            "type",
                                            "active",
                                            "flexible",
                                            "s-tab-button-kind",
                                            "single"
                                        ])), [
                                            [
                                                _directive_data_test,
                                                {
                                                    el: 'tab',
                                                    id: buttonProps.id,
                                                    active: _ctx.activeId === buttonProps.id
                                                }
                                            ]
                                        ])
                                    ]),
                                _: 2
                            }, 1032, [
                                "class"
                            ]);
                        }), 128))
                    ]),
                _: 2
            }, [
                _ctx.$slots.button ? {
                    name: "pagination-footer",
                    fn: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['tabs__menu'])
                            }, [
                                _renderSlot(_ctx.$slots, "button")
                            ], 2)
                        ]),
                    key: "0"
                } : void 0
            ]), 1032, [
                "class",
                "wrapper-class",
                "role"
            ])), [
                [
                    _directive_auto_id,
                    'VTabs'
                ]
            ]);
        };
    }
});
