// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.currency-select-loader_nMrNT{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currency-select-loader": `currency-select-loader_nMrNT`
};
export default ___CSS_LOADER_EXPORT___;
