// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus-how-does-it-work_iyfUx{color:var(--TextSecondary)}.bonus-how-does-it-work__title_ZK1JP{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;margin:0 0 18px;color:var(--TextDefault)}.bonus-how-does-it-work__content_iR9r3{padding:0}html.app__layout--desktop .bonus-how-does-it-work__content_iR9r3{padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-how-does-it-work": `bonus-how-does-it-work_iyfUx`,
	"bonus-how-does-it-work__title": `bonus-how-does-it-work__title_ZK1JP`,
	"bonus-how-does-it-work__content": `bonus-how-does-it-work__content_iR9r3`
};
export default ___CSS_LOADER_EXPORT___;
