// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-copyright_vlNz2{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary);text-align:center}.footer-copyright__year_FxhJi{padding:0;margin-bottom:8px;background-color:#0000}.footer-copyright__text--left_Pp9jL{font-size:9px;font-weight:500;line-height:12px;text-align:left}@media(min-width:1990px){.footer-copyright--is-landing_TiLax{font-family:Mulish,sans-serif;font-size:26px;line-height:33px}}.footer-copyright__container_v1l9o{font-size:11px;font-weight:400;line-height:16px;padding:16px;color:var(--ColorContent1a);background-color:var(--ColorBackground2);border-radius:0 0 20px 20px}.footer-copyright__container-inside_hwj0C{display:flex;gap:16px;padding-bottom:16px}html.app__layout--desktop .footer-copyright__container_v1l9o{padding:24px}.footer-copyright__inside-year_ZEjds{display:flex;align-items:center;justify-content:space-between;padding-top:16px;color:var(--ColorContent1);border-top:1px solid var(--ColorBackground1)}.footer-copyright__inside-year-text_lJEID{font-size:14px;font-weight:500;line-height:20px;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-copyright": `footer-copyright_vlNz2`,
	"footer-copyright__year": `footer-copyright__year_FxhJi`,
	"footer-copyright__text--left": `footer-copyright__text--left_Pp9jL`,
	"footer-copyright--is-landing": `footer-copyright--is-landing_TiLax`,
	"footer-copyright__container": `footer-copyright__container_v1l9o`,
	"footer-copyright__container-inside": `footer-copyright__container-inside_hwj0C`,
	"footer-copyright__inside-year": `footer-copyright__inside-year_ZEjds`,
	"footer-copyright__inside-year-text": `footer-copyright__inside-year-text_lJEID`
};
export default ___CSS_LOADER_EXPORT___;
