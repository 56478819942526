import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SSpinner from '../SSpinner/SSpinner.vue';
import LSpinner from '../LSpinner/LSpinner.vue';
import { remapVSpinnerPropsToSSpinnerProps } from '../../utils/remapVSpinnerPropsToSSpinnerProps';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSpinner',
    props: {
        size: {},
        color: {},
        customSize: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const SpinnerComponent = LSpinner;
        function remapProps(inputProps) {
            return inputProps;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(SpinnerComponent), _normalizeProps(_guardReactiveProps(remapProps(props))), null, 16)), [
                [
                    _directive_auto_id,
                    'VSpinner'
                ]
            ]);
        };
    }
});
