// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.brand_dHN0w{width:78px;height:16px}@media(min-width:768px){.brand_dHN0w{width:106px;height:22px}}@media(min-width:1280px){.brand_dHN0w{width:106px;height:22px}}.brand--short_VpSBg{width:auto;height:24px}@media(min-width:768px){.brand--short_VpSBg{width:auto;height:24px}}@media(min-width:1280px){.brand--short_VpSBg{width:auto;height:24px}}.brand--fix-size_A1n7J{width:106px;height:22px}@media(min-width:768px){.brand--fix-size_A1n7J{width:106px;height:22px}}@media(min-width:1280px){.brand--fix-size_A1n7J{width:106px;height:22px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"brand": `brand_dHN0w`,
	"brand--short": `brand--short_VpSBg`,
	"brand--fix-size": `brand--fix-size_A1n7J`
};
export default ___CSS_LOADER_EXPORT___;
