// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-loyalty-program-progress_stzcf{position:relative;display:flex;align-items:center;justify-content:center;width:42px;height:42px;background-color:var(--OpacityLayer2);border-radius:50%}.casino-loyalty-program-progress--size-92_Hm0jo{width:92px;height:92px}.casino-loyalty-program-progress--size-94_N4dLm{width:94px;height:94px}.casino-loyalty-program-progress--size-112_suIhK{width:112px;height:112px}.casino-loyalty-program-progress--has-shadow_dRo8b{filter:drop-shadow(0 8px 16px var(--ModalShadow))}@media(hover:hover)and (pointer:fine){.casino-loyalty-program-progress--has-shadow_dRo8b{cursor:pointer}}.casino-loyalty-program-progress--has-no-image_tz_JY .casino-loyalty-program-progress__logo_TdNBt{max-width:60%}.casino-loyalty-program-progress--hide-background_Ijx_2{background-color:#0000}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-loyalty-program-progress": `casino-loyalty-program-progress_stzcf`,
	"casino-loyalty-program-progress--size-92": `casino-loyalty-program-progress--size-92_Hm0jo`,
	"casino-loyalty-program-progress--size-94": `casino-loyalty-program-progress--size-94_N4dLm`,
	"casino-loyalty-program-progress--size-112": `casino-loyalty-program-progress--size-112_suIhK`,
	"casino-loyalty-program-progress--has-shadow": `casino-loyalty-program-progress--has-shadow_dRo8b`,
	"casino-loyalty-program-progress--has-no-image": `casino-loyalty-program-progress--has-no-image_tz_JY`,
	"casino-loyalty-program-progress__logo": `casino-loyalty-program-progress__logo_TdNBt`,
	"casino-loyalty-program-progress--hide-background": `casino-loyalty-program-progress--hide-background_Ijx_2`
};
export default ___CSS_LOADER_EXPORT___;
