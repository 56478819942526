import { computed, toRef } from 'vue';
import { BetLine } from '@leon-hub/api-sdk';
import { SportlineType, SelectionTag } from 'web/src/modules/sportline/enums';
import { selectionTagsToRunnerTags } from './selectionTagsToRunnerTags';
import { sportEventMarketTypeToApi } from './sportEventMarketTypeToApi';
function sportlineTypeToBetLine(param) {
    let { type, isOutrightEvent } = param;
    switch(true){
        case isOutrightEvent:
            return BetLine.OUTRIGHT;
        case type === SportlineType.Live:
            return BetLine.LIVE;
        case type === SportlineType.Prematch:
            return BetLine.PREMATCH;
        default:
            return BetLine.VSPORTS;
    }
}
export function getBetChoice(runner, competitors) {
    const tags = new Set(runner.tags);
    if (tags.has(SelectionTag.HOME) && competitors[0]) return competitors[0];
    if (tags.has(SelectionTag.AWAY) && competitors[1]) return competitors[1];
    return runner.name;
}
export function createSlipInfoRef(props) {
    const runner = toRef(props.runner ?? null);
    const market = toRef(props.market ?? null);
    const slipCaption = toRef(props.slipCaption);
    const sportEventId = toRef(props.sportEventId ?? null);
    const isOutrightEvent = toRef(props.isOutrightEvent ?? false);
    const sportlineType = toRef(props.sportlineType ?? null);
    return computed(()=>{
        if (!runner.value || !market.value || !sportlineType.value || !sportEventId.value) return null;
        const slipItem = {
            competitors: slipCaption.value.competitors,
            eventName: slipCaption.value.eventName || '',
            marketName: market.value.name || '',
            // @TODO fix after requirements will be updated for LEONWEB-14160
            // runnerName: getBetChoice(runner.value, slipCaption.value.competitors ?? []),
            runnerName: runner.value.name,
            event: Number(sportEventId.value),
            market: Number(market.value.id || 0),
            runner: Number(runner.value.id),
            odds: runner.value.price,
            oddsStr: runner.value.value,
            betline: sportlineTypeToBetLine({
                type: sportlineType.value,
                isOutrightEvent: isOutrightEvent.value
            }),
            zeroMargin: !!market.value.hasZeroMargin,
            isPrimaryMarket: !!market.value.isPrimary,
            isTotalHandicap: !!runner.value?.handicap,
            ts: Date.now(),
            marketTypeIdentifier: market.value?.marketTypeId,
            runnerTags: selectionTagsToRunnerTags(runner.value?.tags),
            marketTypeTag: sportEventMarketTypeToApi(market.value?.typeTag)
        };
        return slipItem;
    });
}
