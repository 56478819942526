// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposit-bonus-selector_FVtcV{margin-top:20px}.deposit-bonus-selector__selector_iMRrS{display:flex}.deposit-bonus-selector__selector-button_g9lYP{flex:1;margin:0}.deposit-bonus-selector_FVtcV .deposit-bonus-selector__selector-button-inner_h31i1{color:var(--BrandDefault)}.deposit-bonus-selector__button-info_SJHjc{flex-shrink:0;margin-left:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deposit-bonus-selector": `deposit-bonus-selector_FVtcV`,
	"deposit-bonus-selector__selector": `deposit-bonus-selector__selector_iMRrS`,
	"deposit-bonus-selector__selector-button": `deposit-bonus-selector__selector-button_g9lYP`,
	"deposit-bonus-selector__selector-button-inner": `deposit-bonus-selector__selector-button-inner_h31i1`,
	"deposit-bonus-selector__button-info": `deposit-bonus-selector__button-info_SJHjc`
};
export default ___CSS_LOADER_EXPORT___;
