import { watch } from 'vue';
import { whenDomReady } from '@leon-hub/dom-ready';
import { localStorageManager } from '@leon-hub/local-storage';
import { logger as loggerInstance } from '@leon-hub/logging';
import useServiceWorkerStore from 'web/src/modules/service-worker/store/useServiceWorkerStore';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
const logger = loggerInstance.createNamespace('ServiceWorker');
const serviceWorkerName = 'sw.app.js';
const serviceWorkerLegacyName = 'sw.js';
const serviceWorkerHashStorageKey = 'sw-hash';
const serviceWorkerTimeInstalledStorageKey = 'sw-time';
async function getRegistration() {
    // Bypass InvalidStateError.
    await whenDomReady();
    const registrations = await navigator.serviceWorker.getRegistrations();
    return registrations.find((sw)=>sw.active?.scriptURL.includes(serviceWorkerName));
}
async function uninstall() {
    const registration = await getRegistration();
    if (registration) {
        logger.info('Service worker uninstalling');
        await registration.unregister();
    }
}
async function removeLegacyServiceWorkers() {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations)if (registration.active?.scriptURL.includes(serviceWorkerLegacyName)) {
        logger.info(`Removing legacy service worker: ${registration.active?.scriptURL}`);
        registration.unregister();
    }
}
function updateRegistrationConfig(registration, headers) {
    const message = {
        type: 'sw:config:update',
        headers
    };
    if (registration.active) registration.active.postMessage(message);
    else if (registration.waiting) registration.waiting.postMessage(message);
    else if (registration.installing) registration.installing.postMessage(message);
}
async function updateSWConfig(headers) {
    const registration = await getRegistration();
    if (registration) updateRegistrationConfig(registration, headers);
}
export async function getConfig() {
    const registration = await getRegistration();
    if (registration) return new Promise((resolve)=>{
        const messageChannel = new MessageChannel();
        // eslint-disable-next-line unicorn/prefer-add-event-listener
        messageChannel.port1.onmessage = (event)=>{
            if ('sw:config:get' === event.data.type) resolve(event.data.config);
        };
        registration.active?.postMessage({
            type: 'sw:config:get'
        }, [
            messageChannel.port2
        ]);
    });
    return null;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export async function initSw() {
    const serviceWorkerStore = useServiceWorkerStore();
    const api = useGraphqlClient();
    const { isLoggedIn } = useIsLoggedIn();
    const serviceWorker = globalThis?.navigator?.serviceWorker;
    const headers = api.getHeaders();
    if (serviceWorker) {
        removeLegacyServiceWorkers();
        try {
            await serviceWorkerStore.loadConfig();
            if (serviceWorkerStore.isEnabled) {
                let registration = await getRegistration();
                if (registration) {
                    logger.info('Service worker already installed');
                    const hash = localStorageManager.getItem(serviceWorkerHashStorageKey) || '';
                    if ("48f372ef19cf64db2f2c239de13a4dc9" !== hash) {
                        // ref: https://developer.mozilla.org/ru/docs/Web/API/ServiceWorkerRegistration/update - 24H caches updates
                        const timeUpdated = Number(localStorageManager.getItem(serviceWorkerTimeInstalledStorageKey) || '0');
                        const timeLeft = Date.now() - timeUpdated;
                        const dayMillis = 86400000;
                        if (timeLeft > dayMillis) {
                            logger.info('Service worker needs to be reinstalled');
                            logger.info(`Old hash: ${hash}, new hash: 48f372ef19cf64db2f2c239de13a4dc9`);
                            await registration.update();
                            localStorageManager.setItem(serviceWorkerHashStorageKey, "48f372ef19cf64db2f2c239de13a4dc9");
                            localStorageManager.setItem(serviceWorkerTimeInstalledStorageKey, String(Date.now()));
                        } else // eslint-disable-next-line max-len
                        logger.info(`Service worker received new version, but waiting caches removal: elapsed ${dayMillis - timeLeft}ms`);
                    }
                    await updateSWConfig(headers);
                } else try {
                    logger.info('Service worker needs to be installed');
                    registration = await navigator.serviceWorker.register(`/${serviceWorkerName}`);
                    updateRegistrationConfig(registration, headers);
                    localStorageManager.setItem(serviceWorkerHashStorageKey, "48f372ef19cf64db2f2c239de13a4dc9");
                    localStorageManager.setItem(serviceWorkerTimeInstalledStorageKey, String(Date.now()));
                } catch (error) {
                    logger.warn('Service worker failed to install', error);
                }
                watch(isLoggedIn, ()=>{
                    updateSWConfig(headers);
                });
            } else await uninstall();
        } catch (error) {
            logger.warn('Failed to update Service Worker configuration', error);
        }
    }
}
