import { marketTypeTagHandicap, marketTypeTagRegular, marketTypeTagTotal } from '@leon-hub/api-sdk';
import { SportEventMarketTypeTag } from 'web/src/modules/sportline/enums';
const marketTypeMap = {
    [SportEventMarketTypeTag.HANDICAP]: marketTypeTagHandicap,
    [SportEventMarketTypeTag.TOTAL]: marketTypeTagTotal,
    [SportEventMarketTypeTag.REGULAR]: marketTypeTagRegular
};
export function sportEventMarketTypeToApi(input) {
    if (!input) return;
    return marketTypeMap[input];
}
