// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-select-icon_tDFZc{position:absolute;top:14px;right:12px;pointer-events:none;cursor:pointer;fill:var(--TextSecondary);transition:transform .15s ease-in-out}.dropdown-select-icon--large_Mlzo3{top:14px;right:12px}.dropdown-select-icon--open_P0d2A{transform:rotate(180deg)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-select-icon": `dropdown-select-icon_tDFZc`,
	"dropdown-select-icon--large": `dropdown-select-icon--large_Mlzo3`,
	"dropdown-select-icon--open": `dropdown-select-icon--open_P0d2A`
};
export default ___CSS_LOADER_EXPORT___;
