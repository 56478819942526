import { onBeforeUnmount, onBeforeMount, toRef } from 'vue';
import { CustomerConfig } from '@leon-hub/api-sdk';
import { useBonusStore } from 'web/src/modules/bonuses/store';
import { useUserStore } from 'web/src/modules/user/store';
import { DEPOSIT_BONUS_DEFAULT_VALUE, DEPOSIT_BONUS_NONE_SELECTED } from 'web/src/modules/payments/constants';
export default function usePaymentBonuses(props, emit) {
    const userStore = useUserStore();
    const bonusStore = useBonusStore();
    const selectedBonus = toRef(()=>bonusStore.selectedBonus);
    const isBonusChooserShownBeforeDeposits = toRef(()=>bonusStore.isBonusChooserShownBeforeDeposits);
    async function beforeMount() {
        if (!isBonusChooserShownBeforeDeposits.value) await userStore.setAdditionalPropsUser({
            config: CustomerConfig.BONUS_CHOOSER_SHOWN_BEFORE_DEPOSITS,
            value: {
                value: true
            }
        });
        if (props.autoSelectFirst && props.bonusList.length > 0 && (selectedBonus.value === DEPOSIT_BONUS_NONE_SELECTED || selectedBonus.value === DEPOSIT_BONUS_DEFAULT_VALUE)) bonusStore.setBonusSelection(props.bonusList[0]?.campaignId);
    }
    onBeforeUnmount(()=>{
        if (!isBonusChooserShownBeforeDeposits.value) bonusStore.setIsBonusChooserShownBeforeDeposits(true);
    });
    function emitBonusSelection(param) {
        let { campaignId, bonusCode } = param;
        bonusStore.setBonusSelection(campaignId);
        emit('change', bonusCode);
    }
    function handleSelectionComplete() {
        bonusStore.setChoosingBonusBeforeDeposit(false);
        if (!isBonusChooserShownBeforeDeposits.value) {
            emit('change-complete', `${selectedBonus.value}`);
            bonusStore.setIsBonusChooserShownBeforeDeposits(true);
        }
    }
    function onNoDepositClick() {
        emitBonusSelection({
            campaignId: DEPOSIT_BONUS_NONE_SELECTED,
            bonusCode: `${DEPOSIT_BONUS_NONE_SELECTED}`
        });
        handleSelectionComplete();
    }
    onBeforeMount(beforeMount);
    return {
        selectedBonus,
        emitBonusSelection,
        handleSelectionComplete,
        onNoDepositClick
    };
}
