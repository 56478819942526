// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.country-flag_QBFDp{display:inline-flex;align-items:center;justify-content:center}.country-flag__img_rsm0t{display:inline-block;width:100%;height:100%;overflow:hidden;background-repeat:no-repeat;background-position:50%;background-size:contain;border-radius:2px}.country-flag__rounded_Yx0VZ{width:20px;height:20px;padding:1.6px}.country-flag__rounded_Yx0VZ .country-flag__img_rsm0t{width:100%;height:100%;background-size:cover;border-radius:100%}.country-flag-size-0_z5HAV{width:0;height:0}.country-flag-size-10_XYsqP{width:13.3333333333px;height:10px}.country-flag-size-12_ZTXzj{width:16px;height:12px}.country-flag-size-16_cAf0l{width:21.3333333333px;height:16px}.country-flag-size-18_Ny1bz{width:24px;height:18px}.country-flag-size-20_uv3a3{width:26.6666666667px;height:20px}.country-flag-size-22_GJGAV{width:29.3333333333px;height:22px}.country-flag-size-24_pvOja{width:32px;height:24px}.country-flag-size-26_VXl0G{width:34.6666666667px;height:26px}.country-flag-size-28_lKEn8{width:37.3333333333px;height:28px}.country-flag-size-40__Vd07{width:53.3333333333px;height:40px}.country-flag-size-54_IwRMe{width:72px;height:54px}.country-flag-size-full-width_J4H5v{width:100%;height:75%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"country-flag": `country-flag_QBFDp`,
	"country-flag__img": `country-flag__img_rsm0t`,
	"country-flag__rounded": `country-flag__rounded_Yx0VZ`,
	"country-flag-size-0": `country-flag-size-0_z5HAV`,
	"country-flag-size-10": `country-flag-size-10_XYsqP`,
	"country-flag-size-12": `country-flag-size-12_ZTXzj`,
	"country-flag-size-16": `country-flag-size-16_cAf0l`,
	"country-flag-size-18": `country-flag-size-18_Ny1bz`,
	"country-flag-size-20": `country-flag-size-20_uv3a3`,
	"country-flag-size-22": `country-flag-size-22_GJGAV`,
	"country-flag-size-24": `country-flag-size-24_pvOja`,
	"country-flag-size-26": `country-flag-size-26_VXl0G`,
	"country-flag-size-28": `country-flag-size-28_lKEn8`,
	"country-flag-size-40": `country-flag-size-40__Vd07`,
	"country-flag-size-54": `country-flag-size-54_IwRMe`,
	"country-flag-size-full-width": `country-flag-size-full-width_J4H5v`
};
export default ___CSS_LOADER_EXPORT___;
