import { computed, ref } from 'vue';
import { groupMarketsIntoGridCells } from 'web/src/modules/sportline/submodules/markets-grid/utils';
export function useMarketsListGroups(props) {
    const { allMarketsList, isAllTabSelected, activeGridFilter, closedMarketGroups, defaultMarketsLimit } = props;
    const isMoreMarketsClicked = ref(false);
    const groups = computed(()=>groupMarketsIntoGridCells({
            doShowAll: isAllTabSelected.value,
            filter: activeGridFilter.value,
            markets: allMarketsList.value,
            collapsedCellKeys: closedMarketGroups.value
        }));
    const isMoreMarketButtonVisible = computed(()=>defaultMarketsLimit.value > 0 && !isMoreMarketsClicked.value && defaultMarketsLimit.value < groups.value.length);
    const filteredGroups = computed(()=>{
        if (!isMoreMarketButtonVisible.value) return groups.value;
        return groups.value.slice(0, defaultMarketsLimit.value);
    });
    const oddGroups = computed(()=>filteredGroups.value.filter((g, index)=>!(index % 2)));
    const evenGroups = computed(()=>filteredGroups.value.filter((g, index)=>index % 2));
    const hasAnyGroups = computed(()=>groups.value.length > 0);
    return {
        isMoreMarketsClicked,
        isMoreMarketButtonVisible,
        filteredGroups,
        oddGroups,
        evenGroups,
        hasAnyGroups
    };
}
