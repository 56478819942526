// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-list_ba8VT{display:flex;flex-direction:column;gap:24px;padding:0 16px 70px}.landing-list__header_d6fxT{display:flex;flex-direction:column;gap:8px;align-items:center;text-align:center}.landing-list__header--left_L69RK{align-items:flex-start}.landing-list__body_of3Xr{display:flex;flex-direction:column;gap:12px}@media(min-width:1024px){.landing-list_ba8VT{gap:40px;padding:0}.landing-list--vertical-padding_ZKmzv{padding:60px 0}.landing-list__item_doQiq{width:33.33%}.landing-list__body_of3Xr{flex-direction:row}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-list": `landing-list_ba8VT`,
	"landing-list__header": `landing-list__header_d6fxT`,
	"landing-list__header--left": `landing-list__header--left_L69RK`,
	"landing-list__body": `landing-list__body_of3Xr`,
	"landing-list--vertical-padding": `landing-list--vertical-padding_ZKmzv`,
	"landing-list__item": `landing-list__item_doQiq`
};
export default ___CSS_LOADER_EXPORT___;
