// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deposits-success__text_QpS_i{font-size:14px;font-weight:500;line-height:24px;letter-spacing:.25px;margin-bottom:8px}.deposits-success__value_D0Zg5{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deposits-success__text": `deposits-success__text_QpS_i`,
	"deposits-success__value": `deposits-success__value_D0Zg5`
};
export default ___CSS_LOADER_EXPORT___;
