// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes modal-slide-up-outer-leave_Rf50L{0%{opacity:1}75%{opacity:1}to{opacity:0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-slide-up-outer-leave": `modal-slide-up-outer-leave_Rf50L`
};
export default ___CSS_LOADER_EXPORT___;
