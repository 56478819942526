import { toRef, watch } from 'vue';
import { whenClientNetworkIdle } from '@leon-hub/idle';
import SportRadarService from 'web/src/modules/analytics/services/sportRadarService';
import { useMetricsStore } from 'web/src/modules/analytics/store';
export default (()=>()=>{
        const metricsStore = useMetricsStore();
        const sportradarCounterId = toRef(metricsStore, 'sportradarCounterId');
        const sportradarPixelManager = toRef(metricsStore, 'sportradarPixelManager');
        watch(sportradarCounterId, (newValue)=>{
            if (newValue) whenClientNetworkIdle({
                interval: 300
            }).then(()=>{
                SportRadarService.getInstance().setPixelManager(sportradarPixelManager.value);
            });
        }, {
            immediate: true
        });
    });
