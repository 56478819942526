import { useLazyCustomerDataLogin } from 'web/src/modules/auth/utils/useLazyCustomerDataLogin';
import { startLoginDeviceRoutineEventType, useAppEmitter } from 'web/src/modules/emitter';
import { getDeferredDeviceIDStorage } from './getDeferredDeviceIDStorage';
export function onAppMounted() {
    useLazyCustomerDataLogin().then(async (login)=>{
        const storage = getDeferredDeviceIDStorage(login);
        const record = await storage.get();
        if (record.exists && record.value) {
            storage.clear();
            useAppEmitter().emit(startLoginDeviceRoutineEventType);
        }
    });
}
