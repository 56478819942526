// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-loyalty-program-current-level-card_I7GMC{position:relative;display:flex;flex-direction:column;align-items:center;justify-content:center;padding:16px;background:radial-gradient(98.83% 180.3% at .72% 1.17%,#849aea4d 0,#39277b4d 100%);border-radius:5px}@media(hover:hover)and (pointer:fine){.casino-loyalty-program-current-level-card_I7GMC:hover:not(.casino-loyalty-program-current-level-card--init-level_BT3xl){cursor:pointer}}.casino-loyalty-program-current-level-card__button_oHF51{position:absolute;top:16px;right:16px}.casino-loyalty-program-current-level-card__progress_DJtWN{margin-bottom:16px}.casino-loyalty-program-current-level-card__icon_iJSsw{margin-bottom:4px}.casino-loyalty-program-current-level-card__title_MtdiX{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;width:100%;margin-bottom:8px;overflow:hidden;color:var(--BrandText);text-align:center;text-overflow:ellipsis;white-space:nowrap}.casino-loyalty-program-current-level-card__points-info_tMXHA{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;margin-bottom:8px;color:var(--BrandText)}.casino-loyalty-program-current-level-card__current_eg0OC{font-size:13px;font-weight:500;line-height:20px;letter-spacing:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-loyalty-program-current-level-card": `casino-loyalty-program-current-level-card_I7GMC`,
	"casino-loyalty-program-current-level-card--init-level": `casino-loyalty-program-current-level-card--init-level_BT3xl`,
	"casino-loyalty-program-current-level-card__button": `casino-loyalty-program-current-level-card__button_oHF51`,
	"casino-loyalty-program-current-level-card__progress": `casino-loyalty-program-current-level-card__progress_DJtWN`,
	"casino-loyalty-program-current-level-card__icon": `casino-loyalty-program-current-level-card__icon_iJSsw`,
	"casino-loyalty-program-current-level-card__title": `casino-loyalty-program-current-level-card__title_MtdiX`,
	"casino-loyalty-program-current-level-card__points-info": `casino-loyalty-program-current-level-card__points-info_tMXHA`,
	"casino-loyalty-program-current-level-card__current": `casino-loyalty-program-current-level-card__current_eg0OC`
};
export default ___CSS_LOADER_EXPORT___;
