// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sms__resend_PSdch{margin-top:10px;text-align:center}.sms__countdown_Xdgqk{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;display:block;color:var(--TextDefault)}.sms__sent-code_KI8jI{margin-top:20px;text-align:center}.sms__container_JlkUB,.sms__sent-again_HqCii{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}.sms__container_JlkUB{text-align:center}.sms__container--text_L51g7{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:0 0 16px;color:var(--TextPrimary)}.sms__container--change_z7_81{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--ColorContent2);color:var(--TextSecondary);cursor:pointer}.sms__container--link_pDX1C{-webkit-text-decoration:underline;text-decoration:underline}.sms__container--important-text_Rt1QF{color:var(--TextDefault);white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sms__resend": `sms__resend_PSdch`,
	"sms__countdown": `sms__countdown_Xdgqk`,
	"sms__sent-code": `sms__sent-code_KI8jI`,
	"sms__container": `sms__container_JlkUB`,
	"sms__sent-again": `sms__sent-again_HqCii`,
	"sms__container--text": `sms__container--text_L51g7`,
	"sms__container--change": `sms__container--change_z7_81`,
	"sms__container--link": `sms__container--link_pDX1C`,
	"sms__container--important-text": `sms__container--important-text_Rt1QF`
};
export default ___CSS_LOADER_EXPORT___;
