// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .home-promotions__heading_HLEXM{padding-right:0}html.app__layout--desktop .home-promotions__slide_lzqOf{box-sizing:initial;width:320px;padding:8px 16px 16px 0;font-size:13px;color:var(--TextDefault);cursor:pointer}html.app__layout--desktop .home-promotions__slide_lzqOf:last-of-type{padding-right:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home-promotions__heading": `home-promotions__heading_HLEXM`,
	"home-promotions__slide": `home-promotions__slide_lzqOf`
};
export default ___CSS_LOADER_EXPORT___;
