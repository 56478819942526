// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .sportline-event-meta-info_xxlOe{display:flex;flex-wrap:wrap;justify-content:space-between}html.app__layout--desktop .sportline-event-meta-info__left-column_lwnFo{flex:1;align-items:center;width:100%;padding:0;line-height:12px}html.app__layout--desktop .sportline-event-meta-info__left-column_lwnFo span:not(:last-child){margin-right:4px}html.app__layout--desktop .sportline-event-meta-info__right-column_jjoVE{display:flex}.kickoff-countdown_KjOLF{font-size:12px;line-height:12px;letter-spacing:normal}.kickoff-countdown_KjOLF,.kickoff-countdown__value_O_Ss1{font-weight:500;color:var(--TextDefault)}.kickoff-countdown__date_cGcOJ{color:var(--TextDefault)}.kickoff-countdown__time_9T80r{font-weight:400;color:var(--TextSecondary)}.kickoff-countdown--filled_DG9qw{font-size:9px;font-weight:700;line-height:12px;text-transform:uppercase;letter-spacing:.2px;padding:2px 4px;background-color:var(--Highlight);border-radius:2px}.stream-indicator_W5TNG{fill:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-event-meta-info": `sportline-event-meta-info_xxlOe`,
	"sportline-event-meta-info__left-column": `sportline-event-meta-info__left-column_lwnFo`,
	"sportline-event-meta-info__right-column": `sportline-event-meta-info__right-column_jjoVE`,
	"kickoff-countdown": `kickoff-countdown_KjOLF`,
	"kickoff-countdown__value": `kickoff-countdown__value_O_Ss1`,
	"kickoff-countdown__date": `kickoff-countdown__date_cGcOJ`,
	"kickoff-countdown__time": `kickoff-countdown__time_9T80r`,
	"kickoff-countdown--filled": `kickoff-countdown--filled_DG9qw`,
	"stream-indicator": `stream-indicator_W5TNG`
};
export default ___CSS_LOADER_EXPORT___;
