import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withDirectives as _withDirectives, createVNode as _createVNode, createCommentVNode as _createCommentVNode, isRef as _isRef, createBlock as _createBlock, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 0,
    ref: "standings"
};
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import vPopperHint from 'web/src/components/Popper/directives/popperHint';
import StatisticLeagueStandingsPromotion from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingsPromotion.vue';
import StatisticTeamLogo from 'web/src/modules/sportline/submodules/event-statistic/components/StatisticTeamLogo.vue';
import ExpandButton from 'web/src/modules/sportline/submodules/core-statistic/components/ExpandButton.vue';
import useStatisticLeagueStandings from './useStatisticLeagueStandings';
export default /*@__PURE__*/ _defineComponent({
    __name: 'StatisticLeagueStandings',
    props: {
        leagueStandings: {},
        teams: {},
        alwaysExpanded: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const leagueStandings = toRef(props, 'leagueStandings');
        const teams = toRef(props, 'teams', null);
        const alwaysExpanded = toRef(props, 'alwaysExpanded', false);
        const { isExpanded, isDisplayFullTable, teamsIds, tournament, promotions } = useStatisticLeagueStandings({
            leagueStandings,
            teams,
            alwaysExpanded
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(tournament) ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tournament).seasons, (season)=>(_openBlock(), _createElementBlock("div", {
                        key: season.name ?? void 0
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(season.groups, (group)=>(_openBlock(), _createElementBlock("table", {
                                key: `${season.name}_${group.id}_group`,
                                class: _normalizeClass(_ctx.$style['standings-team-promotions-group'])
                            }, [
                                _createElementVNode("thead", null, [
                                    _createElementVNode("tr", {
                                        class: _normalizeClass([
                                            _ctx.$style['standings-team-promotion-item'],
                                            _ctx.$style['standings-team-promotion-item--header']
                                        ])
                                    }, [
                                        _createElementVNode("td", {
                                            class: _normalizeClass(_ctx.$style['standings-team-promotion-item-cell'])
                                        }, [
                                            _createElementVNode("div", {
                                                class: _normalizeClass(_ctx.$style['standings-team-promotion-item__group-name'])
                                            }, _toDisplayString(group.name), 3)
                                        ], 2),
                                        _withDirectives((_openBlock(), _createElementBlock("td", {
                                            class: _normalizeClass([
                                                _ctx.$style['standings-team-promotion-item-cell'],
                                                _ctx.$style['standings-team-promotion-team-matches-total']
                                            ])
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_GAMES_SHORT')), 1)
                                        ], 2)), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_GAMES')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", {
                                            class: _normalizeClass([
                                                _ctx.$style['standings-team-promotion-item-cell'],
                                                _ctx.$style['standings-team-promotion-team-win-total']
                                            ])
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN_SHORT')), 1)
                                        ], 2)), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_WIN')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", {
                                            class: _normalizeClass([
                                                _ctx.$style['standings-team-promotion-item-cell'],
                                                _ctx.$style['standings-team-promotion-team-draw-total']
                                            ])
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW_SHORT')), 1)
                                        ], 2)), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_DRAW')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", {
                                            class: _normalizeClass([
                                                _ctx.$style['standings-team-promotion-item-cell'],
                                                _ctx.$style['standings-team-promotion-team-loss-total']
                                            ])
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE_SHORT')), 1)
                                        ], 2)), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_LOSE')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", {
                                            class: _normalizeClass([
                                                _ctx.$style['standings-team-promotion-item-cell'],
                                                _ctx.$style['standings-team-promotion-team-goals-total']
                                            ])
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_BALLS_DIFF_SHORT')), 1)
                                        ], 2)), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_BALLS_DIFF')
                                            ]
                                        ]),
                                        _withDirectives((_openBlock(), _createElementBlock("td", {
                                            class: _normalizeClass([
                                                _ctx.$style['standings-team-promotion-item-cell'],
                                                _ctx.$style['standings-team-promotion-team-points-total']
                                            ])
                                        }, [
                                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_POINTS_SHORT')), 1)
                                        ], 2)), [
                                            [
                                                _unref(vPopperHint),
                                                _ctx.$t('WEB2_MATCH_STATISTIC_RESULT_INDICATOR_TOTAL_POINTS')
                                            ]
                                        ])
                                    ], 2)
                                ]),
                                _createElementVNode("tbody", null, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.teams, (team, index)=>(_openBlock(), _createElementBlock(_Fragment, {
                                            key: `${season.name}_${group.id}_${team.position}_item`
                                        }, [
                                            _createElementVNode("tr", {
                                                class: _normalizeClass({
                                                    [_ctx.$style['standings-team-promotion-item']]: true,
                                                    [_ctx.$style['standings-team-promotion-item--highlighted']]: !!team.team?.id && _unref(teamsIds).has(team.team.id)
                                                })
                                            }, [
                                                _createElementVNode("td", {
                                                    class: _normalizeClass(_ctx.$style['standings-team-promotion-item-cell'])
                                                }, [
                                                    _createElementVNode("div", {
                                                        class: _normalizeClass(_ctx.$style['standings-team-promotion-team-info'])
                                                    }, [
                                                        _createVNode(StatisticLeagueStandingsPromotion, {
                                                            class: _normalizeClass(_ctx.$style['standings-team-promotion-team-info__indicator']),
                                                            promotion: team.promotion
                                                        }, null, 8, [
                                                            "class",
                                                            "promotion"
                                                        ]),
                                                        _createElementVNode("div", {
                                                            class: _normalizeClass(_ctx.$style['standings-team-promotion-team-info__number'])
                                                        }, _toDisplayString(team.position), 3),
                                                        team.team ? (_openBlock(), _createElementBlock(_Fragment, {
                                                            key: 0
                                                        }, [
                                                            _createVNode(StatisticTeamLogo, {
                                                                class: _normalizeClass(_ctx.$style['standings-team-promotion-team-info__icon']),
                                                                team: team.team
                                                            }, null, 8, [
                                                                "class",
                                                                "team"
                                                            ]),
                                                            _createElementVNode("div", {
                                                                class: _normalizeClass(_ctx.$style['standings-team-promotion-team-info__name'])
                                                            }, _toDisplayString(team.team.name), 3)
                                                        ], 64)) : _createCommentVNode("", true)
                                                    ], 2)
                                                ], 2),
                                                _createElementVNode("td", {
                                                    class: _normalizeClass([
                                                        _ctx.$style['standings-team-promotion-item-cell'],
                                                        _ctx.$style['standings-team-promotion-team-matches-total']
                                                    ])
                                                }, _toDisplayString(team.totals.matches), 3),
                                                _createElementVNode("td", {
                                                    class: _normalizeClass([
                                                        _ctx.$style['standings-team-promotion-item-cell'],
                                                        _ctx.$style['standings-team-promotion-team-win-total']
                                                    ])
                                                }, _toDisplayString(team.totals.win), 3),
                                                _createElementVNode("td", {
                                                    class: _normalizeClass([
                                                        _ctx.$style['standings-team-promotion-item-cell'],
                                                        _ctx.$style['standings-team-promotion-team-draw-total']
                                                    ])
                                                }, _toDisplayString(team.totals.draw), 3),
                                                _createElementVNode("td", {
                                                    class: _normalizeClass([
                                                        _ctx.$style['standings-team-promotion-item-cell'],
                                                        _ctx.$style['standings-team-promotion-team-loss-total']
                                                    ])
                                                }, _toDisplayString(team.totals.loss), 3),
                                                _createElementVNode("td", {
                                                    class: _normalizeClass([
                                                        _ctx.$style['standings-team-promotion-item-cell'],
                                                        _ctx.$style['standings-team-promotion-team-goals-total']
                                                    ])
                                                }, _toDisplayString(team.totals.goals[0]) + "-" + _toDisplayString(team.totals.goals[1]), 3),
                                                _createElementVNode("td", {
                                                    class: _normalizeClass([
                                                        _ctx.$style['standings-team-promotion-item-cell'],
                                                        _ctx.$style['standings-team-promotion-team-points-total']
                                                    ])
                                                }, _toDisplayString(team.totals.points), 3)
                                            ], 2),
                                            !_unref(isDisplayFullTable) && index + 1 < group.teams.length && group.teams[index + 1].position - team.position > 1 ? (_openBlock(), _createElementBlock("tr", {
                                                key: `${season.name}_${group.id}_${team.position}_divider`,
                                                class: _normalizeClass([
                                                    _ctx.$style['standings-team-promotion-item'],
                                                    _ctx.$style['standings-team-promotion-item--divider']
                                                ])
                                            }, [
                                                _createElementVNode("td", {
                                                    colspan: "7",
                                                    class: _normalizeClass(_ctx.$style['statistic-league-standings-group-divider'])
                                                }, [
                                                    _createVNode(_unref(VIcon), {
                                                        class: _normalizeClass(_ctx.$style['statistic-league-standings-group-divider__icon']),
                                                        name: _unref(IconName).MORE,
                                                        size: _unref(IconSize).SIZE_16
                                                    }, null, 8, [
                                                        "class",
                                                        "name",
                                                        "size"
                                                    ])
                                                ], 2)
                                            ], 2)) : _createCommentVNode("", true)
                                        ], 64))), 128))
                                ])
                            ], 2))), 128))
                    ]))), 128)),
                _unref(isDisplayFullTable) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['standings-team-promotion-legend'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(promotions), (promotion)=>(_openBlock(), _createElementBlock("div", {
                            key: promotion.id ?? void 0,
                            class: _normalizeClass(_ctx.$style['standings-team-promotion-legend-item'])
                        }, [
                            _createVNode(StatisticLeagueStandingsPromotion, {
                                class: _normalizeClass(_ctx.$style['statistic-league-standings-promotion-indicator']),
                                promotion: promotion
                            }, null, 8, [
                                "class",
                                "promotion"
                            ]),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['standings-team-promotion-legend-item__label'])
                            }, _toDisplayString(promotion.name), 3)
                        ], 2))), 128))
                ], 2)) : _createCommentVNode("", true),
                alwaysExpanded.value ? _createCommentVNode("", true) : (_openBlock(), _createBlock(ExpandButton, {
                    key: 1,
                    "is-expanded": _unref(isExpanded),
                    "onUpdate:isExpanded": _cache[0] || (_cache[0] = ($event)=>_isRef(isExpanded) ? isExpanded.value = $event : null),
                    "opened-title": _ctx.$t('WEB2_CLOSE'),
                    "closed-title": _ctx.$t('WEB2_MATCH_STATISTIC_MORE_TOURNAMENT_TABLE'),
                    class: _normalizeClass(_ctx.$style['standings-team-promotion-list-toggle'])
                }, null, 8, [
                    "is-expanded",
                    "opened-title",
                    "closed-title",
                    "class"
                ]))
            ])), [
                [
                    _directive_auto_id,
                    'StatisticLeagueStandings'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
