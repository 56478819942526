// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highest-odds-icon--default_iDDnL{fill:var(--TextDefault)}.highest-odds-icon--primary_ig8A9{fill:var(--TextPrimary)}.highest-odds-icon--gradient_DSdAL,.highest-odds-icon--highlight_q6Mww{fill:var(--ErrorHighlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highest-odds-icon--default": `highest-odds-icon--default_iDDnL`,
	"highest-odds-icon--primary": `highest-odds-icon--primary_ig8A9`,
	"highest-odds-icon--gradient": `highest-odds-icon--gradient_DSdAL`,
	"highest-odds-icon--highlight": `highest-odds-icon--highlight_q6Mww`
};
export default ___CSS_LOADER_EXPORT___;
