// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-link_mG9UH{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;justify-content:center;width:100%;padding:12px 0;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action-link": `action-link_mG9UH`
};
export default ___CSS_LOADER_EXPORT___;
