// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-top-bar__suffix_DbIEA{position:absolute;right:0;display:flex}.onboarding-top-bar__button_DxcnO{color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"onboarding-top-bar__suffix": `onboarding-top-bar__suffix_DbIEA`,
	"onboarding-top-bar__button": `onboarding-top-bar__button_DxcnO`
};
export default ___CSS_LOADER_EXPORT___;
