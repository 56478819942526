// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate-history__head-line_xN_3v{top:30px;padding:2px 0 0}.affiliate-history__wrapper_rirdt{margin:0 8px}.affiliate-history__list_ZILxf{margin-top:8px;margin-bottom:16px}.affiliate-history__select_VXv4A{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;position:relative}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-history__head-line": `affiliate-history__head-line_xN_3v`,
	"affiliate-history__wrapper": `affiliate-history__wrapper_rirdt`,
	"affiliate-history__list": `affiliate-history__list_ZILxf`,
	"affiliate-history__select": `affiliate-history__select_VXv4A`
};
export default ___CSS_LOADER_EXPORT___;
