// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flag-icon_HTAIX{display:inline-flex;flex-direction:column;flex-shrink:0;align-items:center;justify-content:center}.flag-icon--size-20_wHKes{width:20px;height:20px}.flag-icon--size-24_vUiZj{width:24px;height:24px}.flag-icon--size-28_ePO4g{width:28px;height:28px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flag-icon": `flag-icon_HTAIX`,
	"flag-icon--size-20": `flag-icon--size-20_wHKes`,
	"flag-icon--size-24": `flag-icon--size-24_vUiZj`,
	"flag-icon--size-28": `flag-icon--size-28_ePO4g`
};
export default ___CSS_LOADER_EXPORT___;
