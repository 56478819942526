import { computed } from 'vue';
import { LandingStyleAnimation } from 'web/src/modules/landings/system/types';
export default function useGlobalLandingClass(preset, animation) {
    import(`./system/presets/${preset}.scss`);
    if (animation !== LandingStyleAnimation.NO_ANIMATION) import(`./system/animations/${animation}.scss`);
    const presetClass = computed(()=>`landing--${preset.toLowerCase()}`);
    const animationClass = computed(()=>animation !== LandingStyleAnimation.NO_ANIMATION ? `landing--${animation.toLowerCase()}` : '');
    return {
        presetClass,
        animationClass
    };
}
