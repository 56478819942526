// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-no-results_aYerP{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;padding:12px 0;color:var(--TextSecondary);text-align:center}@media(min-width:768px){.search-no-results_aYerP{padding:12px 0}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-no-results": `search-no-results_aYerP`
};
export default ___CSS_LOADER_EXPORT___;
