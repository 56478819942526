// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .language-selector_lXW5z{display:flex;flex-direction:column;gap:4px;padding:0 32px 32px;margin:0;list-style:none}html.app__layout--desktop .language-selector__item_rcPWx{position:relative;display:flex;flex-direction:row;gap:0;align-items:center;justify-content:space-between;width:100%;height:44px;padding:0 12px;color:var(--TextPrimary);cursor:pointer;background-color:var(--Layer1);border:none;border-radius:5px}html.app__layout--desktop .language-selector__item_rcPWx:hover:not(html.app__layout--desktop .language-selector__item-active_YRapP){color:var(--TextDefault);background-color:var(--LanguageItemHoverColor)}html.app__layout--desktop .language-selector__item-active_YRapP{color:var(--TextDefault);cursor:default;background-color:var(--Highlight);border:1px none}html.app__layout--desktop .language-selector__item-text_p77UM{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:inline-flex;flex-grow:1;padding:0 14px 0 8px;-webkit-user-select:none;user-select:none}html.app__layout--desktop .language-selector__icon-selected_u7zuU{color:var(--TextDefault)}html.app__layout--desktop .language-selector__icon-container_bznxs{position:relative}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language-selector": `language-selector_lXW5z`,
	"language-selector__item": `language-selector__item_rcPWx`,
	"language-selector__item-active": `language-selector__item-active_YRapP`,
	"language-selector__item-text": `language-selector__item-text_p77UM`,
	"language-selector__icon-selected": `language-selector__icon-selected_u7zuU`,
	"language-selector__icon-container": `language-selector__icon-container_bznxs`
};
export default ___CSS_LOADER_EXPORT___;
