// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.currency-input__currency_m8xys{pointer-events:none}.currency-input__currency--left__6GAg{margin-right:5px}.currency-input__currency--right_RFfi_{margin-left:5px}.currency-input__container_mr7If{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;width:100%;min-height:44px;padding:0 12px;color:var(--TextDefault);background-color:#0000;caret-color:var(--Blue);border:none;position:relative;display:flex;align-items:center;overflow:hidden;cursor:text}.currency-input__container_mr7If::placeholder{color:#0000}.currency-input__container_mr7If:active,.currency-input__container_mr7If:focus{z-index:1;color:var(--TextDefault)}.currency-input__container_mr7If:disabled{color:var(--TextSecondary);-webkit-text-fill-color:var(--TextSecondary);opacity:1}.currency-input__container--error_lK4fy{color:var(--TextDefault)}.currency-input__container--error_lK4fy .currency-input__placeholder_OQN9w{color:var(--ErrorText)}.currency-input__container--disabled_MdO0l{color:var(--TextSecondary);cursor:default}.currency-input__container--icon-suffix_OIMeI{padding-right:36px}.currency-input--large_EEYlc .currency-input__container_mr7If{min-height:44px;padding:0 12px;border-radius:5px}.currency-input--large_EEYlc .currency-input__container--icon-suffix_OIMeI{padding-right:36px}.currency-input__content_UvC3_{min-height:16px;position:relative;display:inline-flex;flex:0 1 auto;overflow:hidden}.currency-input__content--empty_DK5Er{flex:1}.currency-input__input_d6iD4,.currency-input__width-holder_ksxXY{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;padding:0;background-color:#0000}.currency-input__input_d6iD4{position:absolute;top:0;right:0;bottom:0;left:0;max-width:100%;color:var(--TextDefault);border:none}.currency-input__input_d6iD4::placeholder{color:var(--TextSecondary)}.currency-input__width-holder_ksxXY{position:relative;top:-100%;clip:rect(0 0 0 0);clip-path:inset(50%);white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"currency-input__currency": `currency-input__currency_m8xys`,
	"currency-input__currency--left": `currency-input__currency--left__6GAg`,
	"currency-input__currency--right": `currency-input__currency--right_RFfi_`,
	"currency-input__container": `currency-input__container_mr7If`,
	"currency-input__container--error": `currency-input__container--error_lK4fy`,
	"currency-input__placeholder": `currency-input__placeholder_OQN9w`,
	"currency-input__container--disabled": `currency-input__container--disabled_MdO0l`,
	"currency-input__container--icon-suffix": `currency-input__container--icon-suffix_OIMeI`,
	"currency-input--large": `currency-input--large_EEYlc`,
	"currency-input__content": `currency-input__content_UvC3_`,
	"currency-input__content--empty": `currency-input__content--empty_DK5Er`,
	"currency-input__input": `currency-input__input_d6iD4`,
	"currency-input__width-holder": `currency-input__width-holder_ksxXY`
};
export default ___CSS_LOADER_EXPORT___;
