import { computed, ref, toRef } from 'vue';
import { defineStore } from 'pinia';
import { uiAccountTypeMain } from '@leon-hub/api-sdk';
import { isUndefined } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { requestSaveBetSlip, requestSavePlacedBet } from '../utils';
import { useSlipInfoStore } from '../../slip-info/store';
import { getMakeBetEntryItems } from '../../place-bet/utils/getMakeBetEntryItems';
import { useShareEventLogging } from '../../../composable';
const useSlipShareStore = defineStore('slip-share-store', ()=>{
    const apiClient = useGraphqlClient();
    const siteConfigStore = useSiteConfigStore();
    const { isLoggedIn } = useIsLoggedIn();
    const { convertToBaseError } = useErrorsConverter();
    const { logUnableToShare } = useShareEventLogging();
    const slipBlock = toRef(siteConfigStore, 'slipBlock');
    const sharedBetSlipBookingCodeEnabled = computed(()=>slipBlock.value?.sharedBetSlipBookingCodeEnabled ?? false);
    const sharedBetSlipLinkEnabled = computed(()=>slipBlock.value?.sharedBetSlipLinkEnabled ?? false);
    const isSlipShareEnabled = computed(()=>isLoggedIn.value && (sharedBetSlipBookingCodeEnabled.value || sharedBetSlipLinkEnabled.value));
    const isCurrentSlipShareEnabled = computed(()=>isSlipShareEnabled.value && !!slipBlock.value?.currentSlipShareEnabled);
    const isCustomerBetShareEnabled = computed(()=>isSlipShareEnabled.value && !!slipBlock.value?.customerBetShareEnabled);
    const slipInfoStore = useSlipInfoStore();
    const slipEntries = toRef(slipInfoStore, 'slipEntries');
    const slipType = toRef(slipInfoStore, 'slipType');
    const bookingCode = ref(null);
    const imageUrl = ref(null);
    const shareLink = ref(null);
    const amountSwitcherIsVisible = ref(false);
    const showAmountEnabled = ref(false);
    const shareModalShown = ref(false);
    const isSharingPlacedBet = ref(false);
    const isPending = ref(false);
    const errorMessage = ref(null);
    const setPending = (value)=>{
        isPending.value = value;
    };
    const setErrorMessage = (value)=>{
        errorMessage.value = value;
    };
    const setShareData = (data)=>{
        bookingCode.value = data?.bookingCode ?? null;
        shareLink.value = data?.sharedLink ?? '';
        imageUrl.value = data?.imgSrc ?? null;
    };
    const handleError = (error)=>{
        const baseError = convertToBaseError(error);
        logger.error(baseError);
        setErrorMessage(baseError.message);
        logUnableToShare(baseError.message);
        setPending(false);
    };
    const shareCurrentSlipData = async ()=>{
        if (!slipEntries.value || !slipType.value) return;
        setPending(true);
        try {
            const savedSlipData = await requestSaveBetSlip({
                slipEntries: getMakeBetEntryItems(slipEntries.value),
                slipType: slipType.value
            }, apiClient);
            if (savedSlipData) {
                setShareData(savedSlipData);
                isSharingPlacedBet.value = false;
                setPending(false);
            }
        } catch (error) {
            handleError(error);
        }
    };
    let lastSharePayload = null;
    let cachedSharedBets = [];
    const getSharedBetFromApi = async (payload)=>{
        setErrorMessage(null);
        if (!payload.doNotSetPending) setPending(true);
        try {
            const savedSlipData = await requestSavePlacedBet({
                betId: payload.betId,
                // uiAccountTypeMain is default, uiAccountTypeSbc is being used for freebet
                accountType: payload.accountType ?? uiAccountTypeMain,
                showAmount: payload.showAmount ?? false
            }, apiClient);
            cachedSharedBets.push({
                payload,
                sharedData: savedSlipData
            });
            setShareData(savedSlipData);
            setPending(false);
        } catch (error) {
            handleError(error);
        }
    };
    const shareBetById = async (payload)=>{
        lastSharePayload = payload;
        if (!isUndefined(payload.showAmount)) showAmountEnabled.value = payload.showAmount;
        isSharingPlacedBet.value = true;
        const matchedCacheRecord = cachedSharedBets.find((item)=>item.payload.betId === payload.betId && item.payload.showAmount === payload.showAmount);
        if (matchedCacheRecord) {
            setShareData(matchedCacheRecord.sharedData);
            return;
        }
        await getSharedBetFromApi(payload);
    };
    const regenerateSharePicture = (showAmount)=>{
        if (lastSharePayload) {
            imageUrl.value = null;
            shareBetById({
                ...lastSharePayload,
                showAmount,
                doNotSetPending: true
            });
        }
    };
    const showSharedModal = ()=>{
        shareModalShown.value = true;
    };
    const showAmountSwitcher = ()=>{
        amountSwitcherIsVisible.value = true;
    };
    const canselShare = ()=>{
        setShareData(null);
        setErrorMessage(null);
        shareModalShown.value = false;
        amountSwitcherIsVisible.value = false;
        showAmountEnabled.value = false;
        lastSharePayload = null;
        cachedSharedBets = [];
    };
    return {
        bookingCode,
        imageUrl,
        shareLink,
        shareModalShown,
        isSlipShareEnabled,
        amountSwitcherIsVisible,
        showAmountEnabled,
        isCurrentSlipShareEnabled,
        isCustomerBetShareEnabled,
        isSharingPlacedBet,
        isPending,
        errorMessage,
        canselShare,
        shareCurrentSlipData,
        shareBetById,
        showSharedModal,
        showAmountSwitcher,
        regenerateSharePicture
    };
});
export default useSlipShareStore;
