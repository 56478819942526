// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .slots-search-item_HxqDW{list-style:none}html.app__layout--desktop .slots-search-item__link_Cw3ry{display:flex;align-items:center;padding:8px;-webkit-text-decoration:none;text-decoration:none;background:var(--Layer1);border-radius:var(--BorderRadius)}html.app__layout--desktop .slots-search-item__link_Cw3ry:hover{background:var(--Highlight)}html.app__layout--desktop .slots-search-item__image-holder_AdWai{position:relative;align-self:flex-start;width:80px;min-width:80px;height:80px}html.app__layout--desktop .slots-search-item__nt-badge_e1VRx{position:absolute;top:5px;right:5px}html.app__layout--desktop .slots-search-item__image_J3IRz{width:100%;height:100%;overflow:hidden;border-radius:var(--BorderRadius)}html.app__layout--desktop .slots-search-item__info_BHfPh{padding-left:16px}html.app__layout--desktop .slots-search-item__name_FxzjX{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;margin-bottom:4px;color:var(--TextDefault)}html.app__layout--desktop .slots-search-item__categories_bqXQR{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;flex-wrap:wrap;color:var(--TextPrimary)}html.app__layout--desktop .slots-search-item__categories-item--group_ai8vM{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"slots-search-item": `slots-search-item_HxqDW`,
	"slots-search-item__link": `slots-search-item__link_Cw3ry`,
	"slots-search-item__image-holder": `slots-search-item__image-holder_AdWai`,
	"slots-search-item__nt-badge": `slots-search-item__nt-badge_e1VRx`,
	"slots-search-item__image": `slots-search-item__image_J3IRz`,
	"slots-search-item__info": `slots-search-item__info_BHfPh`,
	"slots-search-item__name": `slots-search-item__name_FxzjX`,
	"slots-search-item__categories": `slots-search-item__categories_bqXQR`,
	"slots-search-item__categories-item--group": `slots-search-item__categories-item--group_ai8vM`
};
export default ___CSS_LOADER_EXPORT___;
