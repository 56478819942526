import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "crossorigin",
    "target",
    "allow",
    "allowfullscreen",
    "height",
    "name",
    "title",
    "scrolling"
];
import { ref, toRef, onBeforeMount, onBeforeUnmount, normalizeClass } from 'vue';
import VPreloader from 'web/src/components/Preloader/VPreloader/VPreloader.vue';
import { useVIframe } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VIframe',
    props: {
        src: {},
        name: {},
        showLoader: {
            type: Boolean
        },
        additionalQueryStringParameters: {},
        crossorigin: {
            default: 'anonymous'
        },
        target: {
            default: '_parent'
        },
        allow: {},
        allowfullscreen: {
            type: Boolean
        },
        scrolling: {},
        fullSize: {
            type: Boolean
        },
        fullHeight: {
            type: Boolean
        },
        heightAspectRatio: {},
        flexLayout: {
            type: Boolean
        },
        fullWidth: {
            type: Boolean
        },
        autoHeight: {
            type: Boolean
        },
        isDark: {
            type: Boolean
        },
        hideFullHeightInUrl: {
            type: Boolean
        },
        hideTitle: {
            type: Boolean
        },
        timeout: {
            default: 0
        },
        iframeClass: {}
    },
    emits: [
        "window-iframe-load",
        "iframe-application-ready",
        "load",
        "error"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const iframeElement = ref();
        const { loading, reCreateIframeKey, styleObject, iframeVisible, title, height, parametersDecoratedSrc, onLoad, beforeMount, beforeUnmount, resizeIframe, postMessage, onError } = useVIframe({
            iframeElement,
            src: toRef(props, 'src'),
            name: toRef(props, 'name'),
            timeout: toRef(props, 'timeout'),
            additionalQueryStringParameters: toRef(props, 'additionalQueryStringParameters', []),
            isHideFullHeightInUrl: toRef(props, 'hideFullHeightInUrl'),
            isFullSize: toRef(props, 'fullSize', false),
            heightAspectRatio: toRef(props, 'heightAspectRatio'),
            isAutoHeight: toRef(props, 'autoHeight', false),
            isHideTitle: toRef(props, 'hideTitle', false)
        }, emit);
        onBeforeMount(beforeMount);
        onBeforeUnmount(beforeUnmount);
        __expose({
            resizeIframe,
            postMessage
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['v-iframe']]: true,
                    [_ctx.$style['v-iframe--full-height']]: _ctx.fullHeight || _ctx.fullSize,
                    [_ctx.$style['v-iframe--aspect-ratio-height']]: !!_ctx.heightAspectRatio,
                    [_ctx.$style['v-iframe--full-width']]: _ctx.fullWidth || _ctx.fullSize,
                    [_ctx.$style['v-iframe--flex-layout']]: _ctx.flexLayout
                }),
                style: _normalizeStyle(_unref(styleObject))
            }, [
                _ctx.showLoader && _unref(loading) ? (_openBlock(), _createBlock(VPreloader, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['v-iframe__loader']),
                    hint: ""
                }, null, 8, [
                    "class"
                ])) : _createCommentVNode("", true),
                (_openBlock(), _createElementBlock("iframe", {
                    key: _unref(reCreateIframeKey),
                    ref_key: "iframeElement",
                    ref: iframeElement,
                    src: _unref(parametersDecoratedSrc),
                    crossorigin: _ctx.crossorigin,
                    target: _ctx.target,
                    allow: _ctx.allow,
                    allowfullscreen: _ctx.allowfullscreen || void 0,
                    class: _normalizeClass({
                        [_ctx.$style['v-iframe__iframe']]: true,
                        [_ctx.$style['v-iframe__iframe--hidden']]: !_unref(iframeVisible),
                        [_ctx.$style['v-iframe__iframe--dark']]: _ctx.isDark,
                        [normalizeClass(_ctx.iframeClass)]: !!_ctx.iframeClass
                    }),
                    height: _unref(height),
                    name: _ctx.name,
                    title: _unref(title),
                    scrolling: _ctx.scrolling,
                    onLoad: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onLoad) && _unref(onLoad)(...args);
                    }),
                    onError: _cache[1] || (_cache[1] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onError) && _unref(onError)(...args);
                    })
                }, null, 42, _hoisted_1))
            ], 6)), [
                [
                    _directive_auto_id,
                    'VIframe'
                ]
            ]);
        };
    }
});
