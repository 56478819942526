// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prematch-info-countdown_YagOE{display:flex;align-items:flex-start;justify-content:center}.prematch-info-countdown__item_fGg0r{display:flex;flex-direction:column;align-items:center;text-align:center}.prematch-info-countdown__delimiter_KEbz5{width:7px;margin:0 4px;text-align:center;opacity:.5}.prematch-info-countdown__counter_v1S1d,.prematch-info-countdown__delimiter_KEbz5{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--White)}.prematch-info-countdown__counter_v1S1d{width:40px}.prematch-info-countdown__label_ghJRn{font-size:10px;font-weight:400;line-height:12px;letter-spacing:normal;color:var(--White);text-transform:uppercase;letter-spacing:.2px;opacity:.5}.prematch-info-countdown--narrow_MkzxM .prematch-info-countdown__delimiter_KEbz5{margin:0 2px}.prematch-info-countdown--narrow_MkzxM .prematch-info-countdown__counter_v1S1d{width:30px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"prematch-info-countdown": `prematch-info-countdown_YagOE`,
	"prematch-info-countdown__item": `prematch-info-countdown__item_fGg0r`,
	"prematch-info-countdown__delimiter": `prematch-info-countdown__delimiter_KEbz5`,
	"prematch-info-countdown__counter": `prematch-info-countdown__counter_v1S1d`,
	"prematch-info-countdown__label": `prematch-info-countdown__label_ghJRn`,
	"prematch-info-countdown--narrow": `prematch-info-countdown--narrow_MkzxM`
};
export default ___CSS_LOADER_EXPORT___;
