import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed, onMounted } from 'vue';
import { sleep } from '@leon-hub/utils';
import LSnackbarContent from 'web/src/components/SnackBar/VSnackBar/components/LSnackbarContent/LSnackbarContent.vue';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSnackBar',
    props: {
        type: {
            default: 'default'
        },
        title: {},
        text: {},
        buttonLabel: {},
        isSecondaryButton: {
            type: Boolean
        },
        hasClose: {
            type: Boolean
        },
        isTabBarHidden: {
            type: Boolean
        },
        isTooltipZIndex: {
            type: Boolean
        },
        iconName: {},
        image: {},
        duration: {},
        isDone: {
            type: Boolean
        },
        primaryButton: {},
        secondaryButton: {},
        linkButton: {},
        isPrimaryButtonDone: {
            type: Boolean
        },
        isSecondaryButtonDone: {
            type: Boolean
        },
        isMaxZIndex: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "click-close",
        "click-secondary",
        "click-link"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        onMounted(async ()=>{
            if (props.duration) {
                await sleep(1000 * props.duration);
                emit('click-close', new MouseEvent('click'));
            }
        });
        computed(()=>{
            switch(props.type){
                case 'error':
                    return 'error';
                case 'success':
                    return 'success';
                default:
                    return 'primary';
            }
        });
        computed(()=>'iconName' in props ? props.iconName : void 0);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['snack-bar']]: true,
                    [_ctx.$style['snack-bar--hidden-tabbar']]: _ctx.isTabBarHidden,
                    [_ctx.$style['snack-bar--tooltip-index']]: _ctx.isTooltipZIndex,
                    [_ctx.$style['snack-bar--max-z-index']]: _ctx.isMaxZIndex
                })
            }, [
                (_openBlock(), _createBlock(LSnackbarContent, _mergeProps({
                    key: 1
                }, props, {
                    class: _ctx.$style['snack-bar__content'],
                    onClick: _cache[2] || (_cache[2] = ($event)=>emit('click', $event)),
                    onClickClose: _cache[3] || (_cache[3] = ($event)=>emit('click-close', $event)),
                    onClickSecondary: _cache[4] || (_cache[4] = ($event)=>emit('click-secondary', $event)),
                    onClickLink: _cache[5] || (_cache[5] = ($event)=>emit('click-link', $event))
                }), null, 16, [
                    "class"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VSnackBar'
                ]
            ]);
        };
    }
});
