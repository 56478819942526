// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-filter-tabs_pv2Gu{position:relative}.history-filter-tabs__with-gap_Gz2m0{gap:0}.history-filter-tabs__wrapper__Sni_{padding:0 0 16px;background-color:var(--ColorBackground3)}.history-filter-tabs__swiper_BuxD_{padding:0 16px}html.app__layout--desktop .history-filter-tabs_pv2Gu{top:auto;background-color:#0000}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-filter-tabs": `history-filter-tabs_pv2Gu`,
	"history-filter-tabs__with-gap": `history-filter-tabs__with-gap_Gz2m0`,
	"history-filter-tabs__wrapper": `history-filter-tabs__wrapper__Sni_`,
	"history-filter-tabs__swiper": `history-filter-tabs__swiper_BuxD_`
};
export default ___CSS_LOADER_EXPORT___;
