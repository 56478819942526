import { isString } from '@leon-hub/guards';
import { FormControlType } from '../enums';
const allWidgets = [
    FormControlType.Text,
    FormControlType.Email,
    FormControlType.Password,
    FormControlType.PasswordValidator,
    FormControlType.Select,
    FormControlType.DropListSelect,
    FormControlType.Phone,
    FormControlType.PhoneInput,
    FormControlType.PhoneCountriesSelectorInput,
    FormControlType.Checkbox,
    FormControlType.Date,
    FormControlType.BonusCode,
    FormControlType.SmsCode,
    FormControlType.Switch,
    FormControlType.Hidden,
    FormControlType.FileSingle,
    FormControlType.FileMultiple,
    FormControlType.FileDragAndDrop,
    FormControlType.FastSum,
    FormControlType.CreditCardSelector,
    FormControlType.PhoneDisabledInput,
    FormControlType.WalletDisabledInput,
    FormControlType.WebmoneyDisabledInput,
    FormControlType.Captcha,
    FormControlType.CaptchaV3,
    FormControlType.PhotoInput,
    FormControlType.Fio,
    FormControlType.PieTimerInput,
    FormControlType.MultilineTextInput,
    FormControlType.AutoheightTextInput,
    FormControlType.CardCvv,
    FormControlType.CardExpirationDate,
    FormControlType.CardNumber,
    FormControlType.DepositBonus,
    FormControlType.CurrencyInput,
    FormControlType.CardName,
    FormControlType.SuggestEmail,
    FormControlType.CPFNumber,
    FormControlType.AutocompleteAddress,
    FormControlType.SelectWithSearch,
    FormControlType.CurrencySelect,
    FormControlType.AsoTermsCheckbox,
    FormControlType.TermsAcceptionCheckbox,
    FormControlType.CryptoPaymentData
];
export default function isFormWidget(value) {
    if (isString(value) && allWidgets.includes(value)) return true;
    return false;
}
