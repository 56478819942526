import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import LandingListItem from 'web/src/modules/landings/system/components/LandingListItem/LandingListItem.vue';
import LandingTitle from 'web/src/modules/landings/system/components/LandingTitle/LandingTitle.vue';
import LandingLabel from 'web/src/modules/landings/system/components/LandingLabel/LandingLabel.vue';
import { LabelKind } from 'web/src/modules/landings/system/components/LandingLabel/types';
import { TitleKind } from 'web/src/modules/landings/system/components/LandingTitle/types';
import { textAlign } from 'web/src/modules/landings/system/components/LandingList/types';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingList',
    props: {
        title: {},
        headerTextAlign: {
            default: textAlign.CENTER
        },
        label: {},
        list: {},
        isVerticalPadding: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VAnimationStarter, {
                class: _normalizeClass({
                    [_ctx.$style['landing-list']]: true,
                    [_ctx.$style['landing-list--vertical-padding']]: _ctx.isVerticalPadding
                }),
                "root-margin": "0px 0px -50px 0px"
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['landing-list__header']]: true,
                                [_ctx.$style[`landing-list__header--${_ctx.headerTextAlign}`]]: _ctx.headerTextAlign !== _unref(textAlign).CENTER
                            })
                        }, [
                            _ctx.title ? (_openBlock(), _createBlock(LandingTitle, {
                                key: 0,
                                content: _ctx.title,
                                kind: _unref(TitleKind).SECONDARY
                            }, null, 8, [
                                "content",
                                "kind"
                            ])) : _createCommentVNode("", true),
                            _ctx.label ? (_openBlock(), _createBlock(LandingLabel, {
                                key: 1,
                                content: _ctx.label,
                                kind: _unref(LabelKind).SECONDARY
                            }, null, 8, [
                                "content",
                                "kind"
                            ])) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['landing-list__body'])
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item)=>(_openBlock(), _createBlock(LandingListItem, _mergeProps({
                                    key: item.index,
                                    ref_for: true
                                }, item, {
                                    class: _ctx.$style['landing-list__item']
                                }), null, 16, [
                                    "class"
                                ]))), 128))
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'LandingList'
                ]
            ]);
        };
    }
});
