// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .casino-game-iframe-error_Synzd{width:100%;height:100%;background-color:var(--Layer1)}html.app__layout--desktop .casino-game-iframe-error__inner_P3k2V{position:absolute;top:50%;left:50%;width:320px;padding-bottom:64px;transform:translate(-50%,-50%)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-game-iframe-error": `casino-game-iframe-error_Synzd`,
	"casino-game-iframe-error__inner": `casino-game-iframe-error__inner_P3k2V`
};
export default ___CSS_LOADER_EXPORT___;
