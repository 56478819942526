// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-navigation-points_dZd48{display:flex}.swiper-navigation-points--default_jWvXy{gap:12px}.swiper-navigation-points--linear__I6WJ{gap:4px}.swiper-navigation-points--landing_o5YOy{gap:12px}@media(hover:hover)and (pointer:fine){.swiper-navigation-points__item_GBVoR:hover{cursor:pointer}}.swiper-navigation-points__item--default_Q7l8_{width:8px;height:8px;background-color:var(--Highlight);border-radius:50%}.swiper-navigation-points__item--default-selected_zf5uw{background-color:var(--TextDefault)}.swiper-navigation-points__item--linear_J14pk{width:4px;height:4px;background-color:var(--Highlight);border-radius:4px}@media(hover:hover)and (pointer:fine){.swiper-navigation-points__item--linear_J14pk:hover{cursor:auto}}.swiper-navigation-points__item--linear-transition_PNqWT{transition:width .3s ease-in-out,background-color .3s ease-in-out}.swiper-navigation-points__item--linear-selected_yqUi5{width:24px;background-color:var(--TextDefault);border-radius:4px}.swiper-navigation-points__item--landing_ysKYA{width:8px;height:8px;background-color:var(--DHighlight);border-radius:50%}.swiper-navigation-points__item--landing-transition_U1naO{transition:background-color .15s ease-in-out}.swiper-navigation-points__item--landing-selected_O1poz{background-color:var(--DBrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper-navigation-points": `swiper-navigation-points_dZd48`,
	"swiper-navigation-points--default": `swiper-navigation-points--default_jWvXy`,
	"swiper-navigation-points--linear": `swiper-navigation-points--linear__I6WJ`,
	"swiper-navigation-points--landing": `swiper-navigation-points--landing_o5YOy`,
	"swiper-navigation-points__item": `swiper-navigation-points__item_GBVoR`,
	"swiper-navigation-points__item--default": `swiper-navigation-points__item--default_Q7l8_`,
	"swiper-navigation-points__item--default-selected": `swiper-navigation-points__item--default-selected_zf5uw`,
	"swiper-navigation-points__item--linear": `swiper-navigation-points__item--linear_J14pk`,
	"swiper-navigation-points__item--linear-transition": `swiper-navigation-points__item--linear-transition_PNqWT`,
	"swiper-navigation-points__item--linear-selected": `swiper-navigation-points__item--linear-selected_yqUi5`,
	"swiper-navigation-points__item--landing": `swiper-navigation-points__item--landing_ysKYA`,
	"swiper-navigation-points__item--landing-transition": `swiper-navigation-points__item--landing-transition_U1naO`,
	"swiper-navigation-points__item--landing-selected": `swiper-navigation-points__item--landing-selected_O1poz`
};
export default ___CSS_LOADER_EXPORT___;
