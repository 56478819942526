import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VLogoLoader from 'web/src/components/Loader/VLogoLoader/VLogoLoader.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoGameLoader',
    props: {
        isDark: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-game-loader']]: true,
                    [_ctx.$style['casino-game-loader--dark']]: _ctx.isDark
                })
            }, [
                _createVNode(VLogoLoader, {
                    class: _normalizeClass(_ctx.$style['casino-game-loader__logo'])
                }, null, 8, [
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoGameLoader'
                ]
            ]);
        };
    }
});
