// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs-related-list-item_ADpFV{min-height:40px;margin-bottom:0;background-color:#0000;font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal}.breadcrumbs-related-list-item--borderless_cdrrn.breadcrumbs-related-list-item_ADpFV:first-child,.breadcrumbs-related-list-item--borderless_cdrrn.breadcrumbs-related-list-item_ADpFV:first-child:last-of-type{border-top-left-radius:0}.breadcrumbs-related-list-item--borderless_cdrrn.breadcrumbs-related-list-item_ADpFV:not(:last-of-type){margin-bottom:0}.breadcrumbs-related-list-item__link_Kgw7l{display:block;padding:12px;color:var(--Layer1);-webkit-text-decoration:none;text-decoration:none;cursor:pointer;transition:color .15s cubic-bezier(.25,.8,.25,1),background-color .15s cubic-bezier(.25,.8,.25,1)}.breadcrumbs-related-list-item__link_Kgw7l:hover{color:var(--TextDefault);background-color:var(--Highlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumbs-related-list-item": `breadcrumbs-related-list-item_ADpFV`,
	"breadcrumbs-related-list-item--borderless": `breadcrumbs-related-list-item--borderless_cdrrn`,
	"breadcrumbs-related-list-item__link": `breadcrumbs-related-list-item__link_Kgw7l`
};
export default ___CSS_LOADER_EXPORT___;
