import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import useSwiperNavigationButton from './composables/useSwiperNavigationButton';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SwiperNavigationButton',
    props: {
        isNext: {
            type: Boolean
        },
        isRounded: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { iconName, iconSize } = useSwiperNavigationButton(props);
        function emitClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['swiper-navigation-button']]: true,
                    [_ctx.$style['swiper-navigation-button--rounded']]: _ctx.isRounded,
                    [_ctx.$style['swiper-navigation-button--next']]: _ctx.isNext
                }),
                onClick: emitClick
            }, [
                _createVNode(_unref(VIcon), {
                    name: _unref(iconName),
                    size: _unref(iconSize)
                }, null, 8, [
                    "name",
                    "size"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'SwiperNavigationButton'
                ]
            ]);
        };
    }
});
