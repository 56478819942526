// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-extended-skeleton_TN3sN{display:flex;align-items:center;height:44px;padding:0 10px;background-color:var(--Layer1);border-radius:0 0 5px 5px}.sport-event-extended-skeleton__item_SkjZf{padding:3px 11px 2px;margin:0 10px}.sport-event-extended-skeleton__item_SkjZf:before{display:block;width:64px;height:7px;content:"";background-color:var(--Highlight);border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-extended-skeleton": `sport-event-extended-skeleton_TN3sN`,
	"sport-event-extended-skeleton__item": `sport-event-extended-skeleton__item_SkjZf`
};
export default ___CSS_LOADER_EXPORT___;
