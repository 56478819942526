// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-header_EdZSs{display:flex;align-items:center;padding:16px 0 12px 8px;color:var(--TextSecondary)}.sub-header__text_OdTNe{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;flex:1;margin:0;color:var(--TextSecondary)}.sub-header__icon--prefix_qOI2I{margin-right:8px}.sub-header__icon--suffix_Gl0Uh{margin:0 8px}.sub-header--link_cPUyD{-webkit-text-decoration:none;text-decoration:none}html.app__layout--desktop .sub-header--link_cPUyD{cursor:pointer}html.app__layout--desktop .sub-header--link_cPUyD:hover{color:var(--TextDefault)}html.app__layout--desktop .sub-header--link_cPUyD:hover .sub-header__text_OdTNe{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sub-header": `sub-header_EdZSs`,
	"sub-header__text": `sub-header__text_OdTNe`,
	"sub-header__icon--prefix": `sub-header__icon--prefix_qOI2I`,
	"sub-header__icon--suffix": `sub-header__icon--suffix_Gl0Uh`,
	"sub-header--link": `sub-header--link_cPUyD`
};
export default ___CSS_LOADER_EXPORT___;
