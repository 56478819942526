// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.theme-list_ZEHnx{display:flex;flex-direction:column;gap:4px}.theme-list__item_Mr3BR{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;justify-content:space-between;width:100%;height:44px;padding-right:14px;color:var(--TextPrimary);cursor:pointer;background-color:var(--Layer1);border-radius:5px}@media(hover:hover)and (pointer:fine){.theme-list__item_Mr3BR:hover{background-color:var(--Layer2)}}.theme-list__item--active_HdCA7{color:var(--TextDefault);background-color:var(--Highlight)}@media(hover:hover)and (pointer:fine){.theme-list__item--active_HdCA7:hover{background-color:var(--Highlight)}}.theme-list__icon_yJyta{justify-content:center;width:36px;height:44px}.theme-list__icon_yJyta,.theme-list__left_NUK5N{display:flex;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"theme-list": `theme-list_ZEHnx`,
	"theme-list__item": `theme-list__item_Mr3BR`,
	"theme-list__item--active": `theme-list__item--active_HdCA7`,
	"theme-list__icon": `theme-list__icon_yJyta`,
	"theme-list__left": `theme-list__left_NUK5N`
};
export default ___CSS_LOADER_EXPORT___;
