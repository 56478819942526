// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-carousel-slide_Oj0he{position:absolute;top:0;left:0;z-index:0;width:100%;-webkit-user-select:none;user-select:none;opacity:0;transition:opacity .4s ease-in-out 0s;-webkit-user-drag:none}.fade-carousel-slide--active_MxNE0{position:relative;z-index:1;opacity:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fade-carousel-slide": `fade-carousel-slide_Oj0he`,
	"fade-carousel-slide--active": `fade-carousel-slide--active_MxNE0`
};
export default ___CSS_LOADER_EXPORT___;
