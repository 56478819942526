// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-list-item_oL4a5{position:relative;display:flex;flex-direction:row;gap:12px;justify-content:flex-start;padding:16px;background:var(--landingListItemBackgroundPrimary);border:var(--landingListItemBorderPrimary);border-radius:24px;opacity:var(--animation-list-item-opacity);transition:var(--animation-list-item-transition);transform:var(--animation-list-item-transform)}.landing-list-item_oL4a5:first-child{transition-delay:var(--animation-list-item-delay-1)}.landing-list-item_oL4a5:nth-child(2){transition-delay:var(--animation-list-item-delay-2)}.landing-list-item--secondary_xvLYX{background:var(--landingListItemBackgroundSecondary);border:var(--landingListItemBorderSecondary)}.landing-list-item__paragraph_xhgIq{display:flex;flex-direction:column;gap:8px}.landing-list-item__step-number_hcHCi{position:absolute;top:24px;right:24px}.landing-list-item--row-center_UzXAQ{align-items:center}.landing-list-item--row-top-reverse_AbhI_{flex-direction:row-reverse}.landing-list-item--row-center-reverse_VNAaR{flex-direction:row-reverse;align-items:center}.landing-list-item--column-left_udruF{flex-direction:column}.landing-list-item--column-left_udruF .landing-list-item__paragraph_xhgIq{gap:10px}.landing-list-item--column-center_ePFMB{flex-direction:column;align-items:center}.landing-list-item--column-center_ePFMB .landing-list-item__paragraph_xhgIq{gap:10px;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-list-item": `landing-list-item_oL4a5`,
	"landing-list-item--secondary": `landing-list-item--secondary_xvLYX`,
	"landing-list-item__paragraph": `landing-list-item__paragraph_xhgIq`,
	"landing-list-item__step-number": `landing-list-item__step-number_hcHCi`,
	"landing-list-item--row-center": `landing-list-item--row-center_UzXAQ`,
	"landing-list-item--row-top-reverse": `landing-list-item--row-top-reverse_AbhI_`,
	"landing-list-item--row-center-reverse": `landing-list-item--row-center-reverse_VNAaR`,
	"landing-list-item--column-left": `landing-list-item--column-left_udruF`,
	"landing-list-item--column-center": `landing-list-item--column-center_ePFMB`
};
export default ___CSS_LOADER_EXPORT___;
