// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.balance_Pqy92{display:flex;align-items:center;padding:2px;color:var(--TextDefault);border-radius:40px}@media(hover:hover)and (pointer:fine){.balance_Pqy92{cursor:pointer}.balance_Pqy92:hover .balance__gift_le9le{background-color:var(--OpacityLayer2)}.balance_Pqy92:hover .balance__gift_le9le:hover{background-color:var(--BrandHighlight)}.balance_Pqy92:hover .balance__gift_le9le:hover .balance__gift-icon_I6e98{color:var(--BrandText)}.balance_Pqy92:hover .balance__button_I03S7{background-color:var(--DBrandHighlight)}}.balance__text_IerpW{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;padding:0 8px 0 10px}.balance__button_I03S7{min-width:32px}.balance__gift_le9le{width:24px;height:24px;margin-left:4px;background-color:var(--Highlight);display:flex;align-items:center;justify-content:center;border-radius:50%}@media(hover:hover)and (pointer:fine){.balance__gift_le9le:hover{background-color:var(--BrandHighlight)}.balance__gift_le9le:hover .balance__gift-icon_I6e98{color:var(--BrandText)}}.balance__gift-icon_I6e98{color:var(--BrandHighlight)}.balance--low_Hreqw{color:var(--ErrorText)}.balance--is-deposit-hidden_z12Ym{padding:0}.balance--is-header_gzmfF{color:var(--DTextDefault)}@media(hover:hover)and (pointer:fine){.balance--is-header_gzmfF:hover .balance__gift_le9le{background-color:var(--DOpacityLayer2)}.balance--is-header_gzmfF:hover .balance__gift_le9le:hover{background-color:var(--DBrandHighlight)}.balance--is-header_gzmfF:hover .balance__gift_le9le:hover .balance__gift-icon_I6e98{color:var(--DBrandText)}.balance--is-header_gzmfF:hover .balance__button_I03S7{background-color:var(--DBrandHighlight)}}.balance--game_QT92s{background-color:var(--ColorBackground2)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance": `balance_Pqy92`,
	"balance__gift": `balance__gift_le9le`,
	"balance__gift-icon": `balance__gift-icon_I6e98`,
	"balance__button": `balance__button_I03S7`,
	"balance__text": `balance__text_IerpW`,
	"balance--low": `balance--low_Hreqw`,
	"balance--is-deposit-hidden": `balance--is-deposit-hidden_z12Ym`,
	"balance--is-header": `balance--is-header_gzmfF`,
	"balance--game": `balance--game_QT92s`
};
export default ___CSS_LOADER_EXPORT___;
