import { isUndefined, bindIsArrayOf, isNumber, isString, isValidInterface } from '@leon-hub/guards';
function isMarketGroupStyleResponse(value) {
    return isValidInterface(value, {
        type: [
            isUndefined,
            isString
        ]
    });
}
function isMarketGroupSpecifierResponse(value) {
    return isValidInterface(value, {
        specifier: [
            isUndefined,
            isString
        ]
    });
}
export const isCoreSportMarketGroupFieldGuards = {
    id: isNumber,
    name: isString,
    marketTypeIds: bindIsArrayOf(isNumber),
    style: [
        isUndefined,
        isMarketGroupStyleResponse
    ],
    specifier: [
        isUndefined,
        isMarketGroupSpecifierResponse
    ]
};
export function isCoreSportMarketGroup(value) {
    return isValidInterface(value, isCoreSportMarketGroupFieldGuards);
}
