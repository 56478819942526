// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotions-shared__title_zGaSe{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault);margin:0;text-align:center}html.app__layout--desktop .promotions-shared__title_zGaSe{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.promotions-shared__title--left_aZE7c{text-align:left}.promotions-shared__title--big_D1lKd{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.how-to-receive-bonus__title_Ja2QP{margin-bottom:16px}.steps-hint__panel__YB9V{position:relative;padding:32px 12px;margin-bottom:8px;text-align:center;background-color:var(--Layer1);border-radius:5px}.steps-hint__panel__YB9V:last-of-type .steps-hint__arrow_tLcxs{display:none}html.app__layout--desktop .steps-hint__panel__YB9V:last-of-type{margin-bottom:0}html.app__layout--desktop .steps-hint__panel__YB9V{padding-right:16px;padding-left:16px}.steps-hint__image-holder_udulq{width:60px;height:60px;margin:0 auto 16px;overflow:hidden;border-radius:12px}html.app__layout--desktop .steps-hint__image-holder_udulq{border-radius:8px}.steps-hint__image_ZR26G{display:flex;align-self:start;width:100%;height:100%;object-fit:cover}.steps-hint__heading_DWQ_p{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;margin:0 0 8px;color:var(--TextDefault)}.steps-hint__text_vQYnN{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}.steps-hint__arrow_tLcxs{z-index:1;position:absolute;bottom:-24px;left:50%;display:flex;align-items:center;justify-content:center;width:40px;height:40px;color:var(--TextDefault);background-color:var(--Layer2);border-radius:50%;transform:translateX(-50%)}.steps-hint__arrow-icon_lfRL9{fill:currentcolor}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotions-shared__title": `promotions-shared__title_zGaSe`,
	"promotions-shared__title--left": `promotions-shared__title--left_aZE7c`,
	"promotions-shared__title--big": `promotions-shared__title--big_D1lKd`,
	"how-to-receive-bonus__title": `how-to-receive-bonus__title_Ja2QP`,
	"steps-hint__panel": `steps-hint__panel__YB9V`,
	"steps-hint__arrow": `steps-hint__arrow_tLcxs`,
	"steps-hint__image-holder": `steps-hint__image-holder_udulq`,
	"steps-hint__image": `steps-hint__image_ZR26G`,
	"steps-hint__heading": `steps-hint__heading_DWQ_p`,
	"steps-hint__text": `steps-hint__text_vQYnN`,
	"steps-hint__arrow-icon": `steps-hint__arrow-icon_lfRL9`
};
export default ___CSS_LOADER_EXPORT___;
