import { toRef } from 'vue';
import { useSnackbarsStore } from 'web/src/modules/snackbars/store';
import { CONNECTION_LOST_SNACKBAR_ID } from 'web/src/modules/snackbars/constants';
export default function useSnackbarHolder() {
    const snackbarsStore = useSnackbarsStore();
    const snackbars = toRef(()=>snackbarsStore.snackbars);
    const snackbar = toRef(()=>snackbars.value[0]);
    const hasSnackbars = toRef(()=>snackbars.value.length > 0);
    const snackbarProps = toRef(()=>{
        const src = snackbar.value?.image;
        return {
            type: snackbar.value?.type,
            title: snackbar.value?.title,
            text: snackbar.value?.text || '',
            buttonLabel: snackbar.value?.accept?.isDone ? void 0 : snackbar.value?.accept?.label,
            isDone: snackbar.value?.accept?.isDone,
            iconName: snackbar.value?.iconName,
            hasClose: !!snackbar.value?.cancel,
            duration: snackbar.value?.duration,
            isMaxZIndex: snackbar.value?.id === CONNECTION_LOST_SNACKBAR_ID,
            image: src ? {
                src
            } : void 0
        };
    });
    const snackbarId = toRef(()=>snackbar.value?.id);
    const className = toRef(()=>{
        const baseClass = 'snackbar';
        const classNames = [
            baseClass
        ];
        if (snackbarId.value) classNames.push(`${baseClass}--${snackbarId.value}`);
        return classNames;
    });
    function onClose() {
        return snackbarsStore.hide(snackbar.value?.id);
    }
    async function onAccept() {
        await snackbar.value?.accept?.action?.();
        await onClose();
    }
    async function onCancel() {
        await snackbar.value?.cancel?.action?.();
        await onClose();
    }
    return {
        className,
        hasSnackbars,
        snackbarProps,
        snackbarId,
        onAccept,
        onCancel
    };
}
