import { ref, toRef } from 'vue';
import { logger } from '@leon-hub/logging';
import { safeParseInt } from '@leon-hub/utils';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useStreamUrlAvailable, useStreamAvailableIndicator } from 'web/src/modules/sportline/submodules/streams/composables';
import { getMatchStreamUrl } from 'web/src/modules/sportline/utils/api';
import { useStreamPreviewStoreComposable } from './useStreamPreviewStoreComposable';
export function useStreamUrlStoreComposable(props) {
    const sportlineEventId = toRef(props.sportlineEventId);
    const sportlineType = toRef(props.sportlineType);
    const streamUrl = ref(null);
    const { doShowStreamPreviewInfo, startPreview, stopPreview } = useStreamPreviewStoreComposable({
        sportlineEventId
    });
    const { isStreamAvailable, isPreviewOnly } = useStreamUrlAvailable({
        sportlineEventId,
        sportlineType
    });
    const { isStreamIndicatorAvailable } = useStreamAvailableIndicator({
        sportEventId: sportlineEventId,
        sportlineType
    });
    const { convertToBaseError } = useErrorsConverter();
    async function reloadStream() {
        if (!sportlineEventId.value) return;
        const eventId = safeParseInt(sportlineEventId.value);
        if (!eventId) return;
        try {
            if (doShowStreamPreviewInfo.value) {
                // do not request stream url for enabled preview (mark url as error)
                streamUrl.value = false;
                return;
            }
            if (!isStreamAvailable.value) {
                streamUrl.value = null;
                return;
            }
            const response = await getMatchStreamUrl({
                eventId
            }, {
                silent: true
            });
            // @see LEONWEB-8356 stream component still available with error status (streamUrl === false)
            streamUrl.value = 'OK' === response.result && response.src;
        } catch (rawError) {
            streamUrl.value = false;
            const error = convertToBaseError(rawError);
            // TODO check if there should be a silence error log
            logger.info(`Stream: ${error.message}`);
        }
    }
    return {
        streamUrl,
        isStreamAvailable,
        isStreamIndicatorAvailable,
        isStreamPreviewOnly: isPreviewOnly,
        doShowStreamPreviewInfo,
        clearStreamInfo () {
            streamUrl.value = null;
        },
        releaseStream () {
            stopPreview();
        },
        onStreamStarted () {
            if (!isPreviewOnly.value) return;
            startPreview();
        },
        reloadStream
    };
}
