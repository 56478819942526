// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restore_U4rdj{width:100%;padding:16px;color:var(--BrandDefault)}.restore__resend-form_iQ1sI{display:none}.restore__tooltip_ijClH{top:calc(50% - 60px)}html.app__layout--desktop .restore_U4rdj{padding:16px 32px 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restore": `restore_U4rdj`,
	"restore__resend-form": `restore__resend-form_iQ1sI`,
	"restore__tooltip": `restore__tooltip_ijClH`
};
export default ___CSS_LOADER_EXPORT___;
