import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue";
const _hoisted_1 = [
    "autofocus",
    "name",
    "disabled"
];
const _hoisted_2 = [
    "selected"
];
const _hoisted_3 = [
    "value",
    "selected"
];
import { useAutofocus } from 'web/src/components/Input/composables';
import DropdownSelectIcon from './DropdownSelectIcon.vue';
import useNativeSelectEvents from '../composables/useNativeSelectEvents';
export default /*@__PURE__*/ _defineComponent({
    __name: 'NativeSelect',
    props: {
        autofocus: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        hasError: {
            type: Boolean
        },
        hasIconPrefix: {
            type: Boolean
        },
        isEmpty: {
            type: Boolean
        },
        placeholder: {},
        selectedLabel: {},
        isLarge: {
            type: Boolean
        },
        themeBgColor: {
            type: Boolean
        },
        name: {
            default: 'select'
        },
        selectedValue: {},
        options: {
            default: ()=>[]
        }
    },
    emits: [
        "change",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { emitChange, emitBlur, emitFocus } = useNativeSelectEvents(props, emits);
        const { focusable, focus } = useAutofocus(props);
        __expose({
            focus
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['native-select']]: true,
                    [_ctx.$style['native-select--disabled']]: _ctx.disabled,
                    [_ctx.$style['native-select--empty']]: _ctx.isEmpty,
                    [_ctx.$style['native-select--error']]: _ctx.hasError,
                    [_ctx.$style['native-select--large']]: _ctx.isLarge
                })
            }, [
                _withDirectives((_openBlock(), _createElementBlock("select", {
                    ref_key: "focusable",
                    ref: focusable,
                    autofocus: process.env.VUE_APP_OS_IOS ? void 0 : _ctx.autofocus,
                    name: _ctx.name,
                    disabled: _ctx.disabled,
                    class: _normalizeClass({
                        [_ctx.$style['native-select__dropdown']]: true,
                        [_ctx.$style['native-select__dropdown--icon-suffix']]: true,
                        [_ctx.$style['native-select__dropdown--icon-prefix']]: _ctx.hasIconPrefix,
                        [_ctx.$style['native-select__dropdown--theme-bg-color']]: _ctx.themeBgColor
                    }),
                    onChange: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(emitChange) && _unref(emitChange)(...args);
                    }, [
                        "stop"
                    ])),
                    onFocus: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(emitFocus) && _unref(emitFocus)(...args);
                    }, [
                        "stop"
                    ])),
                    onBlur: _cache[2] || (_cache[2] = _withModifiers(//@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(emitBlur) && _unref(emitBlur)(...args);
                    }, [
                        "stop"
                    ]))
                }, [
                    _ctx.placeholder ? (_openBlock(), _createElementBlock("option", {
                        key: 0,
                        value: "",
                        disabled: "",
                        selected: _ctx.isEmpty || !_ctx.options?.length
                    }, _toDisplayString(_ctx.placeholder), 9, _hoisted_2)) : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index)=>(_openBlock(), _createElementBlock("option", {
                            key: index,
                            value: item.value,
                            selected: item.value === _ctx.selectedValue
                        }, _toDisplayString(item.label), 9, _hoisted_3))), 128))
                ], 42, _hoisted_1)), [
                    [
                        _directive_data_test,
                        {
                            el: 'select',
                            selectedLabel: _ctx.selectedLabel,
                            selectedValue: _ctx.selectedValue
                        }
                    ]
                ]),
                _ctx.disabled ? _createCommentVNode("", true) : (_openBlock(), _createBlock(DropdownSelectIcon, {
                    key: 0,
                    "is-large": _ctx.isLarge
                }, null, 8, [
                    "is-large"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'NativeSelect'
                ]
            ]);
        };
    }
});
