// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .sportline-event-main-info_OwECd{display:flex;flex:1 1 auto;gap:6px;justify-content:space-between;width:auto}html.app__layout--desktop .sportline-event-main-info--compact_wuh7J{gap:4px}html.app__layout--desktop .sportline-event-main-info--pushed_DKIz5{margin-top:20px}html.app__layout--desktop .sportline-event-main-info__left-column_CnHaU{display:flex;flex:1 1 auto;gap:8px;overflow:hidden}@media(hover:hover)and (pointer:fine){html.app__layout--desktop .sportline-event-main-info__left-column_CnHaU:hover{-webkit-text-decoration:underline;text-decoration:underline}}html.app__layout--desktop .sportline-event-main-info__column_W0y6F{flex-shrink:0;padding:0}html.app__layout--desktop .sportline-event-championship_r1qjF{display:flex;flex-direction:column;gap:6px;overflow:hidden}html.app__layout--desktop .sportline-event-championship__name_MZzop{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextPrimary)}html.app__layout--desktop .sportline-event-championship__market_x1GcB{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-event-main-info": `sportline-event-main-info_OwECd`,
	"sportline-event-main-info--compact": `sportline-event-main-info--compact_wuh7J`,
	"sportline-event-main-info--pushed": `sportline-event-main-info--pushed_DKIz5`,
	"sportline-event-main-info__left-column": `sportline-event-main-info__left-column_CnHaU`,
	"sportline-event-main-info__column": `sportline-event-main-info__column_W0y6F`,
	"sportline-event-championship": `sportline-event-championship_r1qjF`,
	"sportline-event-championship__name": `sportline-event-championship__name_MZzop`,
	"sportline-event-championship__market": `sportline-event-championship__market_x1GcB`
};
export default ___CSS_LOADER_EXPORT___;
