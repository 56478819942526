// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form__footer_odw4T{position:relative;margin:20px 0 0}.form__mask_7vfmX{z-index:69;position:fixed;top:0;right:0;bottom:0;left:0;background-color:var(--Layer0);opacity:.7}.form__button-mask_Tc1h1{position:absolute;top:0;width:100%;height:100%}.form__fields_IrZqF{position:relative;display:flex;flex-wrap:wrap;justify-content:space-between}.form__control_t8_Lp{flex:0 0 100%;max-width:100%}.form__control_t8_Lp:only-child{margin-bottom:6px}.form__control-short_CRlOR{flex:0 0 calc(50% - 8px)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form__footer": `form__footer_odw4T`,
	"form__mask": `form__mask_7vfmX`,
	"form__button-mask": `form__button-mask_Tc1h1`,
	"form__fields": `form__fields_IrZqF`,
	"form__control": `form__control_t8_Lp`,
	"form__control-short": `form__control-short_CRlOR`
};
export default ___CSS_LOADER_EXPORT___;
