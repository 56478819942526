// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.withdrawal-request_mx9AS{display:flex;align-items:center;justify-content:space-between;padding:12px;margin-bottom:4px;background-color:var(--Layer1);border-radius:5px}@media(hover:hover)and (pointer:fine){.withdrawal-request_mx9AS:hover{cursor:pointer}}.withdrawal-request_mx9AS .informer_LDkdh{flex-grow:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"withdrawal-request": `withdrawal-request_mx9AS`,
	"informer": `informer_LDkdh`
};
export default ___CSS_LOADER_EXPORT___;
