// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.photo-input_aFu4z{display:flex}.photo-input__file_yCRLw{display:none}.photo-input__label_sOEzy{margin:0 auto;cursor:pointer}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photo-input": `photo-input_aFu4z`,
	"photo-input__file": `photo-input__file_yCRLw`,
	"photo-input__label": `photo-input__label_sOEzy`
};
export default ___CSS_LOADER_EXPORT___;
