import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconSize } from '@leon-hub/icons';
import { SSpinner } from '@components/spinner';
import VLogoLoader from 'web/src/components/Loader/VLogoLoader/VLogoLoader.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSkeletonImage',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['skeleton-image'])
            }, [
                (_openBlock(), _createBlock(VLogoLoader, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['skeleton-image__logo']),
                    "is-small": ""
                }, null, 8, [
                    "class"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VSkeletonImage'
                ]
            ]);
        };
    }
});
