// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .swiper-navigation-button_urc1h{display:flex;flex-direction:column;justify-content:center;width:100%;height:100%;padding:10px 4px;color:var(--TextDefault);background:var(--Layer0);cursor:pointer}html.app__layout--desktop .swiper-navigation-button_urc1h:hover{color:var(--BrandHighlight)}html.app__layout--desktop .swiper-navigation-button--rounded_KT1Su{align-items:flex-start;width:34px;height:44px;padding:10px 0;margin:auto 0;border-radius:0 24px 24px 0}html.app__layout--desktop .swiper-navigation-button--rounded_KT1Su.swiper-navigation-button--next_hVtuX{align-items:flex-end;border-radius:24px 0 0 24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper-navigation-button": `swiper-navigation-button_urc1h`,
	"swiper-navigation-button--rounded": `swiper-navigation-button--rounded_KT1Su`,
	"swiper-navigation-button--next": `swiper-navigation-button--next_hVtuX`
};
export default ___CSS_LOADER_EXPORT___;
