import { toRef } from 'vue';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { logger } from '@leon-hub/logging';
import { Timer } from '@leon-hub/utils';
import RecaptchaV3PostMessageEvent, { RecaptchaV3PostMessageBusInitiator } from 'web/src/modules/framed-app/components/RecaptchaV3Widget/utils/RecaptchaV3PostMessageEvent';
import { IframeWidget } from 'web/src/modules/framed-app/enums';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { getDefaultWidgetUrl } from 'web/src/modules/framed-app/utils';
export function useRecaptchaV3() {
    const siteConfigStore = useSiteConfigStore();
    const iframeWidgetUrl = toRef(()=>siteConfigStore.widgetIframeUrl);
    const siteKey = toRef(()=>siteConfigStore.captcha?.recaptchaV3?.siteKey);
    const timeout = toRef(()=>siteConfigStore.captcha?.recaptchaV3?.timeout ?? 10000);
    async function getToken(action) {
        const key = siteKey.value;
        if (!key) return;
        const iframe = document.createElement('iframe');
        iframe.src = getDefaultWidgetUrl(IframeWidget.ReCaptchaV3, iframeWidgetUrl.value);
        iframe.style.display = 'none';
        iframe.style.height = '0';
        iframe.style.width = '0';
        document.body.appendChild(iframe);
        const iframeContentWindow = iframe.contentWindow;
        const postMessageBus = new PostMessageBus({
            target: iframeContentWindow,
            targetOrigin: '*',
            initiator: RecaptchaV3PostMessageBusInitiator
        });
        const initTokenRequest = ()=>{
            postMessageBus.emit(RecaptchaV3PostMessageEvent.init, {
                siteKey: key,
                action
            });
        };
        const waitForToken = ()=>new Promise((resolve, reject)=>{
                const timer = Timer.setTimeout(()=>{
                    reject(new Error(`Failed to get ReCaptchaV3 token in ${timeout.value}ms`));
                }, timeout.value);
                initTokenRequest();
                postMessageBus.on(RecaptchaV3PostMessageEvent.mounted, initTokenRequest);
                postMessageBus.on(RecaptchaV3PostMessageEvent.sendToken, (param)=>{
                    let { token } = param;
                    Timer.clearTimeout(timer);
                    resolve(token);
                });
            });
        try {
            return await waitForToken();
        } catch (error) {
            logger.warn('Failed to get RecaptchaV3 token', error);
            return;
        } finally{
            postMessageBus.dispose();
            iframe.remove();
        }
    }
    return {
        getToken
    };
}
