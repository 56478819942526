// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-badge_Y4Def{font-size:9px;font-weight:700;line-height:12px;text-transform:uppercase;letter-spacing:.2px;display:inline-flex;flex-shrink:0;align-items:center;justify-content:center;min-width:16px;height:12px;padding:0 4px;font-family:Roboto Condensed,sans-serif;color:var(--Layer2);vertical-align:middle;pointer-events:none;background-color:var(--TextDefault);border-radius:2px}.sport-event-badge--for-heading_JzXeT{color:var(--DLayer2);background-color:var(--DTextDefault)}html.app__layout--desktop .sportline-event-meta-info_WpTBe{display:flex;flex-wrap:wrap;justify-content:space-between}html.app__layout--desktop .sportline-event-meta-info__left-column_hmZOG{flex:1;align-items:center;width:100%;padding:0;line-height:12px}html.app__layout--desktop .sportline-event-meta-info__left-column_hmZOG span:not(:last-child){margin-right:4px}html.app__layout--desktop .sportline-event-meta-info__right-column_U5a0D{display:flex}html.app__layout--desktop .sportline-event-stream_H6KOp{padding-top:1px;fill:var(--TextSecondary)}html.app__layout--desktop .sportline-event-live-stage_DBkvQ{font-size:12px;font-weight:400;line-height:12px;letter-spacing:normal;color:var(--TextSecondary)}html.app__layout--desktop .sportline-event-game-progress_erz9R,html.app__layout--desktop .sportline-event-live-score-details_QrZGB,html.app__layout--desktop .sportline-event-time-progress_p5ToO{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-badge": `sport-event-badge_Y4Def`,
	"sport-event-badge--for-heading": `sport-event-badge--for-heading_JzXeT`,
	"sportline-event-meta-info": `sportline-event-meta-info_WpTBe`,
	"sportline-event-meta-info__left-column": `sportline-event-meta-info__left-column_hmZOG`,
	"sportline-event-meta-info__right-column": `sportline-event-meta-info__right-column_U5a0D`,
	"sportline-event-stream": `sportline-event-stream_H6KOp`,
	"sportline-event-live-stage": `sportline-event-live-stage_DBkvQ`,
	"sportline-event-game-progress": `sportline-event-game-progress_erz9R`,
	"sportline-event-live-score-details": `sportline-event-live-score-details_QrZGB`,
	"sportline-event-time-progress": `sportline-event-time-progress_p5ToO`
};
export default ___CSS_LOADER_EXPORT___;
