import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ExpandButton',
    props: {
        isExpanded: {
            type: Boolean
        },
        openedTitle: {},
        closedTitle: {}
    },
    emits: [
        "update:isExpanded"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const title = computed(()=>props.isExpanded ? props.openedTitle || '' : props.closedTitle || '');
        function toggleExpandState() {
            emit('update:isExpanded', !props.isExpanded);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['statistic-expand-button']),
                onClick: toggleExpandState
            }, [
                _createTextVNode(_toDisplayString(title.value), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ExpandButton'
                ]
            ]);
        };
    }
});
