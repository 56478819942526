// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .bonus-reminder-modal_vq7xh{display:flex;flex-direction:column;align-items:center}html.app__layout--desktop .bonus-reminder-modal__title_EhwjD{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin-bottom:16px;color:var(--TextDefault)}html.app__layout--desktop .bonus-reminder-modal__list_Bjhp5{display:flex;flex-direction:column;gap:2px;width:100%;margin-bottom:16px}html.app__layout--desktop .bonus-reminder-modal__item_WzQTC{display:flex;gap:12px;align-items:center;justify-content:space-between;padding:8px 12px;background-color:var(--Layer1);border-radius:5px}html.app__layout--desktop .bonus-reminder-modal__item-title_jGzmJ{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary);text-align:left}html.app__layout--desktop .bonus-reminder-modal__item-value_H8k_G{font-size:14px;font-weight:500;line-height:24px;letter-spacing:.25px;color:var(--TextDefault);white-space:nowrap}html.app__layout--desktop .bonus-reminder-modal__button_J2yer{margin:20px 0}html.app__layout--desktop .bonus-reminder-modal__details-link_iM77c{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary);-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer}@media(hover:hover)and (pointer:fine){html.app__layout--desktop .bonus-reminder-modal__details-link_iM77c:hover{color:var(--BrandHighlight)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-reminder-modal": `bonus-reminder-modal_vq7xh`,
	"bonus-reminder-modal__title": `bonus-reminder-modal__title_EhwjD`,
	"bonus-reminder-modal__list": `bonus-reminder-modal__list_Bjhp5`,
	"bonus-reminder-modal__item": `bonus-reminder-modal__item_WzQTC`,
	"bonus-reminder-modal__item-title": `bonus-reminder-modal__item-title_jGzmJ`,
	"bonus-reminder-modal__item-value": `bonus-reminder-modal__item-value_H8k_G`,
	"bonus-reminder-modal__button": `bonus-reminder-modal__button_J2yer`,
	"bonus-reminder-modal__details-link": `bonus-reminder-modal__details-link_iM77c`
};
export default ___CSS_LOADER_EXPORT___;
