import { computed } from 'vue';
import { InputEventType } from 'web/src/components/Input/enums';
export default function useVSwitch(props, emit, slots) {
    const showLabel = computed(()=>Boolean(props.label || slots?.default));
    function onClick(event) {
        if (props.readonly || props.disabled) event.preventDefault();
        emit('click', event);
    }
    function onChange(event) {
        event.target, HTMLInputElement;
        const { checked } = event.target;
        emit(InputEventType.CHANGE, checked);
    }
    function onInput(event) {
        event.target, HTMLInputElement;
        const { checked } = event.target;
        emit(InputEventType.INPUT, checked);
    }
    function emitFocus() {
        emit(InputEventType.FOCUS, !!props.checked);
    }
    function emitBlur() {
        emit(InputEventType.BLUR, !!props.checked);
    }
    function onTransitionEnd() {
        emit('animation-completed', !!props.checked);
    }
    return {
        showLabel,
        emitFocus,
        emitBlur,
        onClick,
        onChange,
        onInput,
        onTransitionEnd
    };
}
