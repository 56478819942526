// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate-customer-card_WRWBM{width:100%;padding:12px;margin-bottom:8px;vertical-align:middle;background-color:var(--Layer1);border-radius:5px}.affiliate-customer-card__bottom-left_qugCn{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.affiliate-customer-card__top-right_aUI7V{font-weight:400}.affiliate-customer-card__top-left_hpLFm,.affiliate-customer-card__top-right_aUI7V{font-size:12px;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}.affiliate-customer-card__top-left_hpLFm{font-weight:500}.affiliate-customer-card__bottom-right_wRiDT{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--BrandDefault)}.affiliate-customer-card_WRWBM .with-margin_ULAcL{margin-right:24px}.affiliate-customer-card_WRWBM .with-space_mFQyt{margin-right:2px}.affiliate-customer-card__inner_kNpco{display:flex;justify-content:space-between}.affiliate-customer-card__inner_kNpco .general-info_Y9J2m{display:flex;flex-direction:column}.affiliate-customer-card__inner_kNpco .bonus-code_lJYnp{display:flex;flex-direction:column;align-items:flex-end}.affiliate-customer-card__inner_kNpco .bonus-code__top-text_qh8gi{margin-bottom:4px;line-height:16px}.affiliate-customer-card__inner_kNpco .bonus-code__bottom-text_Y3lf7{line-height:16px;letter-spacing:.3px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-customer-card": `affiliate-customer-card_WRWBM`,
	"affiliate-customer-card__bottom-left": `affiliate-customer-card__bottom-left_qugCn`,
	"affiliate-customer-card__top-right": `affiliate-customer-card__top-right_aUI7V`,
	"affiliate-customer-card__top-left": `affiliate-customer-card__top-left_hpLFm`,
	"affiliate-customer-card__bottom-right": `affiliate-customer-card__bottom-right_wRiDT`,
	"with-margin": `with-margin_ULAcL`,
	"with-space": `with-space_mFQyt`,
	"affiliate-customer-card__inner": `affiliate-customer-card__inner_kNpco`,
	"general-info": `general-info_Y9J2m`,
	"bonus-code": `bonus-code_lJYnp`,
	"bonus-code__top-text": `bonus-code__top-text_qh8gi`,
	"bonus-code__bottom-text": `bonus-code__bottom-text_Y3lf7`
};
export default ___CSS_LOADER_EXPORT___;
