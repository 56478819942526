// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.primary-market_Vq6aH{display:flex;flex-direction:column;gap:4px;align-items:center}.primary-market__wrapper_bFy8n{display:flex;flex-direction:row;justify-content:center}.primary-market__runners_UKTKU{display:flex;gap:4px;justify-content:center;width:100%}@media(max-width:439px){.primary-market__runners_UKTKU:has(>:nth-child(2)) .primary-market__runner_KPNGp{flex-basis:100%;max-width:calc(50% - 4px)}.primary-market__runners_UKTKU:has(>:nth-child(3)) .primary-market__runner_KPNGp{flex-basis:100%;max-width:calc(33.33333% - 5.33333px)}}.primary-market__runners--double-gap_d20AC{gap:8px}.primary-market__runners_UKTKU .primary-market__runner_KPNGp{max-width:inherit}@media(min-width:440px){.primary-market__runner_KPNGp{min-width:100px}}.primary-market--v2_ssZT0{gap:0}.primary-market--v2_ssZT0 .primary-market__runners_UKTKU{gap:4px;width:140px}@media(min-width:360px){.primary-market--v2_ssZT0 .primary-market__runners_UKTKU{width:152px}}@media(min-width:375px){.primary-market--v2_ssZT0 .primary-market__runners_UKTKU{width:160px}}@media(min-width:412px){.primary-market--v2_ssZT0 .primary-market__runners_UKTKU{width:180px}}@media(min-width:480px){.primary-market--v2_ssZT0 .primary-market__runners_UKTKU{width:216px}}@media(min-width:600px){.primary-market--v2_ssZT0 .primary-market__runners_UKTKU{width:220px}}.primary-market--v2_ssZT0 .primary-market__runner_KPNGp{flex:1;min-width:44px}.primary-market--v2_ssZT0 .primary-market__market-name_ePR8H{width:100%;padding:4px;font-size:14px;font-weight:500;line-height:1;color:var(--BrandText);text-align:center;background:linear-gradient(180deg,#00000080,#0000);border-radius:8px 8px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary-market": `primary-market_Vq6aH`,
	"primary-market__wrapper": `primary-market__wrapper_bFy8n`,
	"primary-market__runners": `primary-market__runners_UKTKU`,
	"primary-market__runner": `primary-market__runner_KPNGp`,
	"primary-market__runners--double-gap": `primary-market__runners--double-gap_d20AC`,
	"primary-market--v2": `primary-market--v2_ssZT0`,
	"primary-market__market-name": `primary-market__market-name_ePR8H`
};
export default ___CSS_LOADER_EXPORT___;
