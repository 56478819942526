// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-details-button_wK41s{gap:20px;margin-top:24px;z-index:2;display:flex;flex-flow:column wrap;align-items:center;width:343px;margin-right:auto;margin-left:auto}.promotion-details-button__button_kPw0O:not([disabled]){cursor:pointer}.promotion-details-button--is-stickied_ucTaZ .promotion-details-button__button_kPw0O{box-shadow:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotion-details-button": `promotion-details-button_wK41s`,
	"promotion-details-button__button": `promotion-details-button__button_kPw0O`,
	"promotion-details-button--is-stickied": `promotion-details-button--is-stickied_ucTaZ`
};
export default ___CSS_LOADER_EXPORT___;
