import { computed, inject, onActivated, onBeforeUnmount, onMounted, ref } from 'vue';
import { VSwiperProvidableKeys } from 'web/src/components/Swiper/VSwiper/enums';
export default function useVSwiperSlide() {
    const isVisible = ref(true);
    const swiperState = inject(VSwiperProvidableKeys.State);
    const onSlideToggle = inject(VSwiperProvidableKeys.OnSlideToggle);
    const slide = ref();
    const isOverflowVisible = computed(()=>isVisible.value && !!swiperState?.isSwiping.value);
    onMounted(()=>{
        onSlideToggle?.();
    });
    onActivated(()=>{
        onSlideToggle?.();
    });
    onBeforeUnmount(()=>{
        onSlideToggle?.();
    });
    function setVisibility(value) {
        isVisible.value = value;
    }
    function getOffsetWidth() {
        return slide?.value?.offsetWidth || 0;
    }
    function getOffsetLeft() {
        return slide?.value?.offsetLeft || 0;
    }
    return {
        isVisible,
        slide,
        isOverflowVisible,
        setVisibility,
        getOffsetWidth,
        getOffsetLeft
    };
}
