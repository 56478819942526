// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-masked_pF7wM{z-index:10;position:absolute;top:1px;right:0;bottom:0;left:0;display:flex;justify-content:center;padding-top:150px;background-color:var(--OpacityLayer0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader-masked": `loader-masked_pF7wM`
};
export default ___CSS_LOADER_EXPORT___;
