import { ref, toRef } from 'vue';
import { defineStore } from 'pinia';
import { SlipStatus } from '@leon-hub/api-sdk';
import { Timer } from '@leon-hub/utils';
import { ApiConnectionError, ApiTechnicalError, GqlApiAccessDeniedError } from '@leon-hub/api';
import { logger } from '@leon-hub/logging';
import { isString } from '@leon-hub/guards';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useTheme } from 'web/src/modules/theme/composables';
import { useCurrency } from 'web/src/modules/money/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import ServerDate from 'web/src/utils/ServerDate';
import { BetSlipResultState, BetSlipWarningTypes, LeaveSlipResultChoice } from '../../../enums';
import { usePendingBetsStore } from '../../pending-bets/store';
import { useSlipSelectedEntriesStore } from '../../slipSelectedEntries/store';
import { useMultiSinglesStore } from '../../multi-singles/store';
import { useFreebetStore } from '../../freebet/store';
import { useStakeInputStore } from '../../stake-input/store';
import { useSlipInfoStore } from '../../slip-info/store';
import { betPlaceErrorYMTrigger, betResultHasLimitExceedError, getBatchMakeBetEntries, isAnotherBetResultError, isAtLeastOneBetWasAccepted, logPlaceBetResultError, makeBetResultLog, parseBetResults, requestLastBetResult, requestPlaceBet } from '../utils';
import { isLiveEventSlipEntry, isUnavailableEntryStatus } from '../../../utils';
import { useDefaultBetValue } from '../../default-bet-value/composables';
const lastResultTimeout = 3000;
const usePlaceBetStore = defineStore('place-bet-store', ()=>{
    const apiClient = useGraphqlClient();
    const bus = useEventsBus();
    const slipInfoStore = useSlipInfoStore();
    const { convertToBaseError } = useErrorsConverter();
    const { removeBetEvents, setLastBetTimeStamp, clearLocalStorageState, applyBatchedSlipInfoUpdate, enableUpdates, disableUpdates, saveToLocalStorage, setCustomWarningMessage } = slipInfoStore;
    const minStake = toRef(slipInfoStore, 'minStake');
    const maxStake = toRef(slipInfoStore, 'maxStake');
    const slipType = toRef(slipInfoStore, 'slipType');
    const isMultiSinglesMode = toRef(slipInfoStore, 'isMultiSinglesMode');
    const slipEntries = toRef(slipInfoStore, 'slipEntries');
    const maxAvailableFastBetValue = toRef(slipInfoStore, 'maxAvailableFastBetValue');
    const multiSinglesUiCount = toRef(slipInfoStore, 'multiSinglesUiCount');
    const combiOrFirstSlip = toRef(slipInfoStore, 'combiOrFirstSlip');
    const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');
    const batchedSlipInfo = toRef(slipInfoStore, 'batchedSlipInfo');
    const lastBetTimeStamp = toRef(slipInfoStore, 'lastBetTimeStamp');
    const combinedEntriesInfo = toRef(slipInfoStore, 'combinedEntriesInfo');
    const selectedStakeValue = toRef(slipInfoStore, 'selectedStakeValue');
    const selectedEntriesIds = toRef(useSlipSelectedEntriesStore(), 'selectedEntriesIds');
    const selectedFreeBetId = toRef(useFreebetStore(), 'selectedFreeBetId');
    const { loadPendingBets } = usePendingBetsStore();
    const { currency } = useCurrency();
    const { theme } = useTheme();
    const analyticsService = useAnalytics();
    const customerDataStore = useCustomerDataStore();
    const useStandardBet = toRef(customerDataStore, 'useStandardBet');
    const standardBetAmount = toRef(customerDataStore, 'standardBetAmount');
    const priceChangePolicy = toRef(customerDataStore, 'priceChangePolicy');
    const totalHandicapPriceChangePolicy = toRef(customerDataStore, 'totalHandicapPriceChangePolicy');
    const login = toRef(customerDataStore, 'login');
    const { defaultBetValue } = useDefaultBetValue();
    const stakeInputStore = useStakeInputStore();
    const { setStakeInputIsFocused, safeSetStakeValue } = stakeInputStore;
    const selectedFastMoneyValue = toRef(stakeInputStore, 'selectedFastMoneyValue');
    const multiSinglesStore = useMultiSinglesStore();
    const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');
    const multiSinglesMetaInfo = toRef(multiSinglesStore, 'multiSinglesMetaInfo');
    // state
    const resultState = ref(BetSlipResultState.INITIAL);
    const setResultState = (value)=>{
        resultState.value = value;
    };
    const placeBetErrorText = ref('');
    const setPlaceBetErrorText = (errorText)=>{
        placeBetErrorText.value = errorText;
    };
    /** responsible gambling limitations */ const limitsExceeded = ref(false);
    const setLimitsExceeded = (isExceeded)=>{
        limitsExceeded.value = isExceeded;
    };
    const totalEntriesInSlip = ref(0);
    const setTotalEntriesInSlip = (count)=>{
        totalEntriesInSlip.value = count;
    };
    const totalBetsAccepted = ref(0);
    const setTotalBetsAccepted = (count)=>{
        totalBetsAccepted.value = count;
    };
    const slipUnsettledSingles = ref(null);
    const setSlipUnsettledSingles = (list)=>{
        slipUnsettledSingles.value = list;
    };
    /** what to clear on slip close after bet was placed */ const leaveBetResultChoice = ref(LeaveSlipResultChoice.CLEAR_ALL);
    const setLeaveBetResultChoice = (choice)=>{
        leaveBetResultChoice.value = choice;
    };
    const lastBetId = ref(null);
    const setLastBetId = (id)=>{
        lastBetId.value = id;
    };
    // former actions
    const logBetResult = (param)=>{
        let { acceptedBets, entriesCount, liveEventsIds } = param;
        makeBetResultLog({
            acceptedBets,
            multiSinglesMetaInfo: multiSinglesMetaInfo.value,
            sameStakeForSingles: sameStakeForSingles.value,
            isMultiSinglesMode: isMultiSinglesMode.value,
            stakeValue: selectedStakeValue.value,
            entriesCount,
            selectedFastMoneyValue: selectedFastMoneyValue.value,
            maxAvailableFastBetValue: maxAvailableFastBetValue.value
        }, {
            liveEventsIds,
            slipType: slipType.value,
            priceChangePolicy: priceChangePolicy.value,
            currency: currency.value,
            login: login.value
        }, {
            analyticsService,
            theme: theme.value,
            useDefaultBet: useStandardBet.value,
            amountBet: standardBetAmount.value || false,
            currentValue: selectedStakeValue.value
        });
    };
    const resetSlipResultState = function() {
        let doClearSlip = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
        if (doClearSlip) removeBetEvents({
            leaveBetResultChoice: leaveBetResultChoice.value,
            slipUnsettledSingles: slipUnsettledSingles.value
        });
        setResultState(BetSlipResultState.INITIAL);
        setLeaveBetResultChoice(LeaveSlipResultChoice.CLEAR_ALL);
        setLastBetId(null);
        setLimitsExceeded(false);
        setSlipUnsettledSingles(null);
        setStakeInputIsFocused(false);
        const nextInputValue = defaultBetValue.value || 0;
        safeSetStakeValue({
            value: nextInputValue,
            minStake: minStake.value,
            maxStake: maxStake.value
        });
    };
    const handleBetPlaceSuccess = ()=>{
        loadPendingBets();
        bus.emit(BusEvent.NEW_BET_HAS_BEEN_PLACED, {});
        setResultState(BetSlipResultState.SUCCESS);
        setLastBetTimeStamp(null);
        clearLocalStorageState();
    };
    const onMarkResultStateAsError = function() {
        let saveTimestamp = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (slipEventsCount.value) enableUpdates();
        if (!saveTimestamp) setLastBetTimeStamp(null);
        setResultState(BetSlipResultState.ERROR);
    };
    const handlePlaceBetResults = (param)=>{
        let { results, entriesCount } = param;
        const { acceptedBets, unsettledBetsIds, updatedSlipEntries, haveLimitsError } = parseBetResults([
            ...results
        ], selectedEntriesIds.value);
        const acceptedCount = acceptedBets.length;
        setTotalEntriesInSlip(multiSinglesUiCount.value);
        setTotalBetsAccepted(acceptedCount);
        if (1 === acceptedCount) setLastBetId(acceptedBets[0].betId ?? null);
        if (haveLimitsError) setLimitsExceeded(true);
        if (updatedSlipEntries) applyBatchedSlipInfoUpdate({
            updatedSlipInfo: {
                slipEntries: updatedSlipEntries,
                combiAvailable: batchedSlipInfo.value?.combiAvailable ?? true
            },
            isBackgroundUpdate: true
        });
        setSlipUnsettledSingles(unsettledBetsIds.length ? unsettledBetsIds : null);
        /** logging and analytics */ const liveEventsIds = slipEntries.value.filter((entry)=>isLiveEventSlipEntry(entry)).map((param)=>{
            let { event } = param;
            return String(event);
        });
        logBetResult({
            acceptedBets,
            liveEventsIds,
            entriesCount
        });
        handleBetPlaceSuccess();
    };
    const getLastBetResult = async (param)=>{
        let { fallbackMessage, remainingAttempts } = param;
        const response = await requestLastBetResult(apiClient);
        if (response && isAtLeastOneBetWasAccepted(response.results)) handlePlaceBetResults({
            results: response.results,
            entriesCount: response.results.length
        });
        else if (remainingAttempts > 1) // call itself again
        Timer.setTimeout(()=>{
            getLastBetResult({
                remainingAttempts: remainingAttempts - 1,
                fallbackMessage
            });
        }, lastResultTimeout);
        else {
            if (fallbackMessage) setPlaceBetErrorText(fallbackMessage);
            onMarkResultStateAsError();
            removeBetEvents();
        }
    };
    const handleBetErrorCode = (errorCode)=>{
        let shouldStop = false;
        if (errorCode.includes('freebet')) {
            bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, {
                type: BetSlipWarningTypes.FREEBET_VALIDATION_ERROR
            });
            setResultState(BetSlipResultState.INITIAL);
            shouldStop = true;
        }
        if (errorCode.includes('bets-limit-exceeded')) setLimitsExceeded(true);
        return {
            shouldStop
        };
    };
    const claimToSlipClose = ()=>{
        bus.emit(BusEvent.CLAIM_TO_SLIP_CLOSE, {});
    };
    const resetResultErrorState = ()=>{
        setPlaceBetErrorText('');
        setResultState(BetSlipResultState.INITIAL);
        setLimitsExceeded(false);
        if (slipEventsCount.value) enableUpdates();
        else claimToSlipClose();
    };
    const setPriceChangesError = (message)=>{
        setCustomWarningMessage(message);
        setPlaceBetErrorText('');
        setResultState(BetSlipResultState.INITIAL);
        enableUpdates();
    };
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const handleSingleBetError = (result)=>{
        const { errorMessage, errorCode, slipInfo } = result;
        const errorText = errorMessage || slipInfo?.message;
        const trackBetErrorPayload = {
            errorMessage: errorText || null,
            errorCode: errorCode || null,
            slipStatus: slipInfo?.status ?? null,
            slipMessage: slipInfo?.message ?? null
        };
        logPlaceBetResultError(trackBetErrorPayload);
        const { shouldStop } = handleBetErrorCode(errorCode ?? '');
        if (shouldStop) {
            onMarkResultStateAsError();
            return;
        }
        if (errorText) setPlaceBetErrorText(errorText);
        let isPriceChangesError = false;
        if (slipInfo && batchedSlipInfo.value) {
            const { status } = slipInfo;
            const trace = combiOrFirstSlip.value?.trace ?? null;
            if (status === SlipStatus.MARKETS_CHANGED) {
                const hasUnavailableEntries = slipInfo.entries?.some((param)=>{
                    let { marketStatus } = param;
                    return isUnavailableEntryStatus(marketStatus);
                });
                if (!hasUnavailableEntries) {
                    betPlaceErrorYMTrigger(trackBetErrorPayload, analyticsService);
                    isPriceChangesError = true;
                }
            }
            applyBatchedSlipInfoUpdate({
                updatedSlipInfo: {
                    slipEntries: [
                        {
                            ...slipInfo,
                            trace
                        }
                    ],
                    combiAvailable: batchedSlipInfo.value.combiAvailable
                }
            });
        }
        if (isPriceChangesError) {
            // LEONWEB-13537
            setPriceChangesError(errorText ?? '');
            setLastBetTimeStamp(null);
            return;
        }
        onMarkResultStateAsError();
    };
    const handleMultipleBetError = (results)=>{
        const resultWithLimitError = results.find((result)=>betResultHasLimitExceedError(result));
        if (resultWithLimitError) {
            setLimitsExceeded(true);
            setPlaceBetErrorText(resultWithLimitError.errorMessage ?? '');
        }
    };
    const handleBetPlaceError = (payload)=>{
        const { results, error } = payload ?? {};
        if (results) {
            if (1 === results.length) {
                // one single or combi slip
                handleSingleBetError(results[0]);
                return;
            }
            // multisingles
            handleMultipleBetError(results);
        }
        let shouldSaveTimestamp = false;
        if (error) {
            const baseError = convertToBaseError(error);
            shouldSaveTimestamp = baseError instanceof ApiConnectionError || baseError instanceof ApiTechnicalError;
            if (isAnotherBetResultError(baseError)) {
                /**
         * With these type of errors we have to call another endpoint and probably got result.
         * Works better with timeout.
         * Why not just don't send 'duration'/accept-in-progress again and now throw error from the backend? No idea
         * */ Timer.setTimeout(()=>{
                    getLastBetResult({
                        remainingAttempts: 2,
                        fallbackMessage: baseError.message
                    });
                }, lastResultTimeout);
                return;
            }
            setPlaceBetErrorText(baseError.message);
        }
        onMarkResultStateAsError(shouldSaveTimestamp);
    };
    const placeBet = async (payload)=>{
        if (!batchedSlipInfo.value) throw new Error('placeBetError: empty batchedSlipInfo');
        if (!lastBetTimeStamp.value) setLastBetTimeStamp(ServerDate.now());
        const freeBetId = selectedFreeBetId.value ? Number(selectedFreeBetId.value) : void 0;
        const batchedEntries = getBatchMakeBetEntries({
            stakeValue: selectedStakeValue.value,
            batchedSlipInfo: batchedSlipInfo.value,
            priceChangePolicy: priceChangePolicy.value,
            isMultiSinglesMode: isMultiSinglesMode.value,
            sameStakeForSingles: sameStakeForSingles.value,
            combinedSlipEntriesForSingles: combinedEntriesInfo.value,
            freeBetId
        });
        /** if retrying, we have payload with same BatchMakeBetRequest options
     * passed as argument */ const requestPayload = payload ?? {
            slipType: slipType.value,
            entries: batchedEntries,
            timestamp: Number(lastBetTimeStamp.value),
            pcp: priceChangePolicy.value,
            totalHandicapPcp: totalHandicapPriceChangePolicy.value
        };
        disableUpdates();
        if (!payload) setResultState(BetSlipResultState.PENDING);
        try {
            saveToLocalStorage();
            const response = await requestPlaceBet(apiClient, requestPayload);
            if (!response) {
                setResultState(BetSlipResultState.INITIAL);
                return;
            }
            const { duration, results } = response;
            if (duration && duration > 0) {
                setResultState(BetSlipResultState.WAIT_FOR_RETRY);
                logger.info('Place bet accept-in-progress', {
                    duration,
                    errorCode: 'betting.accept-in-progress'
                });
                // recursive call
                Timer.setTimeout(()=>{
                    placeBet(requestPayload);
                }, duration);
                return;
            }
            if (isAtLeastOneBetWasAccepted(results)) handlePlaceBetResults({
                results,
                entriesCount: batchedEntries.length
            });
            else /** all bets have been declined */ handleBetPlaceError({
                results
            });
        } catch (error) {
            if (error instanceof GqlApiAccessDeniedError) throw error;
            logger.error('Place bet error', error);
            handleBetPlaceError({
                error
            });
        }
    };
    const handleSlipResultLeave = (closeChoice)=>{
        if (closeChoice === LeaveSlipResultChoice.SAVE_ALL) analyticsService.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                bet_slip: 'keep_in_bet_slip'
            }
        });
        closeChoice && isString(closeChoice);
        if (closeChoice !== LeaveSlipResultChoice.CLEAR_ALL) {
            setLeaveBetResultChoice(closeChoice);
            resetSlipResultState();
            return;
        }
        claimToSlipClose();
    };
    const onLimitsButtonClick = ()=>{
        resetSlipResultState(false);
        claimToSlipClose();
    };
    return {
        resetSlipResultState,
        placeBet,
        resetResultErrorState,
        handleSlipResultLeave,
        setResultState,
        setLeaveBetResultChoice,
        onLimitsButtonClick,
        resultState,
        placeBetErrorText,
        limitsExceeded,
        totalEntriesInSlip,
        totalBetsAccepted,
        leaveBetResultChoice,
        slipUnsettledSingles,
        lastBetId
    };
});
export default usePlaceBetStore;
