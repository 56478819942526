// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_Lk7Im{padding:12px;background-color:var(--Layer1);border-radius:5px}.card_Lk7Im+.card_Lk7Im{margin-top:8px}.card-title_ve2py{padding:0;margin:0}.card-content_dPPyn{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:10px 0 0;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `card_Lk7Im`,
	"card-title": `card-title_ve2py`,
	"card-content": `card-content_dPPyn`
};
export default ___CSS_LOADER_EXPORT___;
