// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sportline-event-logos_dVYHQ{display:flex}.sportline-event-logos-container_Ab7Nw{display:flex;flex-direction:column;justify-content:center}.sportline-event-logos__item_I09xu{display:inline-block;width:32px;height:32px;background-color:var(--White);border-radius:50%}.sportline-event-logos__item--default-type_DrgXO{box-shadow:0 1px 2px #00000026,0 0 6px #0000001a}.sportline-event-logos__item--bordered-type_DAWTW{border:1px solid var(--Layer2)}.sportline-event-logos__item--bordered-type_DAWTW:first-child{z-index:2}.sportline-event-logos__item--second_mDlPB{margin-left:-8px}.sportline-event-logos__logo_RwvnS{width:24px;height:24px;border-radius:50%}.sportline-event-logos__logo-container_JtQW3{display:flex;align-items:center;justify-content:center;width:100%;height:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-event-logos": `sportline-event-logos_dVYHQ`,
	"sportline-event-logos-container": `sportline-event-logos-container_Ab7Nw`,
	"sportline-event-logos__item": `sportline-event-logos__item_I09xu`,
	"sportline-event-logos__item--default-type": `sportline-event-logos__item--default-type_DrgXO`,
	"sportline-event-logos__item--bordered-type": `sportline-event-logos__item--bordered-type_DAWTW`,
	"sportline-event-logos__item--second": `sportline-event-logos__item--second_mDlPB`,
	"sportline-event-logos__logo": `sportline-event-logos__logo_RwvnS`,
	"sportline-event-logos__logo-container": `sportline-event-logos__logo-container_JtQW3`
};
export default ___CSS_LOADER_EXPORT___;
