import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective } from "vue";
import { IconName } from '@leon-hub/icons';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import PaymentBonusItem from 'web/src/modules/payments/components/PaymentBonusItem/PaymentBonusItem.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { usePaymentBonuses } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'PaymentBonuses',
    props: {
        isModal: {
            type: Boolean
        },
        bonusList: {},
        autoSelectFirst: {
            type: Boolean
        }
    },
    emits: [
        "change",
        "change-complete"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { selectedBonus, emitBonusSelection, handleSelectionComplete, onNoDepositClick } = usePaymentBonuses(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['payments-bonuses']]: true,
                    [_ctx.$style['payments-bonuses--modal']]: _ctx.isModal
                })
            }, [
                _withDirectives((_openBlock(), _createBlock(VButton, {
                    kind: _unref(ButtonKind).BASE,
                    "icon-name": _unref(IconName).PROMOS_NO,
                    "full-width": "",
                    onClick: _unref(onNoDepositClick)
                }, {
                    default: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_WITHOUT_BONUS')), 1)
                        ]),
                    _: 1
                }, 8, [
                    "kind",
                    "icon-name",
                    "onClick"
                ])), [
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'no-bonus-btn'
                        }
                    ]
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['payments-bonuses__title'])
                }, _toDisplayString(_ctx.$t('WEB2_BONUSES_AVAILABLE')), 3),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bonusList, (item)=>_withDirectives((_openBlock(), _createBlock(PaymentBonusItem, {
                        key: item.campaignId,
                        "bonus-item": item,
                        "is-selected": item.campaignId === _unref(selectedBonus),
                        onSelect: _unref(emitBonusSelection),
                        onSelectComplete: _unref(handleSelectionComplete)
                    }, null, 8, [
                        "bonus-item",
                        "is-selected",
                        "onSelect",
                        "onSelectComplete"
                    ])), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'bonus-item'
                            }
                        ]
                    ])), 128))
            ], 2)), [
                [
                    _directive_auto_id,
                    'PaymentBonuses'
                ]
            ]);
        };
    }
});
