import { getMarketsGridCellId } from './getMarketsGridCellId';
export function createEmptyMarketsGridCell(market) {
    return {
        id: getMarketsGridCellId(market),
        title: market?.name ?? '',
        markets: [],
        isOpened: false,
        hasZeroMargin: false
    };
}
