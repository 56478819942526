// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker_MbjUm{position:relative;overflow:hidden}.date-picker__input_lYuHk{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;opacity:0}.date-picker__input_lYuHk::-webkit-calendar-picker-indicator{position:absolute;top:0;right:0;bottom:0;left:0;width:auto;height:auto;color:#0000;cursor:pointer;background:#0000}.date-picker__label_gDska{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;color:var(--TextDefault)}.date-picker--from_dUWr1.date-picker--focused-to_XElND .date-picker__label_gDska,.date-picker--to_fOC0T.date-picker--focused-from_fodin .date-picker__label_gDska{color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date-picker": `date-picker_MbjUm`,
	"date-picker__input": `date-picker__input_lYuHk`,
	"date-picker__label": `date-picker__label_gDska`,
	"date-picker--from": `date-picker--from_dUWr1`,
	"date-picker--focused-to": `date-picker--focused-to_XElND`,
	"date-picker--to": `date-picker--to_fOC0T`,
	"date-picker--focused-from": `date-picker--focused-from_fodin`
};
export default ___CSS_LOADER_EXPORT___;
