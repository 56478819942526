import { computed } from 'vue';
import { Theme } from '@leon-hub/api-sdk';
import { ProductSpecificName } from '../enums';
export default function useVLogo(props) {
    const defaultName = ProductSpecificName.LOGO;
    const alt = computed(()=>{
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const logoName = props.productSpecificName || props.name || defaultName;
        return logoName.split('.')[0];
    });
    const src = computed(()=>{
        const themeSuffix = props.theme === Theme.LIGHT ? '-light' : '';
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        const dynamicSrcLogoName = `${props.productSpecificName || defaultName}${themeSuffix}`;
        // eslint-disable-next-line import/no-dynamic-require
        const dynamicSource = require(`web/src/assets/logotypes/li/default/${dynamicSrcLogoName}.svg`);
        const srcByProductSpecificName = props.productSpecificName && dynamicSource;
        // eslint-disable-next-line global-require,import/no-dynamic-require
        const srcByName = props.name && require(`web/src/assets/logotypes/common/${props.name}`);
        // eslint-disable-next-line max-len,global-require,import/no-dynamic-require
        const srcMascot = props.mascotName && require(`web/src/assets/mascot/li/default/${props.mascotName}`);
        return srcByProductSpecificName || srcByName || srcMascot || dynamicSource;
    });
    return {
        src,
        alt
    };
}
