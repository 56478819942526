// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restore_juYDu .restore__helper-text--block_Md2n_{margin-bottom:24px;overflow:hidden;text-overflow:ellipsis}.restore__helper-text--resend_Dhe_q{display:inline}.restore__helper-text--link_hl3N_{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;display:inline-flex;height:auto;padding:0;margin:0 auto;text-transform:none;white-space:nowrap}.restore-text--link_DUjqZ,.restore__helper-text--link_hl3N_{color:var(--TextDefault);-webkit-text-decoration:underline;text-decoration:underline}@media(hover:hover)and (pointer:fine){.restore-text--link_DUjqZ:hover{color:var(--BrandHighlight);cursor:pointer}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restore": `restore_juYDu`,
	"restore__helper-text--block": `restore__helper-text--block_Md2n_`,
	"restore__helper-text--resend": `restore__helper-text--resend_Dhe_q`,
	"restore__helper-text--link": `restore__helper-text--link_hl3N_`,
	"restore-text--link": `restore-text--link_DUjqZ`
};
export default ___CSS_LOADER_EXPORT___;
