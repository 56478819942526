// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.informer__label_cvfGv{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;display:flex;color:var(--TextSecondary);white-space:nowrap}.informer__value_syPIL{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;color:var(--TextDefault)}.informer__value_syPIL svg:first-of-type{margin-left:4px}.informer__value--wrap__rD2p{flex-wrap:wrap}.informer__money_I9ub4{margin-right:8px}.informer__counter-badge_wcIZ8{margin:0 0 0 -4px}.informer__cancel-button_grqIM{font-size:11px;font-weight:500;line-height:16px;text-transform:uppercase;letter-spacing:-.2px;display:inline-flex;align-items:center;justify-content:center;height:20px;padding:0 6px;color:var(--BrandDefault);background-color:var(--OpacityBrandDefault);border-radius:4px}.informer_WLJ6P:hover .informer__label_cvfGv,.informer_WLJ6P:hover .informer__value_syPIL{cursor:pointer}.informer_WLJ6P:hover .informer__label_cvfGv{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"informer__label": `informer__label_cvfGv`,
	"informer__value": `informer__value_syPIL`,
	"informer__value--wrap": `informer__value--wrap__rD2p`,
	"informer__money": `informer__money_I9ub4`,
	"informer__counter-badge": `informer__counter-badge_wcIZ8`,
	"informer__cancel-button": `informer__cancel-button_grqIM`,
	"informer": `informer_WLJ6P`
};
export default ___CSS_LOADER_EXPORT___;
