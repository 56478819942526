// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs--with-navigation_Nalq_{display:flex}.tabs__slide--full-width_bfapK{flex:1}.tabs__slide--highlighted_RaSJC{gap:4px}.tabs__wrapper_JbUXY{padding:0}.tabs__wrapper--with-gap_fUGld{gap:0}.tabs__badge_u8vXR{margin-left:4px}.tabs__menu_pSxSK{display:flex;flex:none;align-items:center}.tabs__icon_IPj_T{color:var(--TextSecondary)}.tabs__icon--active_aXf9x{color:var(--TextDefault)}.tabs__icon--highlighted_pdKJp{color:var(--PinkHighLight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs--with-navigation": `tabs--with-navigation_Nalq_`,
	"tabs__slide--full-width": `tabs__slide--full-width_bfapK`,
	"tabs__slide--highlighted": `tabs__slide--highlighted_RaSJC`,
	"tabs__wrapper": `tabs__wrapper_JbUXY`,
	"tabs__wrapper--with-gap": `tabs__wrapper--with-gap_fUGld`,
	"tabs__badge": `tabs__badge_u8vXR`,
	"tabs__menu": `tabs__menu_pSxSK`,
	"tabs__icon": `tabs__icon_IPj_T`,
	"tabs__icon--active": `tabs__icon--active_aXf9x`,
	"tabs__icon--highlighted": `tabs__icon--highlighted_pdKJp`
};
export default ___CSS_LOADER_EXPORT___;
