import { compareAsc } from 'web/src/utils/sort';
import { getFilterForMarketGroup } from './getFilterForMarketGroup';
function checkSimpleTypeFilter(group, marketsByType, result) {
    if (group.specifier) return false;
    if (!group.marketTypeIds.some((typeId)=>!!marketsByType[typeId]?.length)) return true;
    const filter = getFilterForMarketGroup(group);
    if (!result.has(filter.id)) result.set(filter.id, filter);
    return true;
}
function createFiltersForSpecifierMap(group, marketsInGroup) {
    const groupSpecifier = group.specifier;
    const filtersForSpecifierMap = new Map();
    if (!groupSpecifier) return filtersForSpecifierMap;
    for (const market of marketsInGroup){
        if (!!market.specifiers?.length) {
            for (const specifier of market.specifiers)if (specifier.id === groupSpecifier.id) {
                const filter = getFilterForMarketGroup(group, specifier);
                const filters = filtersForSpecifierMap.get(groupSpecifier.id) ?? [];
                filters.push(filter);
                filtersForSpecifierMap.set(groupSpecifier.id, filters);
            }
        }
    }
    return filtersForSpecifierMap;
}
function checkTypeWithSpecifiersFilter(group, marketsByType, result) {
    const groupSpecifier = group.specifier;
    if (!groupSpecifier) return false;
    const marketsInGroup = group.marketTypeIds.flatMap((typeId)=>marketsByType[typeId] ?? []);
    if (!marketsInGroup.length) return true;
    const filtersForSpecifierMap = createFiltersForSpecifierMap(group, marketsInGroup);
    for (const filters of [
        ...filtersForSpecifierMap.values()
    ]){
        // @see LEONWEB-14926 keep filters in the alphabetical order
        const orderedValues = filters.sort((a, b)=>compareAsc(a.specifier.value, b.specifier.value));
        for (const filter of orderedValues)if (!result.has(filter.id)) result.set(filter.id, filter);
    }
    return true;
}
export function getNonEmptyMarketsGridFilters() {
    let marketGroups = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], markets = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    const result = new Map();
    const marketsByType = {};
    for (const market of markets){
        const typeId = market.type.id;
        if (!marketsByType[typeId]) marketsByType[typeId] = [];
        marketsByType[typeId].push(market);
    }
    for (const group of marketGroups)checkSimpleTypeFilter(group, marketsByType, result) || checkTypeWithSpecifiersFilter(group, marketsByType, result);
    return [
        ...result.values()
    ];
}
