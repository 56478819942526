// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-element-content_qntpi{overflow:hidden;background-color:var(--Layer0);transition-timing-function:cubic-bezier(.4,0,1,1);transition-property:visibility,max-height}.expandable-element-content--hidden_Xli9M{max-height:1px;visibility:hidden}.league-headline_ZzObs{margin-top:12px}.league-headline_ZzObs:first-child{margin-top:0}.sportline-events-list_bJJMX{display:flex;flex-direction:column;gap:2px}.sportline-events-list__container__jRRc{display:flex;flex-direction:column;gap:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandable-element-content": `expandable-element-content_qntpi`,
	"expandable-element-content--hidden": `expandable-element-content--hidden_Xli9M`,
	"league-headline": `league-headline_ZzObs`,
	"sportline-events-list": `sportline-events-list_bJJMX`,
	"sportline-events-list__container": `sportline-events-list__container__jRRc`
};
export default ___CSS_LOADER_EXPORT___;
