import { computed, toRef } from 'vue';
import { HelpButtonMode, HelpChatType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useSupportFaqStore } from 'web/src/modules/support/submodules/faq/store';
export default function useSupport() {
    const siteConfigStore = useSiteConfigStore();
    const supportBlock = toRef(siteConfigStore, 'supportBlock');
    const { isLoggedIn } = useIsLoggedIn();
    const supportFaqStore = useSupportFaqStore();
    const faqs = toRef(supportFaqStore, 'faqs');
    // Help modes
    const helpMode = computed(()=>supportBlock.value?.helpButtonMode ?? HelpButtonMode.HELP);
    const isHelpMode = computed(()=>helpMode.value === HelpButtonMode.HELP);
    const isChatMode = computed(()=>helpMode.value === HelpButtonMode.EXT_CHAT);
    // Chats types
    const chatType = computed(()=>supportBlock.value?.helpChatType);
    const isIntercomAvailable = computed(()=>chatType.value === HelpChatType.INTERCOM);
    const isLiveChatAvailable = computed(()=>chatType.value === HelpChatType.LIVECHAT);
    const isIntercomOptionsListFlow = computed(()=>isHelpMode.value && isIntercomAvailable.value);
    const callbackEnabled = computed(()=>isLoggedIn.value && !!supportBlock.value?.callbackEnabled && !!supportBlock.value?.isCallCenterWorking);
    const faqEnabled = computed(()=>supportBlock.value?.faqEnabled ?? false);
    // Social links
    const instagramUrl = toRef(()=>supportBlock.value?.helpInstagramUrl);
    const whatsappUrl = toRef(()=>supportBlock.value?.helpWhatsappUrl);
    function categoryByUrlId(urlId) {
        return faqs.value.find((category)=>category.urlId === urlId);
    }
    return {
        categoryByUrlId,
        faqEnabled,
        callbackEnabled,
        isHelpMode,
        isChatMode,
        isIntercomAvailable,
        isLiveChatAvailable,
        isIntercomOptionsListFlow,
        instagramUrl,
        whatsappUrl
    };
}
