import { toRef } from 'vue';
import useCustomerDataStore from 'web/src/modules/customer/store/useCustomerDataStore';
import { useRef } from 'web/src/utils/vue/useRef';
import { useIsLoggedIn } from '../composables';
export async function useLazyCustomerDataLogin() {
    await Promise.all([
        // Wait for authorization customer data.
        useRef(toRef(useCustomerDataStore(), 'login')),
        useRef(useIsLoggedIn().isLoggedIn)
    ]);
    return useCustomerDataStore().login;
}
