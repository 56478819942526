/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ import { computed, ref, shallowRef, toRef } from 'vue';
import { useRoute } from 'vue-router';
import { CustomerFieldType, doRegistration, InvalidPhoneExceptionCode, RegistrationStateExceptionCode } from '@leon-hub/api-sdk';
import { isBoolean, isObject, isString, isUndefined } from '@leon-hub/guards';
import { CaptchaRequesterStrategy } from '@leon-hub/module-captcha';
import GqlApiCaptchaRequiredError from '@leon-hub/api/src/client/graphql/errors/GqlApiCaptchaRequiredError';
import { AbstractError } from '@leon-hub/errors';
import { ModalWidth } from '@leon-hub/module-modal';
import { ButtonKind } from '@leon-hub/module-buttons';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { isRegistrationFormFilledFields } from 'web/src/modules/registration/guards/isRegistrationFormFilledFields';
import { CustomCustomerFieldType, RegistrationApiErrorCode, RegistrationFieldName } from 'web/src/modules/registration/enums';
import { useRegistrationComplete } from 'web/src/modules/registration/store/composables/useRegistrationComplete';
import { useRegistrationAnalytics, useRegistrationFormService } from 'web/src/modules/registration/composables';
import mapToObject from 'web/src/utils/map/mapToObject';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCaptchaStore } from 'web/src/modules/captcha/store';
import { FormControlType } from 'web/src/components/Form/enums';
import useI18n from 'web/src/modules/i18n/composables/useI18n';
import { useAvailableRegistrationForms } from 'web/src/modules/registration/store/composables/useAvailableRegistrationForms';
import extractCustomerFormErrors from 'web/src/utils/forms/extractCustomerFormErrors';
import useCaptchaResolver from 'web/src/modules/captcha/composables/useCaptchaResolver';
import { isCaptchaToken } from 'web/src/modules/captcha/store/utils';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useAuthStore } from 'web/src/modules/auth/store';
import { useCurrencyStore } from 'web/src/modules/money/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { phoneNumberStarModifier } from 'web/src/utils/phoneNumberUtils/phoneNumberStarModifier';
import { isFormPhoneValue } from 'web/src/components/Form/guards';
import hasAnyFormErrors from 'web/src/modules/registration/utils/hasAnyFormErrors';
import registrationTypeFormat from 'web/src/modules/registration/utils/registrationTypeFormat';
import DeviceCustomerLoginStorage from 'web/src/modules/identity/utils/deviceCustomerLoginStorage';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import updateFieldErrorByFormError from 'web/src/utils/forms/updateFieldErrorByFormError';
import useThemeStore from 'web/src/modules/theme/store/useThemeStore';
import { useRecaptchaV3Props } from 'web/src/modules/captcha/composables/useRecaptchaV3Props';
import { useRegistrationGeneratedPassword } from './useRegistrationGeneratedPassword';
export function useRegistrationForms() {
    const route = useRoute();
    const gqlClient = useGraphqlClient();
    const regFormService = useRegistrationFormService();
    const availableForms = useAvailableRegistrationForms();
    const siteConfigStore = useSiteConfigStore();
    const { captchaSettingsForStrategy } = useCaptchaStore();
    const customerDataStore = useCustomerDataStore();
    const { $translate } = useI18n();
    const { setCurrency } = useCurrencyStore();
    const { showDialog } = useDialogs();
    const regAnalytics = useRegistrationAnalytics();
    const errorConverter = useErrorsConverter();
    const eventBus = useEventsBus();
    const { setCaptchaToken, resolveCaptcha } = useCaptchaResolver();
    const { isRegistrationCaptchaV3Enabled, configuredCaptchaV3UiSchema } = useRecaptchaV3Props({
        action: 'reg_submit'
    });
    const { setLoggedIn } = useAuthStore();
    const captchaSettings = captchaSettingsForStrategy(CaptchaRequesterStrategy.REGISTRATION);
    const generatedPassword = useRegistrationGeneratedPassword();
    const isRegistrationComplete = useRegistrationComplete();
    const isFormPending = ref(false);
    const isAdditionalRegistrationNeeded = ref(false);
    const bonusCode = ref(null);
    const currentRegistrationForm = shallowRef(null);
    const lastRequestedForm = shallowRef(null);
    const formFilledFields = shallowRef({});
    const submittedPhone = shallowRef({});
    const regFormCustomErrors = shallowRef({});
    const previousFormData = shallowRef({});
    const submitResult = ref('');
    const formsEmailHintsEnabled = toRef(()=>siteConfigStore.formsEmailHintsEnabled);
    const formsEmailHints = toRef(()=>siteConfigStore.formsEmailHints);
    const themeStore = useThemeStore();
    const firstTheme = toRef(()=>themeStore.firstTheme);
    const theme = toRef(()=>themeStore.theme);
    eventBus.on(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, callBackEmmiter);
    eventBus.on(BusEvent.CAPTCHA_SERVICE_ERROR, callBackEmmiter);
    let captchaToken = '';
    const urlBonusCode = computed(()=>route.query.bcode ? `${route.query.bcode}` : null);
    async function resetRegistrationState() {
        resetCaptchaToken();
        formFilledFields.value = {};
        regFormCustomErrors.value = {};
        bonusCode.value = null;
        generatedPassword.set('');
        submitResult.value = '';
        isAdditionalRegistrationNeeded.value = false;
        setSelectedRegistrationType(null);
        setFormPendingStatus(false);
        await fetchForms();
    }
    function setSelectedRegistrationType(registrationType) {
        availableForms.setSelectedRegistrationType(registrationType);
    }
    function setRegFormCustomErrors(errors) {
        regFormCustomErrors.value = errors;
    }
    function setGeneratedPassword(password) {
        generatedPassword.set(password);
    }
    function saveFormFilledFields(formData) {
        const rawData = mapToObject(formData);
        const filteredData = {};
        for (const key of Object.keys(rawData))if (!isUndefined(rawData[key])) filteredData[key] = rawData[key];
        isRegistrationFormFilledFields(filteredData);
        formFilledFields.value = {
            ...formFilledFields.value,
            ...filteredData
        };
    }
    const registrationStep = toRef(()=>currentRegistrationForm.value?.formStep ?? '');
    const registrationProgress = toRef(()=>currentRegistrationForm.value?.formProgress ?? 0);
    const captchaEnabled = toRef(()=>captchaSettings.value.isEnabledToShow);
    const backSchemaId = toRef(()=>currentRegistrationForm.value?.goBack?.schemaId ?? '');
    const backFormParameters = toRef(()=>currentRegistrationForm.value?.goBack?.formParams ?? []);
    const hasCustomErrors = computed(()=>Object.keys(regFormCustomErrors.value).length > 0);
    async function fetchForms() {
        await availableForms.fetchForms();
        const { selectedForm } = availableForms;
        setCurrentForm(selectedForm.value);
    }
    function setInitialAvailableForm() {
        const { selectedForm } = availableForms;
        setCurrentForm(selectedForm.value);
    }
    async function onResendSmsCode(data, options, phoneCodeMethod) {
        if (hasAnyFormErrors(data)) return;
        await handleCaptchaVerification(data);
        await handleRegistrationRequest(data, {
            ...options,
            addFingerprint: false,
            extraFormParams: [
                {
                    key: 'phoneVerificationType',
                    value: phoneCodeMethod
                }
            ]
        });
    }
    async function onSubmitHandler(data, options) {
        if (hasAnyFormErrors(data)) return;
        const { formData } = data;
        await handleCaptchaVerification(data);
        handleBonusCode(formData);
        regAnalytics.registrationSubmit();
        setRegFormCustomErrors({});
        await handleRegistrationRequest(data, options);
        if (formData[CustomerFieldType.PHONE_INPUT] && !hasCustomErrors.value) setSubmittedPhone(formData[CustomerFieldType.PHONE_INPUT]);
    }
    const configuredCaptchaUiSchema = toRef(()=>({
            [CustomCustomerFieldType.CAPTCHA_TYPE]: {
                default: captchaSettings.value.captchaType,
                widget: FormControlType.Hidden
            },
            [CustomCustomerFieldType.CAPTCHA_TOKEN]: {
                options: {
                    captchaRequesterStrategy: CaptchaRequesterStrategy.REGISTRATION,
                    captchaEnabled: captchaEnabled.value,
                    reCaptchaTheme: captchaSettings.value.theme,
                    isEnforced: captchaSettings.value.isEnforced
                },
                hidden: true,
                widget: FormControlType.Captcha
            }
        }));
    const currentFormProperties = computed(()=>{
        const form = currentRegistrationForm.value;
        if (!form?.formName) return;
        const formSchema = regFormService.getFormSchema(form, {
            formFilledFields: formFilledFields.value,
            formsEmailHintsEnabled: formsEmailHintsEnabled.value,
            formsEmailHints: formsEmailHints.value,
            bonusCode: bonusCode.value ?? urlBonusCode.value
        });
        if (formSchema.schema && isRegistrationCaptchaV3Enabled.value && !formSchema.schema.properties[CustomCustomerFieldType.CAPTCHA_TOKEN_V3]) formSchema.schema.properties[CustomCustomerFieldType.CAPTCHA_TOKEN_V3] = {
            type: 'string'
        };
        const isCaptchaFields = CustomCustomerFieldType.CAPTCHA_TYPE in (formSchema?.uiSchema?.fields ?? {});
        return {
            priority: form.priority,
            formName: form.formName,
            schemaId: form.schemaId,
            schema: formSchema.schema,
            uiSchema: {
                ...formSchema.uiSchema,
                fields: {
                    ...formSchema.uiSchema.fields,
                    ...isCaptchaFields ? configuredCaptchaUiSchema.value : {},
                    ...isRegistrationCaptchaV3Enabled.value ? configuredCaptchaV3UiSchema.value : {}
                }
            }
        };
    });
    function callBackEmmiter() {
        setFormPendingStatus(false);
    }
    function setFormPendingStatus() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
        if (isAdditionalRegistrationNeeded.value) {
            isFormPending.value = isAdditionalRegistrationNeeded.value;
            return;
        }
        isFormPending.value = value;
    }
    function filterUiFormOrderFields(fields) {
        return fields.filter((field)=>{
            if (field === CustomCustomerFieldType.CAPTCHA_TOKEN_V3) return isRegistrationCaptchaV3Enabled.value;
            return 'resendSmsStepModifier' !== field;
        });
    }
    function filterUiFormFields(fields) {
        return fields.filter((field)=>{
            if (field.id === CustomCustomerFieldType.CAPTCHA_TOKEN_V3) return isRegistrationCaptchaV3Enabled.value;
            return 'resendSmsStepModifier' !== field.id;
        });
    }
    function setCurrentForm(value) {
        if (null === value) currentRegistrationForm.value = null;
        else {
            const normalizedForm = regFormService.normalizeFormData(value);
            if (normalizedForm) currentRegistrationForm.value = {
                ...normalizedForm,
                uiFormSchema: {
                    ...normalizedForm.uiFormSchema,
                    order: filterUiFormOrderFields(normalizedForm.uiFormSchema.order),
                    fields: filterUiFormFields(normalizedForm.uiFormSchema.fields)
                }
            };
        }
    }
    function setSubmittedPhone(value) {
        const phone = isFormPhoneValue(value) ? value : {
            prefix: '',
            suffix: ''
        };
        submittedPhone.value = phone;
    }
    function resetCaptchaToken() {
        captchaToken = '';
    }
    async function handleCaptcha(forcedCaptchaType) {
        const captchaType = forcedCaptchaType ?? captchaSettings.value.captchaType;
        captchaToken = await resolveCaptcha(captchaType);
    }
    function onInputHandler(data) {
        const token = data.formData[CustomCustomerFieldType.CAPTCHA_TOKEN];
        if (isCaptchaToken(token)) setCaptchaToken(token);
    }
    function handleBonusCode(formData) {
        if (formData[CustomerFieldType.BONUS_CODE]) {
            const formBonusCode = formData[CustomerFieldType.BONUS_CODE];
            isString(formBonusCode);
            bonusCode.value = formBonusCode;
            regAnalytics.setMetrikaBonusCode({
                registrationType: availableForms.registrationType.value,
                bonusCode: formBonusCode
            });
        }
    }
    async function handleCaptchaVerification(data) {
        const { formData } = data;
        if (captchaSettings.value.isEnforced && !captchaToken) await handleCaptcha();
        if (captchaToken) formData[CustomCustomerFieldType.CAPTCHA_TOKEN] = captchaToken;
    }
    async function handleRegistrationRequest(data, options) {
        const { formData } = data;
        let registrationInput = null;
        try {
            setFormPendingStatus(true);
            registrationInput = await regFormService.prepareRegistrationInput(formData, options);
            await makeRegistrationRequest(registrationInput, formData);
        } catch (error) {
            await handleRegistrationError(error, data, registrationInput);
        } finally{
            setFormPendingStatus(false);
        }
    }
    async function makeRegistrationRequest(payload, formData) {
        const response = await doRegistration(gqlClient, (node)=>node.mutations.registration.submit, {
            options: {
                simpleRegistration: payload
            }
        });
        handleResponse(response, formData);
    }
    async function handleRegistrationError(error, data, registrationInput) {
        const { formData } = data;
        if (error instanceof GqlApiCaptchaRequiredError) {
            await handleCaptcha(error.extensions.captchaType);
            if (captchaToken) formData[CustomCustomerFieldType.CAPTCHA_TOKEN] = captchaToken;
            if (registrationInput) {
                await makeRegistrationRequest(registrationInput, formData);
                return;
            }
        }
        regAnalytics.registrationError(errorConverter.convertToBaseError(error), registrationTypeFormat(availableForms.registrationType.value));
        if (error instanceof AbstractError) {
            if (error.code.equals(RegistrationStateExceptionCode.REGISTRATION_STATE_ERROR)) setInitialAvailableForm();
            else if (error.code.equals(InvalidPhoneExceptionCode.INVALID_PHONE) && formData) {
                setRegFormCustomErrors(updateFieldErrorByFormError(formData, CustomerFieldType.PHONE_INPUT, void 0, regFormCustomErrors.value, error.message));
                return;
            } else if (error.code.equals(RegistrationApiErrorCode.TOO_MANY_ATTEMPTS)) {
                showDialog({
                    presetName: PresetName.ALERT_ERROR,
                    options: {
                        title: $translate('WEB2_MODALTITLE_ATTENTION').value,
                        analyticsModalType: void 0,
                        confirmMessage: error.message,
                        width: ModalWidth.SMALL,
                        buttons: [
                            {
                                kind: ButtonKind.PRIMARY,
                                action: DialogAction.MODAL_CLOSE,
                                label: $translate('JSP_PCL_FBOT_CLOSE').value
                            }
                        ]
                    }
                });
                return;
            }
        }
        throw error;
    }
    function handleResponse(response, formData) {
        if (response?.form) setCurrentForm(response?.form);
        lastRequestedForm.value = response?.form ?? null;
        setGeneratedPassword(response?.generatedPassword ?? '');
        handleCustomerData(response?.customerData, formData);
        submitResult.value = response?.result ?? '';
        if (response?.form?.formError || response?.form?.hasFieldsErrors) setRegFormCustomErrors(extractCustomerFormErrors(response?.form?.uiFormSchema, formData));
    }
    function handleCustomerData(customerData, formData) {
        if (customerData) updateCustomerData(customerData);
        else updatePreviousFormData(formData);
    }
    function updateCustomerData(customerData) {
        customerDataStore.setCustomerData(customerData);
        setLoggedIn(true);
        isRegistrationComplete.set(true);
        eventBus.off(BusEvent.CAPTCHA_CHALLENGE_IS_CLOSED, callBackEmmiter);
        eventBus.off(BusEvent.CAPTCHA_SERVICE_ERROR, callBackEmmiter);
        regAnalytics.registrationSuccess({
            userId: customerData?.customerLogin ?? '',
            firstTheme: firstTheme.value,
            theme: theme.value,
            registrationType: availableForms.registrationType.value
        });
        if (customerData.additionalRegistrationNeeded) isAdditionalRegistrationNeeded.value = true;
        const { currency } = customerData;
        if (isString(currency)) setCurrency(currency);
        DeviceCustomerLoginStorage.setLogin(customerData?.customerLogin || '');
    }
    function updatePreviousFormData(formData) {
        previousFormData.value = RegistrationFieldName.SMS_CODE in formData ? {
            ...previousFormData.value,
            [CustomerFieldType.PHONE_INPUT]: formData[CustomerFieldType.PHONE_INPUT]
        } : formData;
    }
    const submittedPhoneStarred = computed(()=>{
        if (!submittedPhone.value) return '';
        return phoneNumberStarModifier({
            prefix: submittedPhone.value?.prefix ?? '',
            suffix: submittedPhone.value?.suffix ?? ''
        });
    });
    const lastRegFormParameters = computed(()=>{
        const currentUiSchema = currentRegistrationForm.value?.uiFormSchema;
        const fields = currentUiSchema?.fields;
        if (!fields?.length) return [];
        return [
            ...fields.filter((field)=>field.id !== RegistrationFieldName.SMS_CODE).map((field)=>({
                    key: field.id,
                    // eslint-disable-next-line no-nested-ternary
                    value: isObject(field.defaultValue) ? field.defaultValue : isBoolean(field.defaultValue) ? field.defaultValue : String(field.defaultValue)
                })),
            {
                key: CustomCustomerFieldType.CAPTCHA_TYPE,
                value: captchaSettings.value.captchaType
            }
        ];
    });
    function getConfiguredCaptchaV3UiSchema(props) {
        return useRecaptchaV3Props(props).configuredCaptchaV3UiSchema;
    }
    return {
        ...availableForms,
        registrationForm: currentRegistrationForm,
        currentFormProperties,
        registrationStep,
        captchaSettings,
        formFilledFields,
        submittedPhone,
        isFormPending,
        regFormCustomErrors,
        bonusCode,
        backSchemaId,
        backFormParams: backFormParameters,
        setRegFormCustomErrors,
        saveFormFilledFields,
        fetchForms,
        setFormPendingStatus,
        setCurrentForm,
        resetRegistrationState,
        configuredCaptchaUiSchema,
        onSubmitHandler,
        onInputHandler,
        onResendSmsCode,
        registrationProgress,
        submittedPhoneStarred,
        prevFormData: previousFormData,
        setSubmittedPhone,
        generatedPassword: toRef(generatedPassword, 'value'),
        submitResult,
        lastRegFormParams: lastRegFormParameters,
        resetCaptchaToken,
        setInitialAvailableForm,
        setSelectedRegistrationType,
        lastRequestedForm,
        hasCustomErrors,
        isRegistrationCaptchaV3Enabled,
        getConfiguredCaptchaV3UiSchema
    };
}
