// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-loader_DZHjE{width:100%;height:100%;fill:var(--Highlight)}.logo-loader--small_EQ_Qe{width:60%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-loader": `logo-loader_DZHjE`,
	"logo-loader--small": `logo-loader--small_EQ_Qe`
};
export default ___CSS_LOADER_EXPORT___;
