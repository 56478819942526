import { computed } from 'vue';
import { ProgressHeight } from 'web/src/components/LinearProgress/VLinearProgress/enums';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
export default function useBonusLoyaltyProgramPanel(props) {
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const progressProperties = computed(()=>({
            height: ProgressHeight.TINY,
            value: parseInt(props.progressText || '', 10),
            of: 100
        }));
    const buttonProperties = computed(()=>({
            label: $translate('WEB2_JSPACC_ACC_EXCHANGE').value,
            kind: ButtonKind.PRIMARY,
            height: ButtonHeight.MEDIUM,
            fullWidth: true
        }));
    const participateButtonProperties = computed(()=>({
            label: $translate('WEB2_TO_PARTICIPATE').value,
            kind: ButtonKind.PRIMARY,
            height: ButtonHeight.MEDIUM,
            fullWidth: true
        }));
    const pointsLabelProperties = computed(()=>$formatMoney(props.amountNumber || 0, {
            currency: 'L',
            hideCurrency: true
        }));
    const requiredAmount = computed(()=>$formatMoney(props.remainingAmountNumber || 0, {
            currency: 'L'
        }));
    return {
        progressProperties,
        buttonProperties,
        participateButtonProperties,
        pointsLabelProperties,
        requiredAmount
    };
}
