// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loyalty-program-panel_DYSJL{padding:16px;margin-bottom:24px;background-color:var(--Layer1);border-radius:5px}.loyalty-program-panel__header_qZ1ja{display:flex;justify-content:space-between}.loyalty-program-panel__header-icon_y51r6{flex-shrink:0;width:44px}.loyalty-program-panel__headline_MZuQH{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;margin-bottom:8px;color:var(--TextSecondary)}.loyalty-program-panel__points_Iddu6{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;display:flex;align-items:center}.loyalty-program-panel__points-label_lBP63{margin-right:4px;color:var(--TextDefault)}.loyalty-program-panel__points-icon_KX6Jh{fill:var(--BrandDefault)}.loyalty-program-panel__progress_SbrsL{display:flex;flex-flow:row wrap;justify-content:space-between;margin-top:20px}.loyalty-program-panel__progress-line_VBoNV{width:100%;margin-bottom:8px}.loyalty-program-panel__progress-label_ArvYc{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--BrandDefault)}.loyalty-program-panel__progress-total_CJTON{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}.loyalty-program-panel__button_Hzdja{margin-top:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loyalty-program-panel": `loyalty-program-panel_DYSJL`,
	"loyalty-program-panel__header": `loyalty-program-panel__header_qZ1ja`,
	"loyalty-program-panel__header-icon": `loyalty-program-panel__header-icon_y51r6`,
	"loyalty-program-panel__headline": `loyalty-program-panel__headline_MZuQH`,
	"loyalty-program-panel__points": `loyalty-program-panel__points_Iddu6`,
	"loyalty-program-panel__points-label": `loyalty-program-panel__points-label_lBP63`,
	"loyalty-program-panel__points-icon": `loyalty-program-panel__points-icon_KX6Jh`,
	"loyalty-program-panel__progress": `loyalty-program-panel__progress_SbrsL`,
	"loyalty-program-panel__progress-line": `loyalty-program-panel__progress-line_VBoNV`,
	"loyalty-program-panel__progress-label": `loyalty-program-panel__progress-label_ArvYc`,
	"loyalty-program-panel__progress-total": `loyalty-program-panel__progress-total_CJTON`,
	"loyalty-program-panel__button": `loyalty-program-panel__button_Hzdja`
};
export default ___CSS_LOADER_EXPORT___;
