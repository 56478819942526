// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton-image_Jytxi{display:flex;align-items:center;justify-content:center;width:100%;height:100%;padding:4px;background-color:var(--Layer1);border-radius:5px}.skeleton-image__logo_E6Tvh{width:60%;max-width:72px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton-image": `skeleton-image_Jytxi`,
	"skeleton-image__logo": `skeleton-image__logo_E6Tvh`
};
export default ___CSS_LOADER_EXPORT___;
