var AccountHistoryRouteName;
var AccountHistoryRouteName1;
(AccountHistoryRouteName1 = AccountHistoryRouteName || (AccountHistoryRouteName = {}))["ACCOUNT_HISTORY"] = "account-history", AccountHistoryRouteName1["ACCOUNT_HISTORY_EGS"] = "account-history-egs", AccountHistoryRouteName1["ACCOUNT_HISTORY_EGS_DETAILS"] = "account-history-egs-details", AccountHistoryRouteName1["ACCOUNT_HISTORY_EGS_ROUND"] = "account-history-egs-round", AccountHistoryRouteName1["ACCOUNT_HISTORY_MARKETING"] = "account-history-marketing", AccountHistoryRouteName1["ACCOUNT_HISTORY_MARKETING_DETAILS"] = "account-history-marketing-details", AccountHistoryRouteName1["ACCOUNT_HISTORY_PAYMENT"] = "account-history-payment", AccountHistoryRouteName1["ACCOUNT_HISTORY_PAYMENT_DETAILS"] = "account-history-payment-details", AccountHistoryRouteName1["ACCOUNT_HISTORY_SPORTSBOOK"] = "account-history-sportsbook", AccountHistoryRouteName1["ACCOUNT_HISTORY_SPORTSBOOK_DETAILS"] = "account-history-sportsbook-details", AccountHistoryRouteName1["ACCOUNT_HISTORY_VSPORT"] = "account-history-vsport", AccountHistoryRouteName1["ACCOUNT_HISTORY_VSPORT_DETAILS"] = "account-history-vsport-details";
// EGS Routes
// Marketing Routes
// Payment Routes
// Sportsbook Routes
// Vsport Routes
export default AccountHistoryRouteName;
const accountHistoryRouteNames = Object.values(AccountHistoryRouteName);
export function isAccountHistoryRouteName(value) {
    return accountHistoryRouteNames.includes(value);
}
