import { defineStore } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { CashbackConfirmationState, getCashback as getCashbackApi, doConfirmCashback, doRefuseCashback } from '@leon-hub/api-sdk';
import { isObject } from '@leon-hub/guards';
import { logger } from '@leon-hub/logging';
import { Json } from '@leon-hub/utils';
import { RegistrationApiErrorCode } from 'web/src/modules/registration/enums';
import DateTime from 'web/src/utils/DateTime';
import { useCustomerBalanceUpdateStore } from 'web/src/modules/customer/store';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useWebSockets } from 'web/src/modules/core/composables';
import { useWebSocketsConfig } from 'web/src/modules/core/composables/site-config';
import { useSiteConfigStore } from 'web/src/modules/core/store';
const useCashbackStore = defineStore('cashback', ()=>{
    const apiClient = useGraphqlClient();
    const { subscribeAuthorized } = useWebSockets();
    const { isCashbackEnabled } = useWebSocketsConfig();
    const { convertToBaseError } = useErrorsConverter();
    const cashbackFromPolling = toRef(useCustomerBalanceUpdateStore(), 'cashbackFromPolling');
    const isWeb2BonusCashbackWidgetEnabled = toRef(useSiteConfigStore(), 'isWeb2BonusCashbackWidgetEnabled');
    const timeout = ref(20000);
    const cashback = ref({});
    const cashbackAmount = ref(0);
    const isCashbackActive = ref(false);
    const isCashbackBeforeStart = ref(false);
    const isCashbackRefused = ref(false);
    const isCashbackRoundActiveNow = ref(false);
    const cashbackCounterTimestamp = ref(0);
    // Getters:
    const actualRoundStartDate = computed(()=>cashback.value.cashback?.actualRoundStartDate || '');
    const actualRoundEndDate = computed(()=>cashback.value.cashback?.actualRoundEndDate || '');
    const timestampStartDate = computed(()=>DateTime.toTimestamp(actualRoundStartDate.value));
    const timestampEndDate = computed(()=>DateTime.toTimestamp(actualRoundEndDate.value));
    const isCashbackParticipatingConfirmed = computed(()=>cashback.value.cashback?.confirmation.confirmationState === CashbackConfirmationState.CONFIRMED);
    const isCashbackParticipatingNotConfirmed = computed(()=>cashback.value.cashback?.confirmation.confirmationState === CashbackConfirmationState.NOT_CONFIRMED);
    const isCurrentCashback = computed(()=>{
        const currentDateTime = Date.now();
        return currentDateTime >= timestampStartDate.value && currentDateTime < timestampEndDate.value;
    });
    const isCashbackAvailable = computed(()=>isCurrentCashback.value && isCashbackParticipatingConfirmed.value);
    const cashbackPromotionUrl = computed(()=>cashback.value.cashback?.actionUrl || '');
    const cashbackPromotionCategoryId = computed(()=>cashback.value.cashback?.categoryId || '');
    const isCashbackDisplay = computed(()=>isCashbackParticipatingConfirmed.value && isCashbackActive.value && !isCashbackRefused.value);
    const isCashbackSuspended = computed(()=>cashback.value.cashback?.suspended ?? false);
    const cashbackWagerCurrentAmount = computed(()=>cashback.value.cashback?.wagerProgress?.currentAmount || 0);
    const cashbackWagerRequiredAmount = computed(()=>cashback.value.cashback?.wagerProgress?.requiredAmount || 0);
    const cashbackCurrency = computed(()=>cashback.value.cashback?.currency);
    const bonusAutoTransferEnabled = computed(()=>cashback.value.cashback?.bonusAutoTransferEnabled ?? false);
    // Mutations:
    function setCashback(value) {
        cashback.value = value;
    }
    function setCashbackAmount(value) {
        cashbackAmount.value = value;
    }
    function updateCashback(data) {
        cashbackAmount.value = data.newProperties.amount || 0;
    }
    function setCashbackActive(value) {
        isCashbackActive.value = value;
    }
    function setCashbackRefused(value) {
        isCashbackRefused.value = value;
    }
    function updateCashbackCounterTimestamp(value) {
        if (value) cashbackCounterTimestamp.value = timestampEndDate.value;
        else cashbackCounterTimestamp.value = timestampStartDate.value;
    }
    // Actions:
    async function getCashback() {
        return new Promise((resolve, reject)=>{
            getCashbackApi(apiClient, (node)=>node.queries.cashback.getCashback).then((result)=>{
                if (!isObject(result)) logger.error(`Unexpected type: cashback is not object=${Json.stringify(result)}`);
                setCashback(result ?? {});
                if (result && 0 !== Object.keys(result).length) {
                    setCashbackActive(true);
                    const currentDateTime = Date.now();
                    isCashbackBeforeStart.value = currentDateTime < timestampStartDate.value;
                    isCashbackRoundActiveNow.value = currentDateTime >= timestampStartDate.value && currentDateTime < timestampEndDate.value;
                    updateCashbackCounterTimestamp(isCashbackRoundActiveNow.value);
                }
                resolve();
            }).catch((originalError)=>{
                const error = convertToBaseError(originalError);
                const { code } = error;
                // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                if (code.toString() === RegistrationApiErrorCode.TECH) {
                    logger.error(error);
                    resolve();
                } else if ('NO_CAMPAIGN' === code.toString()) resolve();
                else reject(originalError);
            });
        });
    }
    async function confirmCashback() {
        const response = await doConfirmCashback(apiClient, (node)=>node.mutations.cashback.confirmCashback);
        if (response) await getCashback();
    }
    async function refuseCashback() {
        try {
            await Promise.all([
                doRefuseCashback(apiClient, (node)=>node.mutations.cashback.refuseCashback),
                getCashback()
            ]);
        } catch (rawError) {
            const error = convertToBaseError(rawError);
            throw new Error(error.message);
        }
    }
    function subscribeOnUpdatedCashback() {
        subscribeAuthorized({
            method: 'onUpdatedCashback',
            onMessage: (data)=>{
                updateCashback({
                    newProperties: {
                        amount: data.onUpdatedCashback.amount
                    }
                });
            },
            isEnabled: isCashbackEnabled,
            polling: {
                timeout,
                callback: async ()=>{
                    if (isWeb2BonusCashbackWidgetEnabled.value) await getCashback();
                },
                callOnLogin: true
            }
        });
    }
    function subscribeOnRefusedCashback() {
        subscribeAuthorized({
            method: 'onRefusedCashback',
            onMessage: ()=>{
                setCashbackRefused(true);
            },
            isEnabled: isCashbackEnabled
        });
    }
    function init() {
        subscribeOnUpdatedCashback();
        subscribeOnRefusedCashback();
        watch(cashbackFromPolling, (newValue)=>{
            setCashbackAmount(newValue);
        }, {
            immediate: true
        });
    }
    init();
    return {
        cashback,
        cashbackAmount,
        isCashbackActive,
        isCashbackRefused,
        isCashbackRoundActiveNow,
        cashbackCounterTimestamp,
        // Getters:
        bonusAutoTransferEnabled,
        cashbackWagerCurrentAmount,
        cashbackWagerRequiredAmount,
        cashbackCurrency,
        timestampEndDate,
        isCashbackDisplay,
        isCashbackSuspended,
        isCurrentCashback,
        actualRoundEndDate,
        timestampStartDate,
        isCashbackAvailable,
        actualRoundStartDate,
        cashbackPromotionUrl,
        isCashbackBeforeStart,
        cashbackPromotionCategoryId,
        isCashbackParticipatingConfirmed,
        isCashbackParticipatingNotConfirmed,
        // Mutations:
        setCashback,
        updateCashback,
        setCashbackAmount,
        setCashbackActive,
        setCashbackRefused,
        // Actions:
        getCashback,
        refuseCashback,
        confirmCashback,
        subscribeOnUpdatedCashback,
        subscribeOnRefusedCashback
    };
});
export default useCashbackStore;
