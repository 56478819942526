import { computed, nextTick, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import RouteName from '@leon-hub/routing-config-names';
import { SearchTab } from 'web/src/modules/search/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useOnAppContentLoadedCallback } from 'web/src/modules/core/composables/content';
import SearchSportlineContent from 'web/src/modules/search/submodules/sportline/views/SearchSportlineContent/SearchSportlineContent.vue';
import SlotResults from 'web/src/modules/search/submodules/casino/views/CasinoSearchContent/CasinoSearchContent.vue';
export default function useSearchRoutePage(props, emits) {
    const { performOnLoad } = useOnAppContentLoadedCallback();
    const input = ref();
    const result = ref();
    const autofocus = ref(false);
    const { $translate } = useI18n();
    const router = useRouter();
    const selectedTabIndex = ref(SearchTab.UNDEFINED);
    const searchText = ref('');
    const searchInputProperties = computed(()=>{
        let placeholder;
        if (selectedTabIndex.value === SearchTab.SPORTS) placeholder = $translate('WEB2_PLACEHOLDER_SEARCH_EVENTS').value;
        if (selectedTabIndex.value === SearchTab.SLOTS) placeholder = $translate('WEB2_PLACEHOLDER_SEARCH_SLOTS').value;
        return {
            placeholder,
            value: searchText.value
        };
    });
    const resultsComponent = computed(()=>{
        let component;
        if (selectedTabIndex.value === SearchTab.SPORTS) component = SearchSportlineContent;
        if (selectedTabIndex.value === SearchTab.SLOTS) component = SlotResults;
        return component;
    });
    const resultsComponentProps = computed(()=>{
        if (selectedTabIndex.value === SearchTab.SLOTS) return {
            shouldReplaceItemRoutes: props.shouldReplaceItemRoutes
        };
        return {};
    });
    async function init() {
        if (props.defaultTabIndex) selectedTabIndex.value = props.defaultTabIndex;
        else selectedTabIndex.value = SearchTab.SPORTS;
        await nextTick();
        onClear();
        if (!process.env.VUE_APP_OS_IOS) performOnLoad(()=>{
            autofocus.value = true;
        });
    }
    async function onFocus() {
        await nextTick();
        input.value?.focusInputElement();
    }
    function setSearchText(value) {
        searchText.value = value;
    }
    function onInput(value) {
        setSearchText(value);
        result.value?.onInput(value);
    }
    function onClear() {
        setSearchText('');
        result.value?.onInputClear();
    }
    function onActiveTabChanged(index) {
        selectedTabIndex.value = index;
        onClear();
        onFocus();
        if (!props.shouldReplaceItemRoutes) router.replace({
            name: index === SearchTab.SLOTS ? CasinoRouteName.CASINO_SEARCH : RouteName.SEARCH
        });
    }
    function emitItemClick() {
        emits('item-click');
    }
    onMounted(init);
    return {
        autofocus,
        input,
        result,
        searchInputProperties,
        onInput,
        onClear,
        onFocus,
        selectedTabIndex,
        onActiveTabChanged,
        resultsComponent,
        resultsComponentProps,
        setSearchText,
        emitItemClick
    };
}
