// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .sportline-event-info_icoGe{position:relative;display:flex;flex-direction:column;gap:10px;padding:8px 16px 8px 0;overflow:hidden;-webkit-text-decoration:none;text-decoration:none;background-color:var(--Layer1)}html.app__layout--desktop .sportline-event-info_icoGe:first-child{padding-left:8px}.sportline-event-block_isrbB:first-child{overflow:hidden;border-top-left-radius:var(--BorderRadius);border-top-right-radius:var(--BorderRadius)}.sportline-event-block_isrbB:last-child{overflow:hidden;border-bottom-right-radius:var(--BorderRadius);border-bottom-left-radius:var(--BorderRadius)}.sportline-event-block-container_NX41j{position:relative;display:flex;background-color:var(--Layer1)}.sportline-event-block-container__favorite_S9oJq{min-width:44px}.sportline-event-block-container__info_cayoG{flex:1 1 auto;flex-wrap:nowrap;min-width:0;padding:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-event-info": `sportline-event-info_icoGe`,
	"sportline-event-block": `sportline-event-block_isrbB`,
	"sportline-event-block-container": `sportline-event-block-container_NX41j`,
	"sportline-event-block-container__favorite": `sportline-event-block-container__favorite_S9oJq`,
	"sportline-event-block-container__info": `sportline-event-block-container__info_cayoG`
};
export default ___CSS_LOADER_EXPORT___;
