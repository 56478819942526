// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-tags__title_sf8Ww{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;margin:16px 8px 12px}.search-tags__buttons_GVXhT{display:flex;flex-wrap:wrap;gap:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-tags__title": `search-tags__title_sf8Ww`,
	"search-tags__buttons": `search-tags__buttons_GVXhT`
};
export default ___CSS_LOADER_EXPORT___;
