// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-loyalty-program-icon_GEEk0{width:24px;height:24px;overflow:hidden}.casino-loyalty-program-icon--size-24_O9VQq{width:24px;height:24px}.casino-loyalty-program-icon--size-32_ljHsD{width:32px;height:32px}.casino-loyalty-program-icon--size-36_Ad9ND{width:36px;height:36px}.casino-loyalty-program-icon--size-42_Y6KZD{width:42px;height:42px}.casino-loyalty-program-icon--size-68_rFNvT{width:68px;height:68px}.casino-loyalty-program-icon--size-80_vnQ2G{width:80px;height:80px}.casino-loyalty-program-icon--size-86_oKlHE{width:86px;height:86px}.casino-loyalty-program-icon--size-92_dTzhN{width:92px;height:92px}.casino-loyalty-program-icon--size-94_Xcu63{width:94px;height:94px}.casino-loyalty-program-icon--size-96_by7ni{width:96px;height:96px}.casino-loyalty-program-icon--size-112_xX3oJ{width:112px;height:112px}.casino-loyalty-program-icon--size-120_zpjh6{width:120px;height:120px}.casino-loyalty-program-icon--size-132_naFnX{width:132px;height:132px}.casino-loyalty-program-icon--size-136_YjsZP{width:136px;height:136px}.casino-loyalty-program-icon--size-160_hgxW1{width:160px;height:160px}.casino-loyalty-program-icon--size-180_sBQfq{width:180px;height:180px}.casino-loyalty-program-icon--size-200_czA0y{width:200px;height:200px}.casino-loyalty-program-icon--size-230_HNGnn{width:230px;height:230px}.casino-loyalty-program-icon--inactive_Mq579 .casino-loyalty-program-icon__img_T82xG{filter:grayscale(100%);opacity:1}.casino-loyalty-program-icon__img_T82xG{width:100%;height:100%;object-fit:contain;object-position:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-loyalty-program-icon": `casino-loyalty-program-icon_GEEk0`,
	"casino-loyalty-program-icon--size-24": `casino-loyalty-program-icon--size-24_O9VQq`,
	"casino-loyalty-program-icon--size-32": `casino-loyalty-program-icon--size-32_ljHsD`,
	"casino-loyalty-program-icon--size-36": `casino-loyalty-program-icon--size-36_Ad9ND`,
	"casino-loyalty-program-icon--size-42": `casino-loyalty-program-icon--size-42_Y6KZD`,
	"casino-loyalty-program-icon--size-68": `casino-loyalty-program-icon--size-68_rFNvT`,
	"casino-loyalty-program-icon--size-80": `casino-loyalty-program-icon--size-80_vnQ2G`,
	"casino-loyalty-program-icon--size-86": `casino-loyalty-program-icon--size-86_oKlHE`,
	"casino-loyalty-program-icon--size-92": `casino-loyalty-program-icon--size-92_dTzhN`,
	"casino-loyalty-program-icon--size-94": `casino-loyalty-program-icon--size-94_Xcu63`,
	"casino-loyalty-program-icon--size-96": `casino-loyalty-program-icon--size-96_by7ni`,
	"casino-loyalty-program-icon--size-112": `casino-loyalty-program-icon--size-112_xX3oJ`,
	"casino-loyalty-program-icon--size-120": `casino-loyalty-program-icon--size-120_zpjh6`,
	"casino-loyalty-program-icon--size-132": `casino-loyalty-program-icon--size-132_naFnX`,
	"casino-loyalty-program-icon--size-136": `casino-loyalty-program-icon--size-136_YjsZP`,
	"casino-loyalty-program-icon--size-160": `casino-loyalty-program-icon--size-160_hgxW1`,
	"casino-loyalty-program-icon--size-180": `casino-loyalty-program-icon--size-180_sBQfq`,
	"casino-loyalty-program-icon--size-200": `casino-loyalty-program-icon--size-200_czA0y`,
	"casino-loyalty-program-icon--size-230": `casino-loyalty-program-icon--size-230_HNGnn`,
	"casino-loyalty-program-icon--inactive": `casino-loyalty-program-icon--inactive_Mq579`,
	"casino-loyalty-program-icon__img": `casino-loyalty-program-icon__img_T82xG`
};
export default ___CSS_LOADER_EXPORT___;
