import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { isString } from '@leon-hub/guards';
import { CategoryType, doButtonMutation, doPromotionParticipation, doPromotionRate, getBonusWalletPromotion, getPromotionDetails, getPromotions, PromotionActionButtonType } from '@leon-hub/api-sdk';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { Timer } from '@leon-hub/utils';
import { PromotionCustomerRatingMethodType } from 'web/src/modules/promotions/types';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useNavigationItemsStore, useNavigationStore, useSiteConfigStore } from 'web/src/modules/core/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { isCategoryType } from 'web/src/modules/promotions/utils';
import { isPromotion } from 'web/src/modules/promotions/utils/typesValidators';
import useIdentificationNeededModal from 'web/src/modules/dialogs/composables/useIdentificationNeededModal';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
const usePromotionsStore = defineStore('promotions', ()=>{
    const DEFAULT_POLLING_INTERVAL = 3000;
    const api = useGraphqlClient();
    const analytics = useAnalytics();
    const navigationStore = useNavigationStore();
    const siteConfigStore = useSiteConfigStore();
    const { showIdentificationNeededModal } = useIdentificationNeededModal();
    const errorConverter = useErrorsConverter();
    const promotionCategoriesNavigationItems = toRef(useNavigationItemsStore(), 'promotionCategoriesNavigationItems');
    let $pollingInterval = DEFAULT_POLLING_INTERVAL;
    const activeCategoryId = ref('');
    const openedActionUrl = ref('');
    const promotions = ref({});
    const bonusWallet = ref(null);
    const participationTable = ref({});
    const filters = ref({});
    const promotionsLoaded = ref(false);
    const finishedPromotions = ref([]);
    const archivedPromotions = ref([]);
    const customerPromotions = ref([]);
    const customerPromoNumbers = ref(0);
    const isRateButtonEnabled = ref(true);
    const promotionLikes = ref(0);
    const categoryIdByCategoryTypeCache = {};
    const customerCategoryId = 'customer';
    let $timeoutId = 0;
    // Mutations:
    function updatePromotionState(value) {
        '' !== value.actionUrl || value.actionUrl;
        // TODO: refactoring promotion.actionUrl as a promotionId
        const promotionId = value.actionUrl || '';
        if (promotionId in promotions.value) promotions.value[promotionId] = {
            ...promotions.value[promotionId],
            ...value
        };
        else promotions.value[promotionId] = value;
    }
    function setPromotions(value) {
        const filter = [];
        for (const promotion of value){
            const promotionId = promotion.actionUrl || '';
            filter.push(promotionId);
            updatePromotionState(promotion);
        }
        promotionsLoaded.value = true;
        if (activeCategoryId.value) filters.value[activeCategoryId.value] = filter;
    }
    function setBonusWallet(bonusWalletValue) {
        bonusWallet.value = bonusWalletValue;
    }
    function updatePromotion(promotion) {
        updatePromotionState(promotion);
    }
    function setCustomerParticipation(param) {
        let { actionUrl, isCustomerParticipating } = param;
        participationTable.value[actionUrl] = isCustomerParticipating;
    }
    function setActiveCategoryId(categoryId) {
        activeCategoryId.value = categoryId;
    }
    function setOpenedActiveUrl() {
        let activeUrl = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        openedActionUrl.value = activeUrl;
    }
    function setPollingInterval(value) {
        $pollingInterval = value;
    }
    function updatePromotionButton(payload) {
        const { actionUrl, actionButton } = payload;
        if (promotions.value[actionUrl]) promotions.value[actionUrl].actionButton = actionButton;
    }
    function setPromotionsLoadedState(value) {
        promotionsLoaded.value = value;
    }
    function setFinishedPromotions(value) {
        const finishedIds = [];
        for (const promotion of value){
            const promotionId = promotion.actionUrl || '';
            finishedIds.push(promotionId);
            finishedPromotions.value = finishedIds;
            updatePromotionState(promotion);
        }
    }
    function setArchivedPromotions(value) {
        const archivedIds = [];
        for (const promotion of value){
            const promotionId = promotion.actionUrl || '';
            archivedIds.push(promotionId);
            archivedPromotions.value = archivedIds;
            updatePromotionState(promotion);
        }
    }
    function setCustomerPromotions(value) {
        const customerIds = [];
        for (const promotion of value){
            const promotionId = promotion.actionUrl || '';
            customerIds.push(promotionId);
            customerPromotions.value = customerIds;
            updatePromotionState(promotion);
        }
    }
    function setCustomerPromoNumbers(promotionsNumbers) {
        customerPromoNumbers.value = promotionsNumbers;
    }
    function setRateButtonEnabled(value) {
        isRateButtonEnabled.value = value;
    }
    function setInitialLikes(likes) {
        promotionLikes.value = likes;
    }
    function setLikes(method) {
        if (method === PromotionCustomerRatingMethodType.INCREASE) promotionLikes.value += 1;
        else promotionLikes.value -= 1;
    }
    // Getters:
    const groupByCategory = computed(()=>Object.keys(promotions.value).reduce((objectByCategory, promotionId)=>{
            const value = promotions.value[promotionId].categoryId;
            const { isCustomerParticipating, isArchived } = promotions.value[promotionId];
            if (value && !isArchived) return {
                ...objectByCategory,
                [value]: [
                    ...objectByCategory[value] || [],
                    promotionId
                ],
                [customerCategoryId]: [
                    ...objectByCategory[customerCategoryId],
                    ...isCustomerParticipating ? [
                        promotionId
                    ] : []
                ]
            };
            return objectByCategory;
        }, {
            [customerCategoryId]: []
        }));
    const allPromos = computed(()=>Object.entries(promotions.value).filter((param)=>{
            let [key, item] = param;
            return !archivedPromotions.value.includes(key) && !item.isArchived;
        }).map((param)=>{
            let [, item] = param;
            return {
                promotionId: item.actionUrl || '',
                isArchived: item.isArchived ?? false,
                isPromotionsLikeEnabled: false
            };
        }));
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const promotionsByCategory = computed(()=>{
        if (!promotionCategoriesNavigationItems.value?.length) return [];
        const items = [];
        for (const item of promotionCategoriesNavigationItems.value){
            const hasCategory = !!item.children?.find((subChild)=>'CATEGORY' === subChild.type);
            if (item.id in groupByCategory.value) items.push({
                id: item.id,
                value: item?.children?.filter((child)=>child.id in promotions.value).map((child)=>child.id) ?? [],
                name: item.caption || '',
                parentId: item.id,
                hasCategory
            });
            item.children?.forEach((subChild)=>{
                if (subChild.id in groupByCategory.value && subChild.children?.length) items.push({
                    id: subChild.id,
                    value: subChild?.children?.filter((child)=>child.id in promotions.value).map((child)=>child.id) || [],
                    name: subChild.caption || '',
                    parentId: item.id,
                    hasCategory
                });
            });
        }
        if (activeCategoryId.value) {
            const categoryGroups = items.filter((item)=>item.parentId === activeCategoryId.value);
            if (categoryGroups.length) return categoryGroups;
            const categoryGroup = items.find((item)=>item.id === activeCategoryId.value);
            if (categoryGroup) return [
                categoryGroup
            ];
        }
        return items;
    });
    const filteredPromotionsByCategory = computed(()=>promotionsByCategory.value.filter((item)=>item.value.length > 0));
    const promotionIsReady = computed(()=>activeCategoryId.value in filters.value);
    const customerCategory = computed(()=>{
        // eslint-disable-next-line max-len
        const navCategory = promotionCategoriesNavigationItems.value?.find((item)=>item.id === customerCategoryId);
        if (!navCategory) return null;
        navCategory.props;
        isString(navCategory.props.type);
        isCategoryType(navCategory.props.type);
        return {
            categoryId: navCategory.id,
            categoryType: navCategory.props.type
        };
    });
    const categoryById = computed(()=>{
        // eslint-disable-next-line max-len
        let navCategory = promotionCategoriesNavigationItems.value?.find((item)=>item.id === activeCategoryId.value);
        if (!navCategory) for (const category of promotionCategoriesNavigationItems.value)// eslint-disable-next-line @typescript-eslint/no-loop-func
        category.children?.forEach((navigationSubItem)=>{
            if (navigationSubItem.id === activeCategoryId.value) navCategory = navigationSubItem;
        });
        if (!navCategory) return null;
        navCategory.props;
        isString(navCategory.props.type);
        isCategoryType(navCategory.props.type);
        return {
            categoryId: navCategory.id,
            categoryType: navCategory.props.type
        };
    });
    function categoryIdByCategoryType(categoryType) {
        if (!categoryIdByCategoryTypeCache[categoryType]) categoryIdByCategoryTypeCache[categoryType] = computed(()=>{
            if (!promotionCategoriesNavigationItems.value) return '';
            const category = promotionCategoriesNavigationItems.value.find((item)=>item.props?.type === categoryType);
            if (!category) return '';
            return category.id;
        });
        return categoryIdByCategoryTypeCache[categoryType];
    }
    const isCustomerParticipating = computed(()=>participationTable.value[openedActionUrl.value]);
    // eslint-disable-next-line max-len
    // const promotionType = computed<PromoActionType | undefined | null>(() => promotions.value[openedActionUrl.value]?.promotionType);
    const promotion = computed(()=>promotions.value[openedActionUrl.value]);
    // eslint-disable-next-line max-len
    const hasCounter = computed(()=>!!promotion.value?.isShowStartDateCountdown || !!promotion.value?.isShowEndDateCountdown);
    const isBeforeStart = computed(()=>{
        if (!!promotion.value?.isShowStartDateCountdown && !!promotion.value?.isShowEndDateCountdown) return false;
        return !!promotion.value?.isShowStartDateCountdown;
    });
    const counterTimestamp = computed(()=>{
        if (isBeforeStart.value) return promotion.value?.startDateCountdown || 0;
        return promotion.value?.endDateNumber || 0;
    });
    const isMyActions = computed(()=>activeCategoryId.value === customerCategoryId);
    const participationNumber = computed(()=>customerPromoNumbers.value);
    const isBlockParticipationButton = computed(()=>promotion.value?.isBlockParticipationButton);
    const getActivePromotions = computed(()=>filters.value[activeCategoryId.value] || []);
    // Actions:
    async function fetchPromotions(payload) {
        /**
     * categoryType === CategoryType.CUSTOM will return empty array without categoryId
     */ const options = {
            ts: 0,
            categoryType: payload.categoryType || CategoryType.ALL,
            categoryId: payload.categoryId || null
        };
        try {
            const data = await getPromotions(api, (node)=>node.queries.promotions.getPromotions, {
                options
            });
            const { promotions: promotionsData } = data;
            if (options.categoryType === CategoryType.CUSTOMER) setCustomerPromoNumbers(promotionsData.length || 0);
            if (options.categoryType === CategoryType.CUSTOMER_ARCHIVED) setFinishedPromotions(promotionsData);
            else setPromotions(promotionsData);
        } catch (error) {
            setPromotions([]);
            throw error;
        }
        setPromotionsLoadedState(true);
    }
    async function loadPromotionDetails(actionUrl) {
        if (actionUrl) {
            setOpenedActiveUrl(actionUrl);
            const filterOptions = {
                actionUrl,
                pageSize: siteConfigStore.promotionsBlock?.leaderboardPageSize || 6,
                ts: 0
            };
            const response = await getPromotionDetails(api, (node)=>node.queries.promotions.getPromotion, {
                options: filterOptions
            });
            const { promotion: promotionResponse, isCustomerParticipating: isCustomerParticipatingResponse } = response;
            if (response) {
                isPromotion(promotionResponse);
                updatePromotion(promotionResponse);
                setCustomerParticipation({
                    actionUrl,
                    isCustomerParticipating: !!isCustomerParticipatingResponse
                });
            }
        }
    }
    async function loadPromotionDetailsWithPolling(data) {
        const { actionUrl, initial } = data;
        if (actionUrl) {
            await loadPromotionDetails(actionUrl);
            promotionDetailsPolling(actionUrl);
            if (!initial) {
                await navigationStore.fetchPromotionsNavigationConfig();
                await getPromotionsByCategoryType(CategoryType.CUSTOMER);
            }
        }
    }
    function promotionDetailsPolling(actionUrl) {
        if (process.env.VUE_APP_PRERENDER || !actionUrl) return;
        const { actionButton } = promotions.value[actionUrl];
        const isProcessing = actionButton?.buttonType === PromotionActionButtonType.PARTICIPATION_PROCESSING;
        if ($timeoutId) {
            Timer.clearTimeout($timeoutId);
            $timeoutId = 0;
        }
        if (isProcessing && $pollingInterval < 10 * DEFAULT_POLLING_INTERVAL) $timeoutId = Timer.setTimeout(()=>{
            loadPromotionDetailsWithPolling({
                actionUrl
            });
            setPollingInterval(2 * $pollingInterval);
        }, $pollingInterval);
        else setPollingInterval(DEFAULT_POLLING_INTERVAL);
    }
    // @TODO: This method renamed getBonusWalletPromotion -> getBonusWalletPromotionAction
    async function getBonusWalletPromotionAction() {
        const response = await getBonusWalletPromotion(api, (node)=>node.queries.promotions.getBonusWalletPromotion);
        const { promotion: promotionResponse } = response;
        if (response) {
            isPromotion(promotionResponse);
            setBonusWallet(promotionResponse);
        }
    }
    async function bonusParticipation(options) {
        try {
            await doPromotionParticipation(api, (node)=>node.mutations.promotions.setParticipation, {
                options
            });
        } catch (rawError) {
            const error = errorConverter.convertToBaseError(rawError);
            const errorCode = error.code;
            if (errorCode.equals('IDENTIFICATION_NEEDED')) showIdentificationNeededModal();
        }
    }
    async function getPromotionsByCategoryId() {
        let categoryIdValue = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        setActiveCategoryId(categoryIdValue);
        const categoryAll = {
            categoryType: CategoryType.ALL,
            categoryId: null
        };
        await fetchPromotions(categoryById.value ?? categoryAll);
    }
    async function getPromotionsByCategoryType(categoryType) {
        await fetchPromotions({
            categoryType,
            categoryId: null
        });
    }
    async function getListOfAll(categoryId) {
        promotions.value = {};
        setPromotionsLoadedState(false);
        await fetchPromotions({
            categoryType: CategoryType.CUSTOM,
            categoryId
        });
    }
    async function getCustomerPromo(categoryId) {
        customerPromotions.value = [];
        setPromotionsLoadedState(false);
        await fetchPromotions({
            categoryType: CategoryType.CUSTOMER,
            categoryId
        });
    }
    function promotionsSendYM(payload) {
        let ymPromoActions = 'allPromo';
        if (payload.categoryName) ymPromoActions = {
            [payload.categoryName]: payload.promotionName || 'all'
        };
        analytics.push(AnalyticsEvent.Z_OPEN_PROMO_PAGE, {
            promoPage: {
                promoActions: ymPromoActions
            }
        });
    }
    // @TODO: This method renamed doButtonMutation -> doButtonMutationAction
    async function doButtonMutationAction(payload) {
        const { actionUrl } = payload;
        const options = {
            ...payload,
            ts: 0
        };
        const { actionButton } = await doButtonMutation(api, (node)=>node.mutations.promotions.updatePromotion, {
            options
        });
        if (actionButton) {
            updatePromotionButton({
                actionUrl,
                actionButton
            });
            promotionDetailsPolling(actionUrl);
        }
    }
    async function promotionRate(payload) {
        const response = await doPromotionRate(api, (node)=>node.mutations.promotions.rate, {
            options: {
                ...payload
            }
        });
        setRateButtonEnabled(false);
        if ('OK' === response.result) setRateButtonEnabled(true);
    }
    return {
        // State:
        openedActionUrl,
        promotions,
        bonusWallet,
        promotionsLoaded,
        finishedPromotions,
        archivedPromotions,
        customerPromotions,
        isRateButtonEnabled,
        promotionLikes,
        // Mutations:
        setPromotions,
        setOpenedActiveUrl,
        setInitialLikes,
        setLikes,
        // Getters:
        promotionsByCategory,
        promotionIsReady,
        customerCategory,
        categoryIdByCategoryType,
        isCustomerParticipating,
        promotion,
        hasCounter,
        isBeforeStart,
        counterTimestamp,
        isMyActions,
        participationNumber,
        isBlockParticipationButton,
        getActivePromotions,
        allPromos,
        filteredPromotionsByCategory,
        // Actions:
        fetchPromotions,
        loadPromotionDetails,
        loadPromotionDetailsWithPolling,
        getBonusWalletPromotionAction,
        bonusParticipation,
        getPromotionsByCategoryId,
        getPromotionsByCategoryType,
        promotionsSendYM,
        doButtonMutationAction,
        promotionRate,
        getListOfAll,
        getCustomerPromo,
        setActiveCategoryId
    };
});
export default usePromotionsStore;
