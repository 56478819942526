// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .accordion-item_tF91e{margin-bottom:4px;overflow:hidden}html.app__layout--desktop .accordion-item--panel_CcT49{background-color:var(--Layer1);border-radius:5px}html.app__layout--desktop .accordion-item--large_JafTK{min-height:64px;margin-bottom:8px}html.app__layout--desktop .accordion-item--large_JafTK .accordion-item__header_NtoMn,html.app__layout--desktop .accordion-item--large_JafTK .accordion-item__indicator_Rwp7K{height:64px}html.app__layout--desktop .accordion-item__header_NtoMn{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;position:relative;display:flex;align-items:center;justify-content:space-between;min-height:44px;padding-left:12px;color:var(--TextPrimary);background-color:var(--Layer1);border-radius:5px;cursor:pointer}@media(hover:hover)and (pointer:fine){html.app__layout--desktop .accordion-item__header_NtoMn:hover{color:var(--TextDefault);cursor:pointer;background-color:var(--Highlight)}}html.app__layout--desktop .accordion-item__header--center_sjBNp{padding-left:0}html.app__layout--desktop .accordion-item__header--center_sjBNp .accordion-item__title_PHY4r{width:100%;max-width:100%;text-align:center}html.app__layout--desktop .accordion-item__header--open_dGua1{color:var(--TextDefault);border-radius:5px 5px 0 0}html.app__layout--desktop .accordion-item__header--open_dGua1:hover{background-color:var(--Layer1)}html.app__layout--desktop .accordion-item__header--large_pJKIb{height:44px}html.app__layout--desktop .accordion-item__indicator_Rwp7K{position:absolute;top:0;right:0;display:flex;align-items:center;justify-content:center;width:44px;height:44px;color:currentcolor}html.app__layout--desktop .accordion-item__content_oxmpl{background-color:var(--Layer1);border-radius:0 0 5px 5px}html.app__layout--desktop .accordion-item__title_PHY4r{max-width:calc(100% - 56px);padding:12px 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion-item": `accordion-item_tF91e`,
	"accordion-item--panel": `accordion-item--panel_CcT49`,
	"accordion-item--large": `accordion-item--large_JafTK`,
	"accordion-item__header": `accordion-item__header_NtoMn`,
	"accordion-item__indicator": `accordion-item__indicator_Rwp7K`,
	"accordion-item__header--center": `accordion-item__header--center_sjBNp`,
	"accordion-item__title": `accordion-item__title_PHY4r`,
	"accordion-item__header--open": `accordion-item__header--open_dGua1`,
	"accordion-item__header--large": `accordion-item__header--large_pJKIb`,
	"accordion-item__content": `accordion-item__content_oxmpl`
};
export default ___CSS_LOADER_EXPORT___;
