// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-validator_JPyQ5{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;padding:0;margin:8px 0 0;color:var(--TextSecondary);list-style:none inside}.password-validator__line_i_Pwj{display:flex;align-items:center;padding:0;margin:0 0 8px}.password-validator__line--error_OO2P_{color:var(--ErrorText)}.password-validator__line--success_WJbWz{color:var(--BrandHighlight)}.password-validator__icon_hXxue{flex:none;margin:0 8px 0 0;margin-right:8px}.password-validator__message_hICy5{flex:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"password-validator": `password-validator_JPyQ5`,
	"password-validator__line": `password-validator__line_i_Pwj`,
	"password-validator__line--error": `password-validator__line--error_OO2P_`,
	"password-validator__line--success": `password-validator__line--success_WJbWz`,
	"password-validator__icon": `password-validator__icon_hXxue`,
	"password-validator__message": `password-validator__message_hICy5`
};
export default ___CSS_LOADER_EXPORT___;
