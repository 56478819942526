// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-caption_sdfbS{font-size:12px;font-weight:500;line-height:16px;text-transform:uppercase;letter-spacing:.4px;padding:12px;margin-bottom:4px;color:var(--TextPrimary);background-color:var(--Button);border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar-caption": `sidebar-caption_sdfbS`
};
export default ___CSS_LOADER_EXPORT___;
