// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-select__input_v_xtO{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;width:100%;min-height:44px;padding:0 12px;color:var(--TextDefault);background-color:#0000;caret-color:var(--Blue);border:none;position:absolute;top:0;left:0;cursor:text}.dropdown-select__input_v_xtO::placeholder{color:#0000}.dropdown-select__input_v_xtO:active,.dropdown-select__input_v_xtO:focus{z-index:1;color:var(--TextDefault)}.dropdown-select__input_v_xtO:disabled{color:var(--TextSecondary);-webkit-text-fill-color:var(--TextSecondary);opacity:1}.dropdown-select__input--large_DtZ7g{min-height:44px;padding:0 12px;border-radius:5px}.dropdown-select__input_v_xtO::placeholder{padding-right:36px;color:var(--TextSecondary)}.dropdown-select__input--large_DtZ7g::placeholder{padding-right:36px}.dropdown-select__input--icon-prefix_kACB2,.dropdown-select__input--icon-prefix_kACB2.dropdown-select__input--large_DtZ7g{padding-left:36px}.dropdown-select__input--hidden_F3FOu{visibility:hidden}.dropdown-select__close_qFZZ3{z-index:5;position:absolute;top:0;right:0;bottom:0;width:44px;cursor:pointer;background:#0000;border:none;opacity:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-select__input": `dropdown-select__input_v_xtO`,
	"dropdown-select__input--large": `dropdown-select__input--large_DtZ7g`,
	"dropdown-select__input--icon-prefix": `dropdown-select__input--icon-prefix_kACB2`,
	"dropdown-select__input--hidden": `dropdown-select__input--hidden_F3FOu`,
	"dropdown-select__close": `dropdown-select__close_qFZZ3`
};
export default ___CSS_LOADER_EXPORT___;
