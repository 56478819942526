// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.push-notifications_heiOA{z-index:71;position:absolute;top:15px;left:114px;width:375px;overflow:hidden;background:var(--Layer1);border-radius:10px;box-shadow:0 8px 16px var(--ModalShadow)}.push-notifications__jubmotron_Mc9NC{padding:32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"push-notifications": `push-notifications_heiOA`,
	"push-notifications__jubmotron": `push-notifications__jubmotron_Mc9NC`
};
export default ___CSS_LOADER_EXPORT___;
