import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = [
    "src"
];
import { ObserveVisibility } from 'vue3-observe-visibility';
import { useVLogoLoader } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLogoLoader',
    props: {
        isAnimationDisabled: {
            type: Boolean
        },
        isFixedLightLogo: {
            type: Boolean
        },
        isSmall: {
            type: Boolean
        },
        isGradient: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { visibilityChanged, iconPath } = useVLogoLoader(props);
        const vObserveVisibility = ObserveVisibility;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("img", {
                class: _normalizeClass({
                    [_ctx.$style['logo-loader']]: true,
                    [_ctx.$style['logo-loader--small']]: _ctx.isSmall
                }),
                src: _unref(iconPath),
                alt: ""
            }, null, 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VLogoLoader'
                ],
                [
                    _unref(vObserveVisibility),
                    {
                        callback: _unref(visibilityChanged)
                    }
                ]
            ]);
        };
    }
});
