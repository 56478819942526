// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .lobby-egs-group-item_FoZSA{position:relative;display:flex;align-items:center;justify-content:center;width:100%;padding-top:53px;overflow:hidden;-webkit-text-decoration:none;text-decoration:none;background-color:var(--Layer1);isolation:isolate;border-radius:5px}html.app__layout--desktop .lobby-egs-group-item__wrapper_UQ4uh{position:absolute;top:4px;right:4px;bottom:4px;left:4px;display:flex;align-items:center;justify-content:center}html.app__layout--desktop .lobby-egs-group-item--active_qxBwQ{border:2px solid var(--BrandDefault)}@media(min-width:768px){html.app__layout--desktop .lobby-egs-group-item--big_HZa1H{padding-top:66px}}html.app__layout--desktop .lobby-egs-group-item__text_UjQFm{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;max-width:120px;color:var(--TextDefault);text-align:center}html.app__layout--desktop .lobby-egs-group-item__logo_qaQax{width:100%;height:100%}html.app__layout--desktop .lobby-egs-group-item_FoZSA:hover{background-color:var(--Highlight)}html.app__layout--desktop .lobby-egs-group-item__nt-badge_yQaI4{position:absolute;top:5px;right:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lobby-egs-group-item": `lobby-egs-group-item_FoZSA`,
	"lobby-egs-group-item__wrapper": `lobby-egs-group-item__wrapper_UQ4uh`,
	"lobby-egs-group-item--active": `lobby-egs-group-item--active_qxBwQ`,
	"lobby-egs-group-item--big": `lobby-egs-group-item--big_HZa1H`,
	"lobby-egs-group-item__text": `lobby-egs-group-item__text_UjQFm`,
	"lobby-egs-group-item__logo": `lobby-egs-group-item__logo_qaQax`,
	"lobby-egs-group-item__nt-badge": `lobby-egs-group-item__nt-badge_yQaI4`
};
export default ___CSS_LOADER_EXPORT___;
