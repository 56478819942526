import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import SearchSubHeader from 'web/src/modules/search/components/SearchSubHeader/SearchSubHeader.vue';
import SearchListItem from 'web/src/modules/search/submodules/sportline/components/SearchListItem/SearchListItem.vue';
import { useSearchListSection } from 'web/src/modules/search/submodules/sportline/components/SearchListSection/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SearchListSection',
    props: {
        caption: {
            default: ''
        },
        searchText: {
            default: ''
        },
        list: {
            default: ()=>[]
        }
    },
    emits: [
        "item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { captionText, getItemProperties, emitItemClick } = useSearchListSection(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                class: _normalizeClass(_ctx.$style['search-list-section'])
            }, [
                _createVNode(SearchSubHeader, {
                    text: _unref(captionText)
                }, null, 8, [
                    "text"
                ]),
                _createElementVNode("ul", {
                    class: _normalizeClass(_ctx.$style['search-list-section__list'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (event)=>(_openBlock(), _createBlock(SearchListItem, _mergeProps({
                            ref_for: true
                        }, _unref(getItemProperties)(event), {
                            key: event.id,
                            class: _ctx.$style['search-list-section__list-item'],
                            onClick: ($event)=>_unref(emitItemClick)(event)
                        }), null, 16, [
                            "class",
                            "onClick"
                        ]))), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SearchListSection'
                ]
            ]);
        };
    }
});
