import { FormType, CustomerFieldType } from '@leon-hub/api-sdk';
import { mapRegistrationFormData } from 'web/src/modules/registration/utils';
import { useFormDefaultFormPhoneValue } from 'web/src/modules/phone/composables';
import { isPartialFormPhoneValue } from 'web/src/components/Form/guards';
import { useDeviceFingerprints } from 'web/src/modules/identity/composables';
let CoreRegistrationFormService = class CoreRegistrationFormService {
    // eslint-disable-next-line class-methods-use-this
    normalizeFormData(form) {
        if (!form?.uiFormSchema?.fields) return null;
        const { parsePhone } = useFormDefaultFormPhoneValue();
        return {
            ...form,
            uiFormSchema: {
                ...form.uiFormSchema,
                fields: form.uiFormSchema.fields.map((field)=>{
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                    if (field.id === CustomerFieldType.PHONE_INPUT && isPartialFormPhoneValue(field.defaultValue) && field.defaultValue?.prefix === '') return {
                        ...field,
                        defaultValue: parsePhone(field.defaultValue?.suffix ?? '')
                    };
                    if (field.type === FormType.BOOLEAN && field.defaultValue) return {
                        ...field,
                        defaultValue: 'true' === field.defaultValue
                    };
                    return field;
                })
            }
        };
    }
    // eslint-disable-next-line class-methods-use-this
    async prepareRegistrationInput(formData, options) {
        let fingerprintData = {};
        let appsflyerId = '';
        if (options.addFingerprint) {
            let authData;
            const { deviceFingerprint, deviceFingerprintJs } = await useDeviceFingerprints().getDeviceFingerprints();
            fingerprintData = {
                ...fingerprintData,
                fingerprint: deviceFingerprint,
                fpjsVisitorId: deviceFingerprintJs,
                cordovaDeviceUUID: authData ? authData.cordovaDeviceUUID : '',
                appGUID: authData ? authData.appGUID : ''
            };
        }
        return {
            ...fingerprintData,
            appsflyerId,
            registrationType: options.registrationType,
            schemaId: options.schemaId,
            formParams: [
                ...mapRegistrationFormData(formData),
                ...options.extraFormParams ?? []
            ]
        };
    }
};
export { CoreRegistrationFormService as default };
