// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-cash-out_N0kUT,.bet-cash-out__jumbotron_rO3Sn{position:relative;display:block;width:100%}.bet-cash-out--overlay_uOFeo{padding:16px}.bet-cash-out__row_CVcKw{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;display:flex;align-items:center;justify-content:center;color:var(--TextDefault)}.bet-cash-out__green_ELSJg{color:var(--BrandDefault)}.bet-cash-out__price-arrow_k5Bn9{margin:0 4px;color:var(--TextSecondary)}.bet-cash-out__cancel-button_S3kMG{margin:20px 0 0}.bet-cash-out__name_J8ONf{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;padding-bottom:8px;color:var(--TextPrimary)}.bet-cash-out__pending_lHBpK{position:absolute;top:0;right:0;bottom:0;left:0;background:var(--Layer1);border-radius:5px 5px 0 0;opacity:.7}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-cash-out": `bet-cash-out_N0kUT`,
	"bet-cash-out__jumbotron": `bet-cash-out__jumbotron_rO3Sn`,
	"bet-cash-out--overlay": `bet-cash-out--overlay_uOFeo`,
	"bet-cash-out__row": `bet-cash-out__row_CVcKw`,
	"bet-cash-out__green": `bet-cash-out__green_ELSJg`,
	"bet-cash-out__price-arrow": `bet-cash-out__price-arrow_k5Bn9`,
	"bet-cash-out__cancel-button": `bet-cash-out__cancel-button_S3kMG`,
	"bet-cash-out__name": `bet-cash-out__name_J8ONf`,
	"bet-cash-out__pending": `bet-cash-out__pending_lHBpK`
};
export default ___CSS_LOADER_EXPORT___;
