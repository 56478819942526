// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-app-list_XJXgW{display:flex;flex-wrap:wrap;gap:16px;max-width:100%}.landing-app-list--centered_GV8s1{justify-content:center}.landing-app-list__item_woT5i{display:flex;height:40px;transition:var(--animation-button-transition);transform:var(--animation-button-transform)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-app-list": `landing-app-list_XJXgW`,
	"landing-app-list--centered": `landing-app-list--centered_GV8s1`,
	"landing-app-list__item": `landing-app-list__item_woT5i`
};
export default ___CSS_LOADER_EXPORT___;
