import { defineStore } from 'pinia';
import { ref } from 'vue';
import { logger } from '@leon-hub/logging';
import getAvatarsAndBackgroundsRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getAvatarsAndBackgroundsRequest';
import getAchievementsRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getAchievementsRequest';
import getCategoriesRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getCategoriesRequest';
import { AchievementStatus } from 'web/src/modules/profile/submodules/achievements/enums';
import getAchievementsProfileRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getAchievementsProfileRequest';
import getAchievementsAvailabilityRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getAvailabilityRequest';
import getAchievementRequest from 'web/src/modules/profile/submodules/achievements/utils/api/getAchievementRequest';
import doAchievementRewardRequest from 'web/src/modules/profile/submodules/achievements/utils/api/doAchievementReward';
import doAchievementMarkAsViewedRequest from 'web/src/modules/profile/submodules/achievements/utils/api/doAchievementMarkAsViewed';
const useAchievementsStore = defineStore('achievements', ()=>{
    const avatarsAndBackgrounds = ref();
    const achievements = ref();
    const categories = ref();
    const profile = ref();
    const availability = ref();
    const isFromNotification = ref(false);
    async function fetchAvailability() {
        try {
            availability.value = await getAchievementsAvailabilityRequest();
        } catch (error) {
            logger.error('An error occurred while updating achievements availability', error);
        }
    }
    async function fetchAvatarsAndBackgrounds() {
        try {
            avatarsAndBackgrounds.value = await getAvatarsAndBackgroundsRequest();
        } catch (error) {
            logger.error('An error occurred while updating achievements avatars and background', error);
        }
    }
    async function fetchProfileData(publicId) {
        try {
            const data = await getAchievementsProfileRequest(publicId);
            if (!publicId) profile.value = data;
            return data;
        } catch (error) {
            logger.error('An error occurred while updating achievements profile data', error);
            return;
        }
    }
    async function fetchAchievements(publicId) {
        try {
            const result = await getAchievementsRequest(publicId);
            achievements.value = result.items;
        } catch (error) {
            logger.error('An error occurred while updating achievements', error);
        }
    }
    async function fetchCategories() {
        try {
            const result = await getCategoriesRequest();
            categories.value = result.categories;
        } catch (error) {
            logger.error('An error occurred while updating categories', error);
        }
    }
    async function doAchievementReward(id) {
        try {
            return await doAchievementRewardRequest(id);
        } catch (error) {
            logger.error('An error occurred while updating reward', error);
            return;
        }
    }
    async function doAchievementMarkAsViewed(id) {
        try {
            return await doAchievementMarkAsViewedRequest(id);
        } catch (error) {
            logger.error('An error occurred while updating achievement', error);
            return;
        }
    }
    async function getAchievementById(id) {
        try {
            return await getAchievementRequest(id);
        } catch (error) {
            logger.error('An error occurred while updating current achievement', error);
            return null;
        }
    }
    function getAchievementsByCategory(selectedCategories) {
        return achievements.value?.filter((item)=>selectedCategories.includes(item.category));
    }
    function getAchievementsByStatus(selectedStatuses) {
        return achievements.value?.filter((item)=>selectedStatuses.some((status)=>checkAchievementStatus(item, status))) ?? [];
    }
    function getFilteredAchievements(selectedCategories, statuses) {
        const filteredByCategory = achievements.value?.filter((item)=>selectedCategories.includes(item.category));
        return filteredByCategory?.filter((item)=>statuses.some((status)=>checkAchievementStatus(item, status)));
    }
    function getGroupedAchievements(selectedCategories, selectedStatuses) {
        if (0 === selectedCategories.length) return {
            all: achievements.value?.filter((item)=>0 === selectedStatuses.length || selectedStatuses.some((status)=>checkAchievementStatus(item, status))) ?? []
        };
        return selectedCategories.reduce((acc, category)=>{
            const filteredByCategory = achievements.value?.filter((item)=>item.category === category) ?? [];
            acc[category] = selectedStatuses.length > 0 ? filteredByCategory.filter((item)=>selectedStatuses.some((status)=>checkAchievementStatus(item, status))) : filteredByCategory;
            return acc;
        }, {});
    }
    function checkAchievementStatus(item, status) {
        switch(status){
            case AchievementStatus.IN_PROGRESS:
                return item.progress?.actual && !item.satisfied;
            case AchievementStatus.LOCKED:
                return !item.progress?.actual && !item.satisfied;
            case AchievementStatus.UNLOCKED:
                return item.satisfied;
            default:
                return false;
        }
    }
    function cleanData() {
        avatarsAndBackgrounds.value = void 0;
        achievements.value = null;
        categories.value = null;
        profile.value = void 0;
    }
    return {
        availability,
        profile,
        avatarsAndBackgrounds,
        achievements,
        isFromNotification,
        categories,
        fetchAvailability,
        fetchAvatarsAndBackgrounds,
        getAchievementById,
        doAchievementReward,
        doAchievementMarkAsViewed,
        fetchAchievements,
        fetchCategories,
        getAchievementsByCategory,
        getAchievementsByStatus,
        getFilteredAchievements,
        getGroupedAchievements,
        fetchProfileData,
        cleanData
    };
});
export default useAchievementsStore;
