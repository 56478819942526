import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, Fragment as _Fragment, renderList as _renderList, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 2
};
import BonusHowDoesItWork from 'web/src/modules/bonuses/components/BonusHowDoesItWork/BonusHowDoesItWork.vue';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import BonusLoyaltyProgramPanel from 'web/src/modules/bonuses/components/BonusLoyaltyProgramPanel/BonusLoyaltyProgramPanel.vue';
import BonusLoyaltyProgramCard from 'web/src/modules/bonuses/components/BonusLoyaltyProgramCard/BonusLoyaltyProgramCard.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { useBonusLoyaltyProgramRoutePage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusLoyaltyProgramRoutePage',
    setup (__props) {
        const { offers, bonusLoaded, isLeonShopEnabledWithOffers, amountNumber, remainingAmountNumber, progress, progressPercentString, bonusLinkLoyalty, bonusLinkEgsLoyalty, isParticipating, handleExchange, handleParticipate, onShopItemClick, isShopOffersLoaded, showExchangeTitle } = useBonusLoyaltyProgramRoutePage();
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['loyalty-program']]: true,
                    [_ctx.$style['loyalty-program--loading']]: !_unref(bonusLoaded)
                })
            }, [
                _unref(bonusLoaded) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(showExchangeTitle) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['loyalty-program--title'])
                    }, _toDisplayString(_ctx.$t('WEB2_BONUS_PROGRAM_TITLE')), 3)) : _createCommentVNode("", true),
                    _createVNode(BonusLoyaltyProgramPanel, {
                        "amount-number": _unref(amountNumber),
                        progress: _unref(progress),
                        "progress-text": _unref(progressPercentString),
                        "remaining-amount-number": _unref(remainingAmountNumber),
                        "is-participating": _unref(isParticipating),
                        onWithdraw: _unref(handleExchange),
                        onParticipate: _unref(handleParticipate)
                    }, null, 8, [
                        "amount-number",
                        "progress",
                        "progress-text",
                        "remaining-amount-number",
                        "is-participating",
                        "onWithdraw",
                        "onParticipate"
                    ]),
                    _unref(isShopOffersLoaded) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _unref(isLeonShopEnabledWithOffers) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['shop-title'])
                            }, _toDisplayString(_ctx.$t('WEB2_SHOP_TITLE')), 3),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['shop-items-container'])
                            }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(offers), (item)=>(_openBlock(), _createBlock(BonusLoyaltyProgramCard, {
                                        key: item.id,
                                        name: item.name,
                                        balance: item.price,
                                        "formatted-balance": item.formattedPrice,
                                        "image-src": item.imageUrl,
                                        "is-locked": item.price > _unref(amountNumber),
                                        image: item.image,
                                        onClick: ($event)=>_unref(onShopItemClick)(item.id)
                                    }, null, 8, [
                                        "name",
                                        "balance",
                                        "formatted-balance",
                                        "image-src",
                                        "is-locked",
                                        "image",
                                        "onClick"
                                    ]))), 128))
                            ], 2)
                        ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createVNode(BonusHowDoesItWork),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['loyalty-program__more-info'])
                            }, [
                                _createVNode(_unref(TextSlotPlaceholder), {
                                    text: _ctx.$t('WEB2_HOW_TO_GET_POINTS')
                                }, _createSlots({
                                    _: 2
                                }, [
                                    _unref(bonusLinkLoyalty) ? {
                                        name: "sportLink",
                                        fn: _withCtx(()=>[
                                                _createVNode(_component_router_link, {
                                                    to: _unref(bonusLinkLoyalty)
                                                }, {
                                                    default: _withCtx(()=>[
                                                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_HOW_TO_GET_POINTS_SPORT')), 1)
                                                        ]),
                                                    _: 1
                                                }, 8, [
                                                    "to"
                                                ])
                                            ]),
                                        key: "0"
                                    } : void 0,
                                    _unref(bonusLinkEgsLoyalty) ? {
                                        name: "egsLink",
                                        fn: _withCtx(()=>[
                                                _createVNode(_component_router_link, {
                                                    to: _unref(bonusLinkEgsLoyalty)
                                                }, {
                                                    default: _withCtx(()=>[
                                                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_HOW_TO_GET_POINTS_EGS')), 1)
                                                        ]),
                                                    _: 1
                                                }, 8, [
                                                    "to"
                                                ])
                                            ]),
                                        key: "1"
                                    } : void 0
                                ]), 1032, [
                                    "text"
                                ])
                            ], 2)
                        ], 64))
                    ])) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                        key: 1
                    }))
                ], 64)) : (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                    key: 0
                }))
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusLoyaltyProgramRoutePage'
                ]
            ]);
        };
    }
});
