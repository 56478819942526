// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-iframe_XgTHp{position:relative;display:flex}.v-iframe--full-height_Ua6QC{height:100%}.v-iframe--aspect-ratio-height_IT7sv{position:relative;flex-basis:100%;min-width:100%;height:0;padding-top:100%;overflow:hidden}.v-iframe--aspect-ratio-height_IT7sv>iframe{position:absolute;top:0;left:0;width:100%;height:100%}.v-iframe--full-width__VfIf{width:100%}.v-iframe--flex-layout_Xpd4A{display:flex;flex-direction:column}.v-iframe__iframe_Npn1R{visibility:visible;border:none}.v-iframe--full-height_Ua6QC .v-iframe__iframe_Npn1R{height:100%}.v-iframe--full-width__VfIf .v-iframe__iframe_Npn1R{width:100%}.v-iframe__iframe--hidden__CwpJ{position:absolute;top:-99999px;visibility:hidden}.v-iframe__iframe--dark_aanMH{background-color:var(--Layer1)}.v-iframe--flex-layout_Xpd4A .v-iframe__iframe_Npn1R{flex:1}.v-iframe__loader_QizcX{z-index:2}.v-iframe__loader-logo_yk8_L{width:100%;max-width:275px;margin:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"v-iframe": `v-iframe_XgTHp`,
	"v-iframe--full-height": `v-iframe--full-height_Ua6QC`,
	"v-iframe--aspect-ratio-height": `v-iframe--aspect-ratio-height_IT7sv`,
	"v-iframe--full-width": `v-iframe--full-width__VfIf`,
	"v-iframe--flex-layout": `v-iframe--flex-layout_Xpd4A`,
	"v-iframe__iframe": `v-iframe__iframe_Npn1R`,
	"v-iframe__iframe--hidden": `v-iframe__iframe--hidden__CwpJ`,
	"v-iframe__iframe--dark": `v-iframe__iframe--dark_aanMH`,
	"v-iframe__loader": `v-iframe__loader_QizcX`,
	"v-iframe__loader-logo": `v-iframe__loader-logo_yk8_L`
};
export default ___CSS_LOADER_EXPORT___;
