// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-heading_hIQF2{display:flex;flex-grow:0;align-items:center;height:56px;padding:0 0 0 16px;margin:0;-webkit-text-decoration:none;text-decoration:none}.home-heading__title_MtMSU{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;display:block;flex:1;color:var(--TextDefault)}.home-heading__button_gdvEI{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;height:40px;color:var(--TextPrimary);background-color:var(--Button);border-radius:5px}.home-heading__button--secondary_gfF8Z{margin-right:4px}.home-heading__button-icon_VTXvh{margin-right:6px;color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home-heading": `home-heading_hIQF2`,
	"home-heading__title": `home-heading__title_MtMSU`,
	"home-heading__button": `home-heading__button_gdvEI`,
	"home-heading__button--secondary": `home-heading__button--secondary_gfF8Z`,
	"home-heading__button-icon": `home-heading__button-icon_VTXvh`
};
export default ___CSS_LOADER_EXPORT___;
