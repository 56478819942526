import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, mergeProps as _mergeProps, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { LazyDefaultModal } from 'web/src/components/Modal/DefaultModal';
import VSearchInput from 'web/src/components/SearchInput/VSearchInput/VSearchInput.vue';
import VList from 'web/src/components/List/VList/VList.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VListItemCountries from 'web/src/components/CountriesSelector/VListItemCountries/VListItemCountries.vue';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { useVCountriesSelector } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VCountriesSelector',
    props: {
        selectedCountryCode: {},
        modalProperties: {
            default: ()=>({
                    isFullHeight: false,
                    width: ModalWidth.SMALL,
                    isOverlayVisible: true,
                    isPaddingTopBig: false
                })
        },
        countriesList: {
            default: ()=>[]
        },
        lazyRenderCount: {
            default: 30
        }
    },
    emits: [
        "clear-text",
        "on-country-select",
        "close-filter"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { popularCountries, unPopularCountries, visibleUnpopularCountries, selectedModalProperties, searchText, searchTextValue, foundCountries, showMoreButtonProperties, isShowUnPopularCountriesList, toggleUnPopularCountriesList, renderMoveUnpopularCountries, modal, setSearchText } = useVCountriesSelector(props);
        function emitOnCountrySelect(item) {
            emit('on-country-select', {
                ...item,
                prefix: item.prefix.replace('+', '')
            });
        }
        function emitClearText() {
            setSearchText('');
            emit('clear-text');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(LazyDefaultModal), _mergeProps(_unref(selectedModalProperties), {
                ref_key: "modal",
                ref: modal,
                class: _ctx.$style['countries-modal'],
                "data-test-id": "countries-selector",
                onClose: _cache[0] || (_cache[0] = ($event)=>emit('close-filter')),
                onScrollReachedBottom: _unref(renderMoveUnpopularCountries)
            }), {
                "inner-content": _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['countries-selector'])
                        }, [
                            _createVNode(VSearchInput, {
                                value: _unref(searchText),
                                placeholder: _ctx.$t('WEB2_PLACEHOLDER_SEARCH_INPUT'),
                                "is-autofocus": true,
                                "is-icon-right": "",
                                onInput: _unref(setSearchText),
                                onClear: emitClearText
                            }, null, 8, [
                                "value",
                                "placeholder",
                                "is-autofocus",
                                "onInput"
                            ]),
                            _unref(searchTextValue) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _unref(popularCountries).length ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    (_openBlock(), _createElementBlock("h4", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['countries-selector__title'])
                                    }, _toDisplayString(_ctx.$t('WEB2_POPULAR_COUNTRIES')), 3)),
                                    _createVNode(VList, {
                                        class: _normalizeClass(_ctx.$style['countries-selector__list'])
                                    }, {
                                        default: _withCtx(()=>[
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(popularCountries), (country)=>(_openBlock(), _createBlock(VListItemCountries, {
                                                        key: `${country.name}${country.code}`,
                                                        name: country.name,
                                                        code: country.code,
                                                        prefix: country.phonePrefix,
                                                        "search-text": _unref(searchTextValue),
                                                        "selected-item-code": _ctx.selectedCountryCode,
                                                        onClick: emitOnCountrySelect
                                                    }, null, 8, [
                                                        "name",
                                                        "code",
                                                        "prefix",
                                                        "search-text",
                                                        "selected-item-code"
                                                    ]))), 128))
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "class"
                                    ])
                                ], 64)) : _createCommentVNode("", true),
                                _unref(unPopularCountries).length ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 1
                                }, [
                                    _createVNode(VButton, _mergeProps(_unref(showMoreButtonProperties), {
                                        class: _ctx.$style['countries-selector__button'],
                                        onClick: _unref(toggleUnPopularCountriesList)
                                    }), null, 16, [
                                        "class",
                                        "onClick"
                                    ]),
                                    _unref(isShowUnPopularCountriesList) ? (_openBlock(), _createBlock(VList, {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['countries-selector__list'])
                                    }, {
                                        default: _withCtx(()=>[
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(visibleUnpopularCountries), (country)=>(_openBlock(), _createBlock(VListItemCountries, {
                                                        key: `${country.name}${country.code}`,
                                                        name: country.name,
                                                        code: country.code,
                                                        prefix: country.phonePrefix,
                                                        "search-text": _unref(searchTextValue),
                                                        "selected-item-code": _ctx.selectedCountryCode,
                                                        onClick: emitOnCountrySelect
                                                    }, null, 8, [
                                                        "name",
                                                        "code",
                                                        "prefix",
                                                        "search-text",
                                                        "selected-item-code"
                                                    ]))), 128))
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "class"
                                    ])) : _createCommentVNode("", true)
                                ], 64)) : _createCommentVNode("", true)
                            ], 64)),
                            _unref(searchTextValue) ? (_openBlock(), _createBlock(VList, {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['countries-selector__list'])
                            }, {
                                default: _withCtx(()=>[
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(foundCountries), (country)=>(_openBlock(), _createBlock(VListItemCountries, {
                                                key: `${country.name}${country.code}`,
                                                name: country.name,
                                                code: country.code,
                                                prefix: country.phonePrefix,
                                                "search-text": _unref(searchTextValue),
                                                "selected-item-code": _ctx.selectedCountryCode,
                                                onClick: emitOnCountrySelect
                                            }, null, 8, [
                                                "name",
                                                "code",
                                                "prefix",
                                                "search-text",
                                                "selected-item-code"
                                            ]))), 128))
                                    ]),
                                _: 1
                            }, 8, [
                                "class"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ]),
                _: 1
            }, 16, [
                "class",
                "onScrollReachedBottom"
            ])), [
                [
                    _directive_auto_id,
                    'VCountriesSelector'
                ]
            ]);
        };
    }
});
