// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-element-content_wjRnw{overflow:hidden;background-color:var(--Layer0);transition-timing-function:cubic-bezier(.4,0,1,1);transition-property:visibility,max-height}.expandable-element-content--hidden_Spvv8{max-height:1px;visibility:hidden}html.app__layout--desktop .sportline-events-list_bf1nX{display:flex;flex-direction:column;gap:2px}html.app__layout--desktop .league-headline_ywG1s{margin-top:12px}html.app__layout--desktop .league-headline_ywG1s:first-child{margin-top:0}html.app__layout--desktop .league-element-inner__holder_lc_XK{padding:0;margin:0 0 8px;overflow:hidden;background-color:var(--Layer0);border-radius:5px}html.app__layout--desktop .league-element-inner__holder_lc_XK:last-child{border-radius:0 0 var(--BorderRadius) var(--BorderRadius)}html.app__layout--desktop .league-element-inner__outrights-title_Xva7Q{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;height:44px;padding:0 12px;background-color:var(--Layer1)}html.app__layout--desktop .league-element-inner__outrights-title_Xva7Q:not(:first-child){margin-top:8px;border-radius:var(--BorderRadius) var(--BorderRadius) 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandable-element-content": `expandable-element-content_wjRnw`,
	"expandable-element-content--hidden": `expandable-element-content--hidden_Spvv8`,
	"sportline-events-list": `sportline-events-list_bf1nX`,
	"league-headline": `league-headline_ywG1s`,
	"league-element-inner__holder": `league-element-inner__holder_lc_XK`,
	"league-element-inner__outrights-title": `league-element-inner__outrights-title_Xva7Q`
};
export default ___CSS_LOADER_EXPORT___;
