// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pin-buttons_qNVNt{display:inline-flex;justify-content:center;width:100%}.pin-buttons__wrapper_FLfZF{display:inline-flex;flex-wrap:wrap;grid-gap:16px;width:100%;max-width:272px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin-buttons": `pin-buttons_qNVNt`,
	"pin-buttons__wrapper": `pin-buttons__wrapper_FLfZF`
};
export default ___CSS_LOADER_EXPORT___;
