// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .favorite-regions-title_V1o6R{display:flex;align-items:center;justify-content:space-between;width:100%;height:56px;padding:0 0 0 16px}html.app__layout--desktop .favorite-regions-title__label_ZNRWx{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal}html.app__layout--desktop .favorite-regions-list__slide_Do1Sd{padding-right:8px;padding-left:0}html.app__layout--desktop .favorite-regions-list__slide_Do1Sd:last-child{padding-right:0}html.app__layout--desktop .favorite-regions-list__item__MJqo{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;box-sizing:border-box;display:flex;align-items:center;height:44px;padding:0 16px;color:var(--TextPrimary);-webkit-text-decoration:none;text-decoration:none;cursor:pointer;background-color:var(--Layer1);border:2px solid #0000;border-radius:var(--BorderRadius);box-shadow:none}html.app__layout--desktop .favorite-regions-list__item__MJqo:hover{color:var(--TextDefault);border-color:var(--Layer2)}html.app__layout--desktop .favorite-regions-list__item--active_EXThv{color:var(--TextDefault);border-color:var(--Highlight)}html.app__layout--desktop .favorite-regions-list__flag_y_CZC{width:22px;height:16px;margin-right:8px;border-radius:2px}html.app__layout--desktop .favorite-regions-list__icon_ZPdmH{margin-right:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"favorite-regions-title": `favorite-regions-title_V1o6R`,
	"favorite-regions-title__label": `favorite-regions-title__label_ZNRWx`,
	"favorite-regions-list__slide": `favorite-regions-list__slide_Do1Sd`,
	"favorite-regions-list__item": `favorite-regions-list__item__MJqo`,
	"favorite-regions-list__item--active": `favorite-regions-list__item--active_EXThv`,
	"favorite-regions-list__flag": `favorite-regions-list__flag_y_CZC`,
	"favorite-regions-list__icon": `favorite-regions-list__icon_ZPdmH`
};
export default ___CSS_LOADER_EXPORT___;
