// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-preview-desktop_WHS86{position:fixed;z-index:100;display:flex;align-items:center;justify-content:center}.image-preview-desktop_WHS86,.image-preview-desktop__overlay_qtfTB{top:0;right:0;bottom:0;left:0}.image-preview-desktop__overlay_qtfTB{position:absolute;background-color:#171a1cb3;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}.image-preview-desktop__image_Jj1ez{width:auto;max-width:100%;height:calc(100vh - 150px);max-height:100%;object-fit:contain;image-orientation:initial}.image-preview-desktop__image-container_RQPGu{position:relative;max-width:calc(100% - 88px);max-height:80%;background:var(--Layer0)}.image-preview-desktop__close_DM2yB{position:absolute;top:0;right:-44px;color:var(--BrandText)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-preview-desktop": `image-preview-desktop_WHS86`,
	"image-preview-desktop__overlay": `image-preview-desktop__overlay_qtfTB`,
	"image-preview-desktop__image": `image-preview-desktop__image_Jj1ez`,
	"image-preview-desktop__image-container": `image-preview-desktop__image-container_RQPGu`,
	"image-preview-desktop__close": `image-preview-desktop__close_DM2yB`
};
export default ___CSS_LOADER_EXPORT___;
