// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rg-bet-limit-info_qsdgA{display:flex;flex-direction:column;width:100%;padding:0 16px 42px}.rg-bet-limit-info__switcher_Cgs8O{margin-bottom:16px}.rg-bet-limit-info__hint_Z7f8Q{margin-bottom:12px}.rg-bet-limit-info__content_K6ZCo{column-gap:16px;height:192px;padding:20px 16px;margin-bottom:20px;background-color:var(--Layer1);border-radius:6px;display:flex;align-items:center;justify-content:space-between}.rg-bet-limit-info__info_K4AtF{flex-direction:column;width:142px;display:inline-flex}.rg-bet-limit-info__buttons_C0icG{flex-direction:column;row-gap:8px;display:flex}.rg-bet-limit-info__buttons-wrapper_rd2fR{width:100%}.rg-bet-limit-info__title_FnwRo{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;margin-bottom:8px;color:var(--TextSecondary)}.rg-bet-limit-info__count_Z1iXv{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;margin-bottom:16px;color:var(--TextDefault)}.rg-bet-limit-info__set_jXahg{row-gap:8px;display:inline-flex;flex-direction:column;align-items:center}.rg-bet-limit-info__set-label_OPOgJ{font-size:12px;font-weight:400;line-height:12px;letter-spacing:normal;color:var(--TextSecondary)}.rg-bet-limit-info__set-value_HLJIv{color:var(--TextDefault)}.rg-bet-limit-info__not-set_L4SZI,.rg-bet-limit-info__set-value_HLJIv{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal}.rg-bet-limit-info__not-set_L4SZI{color:var(--TextSecondary)}html.app__layout--desktop .rg-bet-limit-info_qsdgA{padding:0 16px 42px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rg-bet-limit-info": `rg-bet-limit-info_qsdgA`,
	"rg-bet-limit-info__switcher": `rg-bet-limit-info__switcher_Cgs8O`,
	"rg-bet-limit-info__hint": `rg-bet-limit-info__hint_Z7f8Q`,
	"rg-bet-limit-info__content": `rg-bet-limit-info__content_K6ZCo`,
	"rg-bet-limit-info__info": `rg-bet-limit-info__info_K4AtF`,
	"rg-bet-limit-info__buttons": `rg-bet-limit-info__buttons_C0icG`,
	"rg-bet-limit-info__buttons-wrapper": `rg-bet-limit-info__buttons-wrapper_rd2fR`,
	"rg-bet-limit-info__title": `rg-bet-limit-info__title_FnwRo`,
	"rg-bet-limit-info__count": `rg-bet-limit-info__count_Z1iXv`,
	"rg-bet-limit-info__set": `rg-bet-limit-info__set_jXahg`,
	"rg-bet-limit-info__set-label": `rg-bet-limit-info__set-label_OPOgJ`,
	"rg-bet-limit-info__set-value": `rg-bet-limit-info__set-value_HLJIv`,
	"rg-bet-limit-info__not-set": `rg-bet-limit-info__not-set_L4SZI`
};
export default ___CSS_LOADER_EXPORT___;
