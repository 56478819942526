import { watch } from 'vue';
import { Deferred } from '@leon-hub/utils';
/**
 * Use a ref to be defined or any other condition.
 */ export function useRef(ref) {
    let condition = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : (value)=>!!value;
    if (condition(ref.value)) return Promise.resolve();
    const deferred = new Deferred();
    const stop = watch(ref, (value)=>{
        if (condition(value)) {
            stop();
            deferred.resolve();
        }
    });
    return deferred.promise;
}
