import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
const _hoisted_2 = {
    key: 1
};
import { BonusState } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VLinearProgress from 'web/src/components/LinearProgress/VLinearProgress/VLinearProgress.vue';
import useBonusLoyaltyProgramPanel from './composables/useBonusLoyaltyProgramPanel';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BonusLoyaltyProgramPanel',
    props: {
        title: {
            default: ''
        },
        amountNumber: {
            default: 0
        },
        remainingAmountNumber: {
            default: 0
        },
        progress: {
            default: 0
        },
        progressText: {
            default: ''
        },
        state: {
            default: BonusState.EMPTY
        },
        isParticipating: {
            type: Boolean
        }
    },
    emits: [
        "participate",
        "withdraw"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { progressProperties, buttonProperties, participateButtonProperties, pointsLabelProperties, requiredAmount } = useBonusLoyaltyProgramPanel(props);
        const coinIcon = IconName.COIN_L;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['loyalty-program-panel'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['loyalty-program-panel__header'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['loyalty-program-panel__header-content'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['loyalty-program-panel__headline'])
                        }, _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_POINTS')), 3),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['loyalty-program-panel__points'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['loyalty-program-panel__points-label'])
                            }, _toDisplayString(_unref(pointsLabelProperties)), 3),
                            _createVNode(_unref(VIcon), {
                                class: _normalizeClass(_ctx.$style['loyalty-program-panel__points-icon']),
                                name: _unref(coinIcon)
                            }, null, 8, [
                                "class",
                                "name"
                            ])
                        ], 2)
                    ], 2),
                    _createElementVNode("img", {
                        class: _normalizeClass(_ctx.$style['loyalty-program-panel__header-icon']),
                        src: require('web/src/assets/images/gift-leon.svg'),
                        alt: ""
                    }, null, 10, _hoisted_1)
                ], 2),
                _ctx.progress || _ctx.remainingAmountNumber ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _ctx.progress < 1 ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['loyalty-program-panel__progress'])
                    }, [
                        _createVNode(VLinearProgress, _mergeProps(_unref(progressProperties), {
                            class: _ctx.$style['loyalty-program-panel__progress-line']
                        }), null, 16, [
                            "class"
                        ]),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['loyalty-program-panel__progress-label'])
                        }, _toDisplayString(`${_ctx.progressText}%`), 3),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['loyalty-program-panel__progress-total'])
                        }, _toDisplayString(_unref(requiredAmount)) + " " + _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_BEFORE_EXCHANGE')), 3)
                    ], 2)) : (_openBlock(), _createBlock(VButton, _mergeProps({
                        key: 1
                    }, _unref(buttonProperties), {
                        class: _ctx.$style['loyalty-program-panel__button'],
                        onClick: _cache[1] || (_cache[1] = ($event)=>emit('withdraw'))
                    }), null, 16, [
                        "class"
                    ]))
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BonusLoyaltyProgramPanel'
                ]
            ]);
        };
    }
});
