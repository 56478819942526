// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-list__kzAq{display:flex;flex-flow:column nowrap;gap:2px;justify-content:flex-start;padding:0;overflow:hidden;border-radius:5px}.history-list--separate_aLwZ4>li{margin-bottom:8px;overflow:hidden;border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-list": `history-list__kzAq`,
	"history-list--separate": `history-list--separate_aLwZ4`
};
export default ___CSS_LOADER_EXPORT___;
