import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { VLoader as VLoaderInner } from '@components/loader';
import { VLoaderDelayed } from 'web/src/components/Loader';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VPreloader',
    props: {
        hint: {
            default: ''
        }
    },
    setup (__props) {
        const VLoader = VLoaderDelayed;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['preloader'])
            }, [
                _createVNode(_unref(VLoader), {
                    hint: _ctx.hint
                }, null, 8, [
                    "hint"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VPreloader'
                ]
            ]);
        };
    }
});
