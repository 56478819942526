// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.captcha-badge_nKMZO{padding:12px 0;margin-top:20px;color:var(--TextSecondary);text-align:center}.captcha-badge_nKMZO,.captcha-badge__link_EA6az{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal}.captcha-badge__link_EA6az{padding:0 3px;color:var(--TextDefault);-webkit-text-decoration:underline;text-decoration:underline;border-bottom:none}.captcha-badge__link_EA6az:hover{color:var(--BrandHighlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"captcha-badge": `captcha-badge_nKMZO`,
	"captcha-badge__link": `captcha-badge__link_EA6az`
};
export default ___CSS_LOADER_EXPORT___;
