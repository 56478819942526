// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-switcher_LRk1L{content:""}.language-switcher-list-item__content_ofiGV{display:inline-flex;flex-grow:1;align-items:center;justify-content:space-between}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language-switcher": `language-switcher_LRk1L`,
	"language-switcher-list-item__content": `language-switcher-list-item__content_ofiGV`
};
export default ___CSS_LOADER_EXPORT___;
