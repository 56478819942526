// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar_L1JxK{width:44px;height:44px;background:var(--Layer1);border-radius:10px}.avatar--size-36_XnrlS{width:36px;height:36px}.avatar--size-68_M17z6{width:68px;height:68px}.avatar__button_OCpZ8{font:inherit;color:inherit;cursor:pointer;background-color:#0000;border:none;display:flex;align-items:center;justify-content:center;width:100%;height:100%;padding:0;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `avatar_L1JxK`,
	"avatar--size-36": `avatar--size-36_XnrlS`,
	"avatar--size-68": `avatar--size-68_M17z6`,
	"avatar__button": `avatar__button_OCpZ8`
};
export default ___CSS_LOADER_EXPORT___;
