import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
    key: 1
};
import { onBeforeUnmount, onMounted } from 'vue';
import { useVSmsNumberCheck } from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/composables';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import VForm from 'web/src/components/Form/components/VForm/VForm.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import VTimer from 'web/src/components/Timer/VTimer/VTimer.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSmsNumberCheck',
    props: {
        phone: {},
        uiSchema: {},
        schema: {},
        isPending: {
            type: Boolean
        },
        customErrors: {},
        showButtonTime: {},
        hintTimeout: {},
        isNewTel: {
            type: Boolean
        },
        isPin: {
            type: Boolean
        },
        changePhoneLabel: {},
        isTimer: {
            type: Boolean
        },
        timeLeft: {}
    },
    emits: [
        "show-hint-with-email-redirect",
        "set-retry-sms-code-time",
        "retry-button-click",
        "change-phone",
        "submit",
        "input",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { form, formKey, timer, showButton, timerText, buttonProperties, pageDescription, onSubmit, emitInput, onClick, onComponentCreated, onComponentMounted, onComponentBeforeUnmount, onBlur } = useVSmsNumberCheck(props, emits);
        onComponentCreated();
        onMounted(onComponentMounted);
        onBeforeUnmount(onComponentBeforeUnmount);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['sms__container'])
                }, [
                    _withDirectives((_openBlock(), _createElementBlock("p", {
                        class: _normalizeClass(_ctx.$style['sms__container--text'])
                    }, [
                        _createElementVNode("span", null, _toDisplayString(_unref(pageDescription)) + " ", 1),
                        _ctx.isPin ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['sms__container--important-text'])
                        }, _toDisplayString(_ctx.phone) + ".", 3)),
                        _renderSlot(_ctx.$slots, "phone"),
                        _ctx.changePhoneLabel ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                            _cache[1] || (_cache[1] = _createTextVNode(" (")),
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['sms__container--change']),
                                onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('change-phone'))
                            }, _toDisplayString(_ctx.changePhoneLabel), 3),
                            _cache[2] || (_cache[2] = _createTextVNode(") "))
                        ])) : _createCommentVNode("", true)
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'sms-number-check-container-text'
                            }
                        ]
                    ])
                ], 2),
                (_openBlock(), _createBlock(VForm, {
                    ref_key: "form",
                    ref: form,
                    key: _unref(formKey),
                    schema: _ctx.schema,
                    "ui-schema": _ctx.uiSchema,
                    "custom-errors": _ctx.customErrors,
                    "is-pending": _ctx.isPending,
                    onSubmit: _unref(onSubmit),
                    onInput: _unref(emitInput),
                    onBlur: _unref(onBlur)
                }, null, 8, [
                    "schema",
                    "ui-schema",
                    "custom-errors",
                    "is-pending",
                    "onSubmit",
                    "onInput",
                    "onBlur"
                ])),
                _unref(showButton) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['sms__sent-code'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['sms__sent-again'])
                    }, _toDisplayString(_unref(timerText)), 3),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['sms__countdown'])
                    }, _toDisplayString(_unref(timer)), 3)
                ], 2)),
                !_ctx.showButtonTime && _ctx.timeLeft ? (_openBlock(), _createBlock(VTimer, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['sms__sent-code']),
                    text: _ctx.$t('WEB2_RESEND_SMS_TIME'),
                    seconds: _ctx.timeLeft
                }, null, 8, [
                    "class",
                    "text",
                    "seconds"
                ])) : _unref(showButton) ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['sms__resend'])
                }, [
                    _withDirectives((_openBlock(), _createBlock(VButton, _mergeProps({
                        key: 1
                    }, _unref(buttonProperties), {
                        onClick: _unref(onClick)
                    }), null, 16, [
                        "onClick"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'sms-number-check-resend-button'
                            }
                        ]
                    ])
                ], 2)) : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "content")
            ])), [
                [
                    _directive_auto_id,
                    'VSmsNumberCheck'
                ]
            ]);
        };
    }
});
