// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highest-odds-badge_lTVPn{font-size:9px;font-weight:700;line-height:12px;text-transform:uppercase;letter-spacing:.2px;position:relative;display:flex;align-items:flex-start;justify-content:center;overflow:hidden;font-family:Roboto Condensed,sans-serif;text-align:center;border-radius:5px}.highest-odds-badge--transparent_HagVE{color:var(--TextDefault);background:#0000}.highest-odds-badge--default_RyDAf{color:var(--Layer1);background:var(--TextDefault)}.highest-odds-badge--dark_uXUO6{color:var(--DLayer2);background-color:var(--DTextDefault)}.highest-odds-badge--paddings-for-12_JF_RS{padding:0 4px 0 1px}.highest-odds-badge--paddings-for-16_JV2RM{padding:2px 4px 2px 2px}.highest-odds-badge--height-12_A_eIt{height:12px}.highest-odds-badge--height-16_S0cJ_{height:16px}.highest-odds-badge__icon_sAS2G{margin-bottom:1px}.highest-odds-badge__content_PqwhJ{display:block;max-width:100%;white-space:nowrap}.highest-odds-badge__content--multiline_NnthR{white-space:normal}.highest-odds-badge__label_m9j7w{display:inline-block;white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highest-odds-badge": `highest-odds-badge_lTVPn`,
	"highest-odds-badge--transparent": `highest-odds-badge--transparent_HagVE`,
	"highest-odds-badge--default": `highest-odds-badge--default_RyDAf`,
	"highest-odds-badge--dark": `highest-odds-badge--dark_uXUO6`,
	"highest-odds-badge--paddings-for-12": `highest-odds-badge--paddings-for-12_JF_RS`,
	"highest-odds-badge--paddings-for-16": `highest-odds-badge--paddings-for-16_JV2RM`,
	"highest-odds-badge--height-12": `highest-odds-badge--height-12_A_eIt`,
	"highest-odds-badge--height-16": `highest-odds-badge--height-16_S0cJ_`,
	"highest-odds-badge__icon": `highest-odds-badge__icon_sAS2G`,
	"highest-odds-badge__content": `highest-odds-badge__content_PqwhJ`,
	"highest-odds-badge__content--multiline": `highest-odds-badge__content--multiline_NnthR`,
	"highest-odds-badge__label": `highest-odds-badge__label_m9j7w`
};
export default ___CSS_LOADER_EXPORT___;
