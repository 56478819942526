// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-element-content__6Hft{overflow:hidden;background-color:var(--Layer0);transition-timing-function:cubic-bezier(.4,0,1,1);transition-property:visibility,max-height}.expandable-element-content--hidden_otIyJ{max-height:1px;visibility:hidden}.sport-events-list-sport__headline_xbQ6X{background-color:var(--Layer0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandable-element-content": `expandable-element-content__6Hft`,
	"expandable-element-content--hidden": `expandable-element-content--hidden_otIyJ`,
	"sport-events-list-sport__headline": `sport-events-list-sport__headline_xbQ6X`
};
export default ___CSS_LOADER_EXPORT___;
