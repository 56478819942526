// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-list-item__container_mmJzW{display:grid;grid-row-gap:8px;width:100%}.history-list-item__row_hM00n{display:grid;grid-template-columns:repeat(auto-fit,minmax(0,max-content));column-gap:4px;align-items:self-start;justify-content:space-between}.history-list-item__column--left_IYMMU,.history-list-item__column--right_Rpnb2{display:grid;grid-template-rows:repeat(auto-fit,minmax(0,max-content));row-gap:4px}.history-list-item__column--left_IYMMU{text-align:left}.history-list-item__column--right_Rpnb2{text-align:right}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-list-item__container": `history-list-item__container_mmJzW`,
	"history-list-item__row": `history-list-item__row_hM00n`,
	"history-list-item__column--left": `history-list-item__column--left_IYMMU`,
	"history-list-item__column--right": `history-list-item__column--right_Rpnb2`
};
export default ___CSS_LOADER_EXPORT___;
