import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "casino-loyalty-program-swiper__header"
};
import CasinoLoyaltyProgramCard from 'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramCard/CasinoLoyaltyProgramCard.vue';
import VSwiperSlide from 'web/src/components/Swiper/VSwiperSlide/VSwiperSlide.vue';
import CasinoLoyaltyProgramSwiperNavigation from // eslint-disable-next-line max-len
'web/src/modules/casino-loyalty-program/components/CasinoLoyaltyProgramSwiper/CasinoLoyaltyProgramSwiperNavigation.vue';
import VSwiper from 'web/src/components/Swiper/VSwiper/VSwiper';
import { useCasinoLoyaltyProgramSwiper } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramSwiper',
    props: {
        cards: {},
        hasNavigation: {
            type: Boolean
        },
        currentPoints: {},
        cardSize: {},
        noPadding: {
            type: Boolean
        },
        noHeaderTitlePadding: {
            type: Boolean
        }
    },
    emits: [
        "click-card"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { swiper, emitClickCard } = useCasinoLoyaltyProgramSwiper(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    'casino-loyalty-program-swiper': true,
                    'casino-loyalty-program-swiper__with-horizontal-padding': !_ctx.noPadding,
                    'casino-loyalty-program-swiper__without-horizontal-padding': _ctx.noPadding
                })
            }, [
                _createVNode(_unref(VSwiper), {
                    ref_key: "swiper",
                    ref: swiper,
                    class: "casino-loyalty-program-swiper__list"
                }, {
                    "pagination-header": _withCtx(()=>[
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("div", {
                                    class: _normalizeClass({
                                        'casino-loyalty-program-swiper__header-title': true,
                                        'casino-loyalty-program-swiper__header-title__with-padding': !_ctx.noHeaderTitlePadding,
                                        'casino-loyalty-program-swiper__header-title__without-padding': _ctx.noHeaderTitlePadding
                                    })
                                }, _toDisplayString(_ctx.$t('WEB2_LOYALTY_PROGRAM_LEVELS_TITLE')), 3),
                                _ctx.hasNavigation ? (_openBlock(), _createBlock(CasinoLoyaltyProgramSwiperNavigation, {
                                    key: 0
                                })) : _createCommentVNode("", true)
                            ])
                        ]),
                    default: _withCtx(()=>[
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (item, index)=>(_openBlock(), _createBlock(VSwiperSlide, {
                                    key: index,
                                    class: "casino-loyalty-program-swiper__item"
                                }, {
                                    default: _withCtx(()=>[
                                            _createVNode(CasinoLoyaltyProgramCard, {
                                                title: item.name,
                                                "image-src": item.imageUrl,
                                                "level-status": item.status,
                                                "current-value": _ctx.currentPoints,
                                                total: item.requiredPoints,
                                                progress: item.progress,
                                                "card-size": _ctx.cardSize,
                                                image: item.image,
                                                onClick: ($event)=>_unref(emitClickCard)(item)
                                            }, null, 8, [
                                                "title",
                                                "image-src",
                                                "level-status",
                                                "current-value",
                                                "total",
                                                "progress",
                                                "card-size",
                                                "image",
                                                "onClick"
                                            ])
                                        ]),
                                    _: 2
                                }, 1024))), 128))
                        ]),
                    _: 1
                }, 512)
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramSwiper'
                ]
            ]);
        };
    }
});
