// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-banner_rPeft{display:flex;flex-direction:column;gap:24px;padding:0 16px 70px}.landing-banner__content_tNxgO{display:flex;flex-direction:column;gap:40px}.landing-banner__header_vOGN9{display:flex;flex-direction:column;gap:24px}.landing-banner__footer_fmuOG{display:flex;flex-direction:column;gap:16px}.landing-banner__image_eeKqu{width:100%;transition:var(--animation-banner-image-transition);transform:var(--animation-banner-image-transform)}@media(min-width:1024px){.landing-banner_rPeft{flex-direction:row-reverse;gap:30px;align-items:center;width:100%;padding:0}.landing-banner__content_tNxgO,.landing-banner__image_eeKqu{width:50%}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-banner": `landing-banner_rPeft`,
	"landing-banner__content": `landing-banner__content_tNxgO`,
	"landing-banner__header": `landing-banner__header_vOGN9`,
	"landing-banner__footer": `landing-banner__footer_fmuOG`,
	"landing-banner__image": `landing-banner__image_eeKqu`
};
export default ___CSS_LOADER_EXPORT___;
