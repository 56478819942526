// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.egs-game-item-bottom-badge_Piw6S{z-index:2;font-size:10px;font-weight:400;line-height:12px;letter-spacing:normal;position:absolute;right:0;bottom:0;left:0;display:flex;align-items:center;justify-content:center;padding:4px;color:var(--BrandText);background-color:#00000080}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"egs-game-item-bottom-badge": `egs-game-item-bottom-badge_Piw6S`
};
export default ___CSS_LOADER_EXPORT___;
