// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .main-market-container_e0piW{position:relative;display:flex;flex-direction:column;align-items:flex-start;padding:10px 8px 10px 0}html.app__layout--desktop .main-market-container--with-top-line_pONze{padding-top:4px}html.app__layout--desktop .main-market-container--with-bottom-line_xXgaK{padding-bottom:4px}html.app__layout--desktop .main-market-container__main-content_z34FV{display:flex;gap:8px;height:56px;min-height:40px}html.app__layout--desktop .main-market-container__main-content--short_ZID5_{height:48px}html.app__layout--desktop .market-name-line_FTUYg{font-size:12px;font-weight:400;line-height:12px;letter-spacing:normal;padding:2px;margin-bottom:4px;overflow:hidden;color:var(--TextPrimary);text-align:center;text-overflow:ellipsis;white-space:nowrap;background:var(--Layer2);border-radius:5px}html.app__layout--desktop .highest-odds-line_eB3Kr{display:flex;align-items:center;justify-content:center;margin-top:2px}html.app__layout--desktop .fit-to-columns_Tg9gz{width:100%}html.app__layout--desktop .fit-to-columns--2_Z5Z9K{width:168px}html.app__layout--desktop .fit-to-columns--3_mINzb{width:256px}html.app__layout--desktop .fit-to-columns--extra-2_R32oy{width:168px}html.app__layout--desktop .fit-to-columns--extra-3_CvEzG{width:256px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-market-container": `main-market-container_e0piW`,
	"main-market-container--with-top-line": `main-market-container--with-top-line_pONze`,
	"main-market-container--with-bottom-line": `main-market-container--with-bottom-line_xXgaK`,
	"main-market-container__main-content": `main-market-container__main-content_z34FV`,
	"main-market-container__main-content--short": `main-market-container__main-content--short_ZID5_`,
	"market-name-line": `market-name-line_FTUYg`,
	"highest-odds-line": `highest-odds-line_eB3Kr`,
	"fit-to-columns": `fit-to-columns_Tg9gz`,
	"fit-to-columns--2": `fit-to-columns--2_Z5Z9K`,
	"fit-to-columns--3": `fit-to-columns--3_mINzb`,
	"fit-to-columns--extra-2": `fit-to-columns--extra-2_R32oy`,
	"fit-to-columns--extra-3": `fit-to-columns--extra-3_CvEzG`
};
export default ___CSS_LOADER_EXPORT___;
