// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.balance__multiple-wrapper_Xs615{display:grid;grid-template-columns:repeat(2,minmax(0,1fr));column-gap:8px}.balance__swiper-item_oBofU{width:159.5px;margin-right:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance__multiple-wrapper": `balance__multiple-wrapper_Xs615`,
	"balance__swiper-item": `balance__swiper-item_oBofU`
};
export default ___CSS_LOADER_EXPORT___;
