// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-list-option_Xou3Z{font-weight:400;line-height:16px;height:44px;padding:0 12px;font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;width:100%;text-align:left;cursor:pointer;background:none;border:none}.drop-list-option--active_u4f7n,.drop-list-option--preselected_yFNcf{background-color:var(--Highlight);border-radius:3px}.drop-list-option__checkbox_VMZm2{margin-right:8px}.drop-list-option__icon_BK8Tw{width:16px;height:16px;margin-right:8px;display:flex;flex:0 0 auto;align-items:center;justify-content:center;overflow:hidden;color:var(--TextDefault)}.drop-list-option__img_wOGEN{width:100%;height:100%;object-fit:contain}.drop-list-option--filter-enabled_eekny.drop-list-option--active_u4f7n .drop-list-option__img_wOGEN,.drop-list-option--filter-enabled_eekny.drop-list-option--preselected_yFNcf .drop-list-option__img_wOGEN{filter:invert(14%) sepia(95%) saturate(6678%) hue-rotate(265deg) brightness(85%) contrast(130%)}.drop-list-option__label_xoGR6{flex:1 1 auto;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.drop-list-option__right_kZFiM{flex:0 0 auto;margin-left:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drop-list-option": `drop-list-option_Xou3Z`,
	"drop-list-option--active": `drop-list-option--active_u4f7n`,
	"drop-list-option--preselected": `drop-list-option--preselected_yFNcf`,
	"drop-list-option__checkbox": `drop-list-option__checkbox_VMZm2`,
	"drop-list-option__icon": `drop-list-option__icon_BK8Tw`,
	"drop-list-option__img": `drop-list-option__img_wOGEN`,
	"drop-list-option--filter-enabled": `drop-list-option--filter-enabled_eekny`,
	"drop-list-option__label": `drop-list-option__label_xoGR6`,
	"drop-list-option__right": `drop-list-option__right_kZFiM`
};
export default ___CSS_LOADER_EXPORT___;
