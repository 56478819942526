/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ import { computed, ref } from 'vue';
import { MarketsGridFilterCustomId } from 'web/src/modules/sportline/submodules/markets-grid/enums';
export function useMarketsListTabsControl(props) {
    const { hasStatistic, gridFilters, allowedToSelectLayoutTypes } = props;
    const isStatisticSelected = ref(false);
    const internalMarketGroupTabId = ref(MarketsGridFilterCustomId.All);
    const activeGridFilter = computed(()=>gridFilters.value.find((group)=>group.id === internalMarketGroupTabId.value) ?? null);
    const doShowInlineStatisticSwitch = computed(()=>allowedToSelectLayoutTypes.value.length > 1);
    const isAllTabSelected = computed(()=>internalMarketGroupTabId.value === MarketsGridFilterCustomId.All);
    // displayed market group
    const displayedMarketGroupTabId = computed(()=>{
        if (doShowInlineStatisticSwitch.value) // show real active tab for exist separate switch
        return internalMarketGroupTabId.value;
        // fake selected tab without real switch
        return isStatisticSelected.value && hasStatistic.value ? MarketsGridFilterCustomId.Statistic : internalMarketGroupTabId.value;
    });
    const isSelectedTabExistInMarketGroups = computed(()=>{
        if (internalMarketGroupTabId.value === MarketsGridFilterCustomId.All) return true;
        if (internalMarketGroupTabId.value === MarketsGridFilterCustomId.Statistic) // uniq case for empty markets groups
        // used for do not change isStatisticSelected after user turned off it
        return true;
        return gridFilters.value.some((group)=>group.id === internalMarketGroupTabId.value);
    });
    function recalculateActiveTabId() {
        if (internalMarketGroupTabId.value === MarketsGridFilterCustomId.All) internalMarketGroupTabId.value = gridFilters.value.length > 0 && !process.env.VUE_APP_PRERENDER ? gridFilters.value[0].id : MarketsGridFilterCustomId.All;
    }
    function resetSelectedTabId() {
        internalMarketGroupTabId.value = MarketsGridFilterCustomId.All;
    }
    function setSelectedTabId(value) {
        if (value === MarketsGridFilterCustomId.Statistic) isStatisticSelected.value = true;
        else {
            internalMarketGroupTabId.value = value;
            if (!doShowInlineStatisticSwitch.value) isStatisticSelected.value = false;
        }
    }
    return {
        isAllTabSelected,
        activeGridFilter,
        isStatisticSelected,
        isSelectedTabExistInMarketGroups,
        doShowInlineStatisticSwitch,
        displayedMarketGroupTabId,
        recalculateActiveTabId,
        resetSelectedTabId,
        setSelectedTabId
    };
}
