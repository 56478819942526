// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-carousel-banner__item--link-drag-prevent_H_gBz{display:block;-webkit-user-select:none;user-select:none;-webkit-user-drag:none;-webkit-touch-callout:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fade-carousel-banner__item--link-drag-prevent": `fade-carousel-banner__item--link-drag-prevent_H_gBz`
};
export default ___CSS_LOADER_EXPORT___;
