// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loyalty-program_YrJg_{padding:0 8px 32px}.loyalty-program--loading_UiTBD{display:flex;align-items:center;height:calc(100vh - 61px)}.loyalty-program__more-info__x9Zz{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;width:100%;height:44px;margin:20px auto 0;color:var(--TextPrimary);text-align:center}@media(hover:hover)and (pointer:fine){.loyalty-program__more-info__x9Zz a:hover{color:var(--BrandHighlight)}}.loyalty-program_YrJg_ .shop-items-container_aA7QX{display:grid;grid-template-columns:repeat(2,calc(50% - 4px));grid-gap:8px}.loyalty-program_YrJg_ .loyalty-program--title_oG_Q6,.loyalty-program_YrJg_ .shop-title_ioHxl{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;margin:0 8px 18px}html.app__layout--desktop .loyalty-program_YrJg_{padding:18px 24px 32px}html.app__layout--desktop .loyalty-program--loading_UiTBD{height:150px;padding-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loyalty-program": `loyalty-program_YrJg_`,
	"loyalty-program--loading": `loyalty-program--loading_UiTBD`,
	"loyalty-program__more-info": `loyalty-program__more-info__x9Zz`,
	"shop-items-container": `shop-items-container_aA7QX`,
	"loyalty-program--title": `loyalty-program--title_oG_Q6`,
	"shop-title": `shop-title_ioHxl`
};
export default ___CSS_LOADER_EXPORT___;
