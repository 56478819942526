// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay_YbHvC{position:absolute;top:0;right:0;bottom:0;left:0}.overlay--type-blur-l0_HOX1f{background-color:var(--OverlayL0Color)}.overlay--type-blur-l1_FIYGL{-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px)}html.app__layout--desktop .overlay--type-blur-l1_FIYGL{background-color:#0000004d}.overlay--type-opacity-l0_qjnzN{background-color:var(--Layer0);opacity:.7}.overlay--type-opacity-l1_HHZhO{background-color:var(--Layer1);opacity:.7}.overlay--fixed_tFZUG{position:fixed}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `overlay_YbHvC`,
	"overlay--type-blur-l0": `overlay--type-blur-l0_HOX1f`,
	"overlay--type-blur-l1": `overlay--type-blur-l1_FIYGL`,
	"overlay--type-opacity-l0": `overlay--type-opacity-l0_qjnzN`,
	"overlay--type-opacity-l1": `overlay--type-opacity-l1_HHZhO`,
	"overlay--fixed": `overlay--fixed_tFZUG`
};
export default ___CSS_LOADER_EXPORT___;
