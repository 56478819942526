import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useVTimer } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VTimer',
    props: {
        text: {
            default: ''
        },
        seconds: {
            default: 0
        }
    },
    setup (__props) {
        const props = __props;
        const { timeLeft } = useVTimer(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['timer'])
            }, [
                _ctx.text ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['timer__label'])
                }, _toDisplayString(_ctx.text), 3)) : _createCommentVNode("", true),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['timer__time-left'])
                }, _toDisplayString(_unref(timeLeft)), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VTimer'
                ]
            ]);
        };
    }
});
