// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .payments-cancel-withdrawal_TL_BE{margin-bottom:20px;z-index:1}html.app__layout--desktop .payments-cancel-withdrawal__money_FZ1Id{font-size:14px;font-weight:500;line-height:24px;letter-spacing:.25px}html.app__layout--desktop .payments-cancel-withdrawal__button_iFWaY{color:var(--BrandDefault);background-color:var(--OpacityBrandDefault)}html.app__layout--desktop html.app__layout--desktop .payments-cancel-withdrawal_TL_BE:hover{cursor:pointer}html.app__layout--desktop html.app__layout--desktop .payments-cancel-withdrawal_TL_BE:hover html.app__layout--desktop .payments-cancel-withdrawal__button_iFWaY{color:var(--BrandText);background-color:var(--DBrandHighlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-cancel-withdrawal": `payments-cancel-withdrawal_TL_BE`,
	"payments-cancel-withdrawal__money": `payments-cancel-withdrawal__money_FZ1Id`,
	"payments-cancel-withdrawal__button": `payments-cancel-withdrawal__button_iFWaY`
};
export default ___CSS_LOADER_EXPORT___;
