// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade-enter-active_mGj9w,.fade-leave-active_hkRoo{transition:opacity .3s ease-in-out}.fade-enter-from_PLbT_,.fade-leave-to_schZm{opacity:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fade-enter-active": `fade-enter-active_mGj9w`,
	"fade-leave-active": `fade-leave-active_hkRoo`,
	"fade-enter-from": `fade-enter-from_PLbT_`,
	"fade-leave-to": `fade-leave-to_schZm`
};
export default ___CSS_LOADER_EXPORT___;
