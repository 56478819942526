// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .leagues-list-item_zmihC{padding-right:6px;margin-bottom:2px;background-color:var(--0dd22e58)}html.app__layout--desktop .leagues-list-item__inner_BCubL{grid-template-columns:44px 1fr auto;width:100%;padding-right:8px}html.app__layout--desktop .leagues-list-item__default_VC9kN{padding-left:0}html.app__layout--desktop .leagues-list-item__prefix_gF1zP{margin-right:4px;margin-left:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leagues-list-item": `leagues-list-item_zmihC`,
	"leagues-list-item__inner": `leagues-list-item__inner_BCubL`,
	"leagues-list-item__default": `leagues-list-item__default_VC9kN`,
	"leagues-list-item__prefix": `leagues-list-item__prefix_gF1zP`
};
export default ___CSS_LOADER_EXPORT___;
