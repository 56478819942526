import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, renderList as _renderList, mergeProps as _mergeProps, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "onVnodeMounted"
];
const _hoisted_2 = [
    "onVnodeMounted"
];
const _hoisted_3 = [
    "onVnodeMounted"
];
const _hoisted_4 = {
    key: 3
};
import { VIcon } from '@components/v-icon';
import { SSpinner } from '@components/spinner';
import { VScrollbar } from '@components/v-scrollbar';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import { TextInput } from 'web/src/components/Input';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
import SBadge from 'web/src/components/Badge/SBadge/SBadge.vue';
import LBadge from 'web/src/components/Badge/LBadge/LBadge.vue';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import InputHint from 'web/src/components/Input/components/InputHint';
import { useHintParentProps } from 'web/src/components/Input/composables';
import VLoadMoreObserver from 'web/src/components/LoadMoreObserver/VLoadMoreObserver/VLoadMoreObserver.vue';
import DropListSelectDropdown from './DropListSelectDropdown.vue';
import DropListSelectModal from './DropListSelectModal.vue';
import { arrowIconName, arrowIconSize, buttonIconSize, searchIconName } from '../constants/staticProps';
import DropListOption from './DropListOption.vue';
import { useDropListSelect } from '../composables/useDropListSelect';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DropListSelect',
    props: {
        options: {
            default: ()=>[]
        },
        searchLabel: {},
        isMultiselectMode: {
            type: Boolean
        },
        showSelectedIcon: {
            type: Boolean
        },
        disableHoverFilter: {
            type: Boolean
        },
        selected: {},
        label: {},
        placeholder: {},
        placeholderImg: {},
        placeholderIcon: {},
        searchEnabled: {
            type: Boolean
        },
        isLoadingOptions: {
            type: Boolean
        },
        isSearchByApiQuery: {
            type: Boolean
        },
        primaryButtonLabel: {},
        secondaryButtonLabel: {},
        isBehindModals: {
            type: Boolean
        },
        isHintHidden: {
            type: Boolean
        },
        dropDirection: {},
        modalDropdown: {
            type: Boolean
        },
        itemsToRender: {},
        error: {},
        errorRight: {},
        hint: {},
        hintRight: {},
        hintLink: {},
        hintLinkRight: {}
    },
    emits: [
        "primary-button-click",
        "secondary-button-click",
        "item-click",
        "search-input",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { isSelected, // search
        searchQuery, searchInputRef, onSearchInput, clearSearchQuery, // options
        isSimpleList, optionGroups, plainOptions, setOptionRefs, isEmptySearch, // ui computed properties
        buttonIconName, buttonImgSrc, isButtonImageShown, selectedText, selectedSecondaryText, selectedSecondaryBadge, isButtonsBlockShown, // preselected
        preselectedId, primaryButtonRef, secondaryButtonRef, clearPreselected, // dropdown
        isDropdownShown, toggleOpen, onClose, focusOnDropdownButton, dropdownButton, // handlers
        scrollbarRef, onItemClick, onOptionHover, onPreselect, onInputKeydown, onDropdownOptionKeydown, onPrimaryButtonClick, onSecondaryButtonClick, onActionButtonKeydown, onPrimaryButtonFocus, onPrimaryButtonBlur, onSecondaryButtonFocus, onSecondaryButtonBlur, onFocus, onBlur, // container props
        dropContainerProps, // pagination
        increasePaginationCount, renderComplete, isRenderingMore, // recalculate scroll
        onDropdownUpdate } = useDropListSelect(props, emit);
        const { hintProperties, hasError } = useHintParentProps(props);
        // slots
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['drop-list-container'])
            }, [
                _createElementVNode("button", {
                    ref_key: "dropdownButton",
                    ref: dropdownButton,
                    class: _normalizeClass({
                        [_ctx.$style['drop-list-button']]: true,
                        [_ctx.$style['drop-list-button--active']]: _unref(isDropdownShown),
                        [_ctx.$style['drop-list-button--error']]: _unref(hasError)
                    }),
                    type: "button",
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(toggleOpen) && _unref(toggleOpen)(...args);
                    }),
                    onKeydown: _cache[1] || (_cache[1] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onDropdownOptionKeydown) && _unref(onDropdownOptionKeydown)(...args);
                    }),
                    onFocus: _cache[2] || (_cache[2] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onFocus) && _unref(onFocus)(...args);
                    }),
                    onBlur: _cache[3] || (_cache[3] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onBlur) && _unref(onBlur)(...args);
                    })
                }, [
                    _unref(isButtonImageShown) ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['drop-list-button__img-container'])
                    }, [
                        _unref(buttonImgSrc) ? (_openBlock(), _createBlock(VImage, {
                            key: 0,
                            src: _unref(buttonImgSrc),
                            alt: _unref(selectedText),
                            class: _normalizeClass(_ctx.$style['drop-list-button__img'])
                        }, null, 8, [
                            "src",
                            "alt",
                            "class"
                        ])) : _unref(buttonIconName) ? (_openBlock(), _createBlock(_unref(VIcon), {
                            key: 1,
                            name: _unref(buttonIconName),
                            size: _unref(buttonIconSize)
                        }, null, 8, [
                            "name",
                            "size"
                        ])) : _createCommentVNode("", true)
                    ], 2)) : _createCommentVNode("", true),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['drop-list-button__text-container'])
                    }, [
                        _ctx.label ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['drop-list-button__label'])
                        }, _toDisplayString(_ctx.label), 3)) : _createCommentVNode("", true),
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['drop-list-button__value'])
                        }, [
                            _unref(selectedSecondaryBadge) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                (_openBlock(), _createBlock(LBadge, {
                                    key: 1,
                                    label: _unref(selectedSecondaryBadge),
                                    kind: _unref(BadgeKind).SQUARE_ERROR
                                }, null, 8, [
                                    "label",
                                    "kind"
                                ]))
                            ], 64)) : _createCommentVNode("", true),
                            _createTextVNode(" " + _toDisplayString(_unref(selectedText)) + " ", 1),
                            _unref(selectedSecondaryText) ? (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['drop-list-button__value-secondary'])
                            }, _toDisplayString(_unref(selectedSecondaryText)), 3)) : _createCommentVNode("", true)
                        ], 2)
                    ], 2),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['drop-list-button__icon-right'])
                    }, [
                        _renderSlot(_ctx.$slots, "icon-right", {}, ()=>[
                                _createVNode(_unref(VIcon), {
                                    name: _unref(arrowIconName),
                                    size: _unref(arrowIconSize)
                                }, null, 8, [
                                    "name",
                                    "size"
                                ])
                            ])
                    ], 2),
                    _unref(isDropdownShown) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalDropdown ? DropListSelectModal : DropListSelectDropdown), _mergeProps({
                        key: 1
                    }, _unref(dropContainerProps), {
                        onPreselect: _unref(onPreselect),
                        onReturnFocus: _unref(focusOnDropdownButton),
                        onClose: _unref(onClose),
                        onUpdatePosition: _unref(onDropdownUpdate)
                    }), {
                        search: _withCtx(()=>[
                                _createVNode(_unref(TextInput), {
                                    ref_key: "searchInputRef",
                                    ref: searchInputRef,
                                    "prefix-icon-name": _ctx.modalDropdown ? void 0 : _unref(searchIconName),
                                    "suffix-icon-name": _ctx.modalDropdown ? _unref(searchIconName) : void 0,
                                    "is-hint-hidden": "",
                                    "is-label-hidden": "",
                                    placeholder: _ctx.searchLabel ?? _ctx.$t('WEB2_SEARCH_TITLE'),
                                    autocomplete: "off",
                                    value: _unref(searchQuery),
                                    onInput: _unref(onSearchInput),
                                    onClear: _unref(clearSearchQuery),
                                    onBlur: _unref(focusOnDropdownButton),
                                    onFocus: _unref(clearPreselected),
                                    onKeydown: _unref(onInputKeydown)
                                }, null, 8, [
                                    "prefix-icon-name",
                                    "suffix-icon-name",
                                    "placeholder",
                                    "value",
                                    "onInput",
                                    "onClear",
                                    "onBlur",
                                    "onFocus",
                                    "onKeydown"
                                ])
                            ]),
                        content: _withCtx((param)=>{
                            let { mounted, onContentRedraw } = param;
                            return [
                                _createVNode(_unref(VScrollbar), {
                                    ref_key: "scrollbarRef",
                                    ref: scrollbarRef,
                                    class: _normalizeClass(_ctx.$style['drop-list-content__scrollbar'])
                                }, {
                                    default: _withCtx(()=>[
                                            _createElementVNode("ul", {
                                                class: _normalizeClass({
                                                    [_ctx.$style['drop-list-content__list']]: true,
                                                    [_ctx.$style['drop-list-content__list--modal']]: _ctx.modalDropdown,
                                                    [_ctx.$style['drop-list-content__list--with-search']]: _ctx.searchEnabled,
                                                    [_ctx.$style['drop-list-content__list--with-footer']]: _unref(isButtonsBlockShown)
                                                }),
                                                onVnodeMounted: mounted
                                            }, [
                                                _ctx.isLoadingOptions ? (_openBlock(), _createElementBlock("li", {
                                                    key: 0,
                                                    class: _normalizeClass(_ctx.$style['drop-list-content__loader'])
                                                }, [
                                                    _createVNode(_unref(SSpinner))
                                                ], 2)) : _unref(isSimpleList) ? (_openBlock(true), _createElementBlock(_Fragment, {
                                                    key: 1
                                                }, _renderList(_unref(plainOptions), (item, index)=>(_openBlock(), _createElementBlock("li", {
                                                        key: item.value,
                                                        class: _normalizeClass(_ctx.$style['drop-list-content__item'])
                                                    }, [
                                                        _createVNode(DropListOption, _mergeProps({
                                                            ref_for: true,
                                                            ref: (el)=>_unref(setOptionRefs)(el, 0, index)
                                                        }, item, {
                                                            "with-background": _ctx.modalDropdown,
                                                            "search-query": _unref(searchQuery),
                                                            "is-active": _unref(isSelected)(item.value),
                                                            "is-preselected": _unref(preselectedId) === item.value,
                                                            "is-multiselect-mode": _ctx.isMultiselectMode,
                                                            "disable-hover-filter": _ctx.disableHoverFilter,
                                                            onClick: ($event)=>_unref(onItemClick)(item.value),
                                                            onMouseenter: ($event)=>_unref(onOptionHover)(item.value)
                                                        }), null, 16, [
                                                            "with-background",
                                                            "search-query",
                                                            "is-active",
                                                            "is-preselected",
                                                            "is-multiselect-mode",
                                                            "disable-hover-filter",
                                                            "onClick",
                                                            "onMouseenter"
                                                        ]),
                                                        _ctx.itemsToRender ? (_openBlock(), _createElementBlock(_Fragment, {
                                                            key: 0
                                                        }, [
                                                            index === _unref(plainOptions).length - 1 ? (_openBlock(), _createElementBlock("div", {
                                                                key: 0,
                                                                onVnodeMounted: onContentRedraw
                                                            }, null, 8, _hoisted_2)) : _createCommentVNode("", true)
                                                        ], 64)) : _createCommentVNode("", true)
                                                    ], 2))), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
                                                    key: 2
                                                }, _renderList(_unref(optionGroups), (group, groupIndex)=>(_openBlock(), _createElementBlock("li", {
                                                        key: groupIndex
                                                    }, [
                                                        group.caption ? (_openBlock(), _createElementBlock("p", {
                                                            key: 0,
                                                            class: _normalizeClass({
                                                                [_ctx.$style['drop-list-content__caption']]: true,
                                                                [_ctx.$style['drop-list-content__caption--modal']]: _ctx.modalDropdown
                                                            })
                                                        }, _toDisplayString(group.caption), 3)) : _createCommentVNode("", true),
                                                        _createElementVNode("ul", {
                                                            class: _normalizeClass(_ctx.$style['drop-list-content__inner-list'])
                                                        }, [
                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.options, (item, index)=>(_openBlock(), _createElementBlock("li", {
                                                                    key: item.value,
                                                                    class: _normalizeClass(_ctx.$style['drop-list-content__item'])
                                                                }, [
                                                                    _createVNode(DropListOption, _mergeProps({
                                                                        ref_for: true,
                                                                        ref: (el)=>_unref(setOptionRefs)(el, groupIndex, index)
                                                                    }, item, {
                                                                        "with-background": _ctx.modalDropdown,
                                                                        "is-active": _unref(isSelected)(item.value),
                                                                        "is-preselected": _unref(preselectedId) === item.value,
                                                                        "is-multiselect-mode": _ctx.isMultiselectMode,
                                                                        "disable-hover-filter": _ctx.disableHoverFilter,
                                                                        "search-query": _unref(searchQuery),
                                                                        onClick: ($event)=>_unref(onItemClick)(item.value),
                                                                        onMouseenter: ($event)=>_unref(onOptionHover)(item.value)
                                                                    }), null, 16, [
                                                                        "with-background",
                                                                        "is-active",
                                                                        "is-preselected",
                                                                        "is-multiselect-mode",
                                                                        "disable-hover-filter",
                                                                        "search-query",
                                                                        "onClick",
                                                                        "onMouseenter"
                                                                    ]),
                                                                    _ctx.itemsToRender ? (_openBlock(), _createElementBlock(_Fragment, {
                                                                        key: 0
                                                                    }, [
                                                                        groupIndex === _unref(optionGroups).length - 1 && index === group.options.length - 1 ? (_openBlock(), _createElementBlock("div", {
                                                                            key: 0,
                                                                            onVnodeMounted: onContentRedraw
                                                                        }, null, 8, _hoisted_3)) : _createCommentVNode("", true)
                                                                    ], 64)) : _createCommentVNode("", true)
                                                                ], 2))), 128))
                                                        ], 2)
                                                    ]))), 128)),
                                                _unref(isEmptySearch) ? (_openBlock(), _createElementBlock("li", _hoisted_4, [
                                                    _createVNode(DropListOption, {
                                                        label: _ctx.$t('WEB2_NOTHING_FOUND'),
                                                        value: "",
                                                        "with-background": _ctx.modalDropdown,
                                                        onClick: _unref(clearSearchQuery)
                                                    }, null, 8, [
                                                        "label",
                                                        "with-background",
                                                        "onClick"
                                                    ])
                                                ])) : _createCommentVNode("", true)
                                            ], 10, _hoisted_1),
                                            !_ctx.itemsToRender || _unref(renderComplete) || _unref(isRenderingMore) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(VLoadMoreObserver, {
                                                key: 0,
                                                onLoadMore: ($event)=>_unref(increasePaginationCount)(onContentRedraw)
                                            }, null, 8, [
                                                "onLoadMore"
                                            ]))
                                        ]),
                                    _: 2
                                }, 1032, [
                                    "class"
                                ])
                            ];
                        }),
                        footer: _withCtx(()=>[
                                _ctx.primaryButtonLabel ? (_openBlock(), _createBlock(SButton, {
                                    key: 0,
                                    ref_key: "primaryButtonRef",
                                    ref: primaryButtonRef,
                                    class: _normalizeClass(_ctx.$style['drop-list-content__button']),
                                    kind: "primary",
                                    height: "medium",
                                    label: _ctx.primaryButtonLabel,
                                    onFocus: _unref(onPrimaryButtonFocus),
                                    onBlur: _unref(onPrimaryButtonBlur),
                                    onClick: _unref(onPrimaryButtonClick),
                                    onKeydown: _unref(onActionButtonKeydown)
                                }, null, 8, [
                                    "class",
                                    "label",
                                    "onFocus",
                                    "onBlur",
                                    "onClick",
                                    "onKeydown"
                                ])) : _createCommentVNode("", true),
                                _ctx.secondaryButtonLabel ? (_openBlock(), _createBlock(SButton, {
                                    key: 1,
                                    ref_key: "secondaryButtonRef",
                                    ref: secondaryButtonRef,
                                    class: _normalizeClass(_ctx.$style['drop-list-content__button']),
                                    kind: "quinary-primary",
                                    height: "medium",
                                    label: _ctx.secondaryButtonLabel,
                                    onFocus: _unref(onSecondaryButtonFocus),
                                    onBlur: _unref(onSecondaryButtonBlur),
                                    onClick: _unref(onSecondaryButtonClick),
                                    onKeydown: _unref(onActionButtonKeydown)
                                }, null, 8, [
                                    "class",
                                    "label",
                                    "onFocus",
                                    "onBlur",
                                    "onClick",
                                    "onKeydown"
                                ])) : _createCommentVNode("", true)
                            ]),
                        _: 1
                    }, 16, [
                        "onPreselect",
                        "onReturnFocus",
                        "onClose",
                        "onUpdatePosition"
                    ])) : _createCommentVNode("", true)
                ], 34),
                _ctx.isHintHidden ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(InputHint), _normalizeProps(_mergeProps({
                    key: 0
                }, _unref(hintProperties))), null, 16))
            ], 2)), [
                [
                    _directive_auto_id,
                    'DropListSelect'
                ]
            ]);
        };
    }
});
