// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.egs-game-info-overlay_Z1E3q{font-size:10px;font-weight:400;line-height:12px;letter-spacing:normal;display:flex;justify-content:flex-end}.egs-game-info-overlay--top_Pwz5o{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;position:absolute;top:calc(8px + var(--StatusbarHeight));right:8px;left:44px;display:flex;flex-direction:column}.egs-game-info-overlay__item_tDUPO{z-index:2;display:flex;gap:4px;align-items:center;justify-content:center;padding:4px 4px 4px 8px;color:var(--BrandText);background-color:#00000080;border-radius:17px}.egs-game-info-overlay--top_Pwz5o .egs-game-info-overlay__item_tDUPO{position:static;padding:8px;white-space:nowrap;border-radius:22px}.egs-game-info-overlay__seats_AYeHu:not(.egs-game-info-overlay__seats--busy_ZcKIm):not(.egs-game-info-overlay__seats--behind_VzsZa){letter-spacing:1.5px}.egs-game-info-overlay__seats-indicator_aphzX{width:4px;height:4px;background-color:var(--Green);border-radius:50%}.egs-game-info-overlay__seats--busy_ZcKIm .egs-game-info-overlay__seats-indicator_aphzX{background-color:var(--ErrorText)}.egs-game-info-overlay__seats--behind_VzsZa .egs-game-info-overlay__seats-indicator_aphzX{background-color:var(--AlertText)}.egs-game-info-overlay__seats-icon_DOnDT{fill:var(--BrandText)}.egs-game-info-overlay__seats--behind_VzsZa .egs-game-info-overlay__seats-icon_DOnDT,.egs-game-info-overlay__seats--busy_ZcKIm .egs-game-info-overlay__seats-icon_DOnDT{display:none}.egs-game-info-overlay__badges_wVr5i{z-index:5;display:flex;flex-direction:column;gap:4px;align-items:flex-end;pointer-events:none}.egs-game-info-overlay--top_Pwz5o .egs-game-info-overlay__badges_wVr5i{position:absolute;top:5px;right:5px;gap:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"egs-game-info-overlay": `egs-game-info-overlay_Z1E3q`,
	"egs-game-info-overlay--top": `egs-game-info-overlay--top_Pwz5o`,
	"egs-game-info-overlay__item": `egs-game-info-overlay__item_tDUPO`,
	"egs-game-info-overlay__seats": `egs-game-info-overlay__seats_AYeHu`,
	"egs-game-info-overlay__seats--busy": `egs-game-info-overlay__seats--busy_ZcKIm`,
	"egs-game-info-overlay__seats--behind": `egs-game-info-overlay__seats--behind_VzsZa`,
	"egs-game-info-overlay__seats-indicator": `egs-game-info-overlay__seats-indicator_aphzX`,
	"egs-game-info-overlay__seats-icon": `egs-game-info-overlay__seats-icon_DOnDT`,
	"egs-game-info-overlay__badges": `egs-game-info-overlay__badges_wVr5i`
};
export default ___CSS_LOADER_EXPORT___;
