// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-loyalty-program-level_B7tP5{display:flex;column-gap:4px;align-items:center}.casino-loyalty-program-level--success_WSs71{color:var(--BrandDefault);fill:var(--BrandDefault)}.casino-loyalty-program-level--process_iXSL0{color:var(--TextPrimary);fill:var(--TextPrimary)}.casino-loyalty-program-level--lock_eNZju{color:var(--TextSecondary);fill:var(--TextSecondary)}.casino-loyalty-program-level__label_ROQmv{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;max-width:88px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-loyalty-program-level": `casino-loyalty-program-level_B7tP5`,
	"casino-loyalty-program-level--success": `casino-loyalty-program-level--success_WSs71`,
	"casino-loyalty-program-level--process": `casino-loyalty-program-level--process_iXSL0`,
	"casino-loyalty-program-level--lock": `casino-loyalty-program-level--lock_eNZju`,
	"casino-loyalty-program-level__label": `casino-loyalty-program-level__label_ROQmv`
};
export default ___CSS_LOADER_EXPORT___;
