// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.push-notifications-bets-onboarding_OfQGs{display:flex;flex-direction:column;align-items:center;justify-content:center;padding-bottom:24px;text-align:center}.push-notifications-bets-onboarding__img_dY2Zl{width:100%}.push-notifications-bets-onboarding__content_Vh0d0{padding:32px 16px 0}.push-notifications-bets-onboarding__title_aAFHw{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;margin-bottom:8px;color:var(--TextDefault)}.push-notifications-bets-onboarding__desc_wKJtk{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary)}.push-notifications-bets-onboarding__form-block_qoIK3{width:100%;padding:4px 16px;margin:24px 0;text-align:left;background-color:var(--Layer1);border-radius:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"push-notifications-bets-onboarding": `push-notifications-bets-onboarding_OfQGs`,
	"push-notifications-bets-onboarding__img": `push-notifications-bets-onboarding__img_dY2Zl`,
	"push-notifications-bets-onboarding__content": `push-notifications-bets-onboarding__content_Vh0d0`,
	"push-notifications-bets-onboarding__title": `push-notifications-bets-onboarding__title_aAFHw`,
	"push-notifications-bets-onboarding__desc": `push-notifications-bets-onboarding__desc_wKJtk`,
	"push-notifications-bets-onboarding__form-block": `push-notifications-bets-onboarding__form-block_qoIK3`
};
export default ___CSS_LOADER_EXPORT___;
