import { computed, nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Tag } from '@leon-hub/tags';
import { useWindowResize } from '@leon-hub/browser-composables';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { ObjectFitOption, ObjectPosition } from 'web/src/components/Image/VImage/enums';
import { BadgeKind } from 'web/src/components/Badge/VBadge/enums';
import { getBannerTextStyles, getBannerWrapperStyles, getLinkProps, getWrapperProps, getBannerImageStyles } from './utils';
import { BannerType } from '../enums';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useVHeadlineBanner(props, emits) {
    const router = useRouter();
    const backgroundImage = ref(null);
    const mainImageRef = ref(null);
    const backgroundHeight = ref(0);
    const isVisible = ref(false);
    const wrapper = ref();
    const badgeKind = BadgeKind.WHITE;
    const imageFit = computed(()=>props.isBigHeight ? ObjectFitOption.COVER : ObjectFitOption.CONTAIN);
    // eslint-disable-next-line max-len
    const imagePosition = computed(()=>props.bannerType === BannerType.INLINE_BANNER ? ObjectPosition.LEFT : void 0);
    const isHeadlineAvailable = computed(()=>!!props.headline);
    const isSecondaryTextAvailable = computed(()=>!!props.secondaryText);
    const isButtonAvailable = computed(()=>!!props.buttonText);
    const isFrontImageAvailable = computed(()=>!!props.backgroundImage || !!props.backgroundColor);
    const isBackgroundImageAvailable = computed(()=>!!props.backgroundImage || !props.backgroundColor);
    const backgroundImageSource = computed(()=>props.backgroundImage ?? props.mainImage);
    const hasContentBlock = computed(()=>!!props.headline || !!props.secondaryText);
    const href = computed(()=>props.isInternalLink ? router.resolve(props.url).href : props.url);
    const wrapperComponent = computed(()=>isButtonAvailable.value || props.isDivContainer ? Tag.DIV : Tag.A);
    const headlineComponent = computed(()=>props.bannerType === BannerType.ASIDE_BANNER ? Tag.DIV : Tag.P);
    const bgImageWidth = computed(()=>props.animation ? 'calc(100% + 40px)' : '');
    const linkProperties = computed(()=>getLinkProps(href.value, props));
    // eslint-disable-next-line max-len
    const wrapperProperties = computed(()=>getWrapperProps(isButtonAvailable.value, props, linkProperties.value));
    const textStyles = computed(()=>getBannerTextStyles(props));
    // eslint-disable-next-line max-len
    const wrapperStyles = computed(()=>getBannerWrapperStyles(props, backgroundHeight.value, isFrontImageAvailable.value, hasContentBlock.value));
    const imageStyle = computed(()=>getBannerImageStyles(props, bgImageWidth.value));
    function visibilityChanged(value) {
        isVisible.value = value;
    }
    function onComponentActivated() {
        if (!props.fixHeightImg) initBackgroundHeight();
    }
    async function onComponentMounted() {
        onComponentActivated();
        await nextTick();
        await nextTick();
        if (!props.forceHideImage) forceShowImage();
    }
    useWindowResize(onComponentActivated);
    function forceShowImage() {
        backgroundImage.value?.forceShowImage();
        mainImageRef.value?.forceShowImage();
    }
    function initBackgroundHeight() {
        if (wrapper.value) backgroundHeight.value = wrapper.value.offsetWidth / props.aspectRatio;
    }
    function onWrapperClick() {
        if (!isButtonAvailable.value) onClick();
    }
    function onClick() {
        if (props.isDivContainer) {
            emitClick(props.url);
            return;
        }
        if (!props.isRedirectDisabled) {
            emitClick(props.url);
            if (props.isInternalLink) router.push({
                path: props.url
            });
            else window.open(props.url);
        }
    }
    useIntersectionObserver(wrapper, (isIntersecting)=>{
        if (isIntersecting) onComponentActivated();
    }, void 0, true);
    function emitClick(url) {
        emits('click', url);
    }
    return {
        isVisible,
        backgroundImage,
        mainImageRef,
        wrapper,
        wrapperComponent,
        wrapperProperties,
        wrapperStyles,
        isBackgroundImageAvailable,
        backgroundImageSource,
        bgImageWidth,
        hasContentBlock,
        headlineComponent,
        isHeadlineAvailable,
        textStyles,
        isSecondaryTextAvailable,
        imagePosition,
        linkProperties,
        isFrontImageAvailable,
        isButtonAvailable,
        badgeKind,
        imageFit,
        imageStyle,
        onWrapperClick,
        visibilityChanged,
        onClick,
        onComponentMounted,
        onComponentActivated
    };
}
