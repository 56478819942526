import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { Tag } from '@leon-hub/tags';
import LandingChevron from 'web/src/modules/landings/system/components/LandingChevron/LandingChevron.vue';
import LandingButton from 'web/src/modules/landings/system/components/LandingButton/LandingButton.vue';
import { OrientationImage } from 'web/src/modules/landings/system/types';
import LandingTitle from 'web/src/modules/landings/system/components/LandingTitle/LandingTitle.vue';
import LandingLabel from 'web/src/modules/landings/system/components/LandingLabel/LandingLabel.vue';
import LandingAppList from 'web/src/modules/landings/system/components/LandingAppList/LandingAppList.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingHeroFullScreen',
    props: {
        imageSrc: {},
        bgSrcMobile: {},
        bgSrcDesk: {},
        imageKind: {
            default: OrientationImage.VERTICAL
        },
        chevron: {},
        chevronDecor: {},
        title: {},
        label: {},
        buttonLabel: {},
        buttonIcon: {},
        buttonLink: {},
        appList: {},
        isAnimated: {
            type: Boolean
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['hero-full-screen']),
                style: _normalizeStyle({
                    backgroundImage: _ctx.bgSrcDesk ? `url(${_ctx.bgSrcDesk})` : void 0
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['hero-full-screen__body'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['hero-full-screen__header'])
                    }, [
                        _ctx.chevron ? (_openBlock(), _createBlock(LandingChevron, {
                            key: 0,
                            decoration: _ctx.chevronDecor,
                            label: _ctx.chevron
                        }, null, 8, [
                            "decoration",
                            "label"
                        ])) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['hero-full-screen__paragraph'])
                        }, [
                            _ctx.title ? (_openBlock(), _createBlock(LandingTitle, {
                                key: 0,
                                content: _ctx.title
                            }, null, 8, [
                                "content"
                            ])) : _createCommentVNode("", true),
                            _ctx.label ? (_openBlock(), _createBlock(LandingLabel, {
                                key: 1,
                                content: _ctx.label
                            }, null, 8, [
                                "content"
                            ])) : _createCommentVNode("", true)
                        ], 2)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['hero-full-screen__footer'])
                    }, [
                        _ctx.buttonLabel ? (_openBlock(), _createBlock(LandingButton, {
                            key: 0,
                            tag: _ctx.buttonLink ? _unref(Tag).A : void 0,
                            href: _ctx.buttonLink,
                            "icon-name": _ctx.buttonIcon,
                            label: _ctx.buttonLabel
                        }, null, 8, [
                            "tag",
                            "href",
                            "icon-name",
                            "label"
                        ])) : _createCommentVNode("", true),
                        _ctx.appList ? (_openBlock(), _createBlock(LandingAppList, {
                            key: 1,
                            list: _ctx.appList
                        }, null, 8, [
                            "list"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass({
                        [_ctx.$style['hero-full-screen__image']]: true,
                        [_ctx.$style[`hero-full-screen__image--${_ctx.imageKind}`]]: _ctx.imageKind
                    }),
                    style: _normalizeStyle({
                        backgroundImage: void 0
                    })
                }, [
                    _createElementVNode("img", {
                        src: _ctx.imageSrc
                    }, null, 8, _hoisted_1)
                ], 6)
            ], 6)), [
                [
                    _directive_auto_id,
                    'LandingHeroFullScreen'
                ]
            ]);
        };
    }
});
