// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-margins_Wqxa3>:first-child{margin-top:0}.no-margins_Wqxa3>:last-child{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"no-margins": `no-margins_Wqxa3`
};
export default ___CSS_LOADER_EXPORT___;
