import { computed, onBeforeMount, ref, toRef, useCssModule, watch } from 'vue';
import { useCmsStore } from 'web/src/modules/cms/store';
export default function useBaseCmsContent(props, param) {
    let { currentCmsKey, onLoadCallback } = param;
    const $style = useCssModule();
    const { fetchCmsContentTranslation, fetchCmsContentSpintaxBatchTranslations, hasCmsItem, resetCmsSpintaxData, getCmsContent } = useCmsStore();
    const isSpintaxCms = toRef(props, 'spintaxCms', false);
    const content = getCmsContent(isSpintaxCms, currentCmsKey);
    const isLoaded = ref(false);
    const className = computed(()=>({
            'component-wrapper': true,
            cms__content: true,
            [`cms__content--${currentCmsKey.value}`]: true,
            'cms__content--no-padding': !!props.noPadding,
            // For backward compat in local styles file
            [$style.cms__content]: true,
            [$style['cms__content--no-padding']]: !!props.noPadding,
            [$style[`cms__content--${currentCmsKey.value}`]]: true
        }));
    async function loadData() {
        if (props.spintaxCms) await fetchCmsContentSpintaxBatchTranslations({
            keys: props.cmsData?.keys ?? (props.cmsKey ? [
                props.cmsKey
            ] : []),
            cmsParams: props.cmsData?.params ?? {},
            silent: props.silent
        });
        else {
            if (props.doNotUpdateIfExists && hasCmsItem(currentCmsKey.value)) {
                isLoaded.value = true;
                return;
            }
            await fetchCmsContentTranslation({
                key: currentCmsKey.value,
                silent: props.silent
            });
            isLoaded.value = true;
        }
        onLoadCallback?.();
    }
    onBeforeMount(()=>{
        loadData();
    });
    watch(()=>props.cmsData, ()=>{
        if (props.spintaxCms) resetCmsSpintaxData();
        loadData();
    }, {
        deep: true
    });
    return {
        content,
        isLoaded,
        className,
        loadData
    };
}
