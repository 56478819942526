// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .home-promotions-slide_TH9zc{display:block;height:100%;overflow:hidden;-webkit-text-decoration:none;text-decoration:none;-webkit-user-select:none;user-select:none;background-color:var(--Layer1);border-radius:5px;-webkit-user-drag:none}html.app__layout--desktop .home-promotions-slide_TH9zc:hover .home-promotions-slide__image_DULdX{transform:scale(1.2) rotate(-3deg)}html.app__layout--desktop .home-promotions-slide_TH9zc:hover .home-promotions-slide__image-wrapper_SANPG:after{display:block}html.app__layout--desktop .home-promotions-slide_TH9zc:hover .home-promotions-slide__title_FrTmM{color:var(--BrandHighlight)}html.app__layout--desktop .home-promotions-slide__image_DULdX{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;-webkit-backface-visibility:hidden;backface-visibility:hidden;transition:transform .3s ease-in-out}html.app__layout--desktop .home-promotions-slide__image-badge_IFaZI{position:absolute;top:4px;right:4px}html.app__layout--desktop .home-promotions-slide__title_FrTmM{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;margin:8px 16px;color:var(--TextDefault)}html.app__layout--desktop .home-promotions-slide__desc__D0JX{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;margin:0 16px 8px;color:var(--TextPrimary)}html.app__layout--desktop .home-promotions-slide__image-wrapper_SANPG{position:relative;height:132px;margin:0;overflow:hidden;text-align:center}html.app__layout--desktop .home-promotions-slide__image-wrapper_SANPG:after{position:absolute;top:0;right:0;bottom:0;left:0;display:none;content:"";background-color:hsla(var(--huecolorwhite),var(--saturationcolorwhite),var(--lightnesscolorwhite),.15)}html.app__layout--desktop .home-promotions-slide__image-wrapper_SANPG:before{display:block;width:100%;padding-top:41.66667%;content:""}html.app__browser--safari .home-promotions-slide__image-wrapper_SANPG{z-index:1;border-radius:5px 5px 0 0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home-promotions-slide": `home-promotions-slide_TH9zc`,
	"home-promotions-slide__image": `home-promotions-slide__image_DULdX`,
	"home-promotions-slide__image-wrapper": `home-promotions-slide__image-wrapper_SANPG`,
	"home-promotions-slide__title": `home-promotions-slide__title_FrTmM`,
	"home-promotions-slide__image-badge": `home-promotions-slide__image-badge_IFaZI`,
	"home-promotions-slide__desc": `home-promotions-slide__desc__D0JX`
};
export default ___CSS_LOADER_EXPORT___;
