import { extractMarketStatus } from 'web/src/modules/sportline/utils/rest/pre-build';
import { BetlineMarketTypeTag } from 'web/src/modules/sportline/enums/rest';
import { SportEventMarketTypeTag } from 'web/src/modules/sportline/enums';
import { createRunner } from './createRunner';
import createMarketType from './createMarketType';
const marketTypeTagMap = {
    [BetlineMarketTypeTag.REGULAR]: SportEventMarketTypeTag.REGULAR,
    [BetlineMarketTypeTag.HANDICAP]: SportEventMarketTypeTag.HANDICAP,
    [BetlineMarketTypeTag.TOTAL]: SportEventMarketTypeTag.TOTAL
};
export function createMarket(marketResponse, properties, options) {
    const { eventId, eventStatus } = properties;
    const marketId = String(marketResponse.id);
    const marketStatus = extractMarketStatus(eventStatus, marketResponse);
    const { handicap, specifiers: rawSpecifiers } = marketResponse;
    const createRunnerProperties = {
        eventId,
        marketId,
        handicap,
        eventStatus,
        marketStatus
    };
    const runners = marketResponse.runners.map((runnerResponse)=>createRunner(runnerResponse, createRunnerProperties));
    const specifiers = rawSpecifiers ? Object.keys(rawSpecifiers).map((key)=>({
            id: key,
            value: rawSpecifiers[key]
        })) : void 0;
    return {
        eventId,
        id: marketId,
        name: marketResponse.name,
        type: createMarketType(marketResponse),
        isPrimary: marketResponse.primary,
        marketTypeId: marketResponse.marketTypeId,
        typeTag: marketResponse.typeTag ? marketTypeTagMap[marketResponse.typeTag] : void 0,
        status: marketStatus,
        runners,
        columns: marketResponse.cols,
        handicap,
        hasZeroMargin: options.canUseZeroMargin && (marketResponse.hasZeroMargin ?? false),
        specifiers
    };
}
export function createMarkets(markets, properties, options) {
    return (markets || []).map((marketResponse)=>createMarket(marketResponse, properties, options));
}
