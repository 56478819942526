/* eslint-disable */ function print(value) {
    return value;
}
export var AccountType;
var AccountType1;
/** All built-in and custom scalars, mapped to their actual values */ (AccountType1 = AccountType || (AccountType = {}))["MAIN"] = "MAIN", AccountType1["CBC"] = "CBC", AccountType1["BETTING"] = "BETTING", AccountType1["LOCKED"] = "LOCKED";
export var AchievementEventType;
var AchievementEventType1;
(AchievementEventType1 = AchievementEventType || (AchievementEventType = {}))["REWARD_REQUESTED"] = "REWARD_REQUESTED", AchievementEventType1["REWARD_RECEIVED"] = "REWARD_RECEIVED", AchievementEventType1["REWARD_REJECTED"] = "REWARD_REJECTED", AchievementEventType1["REWARD_EXPIRED"] = "REWARD_EXPIRED", AchievementEventType1["UNKNOWN"] = "UNKNOWN";
export var BetEventType;
var BetEventType1;
(BetEventType1 = BetEventType || (BetEventType = {}))["LIVE"] = "LIVE", BetEventType1["PREMATCH"] = "PREMATCH", BetEventType1["ANY"] = "ANY", BetEventType1["UNKNOWN"] = "UNKNOWN";
export var BetOperationType;
var BetOperationType1;
(BetOperationType1 = BetOperationType || (BetOperationType = {}))["BET"] = "BET", BetOperationType1["WIN"] = "WIN", BetOperationType1["LOSE"] = "LOSE", BetOperationType1["ROLLBACK"] = "ROLLBACK", BetOperationType1["ROLLBACK_WIN"] = "ROLLBACK_WIN";
export var BetType;
var BetType1;
(BetType1 = BetType || (BetType = {}))["SINGLE"] = "SINGLE", BetType1["EXPRESS"] = "EXPRESS", BetType1["SYSTEM"] = "SYSTEM", BetType1["ANY"] = "ANY", BetType1["UNKNOWN"] = "UNKNOWN";
export var BonusAmountFormula;
var BonusAmountFormula1;
(BonusAmountFormula1 = BonusAmountFormula || (BonusAmountFormula = {}))["FIXED_AMOUNT"] = "FIXED_AMOUNT", BonusAmountFormula1["DEPOSIT_PERCENT"] = "DEPOSIT_PERCENT", BonusAmountFormula1["BET_PERCENT"] = "BET_PERCENT", BonusAmountFormula1["UNKNOWN"] = "UNKNOWN";
export var BonusCloseReason;
var BonusCloseReason1;
(BonusCloseReason1 = BonusCloseReason || (BonusCloseReason = {}))["SUCCESSFUL"] = "SUCCESSFUL", BonusCloseReason1["NO_BALANCE"] = "NO_BALANCE", BonusCloseReason1["REFUSED"] = "REFUSED", BonusCloseReason1["EXPIRED"] = "EXPIRED", BonusCloseReason1["FREESPIN_LOST"] = "FREESPIN_LOST", BonusCloseReason1["ERROR"] = "ERROR", BonusCloseReason1["UNKNOWN"] = "UNKNOWN";
export var BonusRequirementChangeType;
var BonusRequirementChangeType1;
(BonusRequirementChangeType1 = BonusRequirementChangeType || (BonusRequirementChangeType = {}))["CREATE"] = "CREATE", BonusRequirementChangeType1["UPDATE"] = "UPDATE", BonusRequirementChangeType1["DELETE"] = "DELETE", BonusRequirementChangeType1["UNKNOWN"] = "UNKNOWN";
export var BonusType;
var BonusType1;
(BonusType1 = BonusType || (BonusType = {}))["BONUS"] = "BONUS", BonusType1["FREESPIN"] = "FREESPIN", BonusType1["UNKNOWN"] = "UNKNOWN";
export var ButtonAction;
(ButtonAction || (ButtonAction = {}))["CLAIM_CASHBACK"] = "CLAIM_CASHBACK";
export var CampaignType;
var CampaignType1;
(CampaignType1 = CampaignType || (CampaignType = {}))["EGS"] = "EGS", CampaignType1["SPORT"] = "SPORT", CampaignType1["UNKNOWN"] = "UNKNOWN";
export var CreatedBonusType;
var CreatedBonusType1;
(CreatedBonusType1 = CreatedBonusType || (CreatedBonusType = {}))["BONUS"] = "BONUS", CreatedBonusType1["SIMPLE_FREEBET"] = "SIMPLE_FREEBET", CreatedBonusType1["COMPLEX_FREEBET"] = "COMPLEX_FREEBET", CreatedBonusType1["UNKNOWN"] = "UNKNOWN";
export var DepositOperationType;
var DepositOperationType1;
(DepositOperationType1 = DepositOperationType || (DepositOperationType = {}))["DEPOSIT"] = "DEPOSIT", DepositOperationType1["DEPOSIT_CANCEL"] = "DEPOSIT_CANCEL";
export var DisplayType;
var DisplayType1;
(DisplayType1 = DisplayType || (DisplayType = {}))["PUSH"] = "PUSH", DisplayType1["SILENT"] = "SILENT", DisplayType1["SNACKBAR"] = "SNACKBAR";
export var FreeBetResultType;
var FreeBetResultType1;
(FreeBetResultType1 = FreeBetResultType || (FreeBetResultType = {}))["WON"] = "WON", FreeBetResultType1["LOST"] = "LOST", FreeBetResultType1["LOST_EXPIRED"] = "LOST_EXPIRED", FreeBetResultType1["LOST_REFUSED"] = "LOST_REFUSED", FreeBetResultType1["UNKNOWN"] = "UNKNOWN";
export var FreeBetTransferType;
var FreeBetTransferType1;
(FreeBetTransferType1 = FreeBetTransferType || (FreeBetTransferType = {}))["WIN"] = "WIN", FreeBetTransferType1["BONUS"] = "BONUS", FreeBetTransferType1["WIN_PLUS_BONUS"] = "WIN_PLUS_BONUS", FreeBetTransferType1["UNKNOWN"] = "UNKNOWN";
export var FreeBetType;
var FreeBetType1;
(FreeBetType1 = FreeBetType || (FreeBetType = {}))["AMOUNT"] = "AMOUNT", FreeBetType1["PERCENT"] = "PERCENT", FreeBetType1["FIXED_AMOUNT"] = "FIXED_AMOUNT", FreeBetType1["UNKNOWN"] = "UNKNOWN";
export var FreeSpinResultType;
var FreeSpinResultType1;
(FreeSpinResultType1 = FreeSpinResultType || (FreeSpinResultType = {}))["WIN"] = "WIN", FreeSpinResultType1["LOSE"] = "LOSE", FreeSpinResultType1["LOST_REFUSED"] = "LOST_REFUSED", FreeSpinResultType1["LOST_EXPIRED"] = "LOST_EXPIRED", FreeSpinResultType1["UNKNOWN"] = "UNKNOWN";
export var FreeSpinType;
var FreeSpinType1;
(FreeSpinType1 = FreeSpinType || (FreeSpinType = {}))["FLEXIBLE"] = "FLEXIBLE", FreeSpinType1["PROVIDER"] = "PROVIDER", FreeSpinType1["UNKNOWN"] = "UNKNOWN";
export var FrontNotificationType;
var FrontNotificationType1;
(FrontNotificationType1 = FrontNotificationType || (FrontNotificationType = {}))["CBC_BONUS_ACTIVATED"] = "CBC_BONUS_ACTIVATED", FrontNotificationType1["ALCR_NOTIFICATION"] = "ALCR_NOTIFICATION", FrontNotificationType1["VIDEO_VERIFICATION"] = "VIDEO_VERIFICATION", FrontNotificationType1["ON_SITE"] = "ON_SITE", FrontNotificationType1["REFPRG"] = "REFPRG", FrontNotificationType1["UNKNOWN"] = "UNKNOWN", FrontNotificationType1["PLAY_TIME_ENDED"] = "PLAY_TIME_ENDED", FrontNotificationType1["BONUS_GAME_REWARD"] = "BONUS_GAME_REWARD", FrontNotificationType1["BONUS_GAME_LAST_ROUND_FEEDBACK"] = "BONUS_GAME_LAST_ROUND_FEEDBACK", FrontNotificationType1["BONUS_EXPIRED_CASHBACK"] = "BONUS_EXPIRED_CASHBACK", FrontNotificationType1["BONUS_CASHBACK"] = "BONUS_CASHBACK", FrontNotificationType1["SUSPENDED_CASHBACK"] = "SUSPENDED_CASHBACK", FrontNotificationType1["RESUMED_CASHBACK"] = "RESUMED_CASHBACK", FrontNotificationType1["CRYPTO_DEPOSIT_STATUS"] = "CRYPTO_DEPOSIT_STATUS", FrontNotificationType1["BS_DEPOSIT"] = "BS_DEPOSIT";
export var IdentificationLevel;
var IdentificationLevel1;
(IdentificationLevel1 = IdentificationLevel || (IdentificationLevel = {}))["NONE"] = "NONE", IdentificationLevel1["SIMPLE"] = "SIMPLE", IdentificationLevel1["FULL"] = "FULL", IdentificationLevel1["UNKNOWN"] = "UNKNOWN";
export var InternalSportBonusType;
var InternalSportBonusType1;
(InternalSportBonusType1 = InternalSportBonusType || (InternalSportBonusType = {}))["BONUS"] = "BONUS", InternalSportBonusType1["FREEBET"] = "FREEBET", InternalSportBonusType1["UNKNOWN"] = "UNKNOWN";
export var LoyaltyCustomerLevelType;
var LoyaltyCustomerLevelType1;
(LoyaltyCustomerLevelType1 = LoyaltyCustomerLevelType || (LoyaltyCustomerLevelType = {}))["BALANCE_CHANGE"] = "BALANCE_CHANGE", LoyaltyCustomerLevelType1["CUSTOMER_LEVEL_UPGRADE"] = "CUSTOMER_LEVEL_UPGRADE", LoyaltyCustomerLevelType1["DEFAULT_LEVEL_INIT"] = "DEFAULT_LEVEL_INIT", LoyaltyCustomerLevelType1["CUSTOMER_LEVEL_RECALCULATE"] = "CUSTOMER_LEVEL_RECALCULATE", LoyaltyCustomerLevelType1["UNKNOWN"] = "UNKNOWN";
export var OfferStatus;
var OfferStatus1;
(OfferStatus1 = OfferStatus || (OfferStatus = {}))["UPCOMING"] = "UPCOMING", OfferStatus1["ACTIVE"] = "ACTIVE", OfferStatus1["ARCHIVED"] = "ARCHIVED", OfferStatus1["UNKNOWN"] = "UNKNOWN";
export var OrderStatus;
var OrderStatus1;
(OrderStatus1 = OrderStatus || (OrderStatus = {}))["CREATED"] = "CREATED", OrderStatus1["IN_PROGRESS"] = "IN_PROGRESS", OrderStatus1["DONE"] = "DONE", OrderStatus1["CANCELLED"] = "CANCELLED", OrderStatus1["UNKNOWN"] = "UNKNOWN";
export var ProgramRequirementType;
var ProgramRequirementType1;
(ProgramRequirementType1 = ProgramRequirementType || (ProgramRequirementType = {}))["REGISTRATION"] = "REGISTRATION", ProgramRequirementType1["DEPOSIT"] = "DEPOSIT", ProgramRequirementType1["BET"] = "BET", ProgramRequirementType1["CONFIRMATION"] = "CONFIRMATION", ProgramRequirementType1["IDENTIFICATION"] = "IDENTIFICATION";
export var ProgramRewardType;
var ProgramRewardType1;
(ProgramRewardType1 = ProgramRewardType || (ProgramRewardType = {}))["FREESPIN"] = "FREESPIN", ProgramRewardType1["FREEBET"] = "FREEBET";
export var RefProgramNotificationType;
var RefProgramNotificationType1;
(RefProgramNotificationType1 = RefProgramNotificationType || (RefProgramNotificationType = {}))["PROGRAM_AVAILABLE"] = "PROGRAM_AVAILABLE", RefProgramNotificationType1["PROGRAM_UNAVAILABLE"] = "PROGRAM_UNAVAILABLE", RefProgramNotificationType1["REFERRER_REQUIREMENT_SATISFIED"] = "REFERRER_REQUIREMENT_SATISFIED", RefProgramNotificationType1["REFERRER_REWARD_ASSIGNED"] = "REFERRER_REWARD_ASSIGNED", RefProgramNotificationType1["REFERRAL_REQUIREMENT_SATISFIED"] = "REFERRAL_REQUIREMENT_SATISFIED", RefProgramNotificationType1["REFERRAL_REWARD_ASSIGNED"] = "REFERRAL_REWARD_ASSIGNED", RefProgramNotificationType1["REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION"] = "REFERRAL_REWARD_ASSIGNED_REFERRER_NOTIFICATION", RefProgramNotificationType1["BONUS_ELIGIBILITY_RULES"] = "BONUS_ELIGIBILITY_RULES";
export var RewardType;
var RewardType1;
(RewardType1 = RewardType || (RewardType = {}))["FREEBET"] = "FREEBET", RewardType1["FREESPIN"] = "FREESPIN", RewardType1["BONUS"] = "BONUS", RewardType1["MULTIPLIER"] = "MULTIPLIER", RewardType1["SUPER_PRIZE"] = "SUPER_PRIZE", RewardType1["EMPTY"] = "EMPTY";
export var SportBonusActivatedType;
var SportBonusActivatedType1;
(SportBonusActivatedType1 = SportBonusActivatedType || (SportBonusActivatedType = {}))["BONUS"] = "BONUS", SportBonusActivatedType1["SIMPLE_FREEBET"] = "SIMPLE_FREEBET", SportBonusActivatedType1["COMPLEX_FREEBET"] = "COMPLEX_FREEBET", SportBonusActivatedType1["UNKNOWN"] = "UNKNOWN";
export var SportBonusCloseReason;
var SportBonusCloseReason1;
(SportBonusCloseReason1 = SportBonusCloseReason || (SportBonusCloseReason = {}))["SUCCESSFUL"] = "SUCCESSFUL", SportBonusCloseReason1["REFUSED"] = "REFUSED", SportBonusCloseReason1["EXPIRED"] = "EXPIRED", SportBonusCloseReason1["ERROR"] = "ERROR", SportBonusCloseReason1["DELETED"] = "DELETED", SportBonusCloseReason1["UNKNOWN"] = "UNKNOWN";
export var SportBonusConfirmationType;
var SportBonusConfirmationType1;
(SportBonusConfirmationType1 = SportBonusConfirmationType || (SportBonusConfirmationType = {}))["SIMPLE"] = "SIMPLE", SportBonusConfirmationType1["BONUS_CODE"] = "BONUS_CODE", SportBonusConfirmationType1["UNKNOWN"] = "UNKNOWN";
export var SportBonusOperationType;
var SportBonusOperationType1;
(SportBonusOperationType1 = SportBonusOperationType || (SportBonusOperationType = {}))["PLACE"] = "PLACE", SportBonusOperationType1["VOID"] = "VOID", SportBonusOperationType1["CANCEL_VOID"] = "CANCEL_VOID", SportBonusOperationType1["WIN"] = "WIN", SportBonusOperationType1["CANCEL_WIN"] = "CANCEL_WIN", SportBonusOperationType1["LOSE"] = "LOSE", SportBonusOperationType1["CANCEL_LOSS"] = "CANCEL_LOSS", SportBonusOperationType1["CASH_OUT"] = "CASH_OUT", SportBonusOperationType1["CANCEL_CASH_OUT"] = "CANCEL_CASH_OUT", SportBonusOperationType1["DEPOSIT"] = "DEPOSIT", SportBonusOperationType1["DEPOSIT_CANCEL"] = "DEPOSIT_CANCEL", SportBonusOperationType1["UNKNOWN"] = "UNKNOWN";
export var SportBonusRequirementChangeOperationType;
var SportBonusRequirementChangeOperationType1;
(SportBonusRequirementChangeOperationType1 = SportBonusRequirementChangeOperationType || (SportBonusRequirementChangeOperationType = {}))["ADDED"] = "ADDED", SportBonusRequirementChangeOperationType1["DELETED"] = "DELETED", SportBonusRequirementChangeOperationType1["UNKNOWN"] = "UNKNOWN";
export var SportBonusRequirementType;
var SportBonusRequirementType1;
(SportBonusRequirementType1 = SportBonusRequirementType || (SportBonusRequirementType = {}))["DEPOSIT"] = "DEPOSIT", SportBonusRequirementType1["BET_COUNT"] = "BET_COUNT", SportBonusRequirementType1["REGISTRATION"] = "REGISTRATION", SportBonusRequirementType1["IDENTIFICATION"] = "IDENTIFICATION", SportBonusRequirementType1["CONFIRMATION"] = "CONFIRMATION", SportBonusRequirementType1["INSTANT_BONUS_CREATE"] = "INSTANT_BONUS_CREATE", SportBonusRequirementType1["START_CAMPAIGN"] = "START_CAMPAIGN", SportBonusRequirementType1["BONUS_CODE_CONFIRMATION"] = "BONUS_CODE_CONFIRMATION", SportBonusRequirementType1["PARTICIPANT_ADDED"] = "PARTICIPANT_ADDED", SportBonusRequirementType1["UNKNOWN"] = "UNKNOWN";
export var SportBonusWagerResultType;
var SportBonusWagerResultType1;
(SportBonusWagerResultType1 = SportBonusWagerResultType || (SportBonusWagerResultType = {}))["SUCCESSFUL"] = "SUCCESSFUL", SportBonusWagerResultType1["CANCELED_EXPIRED"] = "CANCELED_EXPIRED", SportBonusWagerResultType1["CANCELED_BY_ERROR"] = "CANCELED_BY_ERROR", SportBonusWagerResultType1["CANCELED_REFUSED"] = "CANCELED_REFUSED", SportBonusWagerResultType1["UNKNOWN"] = "UNKNOWN";
export var TicketChatEventType;
var TicketChatEventType1;
(TicketChatEventType1 = TicketChatEventType || (TicketChatEventType = {}))["CREATE"] = "CREATE", TicketChatEventType1["UNKNOWN"] = "UNKNOWN";
export var VideoVerificationStatus;
var VideoVerificationStatus1;
(VideoVerificationStatus1 = VideoVerificationStatus || (VideoVerificationStatus = {}))["NONE"] = "NONE", VideoVerificationStatus1["PENDING"] = "PENDING", VideoVerificationStatus1["IGNORED"] = "IGNORED", VideoVerificationStatus1["INVITED"] = "INVITED", VideoVerificationStatus1["NO_ANSWER"] = "NO_ANSWER", VideoVerificationStatus1["SCHEDULED"] = "SCHEDULED", VideoVerificationStatus1["ON_REVIEW"] = "ON_REVIEW", VideoVerificationStatus1["PASSED"] = "PASSED", VideoVerificationStatus1["NOT_PASSED"] = "NOT_PASSED", VideoVerificationStatus1["REFUSED"] = "REFUSED", VideoVerificationStatus1["MISSED"] = "MISSED", VideoVerificationStatus1["ACCEPT_IGNORE"] = "ACCEPT_IGNORE", VideoVerificationStatus1["ACCEPT_REFUSAL"] = "ACCEPT_REFUSAL", VideoVerificationStatus1["UNKNOWN"] = "UNKNOWN";
export var WagerResultType;
var WagerResultType1;
(WagerResultType1 = WagerResultType || (WagerResultType = {}))["COMPLETED"] = "COMPLETED", WagerResultType1["REFUSED"] = "REFUSED", WagerResultType1["EMPTY_BALANCE"] = "EMPTY_BALANCE", WagerResultType1["EXPIRED"] = "EXPIRED";
export var WalletType;
var WalletType1;
(WalletType1 = WalletType || (WalletType = {}))["MAIN"] = "MAIN", WalletType1["CBC"] = "CBC";
export const onAchievementEventDocument = `
    subscription onAchievementEvent {
  onAchievementEvent {
    eventType
    ... on AchievementRewardEvent {
      rewardId
    }
  }
}
    `;
export const onBalanceChangeDocument = `
    subscription onBalanceChange {
  onBalanceChange {
    date
    walletTypeId
    availableBalance
  }
}
    `;
export const onBonusBalanceChangeDocument = `
    subscription onBonusBalanceChange {
  onBonusBalanceChange {
    date
    availableBalance
    remainingAmountNumber
    progress
    progressPercentString
  }
}
    `;
export const onBonusGameRoundEventDocument = `
    subscription onBonusGameRoundEvent {
  onBonusGameRoundEvent {
    startDate
    endDate
    id
    additional
  }
}
    `;
export const onCalculatedCashbackDocument = `
    subscription onCalculatedCashback {
  onCalculatedCashback {
    campaignId
    currency
    amount
    roundId
  }
}
    `;
export const onDepositDocument = `
    subscription onDeposit {
  onDeposit {
    firstDeposit
    operationType
    paymentId
    paymentSystemAbbrev
    systemAmount
  }
}
    `;
export const onFfsBetCountLimitDocument = `
    subscription onFfsBetCountLimit {
  onFfsBetCountLimit {
    amount
    currency
    channel
    internalGameId
    walletTypeId
    freespinTargetWalletTypeId
  }
}
    `;
export const onFfsBetLimitDocument = `
    subscription onFfsBetLimit {
  onFfsBetLimit {
    amount
    currency
    channel
    internalGameId
    walletTypeId
    freespinTargetWalletTypeId
  }
}
    `;
export const onFreeSpinResultDocument = `
    subscription onFreeSpinResult {
  onFreeSpinResult {
    id
    campaignId
    result
    amount
    createdAt
    freespinType
    freespinId
    freespinTargetWalletType
  }
}
    `;
export const onFrontNotificationDocument = `
    subscription onFrontNotification {
  onFrontNotification {
    id
    type
    timestamp
    isDelivered
    delay
    restrictedRoutes
    ... on BonusActivationNotification {
      amount
      depositAmount
      depositTransferAmount
      currency
      actionUrl
      categoryId
      promoName
    }
    ... on AlcrNotification {
      title
      message
      ctaButtonLink
      ctaButtonText
      imageUrl
      displayType
      iconName
      buttonAction
    }
    ... on VideoVerificationNotification {
      status
      message
    }
    ... on RefProgramNotification {
      payload {
        type
        ... on ProgramAvailable {
          programId
          referrerId
        }
        ... on BonusEligibilityRules {
          currency
          rewards {
            depositAmount
            rewardAmount {
              playerReward {
                count
                nominalValue
                amount
                rewardType
              }
              referralReward {
                count
                nominalValue
                amount
                rewardType
              }
            }
          }
          referralRequirements {
            type
            satisfied
            requiredValue
            currency
          }
        }
        ... on ReferralRewardAssigned {
          referrerId
          referralId
          programId
          reward {
            count
            nominalValue
            amount
            rewardType
          }
        }
        ... on ReferralRewardAssignedReferrerNotificationDto {
          programId
          reward {
            count
            nominalValue
            amount
            rewardType
          }
        }
      }
    }
    ... on PlayTimeTimerEndedNotification {
      hours
      minutes
      overallProfit
      currency
    }
    ... on CryptoDepositStatusNotification {
      amountStr
      currency
      initialTimestamp
      delay
    }
    ... on DepositNotification {
      amount
      currency
      date
      delay
    }
  }
}
    `;
export const onLoyaltyCustomerLevelDocument = `
    subscription onLoyaltyCustomerLevel {
  onLoyaltyCustomerLevel {
    type
    currentLevelId
    currentPoints
    multiplier
    pointsUntilNextLevel
  }
}
    `;
export const onRefusedCashbackDocument = `
    subscription onRefusedCashback {
  onRefusedCashback {
    campaignId
    roundId
  }
}
    `;
export const onTicketChatEventDocument = `
    subscription onTicketChatEvent {
  onTicketChatEvent {
    eventType
    date
  }
}
    `;
export const onUpdatedCashbackDocument = `
    subscription onUpdatedCashback {
  onUpdatedCashback {
    campaignId
    amount
    currency
    roundId
  }
}
    `;
export const onWagerCreatedDocument = `
    subscription onWagerCreated {
  onWagerCreated {
    wagerSourceWalletType
  }
}
    `;
export const onWagerResultDocument = `
    subscription onWagerResult {
  onWagerResult {
    resultType
  }
}
    `;
export const onWagerUpdatedDocument = `
    subscription onWagerUpdated {
  onWagerUpdated {
    currentWagerAmount
    currencyCode
    campaignId
  }
}
    `;
export function getWsSdk() {
    return {};
}
export const webSocketDocuments = {
    onAchievementEvent: print(onAchievementEventDocument),
    onBalanceChange: print(onBalanceChangeDocument),
    onBonusBalanceChange: print(onBonusBalanceChangeDocument),
    onBonusGameRoundEvent: print(onBonusGameRoundEventDocument),
    onCalculatedCashback: print(onCalculatedCashbackDocument),
    onDeposit: print(onDepositDocument),
    onFfsBetCountLimit: print(onFfsBetCountLimitDocument),
    onFfsBetLimit: print(onFfsBetLimitDocument),
    onFreeSpinResult: print(onFreeSpinResultDocument),
    onFrontNotification: print(onFrontNotificationDocument),
    onLoyaltyCustomerLevel: print(onLoyaltyCustomerLevelDocument),
    onRefusedCashback: print(onRefusedCashbackDocument),
    onTicketChatEvent: print(onTicketChatEventDocument),
    onUpdatedCashback: print(onUpdatedCashbackDocument),
    onWagerCreated: print(onWagerCreatedDocument),
    onWagerResult: print(onWagerResultDocument),
    onWagerUpdated: print(onWagerUpdatedDocument)
};
