import { computed, ref, nextTick } from 'vue';
import { Timer } from '@leon-hub/utils';
import { useDropdownButton } from '@components/core-dropdown';
import searchSelectOptions from 'web/src/utils/search/searchSelectOptions';
import { isDropListMenuOptionGroupArray, isDropListMenuOptionArray } from '../guards';
import { getOptionGroupsLimitedByCount, isPlainOptions, searchInGroups } from '../utils';
import { useDropListSearchQuery } from './useDropListSearchQuery';
import { useDropListSelected } from './useDropListSelected';
import { useDropListEventHandlers } from './useDropListEventHandlers';
// search
// options
// ui computed properties
// preselected
// dropdown
// handlers
// container props
// pagination
// recalculate scroll
export function useDropListSelect(props, emit) {
    const paginationCount = ref(1);
    const resetPagination = ()=>{
        paginationCount.value = 1;
    };
    // search
    const { searchQuery, searchInputRef, isFilterSearch, onSearchInput, focusOnSearch, clearSearchQuery } = useDropListSearchQuery(props, emit, resetPagination);
    const isSimpleList = computed(()=>isPlainOptions(props.options));
    const optionGroups = computed(()=>{
        /** type-safe options, DropListMenuOptionGroup[] */ if (isSimpleList.value) return [];
        isDropListMenuOptionGroupArray(props.options);
        if (isFilterSearch.value) return searchInGroups(props.options, searchQuery.value);
        return props.options;
    });
    const plainOptions = computed(()=>{
        /** type-safe options, DropListMenuOption[] */ if (!isSimpleList.value) return [];
        isDropListMenuOptionArray(props.options);
        if (isFilterSearch.value) return searchSelectOptions(props.options, searchQuery.value);
        return props.options;
    });
    const allFlatOptions = computed(()=>{
        if (isSimpleList.value) return plainOptions.value;
        return optionGroups.value.flatMap((group)=>group.options);
    });
    // selected
    const { selectedId, selectedText, selectedSecondaryText, selectedSecondaryBadge, selectedOption, multipleSelectedValues, setSingleSelected, toggleMultipleSelected, isSelected } = useDropListSelected(props, allFlatOptions);
    // options
    const isEmptySearch = computed(()=>{
        if (!searchQuery.value.length) return false;
        return !props.isLoadingOptions && !optionGroups.value.length && !plainOptions.value.length;
    });
    // ui computed properties
    const buttonIconName = computed(()=>{
        if (props.showSelectedIcon && selectedOption.value) return selectedOption.value?.icon ?? null;
        return props.placeholderIcon ?? null;
    });
    const buttonImgSrc = computed(()=>{
        if (props.showSelectedIcon && selectedOption.value) return selectedOption.value?.imageSrc ?? null;
        return props.placeholderImg ?? null;
    });
    const isButtonImageShown = computed(()=>!!(buttonIconName.value ?? buttonImgSrc.value));
    const isButtonsBlockShown = computed(()=>!!(props.primaryButtonLabel || props.secondaryButtonLabel));
    // preselected
    const preselectedId = ref(null);
    const setPreselected = (value)=>{
        preselectedId.value = value;
    };
    const clearPreselected = ()=>{
        preselectedId.value = '';
    };
    // cache for cansel button emit
    /** cache for old multiple selected state, for secondary (cancel) button emit */ const multipleSelectedPreviousState = ref([]);
    const setMultipleSelectedPreviousState = (state)=>{
        multipleSelectedPreviousState.value = state;
    };
    // focus
    const haveFocusInside = ref(false);
    const setFocusInside = (isFocused)=>{
        haveFocusInside.value = isFocused;
    };
    const onFocus = ()=>{
        if (haveFocusInside.value) return;
        emit('focus');
    };
    const onBlur = ()=>{
        if (haveFocusInside.value) return;
        emit('blur');
    };
    // computed options and pagination
    const renderCount = computed(()=>{
        if (!props.itemsToRender) return allFlatOptions.value.length;
        return props.itemsToRender * paginationCount.value;
    });
    const isRenderingMore = ref(false);
    const renderComplete = computed(()=>!props.itemsToRender || renderCount.value >= allFlatOptions.value.length);
    const computedPlainOptions = computed(()=>{
        if (!isSimpleList.value) return [];
        if (!props.itemsToRender) return plainOptions.value;
        return plainOptions.value.slice(0, renderCount.value);
    });
    const computedOptionGroups = computed(()=>{
        if (isSimpleList.value) return [];
        if (!props.itemsToRender || renderCount.value >= allFlatOptions.value.length) return optionGroups.value;
        return getOptionGroupsLimitedByCount(optionGroups.value, renderCount.value);
    });
    // dropdown properties
    let isClosing = false;
    const setClosedTimeout = ()=>{
        isClosing = true;
        Timer.setTimeout(()=>{
            isClosing = false;
        }, 300);
    };
    const { isDropdownShown, closeDropdown, openDropdown, focusOnDropdownButton, dropdownProps, dropdownButton } = useDropdownButton({});
    const onOpen = ()=>{
        if (isDropdownShown.value || isClosing) return;
        if (props.isMultiselectMode) {
            setMultipleSelectedPreviousState(multipleSelectedValues.value);
            setPreselected('');
        } else setPreselected(selectedId.value ?? '');
        openDropdown();
        setFocusInside(true);
        focusOnDropdownButton();
    };
    const onClose = ()=>{
        // todo: compare event target?
        setClosedTimeout();
        closeDropdown().then(()=>{
            clearSearchQuery();
            clearPreselected();
            resetPagination();
            focusOnDropdownButton();
            setFocusInside(false);
        });
    };
    const toggleOpen = ()=>isDropdownShown.value ? onClose() : onOpen();
    // handlers
    const onPrimaryButtonClick = ()=>{
        emit('primary-button-click', multipleSelectedValues.value);
        onClose();
    };
    const onSecondaryButtonClick = ()=>{
        emit('secondary-button-click', multipleSelectedPreviousState.value);
        onClose();
    };
    const onItemClick = (value)=>{
        emit('item-click', value);
        if (props.isMultiselectMode) {
            toggleMultipleSelected(value);
            return;
        }
        setSingleSelected(value);
        onClose();
    };
    const onPreselect = ()=>{
        if (preselectedId.value) onItemClick(preselectedId.value);
        else onClose();
    };
    const increasePaginationCount = async ()=>{
        if (!renderComplete.value && !isRenderingMore.value) {
            isRenderingMore.value = true;
            paginationCount.value += 1;
            await nextTick();
            isRenderingMore.value = false;
        }
    };
    const { setOptionRefs, scrollbarRef, primaryButtonRef, secondaryButtonRef, onOptionHover, onInputKeydown, onDropdownOptionKeydown, onActionButtonKeydown, onPrimaryButtonFocus, onPrimaryButtonBlur, onSecondaryButtonFocus, onSecondaryButtonBlur } = useDropListEventHandlers(props, {
        setPreselected,
        preselectedId,
        allFlatOptions,
        focusOnSearch,
        focusOnDropdownButton,
        onOpen,
        onClose,
        onPreselect,
        isDropdownShown,
        increasePaginationCount,
        lastRenderedCount: renderCount
    });
    const dropContainerProps = computed(()=>{
        if (props.modalDropdown) return {
            label: props.label,
            isFooterShown: isButtonsBlockShown.value,
            searchEnabled: props.searchEnabled
        };
        return {
            dropdownProps: dropdownProps.value,
            isBehindModals: props.isBehindModals,
            searchEnabled: props.searchEnabled,
            dropDirection: props.dropDirection,
            isFooterShown: isButtonsBlockShown.value
        };
    });
    // recalculate scroll
    const onDropdownUpdate = async ()=>{
        await nextTick();
        scrollbarRef.value?.scrollUpdate();
    };
    return {
        isSelected,
        // search
        searchQuery,
        searchInputRef,
        onSearchInput,
        clearSearchQuery,
        // options
        isSimpleList,
        optionGroups: computedOptionGroups,
        plainOptions: computedPlainOptions,
        setOptionRefs,
        isEmptySearch,
        // ui computed properties
        buttonIconName,
        buttonImgSrc,
        isButtonImageShown,
        selectedText,
        selectedSecondaryText,
        selectedSecondaryBadge,
        isButtonsBlockShown,
        // preselected
        preselectedId,
        clearPreselected,
        // dropdown
        isDropdownShown,
        toggleOpen,
        onOpen,
        onClose,
        focusOnDropdownButton,
        dropdownButton,
        // handlers
        scrollbarRef,
        primaryButtonRef,
        secondaryButtonRef,
        onPrimaryButtonClick,
        onSecondaryButtonClick,
        onItemClick,
        onOptionHover,
        onPreselect,
        onInputKeydown,
        onDropdownOptionKeydown,
        onActionButtonKeydown,
        onPrimaryButtonFocus,
        onPrimaryButtonBlur,
        onSecondaryButtonFocus,
        onSecondaryButtonBlur,
        onFocus,
        onBlur,
        dropContainerProps,
        // pagination
        increasePaginationCount,
        renderComplete,
        isRenderingMore,
        // recalculate scroll
        onDropdownUpdate
    };
}
