// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.balance-value-wrapper_n28D7{display:flex;flex-direction:column;flex-grow:1;gap:0;align-items:flex-start;padding:0;overflow:hidden}.balance-value_PCIyk{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;white-space:nowrap}.balance-value--type__balance_lzXNj{color:var(--TextDefault)}.balance-caption_WNnXD{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextSecondary);max-width:100%;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}@keyframes apply-text-ratio_Af40E{0%{margin-bottom:-1lh;transform:scale(0)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance-value-wrapper": `balance-value-wrapper_n28D7`,
	"balance-value": `balance-value_PCIyk`,
	"balance-value--type__balance": `balance-value--type__balance_lzXNj`,
	"balance-caption": `balance-caption_WNnXD`,
	"apply-text-ratio": `apply-text-ratio_Af40E`
};
export default ___CSS_LOADER_EXPORT___;
