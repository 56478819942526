// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-widget_mVW4D{filter:drop-shadow(0 8px 16px var(--ModalShadow))}.support-widget__content_AaHlP{max-height:500px;overflow-y:auto;cursor:pointer;transition:max-height .35s ease-out;transform:translateY(-22px)}.support-widget__content--hidden_huFaO{max-height:0;transition:max-height .15s ease-out}.support-widget__content-inner_CSYKe{padding:20px 20px 30px;background:var(--Layer1);border-radius:20px 20px 0}.support-widget__title_q68Zx{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;color:var(--TextDefault)}.support-widget__desc_q317c{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-top:8px;color:var(--TextPrimary)}.support-widget__close_F05pX{position:absolute;right:0;bottom:0}.support-widget__overlay_HQ8zn{z-index:59}html.app__layout--desktop .support-widget__overlay_HQ8zn{z-index:120}.support-widget__fixed_MOQhC{z-index:59;position:fixed;right:8px;bottom:70px}html.app__layout--desktop .support-widget__fixed_MOQhC{z-index:120}html.app__layout--desktop .support-widget__fixed_MOQhC{right:16px;bottom:30px}html.app__layout--desktop .support-widget_mVW4D{width:360px}.live-chat-main_HCOmR{z-index:64;position:fixed;top:0;right:0;bottom:0;left:0}.live-chat-main__window_q5izp{width:100%;height:100%}html.app__layout--desktop .live-chat-main_HCOmR{z-index:120}html.app__layout--desktop .live-chat-main_HCOmR{filter:drop-shadow(0 8px 16px var(--ModalShadow));top:unset;right:32px;bottom:32px;left:unset;width:375px;height:700px;max-height:80vh;overflow:hidden;background-color:var(--Layer0);background-clip:padding-box;border:2px solid var(--Layer1);border-radius:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"support-widget": `support-widget_mVW4D`,
	"support-widget__content": `support-widget__content_AaHlP`,
	"support-widget__content--hidden": `support-widget__content--hidden_huFaO`,
	"support-widget__content-inner": `support-widget__content-inner_CSYKe`,
	"support-widget__title": `support-widget__title_q68Zx`,
	"support-widget__desc": `support-widget__desc_q317c`,
	"support-widget__close": `support-widget__close_F05pX`,
	"support-widget__overlay": `support-widget__overlay_HQ8zn`,
	"support-widget__fixed": `support-widget__fixed_MOQhC`,
	"live-chat-main": `live-chat-main_HCOmR`,
	"live-chat-main__window": `live-chat-main__window_q5izp`
};
export default ___CSS_LOADER_EXPORT___;
