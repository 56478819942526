// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-menu_ZD_TQ{border-radius:5px;box-shadow:0 8px 16px var(--ModalShadow);margin-top:4px;overflow:hidden;background:var(--Layer1)}.drop-menu--light_SRnvm{background:var(--LLayer1)}.drop-menu--direction-top_IKjqz{margin-top:-2px;box-shadow:0 -8px 8px var(--ModalShadow)}.drop-menu__list_NMPAE{padding:2px;margin:0;list-style:none}.drop-menu-button_usqBZ,.drop-menu__item_vMD6T{display:flex;align-items:center}.drop-menu-button_usqBZ{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;height:44px;padding:0 12px;flex:1;max-width:100%;color:var(--TextDefault);white-space:nowrap;cursor:pointer;-webkit-user-select:none;user-select:none;background:#0000;border:none}.drop-menu-button--spaced_ZevZ4{justify-content:space-between}.drop-menu-button--light_EjHxt{color:var(--LTextDefault)}.drop-menu-button--selected_WgUTz{background-color:var(--Highlight);border-radius:3px}.drop-menu-button--light_EjHxt.drop-menu-button--selected_WgUTz{background-color:var(--LHighlight)}.drop-menu-button--empty__mT05{color:var(--TextSecondary)}.drop-menu-button__left_ceBvy{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;overflow:hidden;text-overflow:ellipsis}.drop-menu-button__right_qgP7K{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal}.drop-menu-button__flag_mSdx4{margin-right:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drop-menu": `drop-menu_ZD_TQ`,
	"drop-menu--light": `drop-menu--light_SRnvm`,
	"drop-menu--direction-top": `drop-menu--direction-top_IKjqz`,
	"drop-menu__list": `drop-menu__list_NMPAE`,
	"drop-menu-button": `drop-menu-button_usqBZ`,
	"drop-menu__item": `drop-menu__item_vMD6T`,
	"drop-menu-button--spaced": `drop-menu-button--spaced_ZevZ4`,
	"drop-menu-button--light": `drop-menu-button--light_EjHxt`,
	"drop-menu-button--selected": `drop-menu-button--selected_WgUTz`,
	"drop-menu-button--empty": `drop-menu-button--empty__mT05`,
	"drop-menu-button__left": `drop-menu-button__left_ceBvy`,
	"drop-menu-button__right": `drop-menu-button__right_qgP7K`,
	"drop-menu-button__flag": `drop-menu-button__flag_mSdx4`
};
export default ___CSS_LOADER_EXPORT___;
