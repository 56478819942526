// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .payments-system_Jjpb4{padding:0 32px}html.app__layout--desktop .payments-system__label_j2Z90{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;display:block;margin:12px 0 0;color:var(--TextSecondary);text-align:center}html.app__layout--desktop .payments-system__cancel-withdrawal_Ynu0b{position:absolute;bottom:-216px;left:0;width:100%;text-align:center;margin-top:10px}html.app__layout--desktop .payments-system__logo_MOTag{display:block;max-width:80px;margin:0 auto 20px}html.app__layout--desktop .payments-system__form_94tEX{margin:0 auto}html.app__layout--desktop .payments-system__hint-block_xqr05{margin-top:20px}html.app__layout--desktop .payments-system_Jjpb4 :deep(.finDescPar_PmGRl){margin-top:12px}html.app__layout--desktop .payments-system_Jjpb4 :deep(*+.single-select_v1mhD){margin-top:16px}html.app__layout--desktop .payments-system_Jjpb4 :deep(*+.checkbox_pvBu6),html.app__layout--desktop .payments-system_Jjpb4 :deep(*+.checkbox__container_akcBo){margin-top:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-system": `payments-system_Jjpb4`,
	"payments-system__label": `payments-system__label_j2Z90`,
	"payments-system__cancel-withdrawal": `payments-system__cancel-withdrawal_Ynu0b`,
	"payments-system__logo": `payments-system__logo_MOTag`,
	"payments-system__form": `payments-system__form_94tEX`,
	"payments-system__hint-block": `payments-system__hint-block_xqr05`,
	"finDescPar": `finDescPar_PmGRl`,
	"single-select": `single-select_v1mhD`,
	"checkbox": `checkbox_pvBu6`,
	"checkbox__container": `checkbox__container_akcBo`
};
export default ___CSS_LOADER_EXPORT___;
