// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper-hint_UAmVi{display:block;width:100%;margin:4px 0 10px}.wrapper-hint__wrapper_wDbkc{display:flex;justify-content:space-between;padding:0}.wrapper-hint__text_Smd0o{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary);transition:opacity .15s ease-in-out}.wrapper-hint__text_Smd0o:first-letter{text-transform:uppercase}.wrapper-hint__text--error_yFsJQ{color:var(--ErrorText)}.wrapper-hint__text--left_qz3fs{text-align:left}.wrapper-hint__text--right_OeMSk{margin-left:16px;text-align:right}.wrapper-hint__link_Vguia{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary);-webkit-text-decoration:underline;text-decoration:underline}@media(hover:hover)and (pointer:fine){.wrapper-hint__link_Vguia:hover{color:var(--BrandHighlight);-webkit-text-decoration:underline;text-decoration:underline}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper-hint": `wrapper-hint_UAmVi`,
	"wrapper-hint__wrapper": `wrapper-hint__wrapper_wDbkc`,
	"wrapper-hint__text": `wrapper-hint__text_Smd0o`,
	"wrapper-hint__text--error": `wrapper-hint__text--error_yFsJQ`,
	"wrapper-hint__text--left": `wrapper-hint__text--left_qz3fs`,
	"wrapper-hint__text--right": `wrapper-hint__text--right_OeMSk`,
	"wrapper-hint__link": `wrapper-hint__link_Vguia`
};
export default ___CSS_LOADER_EXPORT___;
