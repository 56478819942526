import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { IconName, IconSize } from '@leon-hub/icons';
import { CustomerConfig } from '@leon-hub/api-sdk';
import DefaultTopBarRouteComponent from 'web/src/modules/core/views/DefaultTopBarRouteComponent/DefaultTopBarRouteComponent.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import useOnboarding from 'web/src/modules/onboarding/pages/composables/useOnboading';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'OnboardingTopbar',
    setup (__props) {
        const router = useRouter();
        const { $translate } = useI18n();
        const { onboardingConfig, swiper } = useOnboarding();
        const title = computed(()=>onboardingConfig.value.topBarTitleBTR ? $translate(onboardingConfig.value.topBarTitleBTR).value : '');
        const slotName = computed(()=>{
            if (onboardingConfig.value.infoLinkRouteName) return 'prefix';
            return null;
        });
        const isLastSlide = computed(()=>{
            const numberOfSlides = swiper.value?.getNumberOfSlides();
            const activeSlideIndex = swiper.value?.getActiveSlideIndex();
            return 'number' == typeof numberOfSlides && numberOfSlides - 1 === activeSlideIndex;
        });
        function onClick() {
            if (onboardingConfig.value.infoLinkRouteName) router.push({
                name: onboardingConfig.value.infoLinkRouteName
            });
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(DefaultTopBarRouteComponent, {
                title: title.value
            }, _createSlots({
                _: 2
            }, [
                slotName.value ? {
                    name: slotName.value,
                    fn: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['onboarding-top-bar2__suffix'])
                            }, [
                                _unref(onboardingConfig).infoLinkRouteName ? (_openBlock(), _createBlock(VButton, {
                                    key: 0,
                                    "icon-name": _unref(IconName).INFO_OUTLINE,
                                    "icon-size": _unref(IconSize).SIZE_24,
                                    kind: _unref(ButtonKind).TRANSPARENT,
                                    class: _normalizeClass(_ctx.$style['onboarding-top-bar__button']),
                                    onClick: onClick
                                }, null, 8, [
                                    "icon-name",
                                    "icon-size",
                                    "kind",
                                    "class"
                                ])) : _createCommentVNode("", true),
                                _createCommentVNode("", true)
                            ], 2)
                        ]),
                    key: "0"
                } : void 0
            ]), 1032, [
                "title"
            ])), [
                [
                    _directive_auto_id,
                    'OnboardingTopbar'
                ]
            ]);
        };
    }
});
