// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.referral-program-reg-body_dYXF9{width:100%}.referral-program-reg-body_dYXF9 .referral-program-reg-body__title_UE3hT{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;padding-bottom:8px;color:var(--TextDefault);text-align:center}.referral-program-reg-body_dYXF9 .referral-program-reg-body__rule_vN34v{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;margin-bottom:12px;color:var(--TextSecondary)}.referral-program-reg-body__title_UE3hT{display:block;width:100%}.referral-program-reg-body__rule_vN34v{display:block;padding:8px}.referral-program-reg-body__steps_dpf72{display:flex;flex-direction:column;row-gap:12px}.referral-program-reg-body__button_DFtvq{margin-top:20px}.referral-program-reg-body__how-to_B5TDs{display:flex;flex-direction:column;width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-reg-body": `referral-program-reg-body_dYXF9`,
	"referral-program-reg-body__title": `referral-program-reg-body__title_UE3hT`,
	"referral-program-reg-body__rule": `referral-program-reg-body__rule_vN34v`,
	"referral-program-reg-body__steps": `referral-program-reg-body__steps_dpf72`,
	"referral-program-reg-body__button": `referral-program-reg-body__button_DFtvq`,
	"referral-program-reg-body__how-to": `referral-program-reg-body__how-to_B5TDs`
};
export default ___CSS_LOADER_EXPORT___;
