// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-input__masked-placeholder_tiYIR{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;position:absolute;top:0;right:12px;left:12px;display:flex;align-items:center;height:44px;padding:14px 0;color:var(--TextSecondary);white-space:nowrap;pointer-events:none}.text-input__masked-placeholder--hidden_xxl3u{z-index:-1;opacity:0}.text-input__masked-placeholder--large_sBino{right:12px;left:12px;height:44px;padding:14px 0}.text-input__masked-placeholder--icon-suffix_vb5dM,.text-input__masked-placeholder--large_sBino.text-input__masked-placeholder--icon-suffix_vb5dM{right:36px}.text-input__masked-placeholder--icon-prefix_VKh41,.text-input__masked-placeholder--large_sBino.text-input__masked-placeholder--icon-prefix_VKh41{left:36px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-input__masked-placeholder": `text-input__masked-placeholder_tiYIR`,
	"text-input__masked-placeholder--hidden": `text-input__masked-placeholder--hidden_xxl3u`,
	"text-input__masked-placeholder--large": `text-input__masked-placeholder--large_sBino`,
	"text-input__masked-placeholder--icon-suffix": `text-input__masked-placeholder--icon-suffix_vb5dM`,
	"text-input__masked-placeholder--icon-prefix": `text-input__masked-placeholder--icon-prefix_VKh41`
};
export default ___CSS_LOADER_EXPORT___;
