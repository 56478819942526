// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-details-market-group_Qf0Ab{padding:0;margin:0}.sport-event-details-market-group_Qf0Ab,.sport-event-details-market-group__wrapper_RY2h_{background-color:var(--Layer1);border-radius:5px}.sport-event-details-market-group__header_jLziG{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;position:relative;display:flex;align-items:center;justify-content:space-between;min-height:44px;padding-left:0;color:var(--TextDefault);background-color:var(--Layer1);border-radius:5px 5px 0 0}.sport-event-details-market-group__header--closed_fHCUN{color:var(--TextPrimary);border-radius:5px}@media(hover:hover)and (pointer:fine){.sport-event-details-market-group__header--closed_fHCUN:hover{color:var(--TextDefault);background-color:var(--Highlight)}}html.app__layout--desktop .sport-event-details-market-group__header_jLziG{cursor:pointer}.sport-event-details-market-group__content_pwQym{background-color:var(--Layer1);border-radius:0 0 5px 5px}.sport-event-details-market-group__market_kQFnS{border-radius:0 0 5px 5px}.sport-event-details-market-group__market--hidden_j6sjV{opacity:.3}.sport-event-details-market-group__indicator_aJy_U{position:absolute;top:0;right:0;display:flex;align-items:center;justify-content:center;width:44px;height:44px}.market-group-title_Zqwjt{display:flex;flex-direction:row;align-items:center;justify-content:center;width:100%;max-width:100%;padding:12px 44px;text-align:center}.market-group-title--closed_VjMGE{padding-bottom:20px}.market-group-title__label_umhdj{vertical-align:middle}.market-group-title__zero-margin_m_ByQ{position:absolute;bottom:-4px}.market-group-title__zero-margin--closed_shYiW{bottom:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-details-market-group": `sport-event-details-market-group_Qf0Ab`,
	"sport-event-details-market-group__wrapper": `sport-event-details-market-group__wrapper_RY2h_`,
	"sport-event-details-market-group__header": `sport-event-details-market-group__header_jLziG`,
	"sport-event-details-market-group__header--closed": `sport-event-details-market-group__header--closed_fHCUN`,
	"sport-event-details-market-group__content": `sport-event-details-market-group__content_pwQym`,
	"sport-event-details-market-group__market": `sport-event-details-market-group__market_kQFnS`,
	"sport-event-details-market-group__market--hidden": `sport-event-details-market-group__market--hidden_j6sjV`,
	"sport-event-details-market-group__indicator": `sport-event-details-market-group__indicator_aJy_U`,
	"market-group-title": `market-group-title_Zqwjt`,
	"market-group-title--closed": `market-group-title--closed_VjMGE`,
	"market-group-title__label": `market-group-title__label_umhdj`,
	"market-group-title__zero-margin": `market-group-title__zero-margin_m_ByQ`,
	"market-group-title__zero-margin--closed": `market-group-title__zero-margin--closed_shYiW`
};
export default ___CSS_LOADER_EXPORT___;
