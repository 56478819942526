import { toRef } from 'vue';
import useCustomerDataStore from '../store/useCustomerDataStore';
export default function usePriceChangePolicy() {
    const customerDataStore = useCustomerDataStore();
    const priceChangePolicy = toRef(customerDataStore, 'priceChangePolicy');
    const totalHandicapPriceChangePolicy = toRef(customerDataStore, 'totalHandicapPriceChangePolicy');
    const { savePriceChangePolicy } = customerDataStore;
    return {
        priceChangePolicy,
        totalHandicapPriceChangePolicy,
        savePriceChangePolicy
    };
}
