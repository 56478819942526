// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-loyalty-program-card_CiOdn{display:flex;flex-direction:column;align-items:center;justify-content:center;width:124px;height:192px;padding:0 8px;background-color:var(--Layer1);border-radius:5px}.casino-loyalty-program-card--size-116_Ln9Uc{width:116px}.casino-loyalty-program-card__title_KhvPo{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;max-width:100%;margin:0 0 4px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.casino-loyalty-program-card__icon_uH65t{margin-bottom:8px;color:var(--TextDefault)}@media(hover:hover)and (pointer:fine){.casino-loyalty-program-card_CiOdn:hover{cursor:pointer;background-color:var(--Highlight)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-loyalty-program-card": `casino-loyalty-program-card_CiOdn`,
	"casino-loyalty-program-card--size-116": `casino-loyalty-program-card--size-116_Ln9Uc`,
	"casino-loyalty-program-card__title": `casino-loyalty-program-card__title_KhvPo`,
	"casino-loyalty-program-card__icon": `casino-loyalty-program-card__icon_uH65t`
};
export default ___CSS_LOADER_EXPORT___;
