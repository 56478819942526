import { computed, ref, watch } from 'vue';
import { isObject } from '@leon-hub/guards';
import { isVCaptchaProperties } from 'web/src/components/Input/guards/isVCaptchaProperties';
import { isFormWrapperWidgetPropertiesByType } from '../../../guards/isFormWrapperWidgetProperties';
import { FormControlType } from '../../../enums';
import getWidgetTypeList from './utils/getWidgetTypeList';
import { getSubmitButtonProperties, getUiSchemaField, hasSubmitButton } from '../../../utils/uiSchema';
import mergeErrors from '../../../utils/mergeErrors';
import getFormWidgetSchemaProperties from './utils/getFormWidgetSchemaProperties';
import getFormWrapperWidgetProperties from './utils/getFormWrapperWidgetProperties';
export default function useFormFields(param) {
    let { schema, uiSchema, isFormReady, isPending, externalErrors, schemaErrors, formData } = param;
    const regularWidgetList = ref([]);
    const captchaWidgetList = ref([]);
    const updateWidgetLists = ()=>{
        const { widgetTypeList, captchaWidgets } = getWidgetTypeList(uiSchema.value);
        regularWidgetList.value = widgetTypeList;
        captchaWidgetList.value = captchaWidgets;
    };
    watch(uiSchema, updateWidgetLists, {
        immediate: true
    });
    const fields = computed(()=>{
        const errors = mergeErrors(externalErrors.value, schemaErrors.value);
        return regularWidgetList.value.map((param)=>{
            let { type, field } = param;
            const fieldUiSchema = getUiSchemaField(uiSchema.value, field);
            const widgetProps = getFormWrapperWidgetProperties({
                name: field,
                fieldUiSchema,
                value: formData.value[field],
                error: errors[field],
                extraProperties: getFormWidgetSchemaProperties(schema.value.properties[field], fieldUiSchema, formData.value)
            });
            const fieldProps = {
                hidden: fieldUiSchema.hidden ?? fieldUiSchema.widget === FormControlType.Hidden,
                shortWidth: fieldUiSchema.shortWidth
            };
            return {
                widget: type,
                widgetProps,
                id: field,
                fieldProps
            };
        });
    });
    const captchaProps = computed(()=>{
        const result = [];
        if (!captchaWidgetList.value.length) return [];
        for (const { field, type } of captchaWidgetList.value){
            const fieldUiSchema = getUiSchemaField(uiSchema.value, field);
            const widgetProps = getFormWrapperWidgetProperties({
                name: field,
                fieldUiSchema,
                value: formData.value[field],
                error: '',
                extraProperties: {}
            });
            type === FormControlType.Captcha && isFormWrapperWidgetPropertiesByType(widgetProps, isVCaptchaProperties) || type === FormControlType.CaptchaV3 && isObject(widgetProps);
            if (type === FormControlType.Captcha && isFormWrapperWidgetPropertiesByType(widgetProps, isVCaptchaProperties) || type === FormControlType.CaptchaV3) result.push(widgetProps);
        }
        return result;
    });
    const buttonProps = computed(()=>{
        if (!hasSubmitButton(uiSchema.value)) return null;
        const properties = getSubmitButtonProperties(uiSchema.value);
        return {
            ...properties,
            isLoading: isPending.value,
            disabled: !isFormReady.value
        };
    });
    return {
        fields,
        buttonProps,
        captchaProps,
        captchaWidgetList
    };
}
