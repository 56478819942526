// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fast-games-page_ouDHb{position:relative;display:flex;flex-direction:column;padding-bottom:env(safe-area-inset-bottom)}.fast-games-page__loader_ZHvNj{position:absolute;top:0;right:0;bottom:0;left:0;display:flex;align-items:center;justify-content:center;background:var(--Layer0)}.fast-games-page__loader-image_wLz3r{width:80%;max-width:300px;max-height:80%}.fast-games-page__iframe_lFBQL{flex:1}html.app__layout--desktop .fast-games-page_ouDHb{min-height:calc(100vh - 60px)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fast-games-page": `fast-games-page_ouDHb`,
	"fast-games-page__loader": `fast-games-page__loader_ZHvNj`,
	"fast-games-page__loader-image": `fast-games-page__loader-image_wLz3r`,
	"fast-games-page__iframe": `fast-games-page__iframe_lFBQL`
};
export default ___CSS_LOADER_EXPORT___;
