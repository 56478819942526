import { ref } from 'vue';
export default function useVScrollbar() {
    const element = ref();
    function scrollTop() {
        element.value?.scrollTop();
    }
    function scrollDown() {
        element.value?.scrollDown();
    }
    function scrollTo(top) {
        element.value?.scrollTo(top);
    }
    function scrollRight() {
        element.value?.scrollRight();
    }
    async function recompose() {
        await element.value?.recompose();
    }
    function scrollToElement(target, options) {
        if (target) {
            const targetElement = target instanceof HTMLElement ? target : target.$el;
            if (targetElement) element.value?.scrollToElement(targetElement, options);
        }
    }
    function scrollUpdate() {
        if (!process.env.VUE_APP_OS_IOS && !process.env.VUE_APP_OS_ANDROID) element.value?.scrollUpdate?.();
    }
    return {
        element,
        scrollTop,
        scrollDown,
        scrollTo,
        scrollRight,
        scrollToElement,
        scrollUpdate,
        recompose
    };
}
