// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar-phone_tDmuD{overflow:auto;-webkit-overflow-scrolling:touch}.scrollbar-phone--flex-fill_dDSb6{display:flex;flex:1;flex-direction:column}.scrollbar-phone--dropdown__NuVQ{max-height:296px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollbar-phone": `scrollbar-phone_tDmuD`,
	"scrollbar-phone--flex-fill": `scrollbar-phone--flex-fill_dDSb6`,
	"scrollbar-phone--dropdown": `scrollbar-phone--dropdown__NuVQ`
};
export default ___CSS_LOADER_EXPORT___;
