// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-list_mL5Pm{display:flex;flex-direction:column;gap:0}.payments-list__category-title_htey9{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;align-items:center;padding:16px 0 12px 8px;color:var(--TextSecondary)}.payments-list-item_xxc6R+.payments-list__category-title_htey9{margin-top:-4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-list": `payments-list_mL5Pm`,
	"payments-list__category-title": `payments-list__category-title_htey9`,
	"payments-list-item": `payments-list-item_xxc6R`
};
export default ___CSS_LOADER_EXPORT___;
