import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { CasinoLoyaltyProgramIconSizes } from 'web/src/modules/casino-loyalty-program/store/types';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import VLogoLoader from 'web/src/components/Loader/VLogoLoader/VLogoLoader.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CasinoLoyaltyProgramIcon',
    props: {
        imageSrc: {},
        size: {
            default: CasinoLoyaltyProgramIconSizes.DEFAULT
        },
        isInactive: {
            type: Boolean
        },
        isAvatar: {
            type: Boolean
        },
        gradientBg: {
            type: Boolean,
            default: false
        },
        image: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['casino-loyalty-program-icon']]: true,
                    [_ctx.$style[`casino-loyalty-program-icon--${_ctx.size}`]]: _ctx.size !== _unref(CasinoLoyaltyProgramIconSizes).DEFAULT,
                    [_ctx.$style['casino-loyalty-program-icon--inactive']]: _ctx.isInactive
                })
            }, [
                _ctx.image?.src ? (_openBlock(), _createBlock(VImage, {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-icon__img']),
                    src: _ctx.image.src,
                    x1: _ctx.image?.x1,
                    x2: _ctx.image?.x2,
                    x3: _ctx.image?.x3,
                    x1webp: _ctx.image?.x1webp,
                    x2webp: _ctx.image?.x2webp,
                    x3webp: _ctx.image?.x3webp,
                    "is-lazy": "",
                    "show-loader": "",
                    "transparent-loader": "",
                    "is-gradient": _ctx.gradientBg
                }, null, 8, [
                    "class",
                    "src",
                    "x1",
                    "x2",
                    "x3",
                    "x1webp",
                    "x2webp",
                    "x3webp",
                    "is-gradient"
                ])) : _ctx.imageSrc ? (_openBlock(), _createBlock(VImage, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-icon__img']),
                    src: _ctx.imageSrc,
                    "is-lazy": "",
                    "show-loader": "",
                    "transparent-loader": "",
                    "is-gradient": _ctx.gradientBg,
                    "object-fit": _ctx.isAvatar ? _unref(ObjectFitOption).COVER : void 0
                }, null, 8, [
                    "class",
                    "src",
                    "is-gradient",
                    "object-fit"
                ])) : _createCommentVNode("", true),
                _createVNode(VLogoLoader, {
                    class: _normalizeClass(_ctx.$style['casino-loyalty-program-icon__placeholder']),
                    "is-gradient": _ctx.gradientBg,
                    "is-animation-disabled": ""
                }, null, 8, [
                    "class",
                    "is-gradient"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'CasinoLoyaltyProgramIcon'
                ]
            ]);
        };
    }
});
