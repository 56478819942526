// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jumbotron_Mqzue{display:flex;flex-direction:column;align-items:center;margin-right:auto;margin-left:auto;color:var(--TextSecondary);text-align:center}.jumbotron__icon_CghON{fill:currentcolor}.jumbotron__icon--success_Gscix{fill:var(--BrandDefault)}.jumbotron__image_y5148{width:60px;height:60px;padding:0;margin-bottom:8px}.jumbotron__image--size__default_l7zmb{width:95px;height:60px}.jumbotron__image-inner_uS_EM{width:100%;height:100%}.jumbotron__icon-container_HYs4r{display:flex;align-items:center;justify-content:center;min-width:60px;min-height:60px;margin-bottom:8px}.jumbotron__icon-container_HYs4r+.jumbotron__footer_JCMjX{margin-top:12px}.jumbotron__heading_x800G{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal;width:100%;margin:0 0 8px;color:var(--TextDefault);text-align:center}.jumbotron__heading_x800G:last-child{margin-bottom:0}.jumbotron__heading_x800G+.jumbotron__footer_JCMjX{margin-top:12px}.jumbotron__text_c3PYK{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;width:100%;margin:0 0 8px;color:var(--TextPrimary);text-align:center}.jumbotron__text_c3PYK:last-child{margin-bottom:0}.jumbotron__text_c3PYK+.jumbotron__footer_JCMjX{margin-top:12px}.jumbotron__content_KNs7A{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;width:100%;color:var(--TextPrimary);text-align:center}.jumbotron__footer_JCMjX{width:100%;margin-top:20px;text-align:center}.jumbotron__footer--phone-modal_IBV92{max-width:376px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"jumbotron": `jumbotron_Mqzue`,
	"jumbotron__icon": `jumbotron__icon_CghON`,
	"jumbotron__icon--success": `jumbotron__icon--success_Gscix`,
	"jumbotron__image": `jumbotron__image_y5148`,
	"jumbotron__image--size__default": `jumbotron__image--size__default_l7zmb`,
	"jumbotron__image-inner": `jumbotron__image-inner_uS_EM`,
	"jumbotron__icon-container": `jumbotron__icon-container_HYs4r`,
	"jumbotron__footer": `jumbotron__footer_JCMjX`,
	"jumbotron__heading": `jumbotron__heading_x800G`,
	"jumbotron__text": `jumbotron__text_c3PYK`,
	"jumbotron__content": `jumbotron__content_KNs7A`,
	"jumbotron__footer--phone-modal": `jumbotron__footer--phone-modal_IBV92`
};
export default ___CSS_LOADER_EXPORT___;
