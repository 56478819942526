import getTrackingSlipEntries from './getTrackingSlipEntries';
import logPlaceBetResultSuccess from './logPlaceBetResultSuccess';
import betPlaceSuccessYMTrigger from './betPlaceSuccessYMTrigger';
export default function makeBetResultLog(getTrackingSlipEntriesPayload, additionalTrackData, param) {
    let { theme, analyticsService, useDefaultBet, amountBet, currentValue } = param;
    const trackingPayload = {
        slipEntries: getTrackingSlipEntries(getTrackingSlipEntriesPayload),
        ...additionalTrackData
    };
    logPlaceBetResultSuccess(trackingPayload);
    betPlaceSuccessYMTrigger(trackingPayload, {
        theme,
        analyticsService,
        useDefaultBet,
        amountBet,
        currentValue
    });
}
