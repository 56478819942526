// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.captcha_h7WIp{padding-top:5px}.captcha--expanded_fXm4n{position:absolute;top:0;right:0;bottom:0;left:0;z-index:6;padding-top:0;background-color:#37373780}.captcha__hint_BTIUE{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;display:block;width:100%;padding-top:4px;color:var(--ErrorDefault);transition:opacity .15s ease-in-out}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"captcha": `captcha_h7WIp`,
	"captcha--expanded": `captcha--expanded_fXm4n`,
	"captcha__hint": `captcha__hint_BTIUE`
};
export default ___CSS_LOADER_EXPORT___;
