// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-social-link_nbs0H{display:flex;align-items:center;justify-content:center;width:var(--landingSocialLinkSize);height:var(--landingSocialLinkSize);background:var(--landingSocialLinkBg);border-radius:50%;fill:var(--landingSocialLinkBgIconColor)}.landing-social-link__icon_btzUm{width:var(--landingSocialLinkIconSize)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-social-link": `landing-social-link_nbs0H`,
	"landing-social-link__icon": `landing-social-link__icon_btzUm`
};
export default ___CSS_LOADER_EXPORT___;
