import { defineComponent as _defineComponent } from 'vue';
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onMounted } from 'vue';
import { useRecaptchaV3 } from '../../composables/useRecaptchaV3';
export default /*@__PURE__*/ _defineComponent({
    inheritAttrs: false,
    __name: 'VRecaptchaV3',
    props: {
        action: {}
    },
    emits: [
        "verify"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { getToken } = useRecaptchaV3();
        const doVerification = async ()=>{
            const token = await getToken(props.action);
            if (token) emit('verify', token);
        };
        onMounted(doVerification);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, null, 512)), [
                [
                    _directive_auto_id,
                    'VRecaptchaV3'
                ],
                [
                    _directive_data_test,
                    {
                        el: 'VRecaptchaV3'
                    }
                ]
            ]);
        };
    }
});
