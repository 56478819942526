// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-details-item_HPGlI{padding:4px 8px 8px;background-color:var(--Layer1)}.sport-event-details-item__runner-holder_kvp97{display:flex;height:auto}.sport-event-details-item__runner_qMJwC{margin:0}.sport-event-details-item__dynamic-columns_oAi6k{display:flex;flex-flow:row wrap;justify-content:space-between}.sport-event-details-item__dynamic-columns_oAi6k:after{flex:auto;content:""}.sport-event-details-item__dynamic-column_IcyaR{flex-basis:100%;max-width:100%}.sport-event-details-item__static-column_i6Pqo:last-child{border:0}.sport-event-details-item__grid_a0ISw{display:grid;grid-column-gap:4px;margin-top:4px}.sport-event-details-item__grid_a0ISw:last-child{margin-bottom:0}.sport-event-details-item__grid--column-1_PD5Bd{grid-template-columns:1fr}.sport-event-details-item__grid--column-2_dxbsB{grid-template-columns:repeat(2,1fr)}.sport-event-details-item__grid--column-3_OYy_w{grid-template-columns:repeat(3,1fr)}html.app__layout--desktop .sport-event-details-item_HPGlI{align-self:flex-start;margin:0 0 8px;overflow:hidden;border-radius:5px;page-break-inside:avoid;break-inside:avoid}html.app__layout--desktop .sport-event-details-item__runner_qMJwC{margin:0}html.app__layout--desktop .sport-event-details-item__grid_a0ISw{background-color:#0000}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-details-item": `sport-event-details-item_HPGlI`,
	"sport-event-details-item__runner-holder": `sport-event-details-item__runner-holder_kvp97`,
	"sport-event-details-item__runner": `sport-event-details-item__runner_qMJwC`,
	"sport-event-details-item__dynamic-columns": `sport-event-details-item__dynamic-columns_oAi6k`,
	"sport-event-details-item__dynamic-column": `sport-event-details-item__dynamic-column_IcyaR`,
	"sport-event-details-item__static-column": `sport-event-details-item__static-column_i6Pqo`,
	"sport-event-details-item__grid": `sport-event-details-item__grid_a0ISw`,
	"sport-event-details-item__grid--column-1": `sport-event-details-item__grid--column-1_PD5Bd`,
	"sport-event-details-item__grid--column-2": `sport-event-details-item__grid--column-2_dxbsB`,
	"sport-event-details-item__grid--column-3": `sport-event-details-item__grid--column-3_OYy_w`
};
export default ___CSS_LOADER_EXPORT___;
