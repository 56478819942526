// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .registration-completion_YW4VN{min-height:100px}html.app__layout--desktop .registration-completion-padding_C7kmm{padding:0 32px 32px;margin-top:16px}html.app__layout--desktop .registration-completion-aso_vKKnB{padding:0 16px 32px}html.app__layout--desktop .registration-completion__go-home_yTRW0{width:100%;margin-top:20px}html.app__layout--desktop .registration-completion__hint-block_kSMvx{margin-top:20px;text-align:left}html.app__layout--desktop .registration-completion__description_Vim2v{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:16px;color:var(--TextPrimary);text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"registration-completion": `registration-completion_YW4VN`,
	"registration-completion-padding": `registration-completion-padding_C7kmm`,
	"registration-completion-aso": `registration-completion-aso_vKKnB`,
	"registration-completion__go-home": `registration-completion__go-home_yTRW0`,
	"registration-completion__hint-block": `registration-completion__hint-block_kSMvx`,
	"registration-completion__description": `registration-completion__description_Vim2v`
};
export default ___CSS_LOADER_EXPORT___;
