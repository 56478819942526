// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-list-route-component__inner_tyc8W{position:relative}.history-list-route-component__container_tEEf0{padding:0;overflow:hidden;border-radius:5px}.history-list-route-component__table_FTbUr{display:table;width:100%;overflow:hidden}.history-list-route-component__table_FTbUr,.history-list-route-component__table_FTbUr>tbody,.history-list-route-component__table_FTbUr>thead{border-spacing:0;border-collapse:initial}.history-list-route-component__list_mAZtV{margin:0 8px 8px}html.app__layout--desktop .history-list-route-component__list_mAZtV{margin:0 24px 14px}.history-list-route-component__filter-select_yTPR1{z-index:11;position:sticky;top:0;background-color:var(--Layer0)}.history-list-route-component__filter-select_yTPR1:not(:empty){margin-bottom:8px;margin-left:4px}html.app__layout--desktop .history-list-route-component__filter-select_yTPR1:not(:empty){margin-right:16px;margin-left:16px}.history-list-route-component__list-empty_PcbKx{padding:8px 16px 16px}.history-list-route-component__loader_TnSjT{margin:10px auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-list-route-component__inner": `history-list-route-component__inner_tyc8W`,
	"history-list-route-component__container": `history-list-route-component__container_tEEf0`,
	"history-list-route-component__table": `history-list-route-component__table_FTbUr`,
	"history-list-route-component__list": `history-list-route-component__list_mAZtV`,
	"history-list-route-component__filter-select": `history-list-route-component__filter-select_yTPR1`,
	"history-list-route-component__list-empty": `history-list-route-component__list-empty_PcbKx`,
	"history-list-route-component__loader": `history-list-route-component__loader_TnSjT`
};
export default ___CSS_LOADER_EXPORT___;
