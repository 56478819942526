import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    key: 1
};
import { SlottIconName } from '@leon-hub/icons';
import VSmsNumberCheck from 'web/src/components/SmsNumberCheck/VSmsNumberCheck/VSmsNumberCheck.vue';
import VCmsContent from 'web/src/modules/cms/components/VCmsContent/VCmsContent.vue';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import VHintBlock from 'web/src/components/HintBlock/VHintBlock/VHintBlock.vue';
import useRestorePasswordCheckPhoneForm from 'web/src/modules/restore-password/components/RestorePasswordCheckPhoneForm/composables/useRestorePasswordCheckPhoneForm';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'RestorePasswordCheckPhoneForm',
    props: {
        isPin: {
            type: Boolean
        },
        isInProfile: {
            type: Boolean
        }
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { phoneAttemptsLimit, isMaxAttempts, isShowHintBlock, iconProperties, closeButtonProperties, cmsKey, handleRestoreButton, smsNumberCheckProperties, checkCode, sendSmsOrCallToPhone, setRetrySmsCodeTime, changeShowHintBlock, onInput, onBlur, goToRestorePasswordMainPage, goToPhoneCheckBySmsStage } = useRestorePasswordCheckPhoneForm(props, emits);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['restore-password'])
            }, [
                _unref(isMaxAttempts) ? (_openBlock(), _createBlock(_unref(VJumbotron), _mergeProps({
                    key: 0
                }, _unref(iconProperties), {
                    heading: _ctx.$t('WEB2_RESTORE_BYSMS_LIMIT', {
                        limit: String(_unref(phoneAttemptsLimit))
                    })
                }), {
                    footer: _withCtx(()=>[
                            _createElementVNode("div", null, [
                                _createVNode(_unref(JumbotronFooterButton), _mergeProps(_unref(closeButtonProperties), {
                                    label: _ctx.$t('WEB2_RESTORE_PASSWORD_CHECK_SMS_CLOSE'),
                                    onClick: _unref(handleRestoreButton)
                                }), null, 16, [
                                    "label",
                                    "onClick"
                                ])
                            ])
                        ]),
                    default: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_RESTORE_PASSWORD_CHECK_SMS_LIMIT_DESCRIPTION')) + " ", 1)
                        ]),
                    _: 1
                }, 16, [
                    "heading"
                ])) : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(VSmsNumberCheck, _mergeProps(_unref(smsNumberCheckProperties), {
                        onSubmit: _unref(checkCode),
                        onRetryButtonClick: _unref(sendSmsOrCallToPhone),
                        onSetRetrySmsCodeTime: _unref(setRetrySmsCodeTime),
                        onShowHintWithEmailRedirect: _unref(changeShowHintBlock),
                        onInput: _unref(onInput),
                        onBlur: _unref(onBlur)
                    }), null, 16, [
                        "onSubmit",
                        "onRetryButtonClick",
                        "onSetRetrySmsCodeTime",
                        "onShowHintWithEmailRedirect",
                        "onInput",
                        "onBlur"
                    ]),
                    _unref(isShowHintBlock) ? _withDirectives((_openBlock(), _createBlock(VHintBlock, {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['restore-password__hint-block'])
                    }, {
                        default: _withCtx(()=>[
                                _createVNode(VCmsContent, {
                                    "cms-key": _unref(cmsKey),
                                    silent: "",
                                    "no-padding": "",
                                    class: _normalizeClass(_ctx.$style['restore-password__hint-block-content']),
                                    onRestorePasswordPhoneCheckGoToEmail: _unref(goToRestorePasswordMainPage),
                                    onRestorePasswordPhoneCheckGoToPhoneCheckBySms: _unref(goToPhoneCheckBySmsStage)
                                }, null, 8, [
                                    "cms-key",
                                    "class",
                                    "onRestorePasswordPhoneCheckGoToEmail",
                                    "onRestorePasswordPhoneCheckGoToPhoneCheckBySms"
                                ])
                            ]),
                        _: 1
                    }, 8, [
                        "class"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'restore-password-phone-check-hint-block'
                            }
                        ]
                    ]) : _createCommentVNode("", true)
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'RestorePasswordCheckPhoneForm'
                ]
            ]);
        };
    }
});
