import { defineStore } from 'pinia';
import { nextTick, ref } from 'vue';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
const useAuthStore = defineStore('auth', ()=>{
    const { isLoggedIn } = useIsLoggedIn();
    const isTemporarySession = ref(false);
    const isLoadedFromRoot = ref(false);
    function setLoggedIn(value) {
        let fromRoot = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        isLoggedIn.value = value;
        nextTick().then(()=>{
            if (fromRoot) isLoadedFromRoot.value = true;
        });
    }
    function setIsTemporarySession(value) {
        isTemporarySession.value = value;
    }
    function forgotSession() {
        isLoggedIn.value = false;
    }
    return {
        isLoggedIn,
        isLoadedFromRoot,
        isTemporarySession,
        setLoggedIn,
        forgotSession,
        setIsTemporarySession
    };
});
export default useAuthStore;
