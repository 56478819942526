import { BetlineFlag, BetlineType } from 'web/src/modules/sportline/enums/rest';
import { SportlineRestErrorCode } from 'web/src/modules/sportline/errors/rest';
import { getEvent } from 'web/src/modules/sportline/utils/api';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
export function useSportlineApiLoadEventDetails(props) {
    const { tryRequestWithCTag, defaultRestApiFlags, isLiveOutrightsEnabled } = props;
    const errorConverter = useErrorsConverter();
    async function getEventDetails(param) {
        let { id, silent } = param;
        try {
            // eslint-disable-next-line no-console
            return await tryRequestWithCTag((ctag)=>getEvent({
                    eventId: id,
                    betline: BetlineType.All,
                    ctag,
                    silent,
                    flags: [
                        ...defaultRestApiFlags.value,
                        BetlineFlag.WithSportMarketGroupsV2,
                        BetlineFlag.OutrightExtendedMarketLayout
                    ],
                    LMCE: isLiveOutrightsEnabled.value
                }));
        } catch (rawError) {
            const error = errorConverter.convertToBaseError(rawError);
            if (error.code.equals(SportlineRestErrorCode.MISSING_EVENT)) return null;
            throw rawError;
        }
    }
    return {
        getEventDetails
    };
}
