export default function getSlipEntryFromCombinedInfo(param) {
    let { ts, event, market, runner, odds, banker, marketStatus, betline, zeroMargin, competitors, runnerTags, marketTypeIdentifier, marketTypeTag } = param;
    return {
        ts,
        event,
        market,
        runner,
        odds,
        banker,
        marketStatus,
        betline,
        zeroMargin,
        competitors,
        runnerTags,
        marketTypeIdentifier,
        marketTypeTag
    };
}
