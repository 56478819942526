// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsible-gambling-form__wrapper_tKYKO{display:flex;column-gap:12px;align-items:flex-start;width:100%}.responsible-gambling-form__form_GyFgb{flex-grow:1;max-width:calc(100% - 140px)}.responsible-gambling-form__time_NmsyL{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:var(--TextPrimary)}.responsible-gambling-form__time-accent_yHcov{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.responsible-gambling-form__button_pphO8{display:inline-flex;align-items:center;min-width:128px;max-width:128px;height:44px;margin-top:18px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"responsible-gambling-form__wrapper": `responsible-gambling-form__wrapper_tKYKO`,
	"responsible-gambling-form__form": `responsible-gambling-form__form_GyFgb`,
	"responsible-gambling-form__time": `responsible-gambling-form__time_NmsyL`,
	"responsible-gambling-form__time-accent": `responsible-gambling-form__time-accent_yHcov`,
	"responsible-gambling-form__button": `responsible-gambling-form__button_pphO8`
};
export default ___CSS_LOADER_EXPORT___;
