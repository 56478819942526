// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fast-games-lobby__title__h8sg{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;margin:16px 0 0;color:var(--TextDefault)}.fast-games-lobby__title--skeleton_vlBzS{position:relative;display:flex;align-items:center;width:160px;max-width:100%;height:28px}.fast-games-lobby__title--skeleton_vlBzS:before{width:100%;height:13px;content:"";background-color:var(--TextDefault);border-radius:20px;opacity:.2}.fast-games-lobby__lobby_iNdqQ{margin-bottom:4px}.fast-games-lobby__desc_Be0lX{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-top:10px;margin-bottom:64px;color:var(--TextPrimary)}.content-min-max_lDyja{width:100%;min-width:1024px;max-width:1440px;padding:0 0 0 16px;margin:0 auto}.content-min-max_lDyja html.app__os--macos{padding-right:16px}.content-min-max_lDyja html.app__os--macos.app__browser--firefox{padding-right:0}.content-min-max_lDyja html.app__layout--tablet{padding-right:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fast-games-lobby__title": `fast-games-lobby__title__h8sg`,
	"fast-games-lobby__title--skeleton": `fast-games-lobby__title--skeleton_vlBzS`,
	"fast-games-lobby__lobby": `fast-games-lobby__lobby_iNdqQ`,
	"fast-games-lobby__desc": `fast-games-lobby__desc_Be0lX`,
	"content-min-max": `content-min-max_lDyja`
};
export default ___CSS_LOADER_EXPORT___;
