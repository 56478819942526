export function getFilterIdForMarketGroup(group, specifier) {
    return `${group.id}|${specifier?.id ?? ''}=${specifier?.value ?? ''}`;
}
export function getFilterForMarketGroup(group, specifier) {
    const { name, style, marketTypeIds } = group;
    const result = {
        id: getFilterIdForMarketGroup(group, specifier),
        name,
        marketTypeIds
    };
    if (style) result.style = style;
    if (specifier) {
        result.specifier = specifier;
        result.name = name.replace(new RegExp(`{${specifier.id}}`, 'g'), String(specifier.value ?? ''));
    }
    return result;
}
