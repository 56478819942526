import { toRef, computed, nextTick } from 'vue';
import { useDropdownButton } from '@components/core-dropdown';
import setDropdownSelectRefs from '../utils/setDropdownSelectRefs';
import useDropdownSearchInput from './useDropdownSearchInput';
import useDropdownKeydownAndFocusIndex from './useDropdownKeydownAndFocusIndex';
import useDropdownList from './useDropdownList';
import useDropdownEvents from './useDropdownEvents';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useDropdownSelect(props, emits) {
    const { isDropdownShown, closeDropdown, openDropdown, focusOnDropdownButton, setDropdownButton, dropdownButton, dropdownProps } = useDropdownButton(props, {
        ignoreScroll: props.ignoreScroll
    });
    const { dropdownSearchInput, searchQuery, handleSearchInput, clearSearchQuery, focusOnDropdownInput } = useDropdownSearchInput();
    const { optionsToRender, allPossibleOptions, renderMoreItems, resetPagination, setPaginationToReachIndex } = useDropdownList({
        enableSearch: toRef(props, 'enableSearch'),
        hideSelectedOption: toRef(props, 'hideSelectedOption'),
        options: toRef(props, 'options'),
        selectedValue: toRef(props, 'selectedValue'),
        itemsPerPage: toRef(props, 'itemsPerPage'),
        searchQuery
    });
    const close = async ()=>{
        await closeDropdown();
        resetPagination();
        emits('close');
    };
    const open = async ()=>{
        if (isDropdownShown.value) return;
        clearSearchQuery();
        openDropdown();
        emits('open');
        if (props.enableSearch) {
            await nextTick();
            focusOnDropdownInput();
        }
    };
    const toggleOpen = ()=>isDropdownShown.value ? close() : open();
    const { onBlur, onChange, onFocus } = useDropdownEvents(props, emits, close, clearSearchQuery);
    const { hiddenSubmit, handleKeyDown, preselectedListIndex, setPreselectedIndex, setHiddenSubmit } = useDropdownKeydownAndFocusIndex({
        openDropdown: open,
        closeDropdown: close,
        onOptionSelect: onChange,
        selectedValue: toRef(props, 'selectedValue'),
        options: allPossibleOptions,
        disableKeySearch: toRef(props, 'enableSearch'),
        isDropdownShown,
        searchQuery,
        setPaginationToReachIndex
    });
    const onButtonBlur = ()=>{
        if (!dropdownSearchInput.value) onBlur();
    };
    const isSearchActive = computed(()=>!!(isDropdownShown.value && props.enableSearch));
    const setRefs = (templateRef)=>{
        setDropdownSelectRefs(templateRef, setDropdownButton, setHiddenSubmit);
    };
    return {
        isDropdownShown,
        onFocus,
        onChange,
        onBlur,
        onButtonBlur,
        close,
        toggleOpen,
        focusOnDropdownButton,
        dropdownProps,
        dropdownButton,
        optionsToRender,
        hiddenSubmit,
        handleKeyDown,
        isSearchActive,
        dropdownSearchInput,
        searchQuery,
        handleSearchInput,
        clearSearchQuery,
        preselectedListIndex,
        setPreselectedIndex,
        renderMoreItems,
        setRefs
    };
}
