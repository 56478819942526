import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ref, toRefs } from 'vue';
import MarketsTabs from 'web/src/modules/sportline/views/markets-list/components/MarketsTabs.vue';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import useMarketsListToolbar from './composables/useMarketsListToolbar';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketsListToolbar',
    props: {
        hasStatistic: {
            type: Boolean
        },
        doShowInlineStatisticSwitch: {
            type: Boolean
        },
        doShowAllMarketsTab: {
            type: Boolean
        },
        isStatisticSelected: {
            type: Boolean
        },
        gridFilters: {},
        activeMarketGroupId: {}
    },
    emits: [
        "market-tab-click",
        "change-statistic-selected"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const tabsRef = ref(null);
        const { isStatisticSelected } = toRefs(props);
        const { slideToActiveTab, onMarketTabClick, onChangeStatisticSelected } = useMarketsListToolbar({
            isStatisticSelected,
            tabsRef
        }, emit);
        __expose({
            slideToActiveTab
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['sport-event-details__controls'])
            }, [
                _createVNode(MarketsTabs, {
                    ref: "tabs",
                    "grid-filters": _ctx.gridFilters,
                    "active-tab-id": _ctx.activeMarketGroupId,
                    "has-statistic": _ctx.hasStatistic && !_ctx.doShowInlineStatisticSwitch,
                    "has-all-tab": _ctx.doShowAllMarketsTab,
                    onTabClick: _unref(onMarketTabClick)
                }, null, 8, [
                    "grid-filters",
                    "active-tab-id",
                    "has-statistic",
                    "has-all-tab",
                    "onTabClick"
                ]),
                _ctx.hasStatistic && _ctx.doShowInlineStatisticSwitch ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['sport-event-details__switch-block'])
                }, [
                    _createVNode(VSwitch, {
                        checked: _unref(isStatisticSelected),
                        label: _ctx.$t('WEB2_MATCH_STATISTIC'),
                        class: _normalizeClass(_ctx.$style['sport-event-details__switch']),
                        onChange: _unref(onChangeStatisticSelected)
                    }, null, 8, [
                        "checked",
                        "label",
                        "class",
                        "onChange"
                    ])
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'MarketsListToolbar'
                ]
            ]);
        };
    }
});
