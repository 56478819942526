// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-list-menu_wJxiJ{padding:2px;position:relative;overflow:hidden}.drop-list-menu__background_QLLXa,.drop-list-menu__footer_mikDE,.drop-list-menu__search_mYhsH{background:var(--Layer1)}.drop-list-menu__background_QLLXa{display:flex;flex-direction:column;overflow:hidden}.drop-list-menu__search_mYhsH{flex:none;padding:8px}.drop-list-menu__footer_mikDE{display:flex;flex:none;padding:8px;overflow:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drop-list-menu": `drop-list-menu_wJxiJ`,
	"drop-list-menu__background": `drop-list-menu__background_QLLXa`,
	"drop-list-menu__footer": `drop-list-menu__footer_mikDE`,
	"drop-list-menu__search": `drop-list-menu__search_mYhsH`
};
export default ___CSS_LOADER_EXPORT___;
