// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leagues-list--top_izzO1{margin-bottom:12px}@media(max-width:699px){.leagues-list--top_izzO1{margin-bottom:0}}.leagues-list__list_w5h8e{background-color:#0000}.leagues-list__list_w5h8e,.leagues-list__list--top_IjaFE{overflow:hidden;border-radius:5px}.leagues-list__title_geCN5{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;display:flex;align-items:center;height:56px;padding-left:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leagues-list--top": `leagues-list--top_izzO1`,
	"leagues-list__list": `leagues-list__list_w5h8e`,
	"leagues-list__list--top": `leagues-list__list--top_IjaFE`,
	"leagues-list__title": `leagues-list__title_geCN5`
};
export default ___CSS_LOADER_EXPORT___;
