// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leader-board__header_apI8W{display:block;height:36px;margin-bottom:2px;background-color:var(--Layer1);border-radius:5px 5px 0 0}html.app__layout--desktop .leader-board__header_apI8W{height:40px}.leader-board__row_leEeR{display:grid;grid-template-columns:36px 1fr 1fr minmax(62px,90px);grid-column-gap:8px;padding:12px;border:0}html.app__layout--desktop .leader-board__row_leEeR{grid-column-gap:16px;padding:16px}.leader-board__cell_mGwaO{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;display:block;color:var(--TextSecondary);text-align:center}.leader-board__cell--place_k66Mf{text-align:left}.leader-board__cell--score_bHngK{text-align:right}.leader-board__tbody_SVtqL{position:relative;display:block;border-radius:0 0 5px 5px}.leader-board__table_NTLmm{z-index:0;width:100%;border-spacing:0;border-collapse:initial}.leader-board__tfoot_YwMLt{display:block;margin-top:2px}.leader-board__title_KWlua{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;margin:0 16px 12px;color:var(--TextDefault);text-align:center}.leader-board__description_JFiRS{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;display:block;padding:0 20px;margin-bottom:16px;color:var(--TextSecondary);text-align:center}.leader-board__loader_fXlsx{border-color:maroon}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leader-board__header": `leader-board__header_apI8W`,
	"leader-board__row": `leader-board__row_leEeR`,
	"leader-board__cell": `leader-board__cell_mGwaO`,
	"leader-board__cell--place": `leader-board__cell--place_k66Mf`,
	"leader-board__cell--score": `leader-board__cell--score_bHngK`,
	"leader-board__tbody": `leader-board__tbody_SVtqL`,
	"leader-board__table": `leader-board__table_NTLmm`,
	"leader-board__tfoot": `leader-board__tfoot_YwMLt`,
	"leader-board__title": `leader-board__title_KWlua`,
	"leader-board__description": `leader-board__description_JFiRS`,
	"leader-board__loader": `leader-board__loader_fXlsx`
};
export default ___CSS_LOADER_EXPORT___;
