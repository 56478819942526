// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-rating_R4J9P{display:flex;flex-direction:column;gap:16px;width:100%;border-radius:0 20px 20px}.feedback-rating__title-box_YONWs{display:flex}.feedback-rating__title-wrapper_yZRxU{display:flex;flex-direction:column;gap:4px}.feedback-rating__text_IXna8{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:0;color:var(--TextPrimary);word-break:break-word}.feedback-rating__title_cwGka{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.feedback-rating__title--left_qgLZA{text-align:left}.feedback-rating__stars_S6JMW{display:flex;justify-content:center;text-align:center}.feedback-rating__stars--flexible_UrAho{gap:10px}.feedback-rating__star_xTjbo{padding:10px 16px;color:var(--Highlight);cursor:pointer}.feedback-rating__star--active_sYNYA{color:var(--AlertDefault);opacity:1}.feedback-rating__star--not-hovered_bXnsj{opacity:.5}.feedback-rating__star--flexible_wdt0e{padding:0}.feedback-rating__info_ZzOIt{margin-right:8px;vertical-align:top}.feedback-rating--confirm_ubKhZ .feedback-rating__star_xTjbo{cursor:default}.feedback-rating--rounded_xh847{padding:16px;background:var(--OpacityLayer1);border-radius:5px}.feedback-rating--inline_fi3UI{flex-direction:row;gap:0;align-items:center;justify-content:space-between}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedback-rating": `feedback-rating_R4J9P`,
	"feedback-rating__title-box": `feedback-rating__title-box_YONWs`,
	"feedback-rating__title-wrapper": `feedback-rating__title-wrapper_yZRxU`,
	"feedback-rating__text": `feedback-rating__text_IXna8`,
	"feedback-rating__title": `feedback-rating__title_cwGka`,
	"feedback-rating__title--left": `feedback-rating__title--left_qgLZA`,
	"feedback-rating__stars": `feedback-rating__stars_S6JMW`,
	"feedback-rating__stars--flexible": `feedback-rating__stars--flexible_UrAho`,
	"feedback-rating__star": `feedback-rating__star_xTjbo`,
	"feedback-rating__star--active": `feedback-rating__star--active_sYNYA`,
	"feedback-rating__star--not-hovered": `feedback-rating__star--not-hovered_bXnsj`,
	"feedback-rating__star--flexible": `feedback-rating__star--flexible_wdt0e`,
	"feedback-rating__info": `feedback-rating__info_ZzOIt`,
	"feedback-rating--confirm": `feedback-rating--confirm_ubKhZ`,
	"feedback-rating--rounded": `feedback-rating--rounded_xh847`,
	"feedback-rating--inline": `feedback-rating--inline_fi3UI`
};
export default ___CSS_LOADER_EXPORT___;
