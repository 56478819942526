export function getMarketsSpecifiersKey(markets) {
    const values = {};
    for (const market of markets){
        if (!!market.specifiers) for (const specifier of market.specifiers){
            values[specifier.id] = values[specifier.id] ?? new Set([]);
            values[specifier.id].add(specifier.value);
        }
    }
    return Object.keys(values).map((id)=>`${id}=${[
            ...values[id].values()
        ].join(',')}`).join(';');
}
