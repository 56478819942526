import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useVSwiperSlide } from './composables';
export default /*@__PURE__*/ _defineComponent({
    name: 'VSwiperSlide',
    __name: 'VSwiperSlide',
    props: {
        originalSlidesIndex: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { slide, isVisible, isOverflowVisible, setVisibility, getOffsetWidth, getOffsetLeft } = useVSwiperSlide();
        function onClick(event) {
            emit('click', event);
        }
        __expose({
            originalSlidesIndex: props.originalSlidesIndex,
            setVisibility,
            getOffsetWidth,
            getOffsetLeft
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "slide",
                ref: slide,
                class: _normalizeClass({
                    'swiper-slide': true,
                    [_ctx.$style['swiper-slide']]: true,
                    [_ctx.$style['swiper-slide--hidden']]: !process.env.VUE_APP_PRERENDER && !_unref(isVisible)
                }),
                onClick: onClick
            }, [
                _unref(isOverflowVisible) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['swiper-slide__overflow'])
                }, null, 2)) : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'VSwiperSlide'
                ]
            ]);
        };
    }
});
