// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("web/src/assets/logotypes/common/ruFlag.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("web/src/assets/logotypes/common/wallet.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("web/src/assets/logotypes/common/webmoney-small.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-payments-input_yXzLP{display:flex;align-items:center;margin-top:16px;background-color:var(--Layer1);border-radius:5px}.form-payments-input__input_X5Fza{width:100%;height:44px;font-size:13px;color:var(--TextSecondary);background-color:#0000;border:none}.form-payments-input__icon_tpvsH{flex-shrink:0;width:42px;height:32px;padding:6px;margin:0 8px;background-repeat:no-repeat;background-position:50%;background-size:contain;border-radius:4px}.form-payments-input__icon--phone_TRTiH{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.form-payments-input__icon--wallet_D7FI0{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_1___})}.form-payments-input__icon--webmoney_MpXDY{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_2___})}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-payments-input": `form-payments-input_yXzLP`,
	"form-payments-input__input": `form-payments-input__input_X5Fza`,
	"form-payments-input__icon": `form-payments-input__icon_tpvsH`,
	"form-payments-input__icon--phone": `form-payments-input__icon--phone_TRTiH`,
	"form-payments-input__icon--wallet": `form-payments-input__icon--wallet_D7FI0`,
	"form-payments-input__icon--webmoney": `form-payments-input__icon--webmoney_MpXDY`
};
export default ___CSS_LOADER_EXPORT___;
