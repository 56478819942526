import { ref, computed, watch, nextTick } from 'vue';
import { getLocationHref } from '@leon-hub/service-locator-env';
import { PostMessageBus } from '@leon-hub/postmessage-bus';
import { stringifyQuery, Timer } from '@leon-hub/utils';
import { useDebounce } from '@leon-hub/debounce';
import { onComponentActivated, onComponentDeactivated } from '@leon-hub/vue-utils';
import IframePostMessageEvent from 'web/src/components/Iframe/types/IframePostMessageEvent';
import { FramedEnvironmentParameter } from 'web/src/modules/framed-app/enums';
import IFrameTimeoutError from 'web/src/modules/errors/errors/IFrameTimeoutError';
import { IframeMessageEvent } from '../enums';
// @see IframeMessageEvent.WindowIframeLoad
// @see IframeMessageEvent.IframeApplicationReady
export default function useVIframe(props, emit) {
    let lastContentView;
    let postMessageBus = null;
    const loading = ref(true);
    const iframeVisible = ref(false);
    const reCreateIframeKey = ref(0);
    const height = ref(0);
    const { timeout, iframeElement, src, name, additionalQueryStringParameters, isHideFullHeightInUrl, isFullSize, heightAspectRatio, isAutoHeight, isHideTitle } = props;
    let timer = 0;
    const styleObject = computed(()=>heightAspectRatio.value ? {
            'padding-top': `calc(100% / ${heightAspectRatio.value})`
        } : {});
    const title = computed(()=>isHideTitle.value ? void 0 : name.value);
    function onIframeApplicationReady() {
        if (isAutoHeight.value) postMessageBus?.on(IframePostMessageEvent.iframeDocumentResize, (value)=>{
            resizeIframe(value.height);
        });
        emit(IframeMessageEvent.IframeApplicationReady);
    }
    function emitWindowIframeLoad() {
        iframeVisible.value = true;
        emit(IframeMessageEvent.WindowIframeLoad);
    }
    function onLoad() {
        loading.value = false;
        emit('load');
    }
    function createPostMessageBus() {
        if (!iframeElement.value?.contentWindow) return;
        if (lastContentView !== iframeElement.value.contentWindow) {
            iframeElement.value.addEventListener('load', emitWindowIframeLoad);
            lastContentView = iframeElement.value.contentWindow;
        }
        postMessageBus?.dispose();
        postMessageBus = new PostMessageBus({
            target: iframeElement.value.contentWindow,
            targetOrigin: '*',
            initiator: 'VIframe'
        });
        postMessageBus.on(IframePostMessageEvent.iframeApplicationReady, onIframeApplicationReady);
    }
    async function beforeMount() {
        await nextTick();
        createPostMessageBus();
    }
    function resizeIframe(value) {
        height.value = value;
    }
    function beforeUnmount() {
        postMessageBus?.dispose();
        lastContentView = void 0;
    }
    const isLocalSrc = computed(()=>{
        if (src.value.startsWith('/')) return true;
        "1";
        {
            const sourceOrigin = new URL(src.value).origin;
            const appHref = getLocationHref();
            return appHref === sourceOrigin || appHref.startsWith(`${sourceOrigin}/`);
        }
    });
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const parametersDecoratedSrc = computed(()=>{
        const extraQuery = {};
        const hasQueryStart = src.value.includes('?');
        const search = hasQueryStart ? src.value.replace(/^[^?]+\?/, '') : '';
        const hasQuery = search.length > 0;
        if (isLocalSrc.value) {
            if (isAutoHeight.value) extraQuery[FramedEnvironmentParameter.AutoHeight] = '1';
            if (isFullSize.value && !isHideFullHeightInUrl.value) extraQuery[FramedEnvironmentParameter.FullHeight] = '1';
        }
        if (additionalQueryStringParameters.value?.length) for (const item of additionalQueryStringParameters.value){
            const key = Object.keys(item)[0];
            if (item[key]) extraQuery[key] = item[key];
        }
        return Object.keys(extraQuery).length ? `${src.value}${hasQuery ? '&' : '?'}${stringifyQuery(extraQuery)}` : src.value;
    });
    const reCreateIframe = useDebounce(async ()=>{
        reCreateIframeKey.value += 1;
        await nextTick();
        createPostMessageBus();
    }, 20);
    watch(src, reCreateIframe);
    function postMessage(message) {
        let target = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '*';
        iframeElement.value?.contentWindow?.postMessage(message, target);
    }
    function onError(error) {
        stopTimer();
        emit('error', error);
    }
    function stopTimer() {
        if (timer) {
            Timer.clearTimeout(timer);
            timer = 0;
        }
    }
    onComponentActivated(()=>{
        if (timeout.value > 0) timer = Timer.setTimeout(()=>{
            onError(new IFrameTimeoutError());
        }, timeout.value);
    });
    onComponentDeactivated(stopTimer);
    return {
        loading,
        reCreateIframeKey,
        styleObject,
        iframeVisible,
        title,
        height,
        parametersDecoratedSrc,
        onLoad,
        beforeMount,
        beforeUnmount,
        resizeIframe,
        postMessage,
        onError
    };
}
