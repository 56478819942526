// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-widget_oFUNf{filter:drop-shadow(0 8px 16px var(--ModalShadow));display:inline-block}.chat-widget__counter_EmUnK{position:absolute;top:-4px;right:-3px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat-widget": `chat-widget_oFUNf`,
	"chat-widget__counter": `chat-widget__counter_EmUnK`
};
export default ___CSS_LOADER_EXPORT___;
