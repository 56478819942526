// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-description_JQBLi .step-description__circle_L_B03{margin:0 12px 0 0}.step-description_JQBLi .step-description__circle-size-40_VN7OM{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal}.step-description_JQBLi .step-description__circle-highlight_dO1O1{color:var(--TextDefault);background-color:var(--Highlight)}.step-description_JQBLi .step-description__label-step_ZZlpv{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.step-description__wrapper_o34AH{display:flex;flex-flow:row nowrap;align-items:center}.step-description__circle_L_B03{display:inline-flex;flex-shrink:0;align-items:center;justify-content:center;width:32px;height:32px;border-radius:100%}.step-description__circle-brand_cSw7P{color:var(--DTextDefault);background-color:var(--BrandDefault)}.step-description__circle-blue_Dq9Rz{color:var(--DTextDefault);background-color:var(--Blue)}.step-description__circle-primary_eufmK{color:var(--Layer0);background-color:var(--TextPrimary)}.step-description__circle-size-40_VN7OM{width:40px;height:40px}.step-description__circle-size-32_xB44a{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;width:32px;height:32px}.step-description__circle-size-24_uihU5{width:24px;height:24px}.step-description__circle-size-24_uihU5,.step-description__label-icon_nVG4p{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px}.step-description__label-icon_nVG4p{color:var(--TextPrimary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step-description": `step-description_JQBLi`,
	"step-description__circle": `step-description__circle_L_B03`,
	"step-description__circle-size-40": `step-description__circle-size-40_VN7OM`,
	"step-description__circle-highlight": `step-description__circle-highlight_dO1O1`,
	"step-description__label-step": `step-description__label-step_ZZlpv`,
	"step-description__wrapper": `step-description__wrapper_o34AH`,
	"step-description__circle-brand": `step-description__circle-brand_cSw7P`,
	"step-description__circle-blue": `step-description__circle-blue_Dq9Rz`,
	"step-description__circle-primary": `step-description__circle-primary_eufmK`,
	"step-description__circle-size-32": `step-description__circle-size-32_xB44a`,
	"step-description__circle-size-24": `step-description__circle-size-24_uihU5`,
	"step-description__label-icon": `step-description__label-icon_nVG4p`
};
export default ___CSS_LOADER_EXPORT___;
