// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sportline-event-game-progress_CrDBK,.sportline-event-time-progress_XcjfK{color:var(--TextDefault)}.sportline-event-game-progress--10_za9Cj,.sportline-event-time-progress--10_XZrAu{font-size:10px;font-weight:500;line-height:12px;letter-spacing:normal}.sportline-event-game-progress--12_bm_xY,.sportline-event-time-progress--12_TG5QA{font-size:12px;font-weight:500;line-height:12px;letter-spacing:normal}.sportline-event-game-progress__holder_qfArR,.sportline-event-time-progress__holder_dlMff{display:inline-flex}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sportline-event-game-progress": `sportline-event-game-progress_CrDBK`,
	"sportline-event-time-progress": `sportline-event-time-progress_XcjfK`,
	"sportline-event-game-progress--10": `sportline-event-game-progress--10_za9Cj`,
	"sportline-event-time-progress--10": `sportline-event-time-progress--10_XZrAu`,
	"sportline-event-game-progress--12": `sportline-event-game-progress--12_bm_xY`,
	"sportline-event-time-progress--12": `sportline-event-time-progress--12_TG5QA`,
	"sportline-event-game-progress__holder": `sportline-event-game-progress__holder_qfArR`,
	"sportline-event-time-progress__holder": `sportline-event-time-progress__holder_dlMff`
};
export default ___CSS_LOADER_EXPORT___;
