import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot } from "vue";
import { useVAmountAnimator } from 'web/src/components/AmountAnimator/VAmountAnimator/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VAmountAnimator',
    props: {
        amount: {},
        duration: {
            default: 15000
        },
        timeRange: {
            default: ()=>({
                    min: 900,
                    max: 900
                })
        },
        stepRange: {
            default: ()=>({
                    min: 0.01,
                    max: 0.1
                })
        },
        isInfinite: {
            type: Boolean,
            default: true
        },
        isHidden: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { animatedValue } = useVAmountAnimator(props);
        return (_ctx, _cache)=>_renderSlot(_ctx.$slots, "default", {
                animatedValue: _unref(animatedValue)
            });
    }
});
