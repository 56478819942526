import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { onBeforeMount, toRef } from 'vue';
import VLazyImage from 'web/src/components/Image/VLazyImage/VLazyImage.vue';
import { useVImage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VImage',
    props: {
        forceHideImage: {
            type: Boolean
        },
        alt: {},
        isLazy: {
            type: Boolean
        },
        showLoader: {
            type: Boolean
        },
        objectFit: {},
        objectPosition: {},
        transparentLoader: {
            type: Boolean
        },
        lightLoader: {
            type: Boolean
        },
        isGradient: {
            type: Boolean
        },
        isRelative: {
            type: Boolean
        },
        src: {},
        x1: {},
        x2: {},
        x3: {},
        x1webp: {},
        x2webp: {},
        x3webp: {}
    },
    emits: [
        "error",
        "image-loaded",
        "lazy-image-mounted",
        "click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const src = toRef(props, 'src');
        const x1 = toRef(props, 'x1');
        const x2 = toRef(props, 'x2');
        const x3 = toRef(props, 'x3');
        const x1webp = toRef(props, 'x1webp');
        const x2webp = toRef(props, 'x2webp');
        const x3webp = toRef(props, 'x3webp');
        const objectFit = toRef(props, 'objectFit');
        const objectPosition = toRef(props, 'objectPosition');
        const { lazyImage, generatedSrc, prepareImageStyles, forceShowImage, created } = useVImage({
            src,
            x1,
            x2,
            x3,
            x1webp,
            x2webp,
            x3webp,
            objectFit,
            objectPosition
        });
        function onError() {
            if (!process.env.VUE_APP_PRERENDER) emit('error');
        }
        function emitClick(event) {
            emit('click', event);
        }
        function emitImageLoaded() {
            emit('image-loaded');
        }
        function emitLazyImageMounted() {
            emit('lazy-image-mounted');
        }
        __expose({
            forceShowImage
        });
        onBeforeMount(created);
        return (_ctx, _cache)=>_unref(generatedSrc) && _ctx.isLazy && !process.env.VUE_APP_PRERENDER && !process.env.STORYBOOK_CHROMATIC ? (_openBlock(), _createBlock(VLazyImage, {
                key: 0,
                ref_key: "lazyImage",
                ref: lazyImage,
                src: _unref(generatedSrc),
                "show-loader": _ctx.showLoader,
                alt: _ctx.alt,
                "object-fit": objectFit.value,
                "object-position": objectPosition.value,
                "force-hide-image": _ctx.forceHideImage,
                "transparent-loader-bg": _ctx.transparentLoader,
                "light-loader": _ctx.lightLoader,
                "is-gradient": _ctx.isGradient,
                "is-relative": _ctx.isRelative,
                onVnodeMounted: emitLazyImageMounted,
                onError: onError,
                onImageLoaded: emitImageLoaded,
                onClick: emitClick
            }, null, 8, [
                "src",
                "show-loader",
                "alt",
                "object-fit",
                "object-position",
                "force-hide-image",
                "transparent-loader-bg",
                "light-loader",
                "is-gradient",
                "is-relative"
            ])) : _unref(generatedSrc) && !_ctx.forceHideImage ? (_openBlock(), _createElementBlock("img", {
                key: 1,
                src: _unref(generatedSrc),
                style: _normalizeStyle(_unref(prepareImageStyles)),
                alt: _ctx.alt,
                onError: onError,
                onLoad: emitImageLoaded,
                onClick: emitClick
            }, null, 44, _hoisted_1)) : _createCommentVNode("", true);
    }
});
