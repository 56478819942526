// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-item_TnbZe{display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding:12px;cursor:default;background-color:var(--Layer1)}.history-item--hover_qwBD1{cursor:pointer}.history-item__left_SCm1a,.history-item__right_OzWKO{display:flex;align-items:center;justify-content:flex-end}.history-item__left_SCm1a{font-weight:400;color:var(--TextPrimary);text-align:left}.history-item__left_SCm1a,.history-item__right_OzWKO{font-size:14px;line-height:20px;letter-spacing:.25px}.history-item__right_OzWKO{font-weight:500;color:var(--TextDefault);text-align:right}.history-item__right--green_Wdw1O{color:var(--BrandDefault)}.history-item__right--column_woJG_{flex-direction:column;align-items:end}.history-item__back-coefficient_fTpwe,.history-item__back-price_y9VzM{font-size:13px;font-weight:400;line-height:20px;letter-spacing:normal;color:var(--TextSecondary);-webkit-text-decoration:line-through;text-decoration:line-through}.history-item__back-coefficient_fTpwe{padding-right:8px}.history-item__percent_Yz_ua{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;height:20px;padding:0 4px;margin-left:4px;color:var(--BrandDefault);text-align:center;background:var(--BrandDefaultOpacity02);border-radius:4px}.history-item__number_LVp_p{display:inline-flex;align-items:center}.history-item__positive_wU4Jv{color:var(--BrandDefault)}.history-item__negative_nPSMH{color:var(--ErrorText)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"history-item": `history-item_TnbZe`,
	"history-item--hover": `history-item--hover_qwBD1`,
	"history-item__left": `history-item__left_SCm1a`,
	"history-item__right": `history-item__right_OzWKO`,
	"history-item__right--green": `history-item__right--green_Wdw1O`,
	"history-item__right--column": `history-item__right--column_woJG_`,
	"history-item__back-coefficient": `history-item__back-coefficient_fTpwe`,
	"history-item__back-price": `history-item__back-price_y9VzM`,
	"history-item__percent": `history-item__percent_Yz_ua`,
	"history-item__number": `history-item__number_LVp_p`,
	"history-item__positive": `history-item__positive_wU4Jv`,
	"history-item__negative": `history-item__negative_nPSMH`
};
export default ___CSS_LOADER_EXPORT___;
