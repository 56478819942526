import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { VIcon } from '@components/v-icon';
import { ProgressHeight, ProgressKind } from './enums';
import { useVLinearProgress } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLinearProgress',
    props: {
        value: {
            default: 0
        },
        of: {
            default: 100
        },
        height: {
            default: ProgressHeight.DEFAULT
        },
        kind: {
            default: ProgressKind.DEFAULT
        },
        noShadow: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { iconProperties, percentage, barStyleObject } = useVLinearProgress(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['linear-progress']]: true,
                    [_ctx.$style[`linear-progress--${_ctx.kind}`]]: _ctx.kind !== _unref(ProgressKind).DEFAULT,
                    [_ctx.$style[`linear-progress--${_ctx.height}`]]: _ctx.height !== _unref(ProgressHeight).DEFAULT,
                    [_ctx.$style["linear-progress--no-shadow"]]: _ctx.noShadow
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['linear-progress__bg'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['linear-progress__bar']),
                        style: _normalizeStyle(_unref(barStyleObject))
                    }, null, 6),
                    100 === _unref(percentage) && _ctx.height === _unref(ProgressHeight).DEFAULT ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['linear-progress__check'])
                    }, [
                        _createVNode(_unref(VIcon), _normalizeProps(_guardReactiveProps(_unref(iconProperties))), null, 16)
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VLinearProgress'
                ]
            ]);
        };
    }
});
