// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .crumb_YxkYj{display:flex;gap:4px;align-items:center;min-height:40px;padding-right:12px;padding-left:12px;color:var(--TextPrimary);-webkit-text-decoration:none;text-decoration:none;-webkit-user-select:none;user-select:none;background-color:var(--Layer1);border-radius:5px}html.app__layout--desktop .crumb--with-indicator_SDcsp{padding-right:8px}html.app__layout--desktop .crumb--with-icon_Azk4R{padding-left:8px}html.app__layout--desktop .crumb--can-interact_lyeun{cursor:pointer}@media(hover:hover)and (pointer:fine){html.app__layout--desktop .crumb--can-interact_lyeun:not(.crumb--open_PTaLB):hover{color:var(--TextDefault);background-color:var(--Highlight)}}html.app__layout--desktop .crumb--open_PTaLB{color:var(--Layer1);background-color:var(--TextDefault)}html.app__layout--desktop .crumb--open_PTaLB:after{position:absolute;top:100%;right:0;left:0;height:4px;content:"";background-color:var(--TextDefault)}html.app__layout--desktop .crumb--open_PTaLB.crumb--menu_XN7zW{border-bottom-right-radius:0;border-bottom-left-radius:0}html.app__layout--desktop .crumb__title_zQ1DD{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;max-width:158px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}html.app__layout--desktop .crumb__icon_eAD1L{display:flex;align-items:center;justify-content:center;margin-right:4px}html.app__layout--desktop .crumb__indicator_lvxVV{display:flex;align-items:center;justify-content:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"crumb": `crumb_YxkYj`,
	"crumb--with-indicator": `crumb--with-indicator_SDcsp`,
	"crumb--with-icon": `crumb--with-icon_Azk4R`,
	"crumb--can-interact": `crumb--can-interact_lyeun`,
	"crumb--open": `crumb--open_PTaLB`,
	"crumb--menu": `crumb--menu_XN7zW`,
	"crumb__title": `crumb__title_zQ1DD`,
	"crumb__icon": `crumb__icon_eAD1L`,
	"crumb__indicator": `crumb__indicator_lvxVV`
};
export default ___CSS_LOADER_EXPORT___;
