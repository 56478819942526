import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { useVScrollbarPhone } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VScrollbarPhone',
    props: {
        flexFill: {
            type: Boolean
        },
        isDropdown: {
            type: Boolean
        },
        useScrollListener: {
            type: Boolean
        },
        testEl: {},
        handleResizeChange: {
            type: Boolean
        }
    },
    emits: [
        "reached-bottom"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { testAttrs, element, scrollTo, scrollTop, scrollDown, scrollRight, scrollToElement } = useVScrollbarPhone(props, emit);
        __expose({
            scrollTo,
            scrollTop,
            scrollDown,
            scrollRight,
            scrollToElement,
            recompose: ()=>Promise.resolve()
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "element",
                ref: element,
                class: _normalizeClass({
                    [_ctx.$style['scrollbar-phone']]: true,
                    [_ctx.$style['scrollbar-phone--flex-fill']]: _ctx.flexFill,
                    [_ctx.$style['scrollbar-phone--dropdown']]: _ctx.isDropdown
                })
            }, [
                _renderSlot(_ctx.$slots, "default")
            ], 2)), [
                [
                    _directive_auto_id,
                    'VScrollbarPhone'
                ],
                [
                    _directive_data_test,
                    _unref(testAttrs)
                ]
            ]);
        };
    }
});
