// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-full-screen_PRKv5{display:flex;flex-direction:column;text-align:left}.hero-full-screen__body_OTUMA{display:flex;flex-direction:column;gap:32px;padding:16px 16px 32px}.hero-full-screen__header_P5TWU,.hero-full-screen__paragraph_v7ieM{display:flex;flex-direction:column;gap:24px}.hero-full-screen__footer_ZiL_A{display:flex;flex-direction:column;gap:16px}.hero-full-screen__image_no1lt{width:100%;padding:0 0 50px;margin:0 auto;background-repeat:no-repeat;background-size:100% auto;animation-name:var(--animation-image-scale-name);animation-duration:var(--animation-image-scale-duration)}.hero-full-screen__image_no1lt img{width:100%}.hero-full-screen__image--vertical_Inbiz{background-size:auto 100%}.hero-full-screen__image--vertical_Inbiz img{padding:0 16px}.hero-full-screen__image--horizontal_WbMWp{background-position:bottom;background-size:cover}@media(min-width:1024px){.hero-full-screen_PRKv5{flex-direction:row-reverse;gap:60px;align-items:center;height:calc(100vh - 56px);background-repeat:no-repeat;background-size:100%}.hero-full-screen__body_OTUMA{gap:32px;width:45%;padding:0}.hero-full-screen__header_P5TWU{gap:24px}.hero-full-screen__paragraph_v7ieM{max-width:80%}.hero-full-screen__image_no1lt{display:flex;justify-content:center;width:55%;padding:0}.hero-full-screen__image_no1lt img{width:100%;height:auto}.hero-full-screen__image--vertical_Inbiz img{width:auto;height:calc(100vh - 56px);padding:0}}@keyframes animate-hero_2eQLj{0%{opacity:0;transform:scale(1.5)}to{opacity:1;transform:scale(1)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero-full-screen": `hero-full-screen_PRKv5`,
	"hero-full-screen__body": `hero-full-screen__body_OTUMA`,
	"hero-full-screen__header": `hero-full-screen__header_P5TWU`,
	"hero-full-screen__paragraph": `hero-full-screen__paragraph_v7ieM`,
	"hero-full-screen__footer": `hero-full-screen__footer_ZiL_A`,
	"hero-full-screen__image": `hero-full-screen__image_no1lt`,
	"hero-full-screen__image--vertical": `hero-full-screen__image--vertical_Inbiz`,
	"hero-full-screen__image--horizontal": `hero-full-screen__image--horizontal_WbMWp`,
	"animate-hero": `animate-hero_2eQLj`
};
export default ___CSS_LOADER_EXPORT___;
