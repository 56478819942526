// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown_qK6U4{z-index:200;position:fixed;top:0;left:0;display:flex;flex-direction:column}.dropdown--hidden_Qynl3{visibility:hidden}.dropdown--modal_kbFyi{top:0;right:0;bottom:0;left:0;display:flex;justify-content:center;padding:calc(16px + var(--StatusbarHeight)) 16px 16px}.dropdown--behind-modals_EQg8X{z-index:61}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `dropdown_qK6U4`,
	"dropdown--hidden": `dropdown--hidden_Qynl3`,
	"dropdown--modal": `dropdown--modal_kbFyi`,
	"dropdown--behind-modals": `dropdown--behind-modals_EQg8X`
};
export default ___CSS_LOADER_EXPORT___;
