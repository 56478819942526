import { watch, toRef } from 'vue';
import { Events, getYMInstance } from '@leon-hub/yandex-metrika';
import { Json, Timer } from '@leon-hub/utils';
import { logger } from '@leon-hub/logging';
import { useTheme } from 'web/src/modules/theme/composables';
import { useMetricsStore } from 'web/src/modules/analytics/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import { useAnalytics } from 'web/src/modules/analytics/composables';
async function registerYandexMetrika(config) {
    const YM = getYMInstance();
    if (config) try {
        await YM.register(config, {
            collectQueues: true
        });
    } catch (rawError) {
        const errorMessage = `Registration error: yandexMetrikaConfig: ${Json.stringify(config)}`;
        logger.warn(errorMessage, useErrorsConverter().convertToBaseError(rawError));
    }
}
export default function initYM(param) {
    let { initTimeout } = param;
    const metricsStore = useMetricsStore();
    const hasYandexMetrikaConfig = toRef(metricsStore, 'hasYandexMetrikaConfig');
    const yandexMetrikaConfig = toRef(metricsStore, 'yandexMetrikaConfig');
    // TODO: create different tools for authorized and guest users
    const customerDataStore = useCustomerDataStore();
    const analytics = useAnalytics();
    const login = toRef(customerDataStore, 'login');
    const countryName = toRef(customerDataStore, 'countryName');
    const vipStatus = toRef(customerDataStore, 'vipStatus');
    const YM = getYMInstance();
    const { theme } = useTheme();
    let timeoutTimer;
    watch(hasYandexMetrikaConfig, (newValue)=>{
        YM.setDefaultOptions({
            collectQueues: newValue
        });
        if (newValue) timeoutTimer = Timer.setTimeout(()=>{
            registerYandexMetrika(yandexMetrikaConfig.value);
            const platform = "web";
            const layout = "desktop";
            const version = "6.102.0";
            if (login.value) {
                const currentTheme = theme.value;
                analytics.push(Events.Z_INIT, {
                    userId: login.value,
                    country: countryName.value,
                    customerType: vipStatus.value || 'normal',
                    type: {
                        [platform]: `${layout}`
                    },
                    webVersion: version,
                    ...currentTheme ? {
                        mode: currentTheme
                    } : {}
                });
            } else analytics.push(Events.Z_INIT, {
                type: {
                    [platform]: `${layout}`
                },
                webVersion: version
            });
        }, initTimeout);
        else {
            if (timeoutTimer) clearTimeout(timeoutTimer);
            YM.setDefaultOptions({
                collectQueues: false
            });
            YM.unregister();
        }
    }, {
        immediate: true
    });
}
