import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, Teleport as _Teleport, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
import { IconName } from '@leon-hub/icons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VImagePreviewDesktop',
    props: {
        src: {},
        name: {},
        allowDelete: {
            type: Boolean
        }
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function emitClose() {
            emit('close');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_Teleport, {
                to: "body"
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['image-preview-desktop'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['image-preview-desktop__overlay']),
                        onClick: emitClose
                    }, null, 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['image-preview-desktop__image-container'])
                    }, [
                        _createElementVNode("img", {
                            src: _ctx.src,
                            class: _normalizeClass(_ctx.$style['image-preview-desktop__image']),
                            alt: _ctx.name || '...'
                        }, null, 10, _hoisted_1),
                        _createVNode(VButton, {
                            kind: _unref(ButtonKind).TRANSPARENT,
                            "icon-name": _unref(IconName).CROSS,
                            class: _normalizeClass(_ctx.$style['image-preview-desktop__close']),
                            onClick: emitClose
                        }, null, 8, [
                            "kind",
                            "icon-name",
                            "class"
                        ])
                    ], 2)
                ], 2)
            ])), [
                [
                    _directive_auto_id,
                    'VImagePreviewDesktop'
                ]
            ]);
        };
    }
});
