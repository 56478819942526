import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WebPushInfo',
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['push-info'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['push-info-bonus'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['push-info-bonus__item'])
                    }, [
                        _createVNode(_unref(VIcon), {
                            class: _normalizeClass(_ctx.$style['push-info-bonus__item-icon']),
                            name: _unref(IconName).CHECK
                        }, null, 8, [
                            "class",
                            "name"
                        ]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_DESC1')), 1)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['push-info-bonus__item'])
                    }, [
                        _createVNode(_unref(VIcon), {
                            class: _normalizeClass(_ctx.$style['push-info-bonus__item-icon']),
                            name: _unref(IconName).CHECK
                        }, null, 8, [
                            "class",
                            "name"
                        ]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_DESC2')), 1)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['push-info-bonus__item'])
                    }, [
                        _createVNode(_unref(VIcon), {
                            class: _normalizeClass(_ctx.$style['push-info-bonus__item-icon']),
                            name: _unref(IconName).CHECK
                        }, null, 8, [
                            "class",
                            "name"
                        ]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_DESC3')), 1)
                    ], 2)
                ], 2),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('WEB2_PUSH_NOTIFICATIONS_MODAL_INFO')), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'WebPushInfo'
                ]
            ]);
        };
    }
});
