import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import HighestOddsBadge from 'web/src/modules/sportline/submodules/highest-odds/components/HighestOddsBadge.vue';
import useMarketsListMarketGroup from '../composables/useMarketsListMarketGroup';
import MarketsListMarket from './MarketsListMarket.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketGroup',
    props: {
        group: {},
        sportlineEvent: {}
    },
    emits: [
        "market-group-toggle"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const group = toRef(props, 'group');
        const sportEvent = toRef(props, 'sportlineEvent', null);
        const { isSportEventSuspended, onMarketGroupToggle } = useMarketsListMarketGroup({
            group,
            sportEvent
        }, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("section", {
                key: group.value.id,
                class: _normalizeClass(_ctx.$style['sport-event-details-market-group'])
            }, [
                _createElementVNode("article", {
                    class: _normalizeClass(_ctx.$style['sport-event-details-market-group__wrapper'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['sport-event-details-market-group__header']]: true,
                            [_ctx.$style['sport-event-details-market-group__header--closed']]: !group.value.isOpened
                        }),
                        onClick: _cache[0] || (_cache[0] = ($event)=>_unref(onMarketGroupToggle)(group.value))
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['market-group-title']]: true,
                                [_ctx.$style['market-group-title--closed']]: !group.value.isOpened && group.value.hasZeroMargin
                            })
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['market-group-title__label'])
                            }, _toDisplayString(group.value.title), 3),
                            group.value.hasZeroMargin ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass({
                                    [_ctx.$style['market-group-title__zero-margin']]: true,
                                    [_ctx.$style['market-group-title__zero-margin--closed']]: !group.value.isOpened
                                })
                            }, [
                                _createVNode(HighestOddsBadge, {
                                    "badge-background": "default",
                                    "highest-odds-icon-color": "highlight"
                                })
                            ], 2)) : _createCommentVNode("", true)
                        ], 2),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['sport-event-details-market-group__indicator'])
                        }, [
                            _createVNode(_unref(VIcon), {
                                name: group.value.isOpened ? _unref(IconName).EXPAND_UP : _unref(IconName).EXPAND_DOWN,
                                size: _unref(IconSize).SIZE_16
                            }, null, 8, [
                                "name",
                                "size"
                            ])
                        ], 2)
                    ], 2),
                    _withDirectives(_createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['sport-event-details-market-group__content'])
                    }, [
                        sportEvent.value ? (_openBlock(), _createBlock(MarketsListMarket, {
                            key: group.value.id,
                            class: _normalizeClass({
                                [_ctx.$style['sport-event-details-market-group__market']]: true,
                                [_ctx.$style['sport-event-details-market-group__market--hidden']]: _unref(isSportEventSuspended)
                            }),
                            group: group.value,
                            "sportline-event": sportEvent.value
                        }, null, 8, [
                            "class",
                            "group",
                            "sportline-event"
                        ])) : _createCommentVNode("", true)
                    ], 2), [
                        [
                            _vShow,
                            group.value.isOpened
                        ]
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'MarketGroup'
                ]
            ]);
        };
    }
});
