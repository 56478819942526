// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .referral-program-list-steps__title_Y_iJT{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;gap:2px;align-items:center;height:44px;padding-left:8px;margin:0;color:var(--TextSecondary);padding:0}html.app__layout--desktop .referral-program-list-steps__list_ZxTSu{display:flex;flex-direction:column;gap:2px}html.app__layout--desktop .referral-program-list-steps--green_Uw7Sn html.app__layout--desktop .referral-program-list-steps__title_Y_iJT{color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"referral-program-list-steps__title": `referral-program-list-steps__title_Y_iJT`,
	"referral-program-list-steps__list": `referral-program-list-steps__list_ZxTSu`,
	"referral-program-list-steps--green": `referral-program-list-steps--green_Uw7Sn`
};
export default ___CSS_LOADER_EXPORT___;
