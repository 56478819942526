// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-item_rJtk9{list-style:none}.search-item__link_uPjka{display:flex;align-items:center;padding:8px;-webkit-text-decoration:none;text-decoration:none;background:var(--Layer1);border-radius:var(--BorderRadius)}html.app__layout--desktop .search-item__link_uPjka:hover{background:var(--Highlight)}.search-item__status_lFijw{width:48px;text-align:center}.search-item__text--medium_NvjAS{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px}.search-item__score_cwMr3{margin-bottom:4px;color:var(--BrandDefault)}.search-item__progress_fQBD5,.search-item__time_FTR1x{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;color:var(--TextPrimary)}.search-item__main_vid07{flex:1;margin-left:16px}.search-item__date_gOzeg,.search-item__name_rAGSI{margin-bottom:4px;color:var(--TextDefault)}.search-item__info_YlnZ7{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;color:var(--TextSecondary)}.search-item__sport_PqTpE{font-weight:400}.search-item__region_HzSea{color:var(--TextPrimary)}.search-item__league_UBOtv{color:var(--TextDefault)}.search-item__stream_IzMiW{margin-left:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-item": `search-item_rJtk9`,
	"search-item__link": `search-item__link_uPjka`,
	"search-item__status": `search-item__status_lFijw`,
	"search-item__text--medium": `search-item__text--medium_NvjAS`,
	"search-item__score": `search-item__score_cwMr3`,
	"search-item__progress": `search-item__progress_fQBD5`,
	"search-item__time": `search-item__time_FTR1x`,
	"search-item__main": `search-item__main_vid07`,
	"search-item__date": `search-item__date_gOzeg`,
	"search-item__name": `search-item__name_rAGSI`,
	"search-item__info": `search-item__info_YlnZ7`,
	"search-item__sport": `search-item__sport_PqTpE`,
	"search-item__region": `search-item__region_HzSea`,
	"search-item__league": `search-item__league_UBOtv`,
	"search-item__stream": `search-item__stream_IzMiW`
};
export default ___CSS_LOADER_EXPORT___;
