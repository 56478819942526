// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.autoheight-text-input_y11G9{display:flex;flex-flow:column wrap;flex-grow:1}.autoheight-text-input__icon_EPCiH{display:flex;flex:none;align-items:center;justify-content:center}.autoheight-text-input__icon--inert_tyXdD{pointer-events:none}.autoheight-text-input__label_W0yCW{display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.autoheight-text-input__input_OuT1D{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;width:100%;min-height:44px;padding:0 12px;color:var(--TextDefault);background-color:#0000;caret-color:var(--Blue);border:none;display:block;resize:none}.autoheight-text-input__input_OuT1D::placeholder{color:#0000}.autoheight-text-input__input_OuT1D:active,.autoheight-text-input__input_OuT1D:focus{z-index:1;color:var(--TextDefault)}.autoheight-text-input__input_OuT1D:disabled{color:var(--TextSecondary);-webkit-text-fill-color:var(--TextSecondary);opacity:1}html.app__os--windows .autoheight-text-input__input_OuT1D{scrollbar-width:thin;scrollbar-color:var(--Highlight) var(--Layer0)}html.app__os--windows .autoheight-text-input__input_OuT1D::-webkit-scrollbar{width:16px}html.app__os--windows .autoheight-text-input__input_OuT1D::-webkit-scrollbar-thumb{min-height:30px;background-color:var(--Highlight);background-clip:content-box;border:4px solid #0000;border-radius:8px}.autoheight-text-input__input_OuT1D::placeholder,.autoheight-text-input__input_OuT1D:focus::placeholder{color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"autoheight-text-input": `autoheight-text-input_y11G9`,
	"autoheight-text-input__icon": `autoheight-text-input__icon_EPCiH`,
	"autoheight-text-input__icon--inert": `autoheight-text-input__icon--inert_tyXdD`,
	"autoheight-text-input__label": `autoheight-text-input__label_W0yCW`,
	"autoheight-text-input__input": `autoheight-text-input__input_OuT1D`
};
export default ___CSS_LOADER_EXPORT___;
