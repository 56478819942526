// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .sport-events-block_EyKmu{padding:0;margin-bottom:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-events-block": `sport-events-block_EyKmu`
};
export default ___CSS_LOADER_EXPORT___;
