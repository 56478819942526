// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pin-button_fxEGz{width:80px;height:80px;color:var(--TextSecondary);cursor:pointer;-webkit-user-select:none;user-select:none;background-color:#0000;border:none;border-radius:32px}.pin-button--filled_oDMcd{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault);background-color:var(--Layer1)}.pin-button--filled_oDMcd.pin-button--active_JpkV7,.pin-button--filled_oDMcd:active{background-color:var(--Highlight)}.pin-button_fxEGz.pin-button--active_JpkV7,.pin-button_fxEGz:active{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pin-button": `pin-button_fxEGz`,
	"pin-button--filled": `pin-button--filled_oDMcd`,
	"pin-button--active": `pin-button--active_JpkV7`
};
export default ___CSS_LOADER_EXPORT___;
