// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-footer_gV8LD{display:flex;flex-direction:column;gap:40px;padding:0 16px 70px}.landing-footer__header_owaBa{text-align:center}.landing-footer__buttons_hX5Ti,.landing-footer__header_owaBa{gap:24px}.landing-footer__body_RWk92,.landing-footer__brand_D6d8q,.landing-footer__buttons_hX5Ti,.landing-footer__header_owaBa{display:flex;flex-direction:column;align-items:center}.landing-footer__brand_D6d8q{padding-bottom:24px}.landing-footer__socials_bDfMz{padding-bottom:32px}.landing-footer__copyright__jNPs,.landing-footer__socials_bDfMz{display:flex;flex-direction:column;align-items:center}.landing-footer__copyright__jNPs{gap:8px;font-family:var(--landingCopyrightFontFamily);font-size:var(--landingCopyrightFontSize);font-weight:var(--landingCopyrightFontWeight);line-height:var(--landingCopyrightLineHeight);color:var(--landingCopyrightColor);text-align:center}@media(min-width:1024px){.landing-footer_gV8LD{gap:80px;padding:60px 0}.landing-footer__header_owaBa{gap:40px}.landing-footer__buttons_hX5Ti{flex-direction:row;gap:12px}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-footer": `landing-footer_gV8LD`,
	"landing-footer__header": `landing-footer__header_owaBa`,
	"landing-footer__buttons": `landing-footer__buttons_hX5Ti`,
	"landing-footer__body": `landing-footer__body_RWk92`,
	"landing-footer__brand": `landing-footer__brand_D6d8q`,
	"landing-footer__socials": `landing-footer__socials_bDfMz`,
	"landing-footer__copyright": `landing-footer__copyright__jNPs`
};
export default ___CSS_LOADER_EXPORT___;
