// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes rotate_kEc7O{to{transform:rotate(1turn)}}@keyframes dash_yWzFG{0%{stroke-dasharray:1%,310%;stroke-dashoffset:0}50%{stroke-dasharray:220%,310%;stroke-dashoffset:-90%}to{stroke-dasharray:220%,310%;stroke-dashoffset:-302%}}.spinner_F2bH8{position:relative;width:20px;height:20px;overflow:visible;color:var(--TextSecondary)}.spinner--size_small_H5S7t{width:12px;height:12px}.spinner--size_large_LxRAN{width:52px;height:52px}.spinner--color-branded_AUjG8 .spinner__circle--path_CTmpz{stroke:var(--BrandDefault)}.spinner--color-branded_AUjG8 .spinner__circle--bg_BGe5a{opacity:1;stroke:var(--Highlight)}.spinner--color-parent_nQSQt{color:currentcolor}.spinner--color-parent_nQSQt .spinner__circle--bg_BGe5a,.spinner--color-parent_nQSQt .spinner__circle--path_CTmpz{stroke:currentcolor}.spinner__group_tzjFx{transform-origin:center;animation:rotate_kEc7O 2s linear infinite}.spinner__circle_mBs3C{fill:none;stroke:var(--TextSecondary);stroke-width:8px}.spinner__circle--bg_BGe5a{opacity:.3}.spinner__circle--path_CTmpz{opacity:.95;stroke-dasharray:1%,310%;stroke-dashoffset:0;transform-origin:center;animation:dash_yWzFG 1.5s ease-in-out infinite}.spinner--custom-size_GFUYC .spinner__circle_mBs3C{stroke-width:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `spinner_F2bH8`,
	"spinner--size_small": `spinner--size_small_H5S7t`,
	"spinner--size_large": `spinner--size_large_LxRAN`,
	"spinner--color-branded": `spinner--color-branded_AUjG8`,
	"spinner__circle--path": `spinner__circle--path_CTmpz`,
	"spinner__circle--bg": `spinner__circle--bg_BGe5a`,
	"spinner--color-parent": `spinner--color-parent_nQSQt`,
	"spinner__group": `spinner__group_tzjFx`,
	"rotate": `rotate_kEc7O`,
	"spinner__circle": `spinner__circle_mBs3C`,
	"dash": `dash_yWzFG`,
	"spinner--custom-size": `spinner--custom-size_GFUYC`
};
export default ___CSS_LOADER_EXPORT___;
