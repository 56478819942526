import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "for"
];
const _hoisted_2 = [
    "id",
    "checked",
    "disabled",
    "name",
    "tabindex",
    "value"
];
import { useSlots, onBeforeMount } from 'vue';
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useVRadio } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VRadio',
    props: {
        checked: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        error: {
            type: Boolean
        },
        type: {},
        id: {},
        name: {},
        tabIndex: {},
        value: {
            type: [
                String,
                Number,
                Boolean
            ]
        },
        label: {},
        subTitle: {},
        hasNoLabel: {
            type: Boolean
        },
        hasIcon: {
            type: Boolean
        }
    },
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const { isChecked, hasLabel, hasSubTitle, onChange, beforeMount } = useVRadio(props, emit, slots);
        onBeforeMount(beforeMount);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("label", {
                class: _normalizeClass({
                    [_ctx.$style['radio']]: true,
                    [_ctx.$style["radio--disabled"]]: _ctx.disabled,
                    [_ctx.$style["radio--checked"]]: _ctx.checked,
                    [_ctx.$style["radio--error"]]: _ctx.error,
                    [_ctx.$style["radio--has-icon"]]: _ctx.hasIcon,
                    [_ctx.$style["radio--has-no-label"]]: _ctx.hasNoLabel
                }),
                for: _ctx.id
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['radio__button'])
                }, [
                    _createElementVNode("input", {
                        id: _ctx.id,
                        ref: "input",
                        checked: _unref(isChecked),
                        disabled: _ctx.disabled,
                        name: _ctx.name,
                        tabindex: _ctx.tabIndex,
                        value: _ctx.value,
                        class: _normalizeClass(_ctx.$style['radio__input']),
                        type: "radio",
                        onChange: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onChange) && _unref(onChange)(...args);
                        }),
                        onClick: _cache[1] || (_cache[1] = _withModifiers(()=>{}, [
                            "stop"
                        ]))
                    }, null, 42, _hoisted_2),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['radio__bg'])
                    }, [
                        _ctx.hasIcon ? (_openBlock(), _createBlock(_unref(VIcon), {
                            key: 0,
                            name: _unref(IconName).CHECK_ROUNDED,
                            class: _normalizeClass(_ctx.$style['radio__icon'])
                        }, null, 8, [
                            "name",
                            "class"
                        ])) : _createCommentVNode("", true)
                    ], 2)
                ], 2),
                _unref(hasLabel) ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['radio__label'])
                }, [
                    _renderSlot(_ctx.$slots, "default", {}, ()=>[
                            _createTextVNode(_toDisplayString(_ctx.label), 1)
                        ])
                ], 2)) : _createCommentVNode("", true),
                _unref(hasSubTitle) ? (_openBlock(), _createElementBlock("span", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['radio__subtitle'])
                }, [
                    _renderSlot(_ctx.$slots, "content", {}, ()=>[
                            _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
                        ])
                ], 2)) : _createCommentVNode("", true)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'VRadio'
                ]
            ]);
        };
    }
});
