import { CaptchaFramedWidgetProperties } from 'web/src/modules/framed-app/components/CaptchaFramedWidget/enums';
export default function getAdditionalQueryStringParameters(props, lang) {
    return [
        {
            [CaptchaFramedWidgetProperties.CaptchaRequesterStrategy]: props.captchaRequesterStrategy
        },
        {
            [CaptchaFramedWidgetProperties.SiteKey]: props.siteKey
        },
        {
            [CaptchaFramedWidgetProperties.CaptchaType]: props.captchaType
        },
        {
            [CaptchaFramedWidgetProperties.Theme]: props.reCaptchaTheme
        },
        {
            [CaptchaFramedWidgetProperties.AutoExecute]: props.autoExecute.toString()
        },
        {
            [CaptchaFramedWidgetProperties.Lang]: lang
        },
        {
            [CaptchaFramedWidgetProperties.ApiUrl]: encodeURIComponent(props.apiUrl)
        },
        {
            [CaptchaFramedWidgetProperties.Version]: "6.102.0"
        }
    ];
}
