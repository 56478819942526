// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loyalty-shop-item_wyf3Z{display:flex;flex-direction:column;align-items:center}.loyalty-shop-item--wrapper_qRHeq{display:flex;align-items:center;justify-content:center;width:180px;height:180px}.loyalty-shop-item__img_HZN5N{max-width:100%;object-fit:contain;object-position:center}.loyalty-shop-item__title_FN5yu{font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal}.loyalty-shop-item__balance_gEIIW{margin-bottom:16px}.loyalty-shop-item__des_ViZg1{font-weight:400;flex-grow:1;padding:0 16px;margin-top:0}.loyalty-shop-item__des_ViZg1,.loyalty-shop-item__mark_srzhG{font-size:14px;line-height:24px;letter-spacing:.25px}.loyalty-shop-item__mark_srzhG{font-weight:500}.loyalty-shop-item__button-wrapper_kakQd{position:sticky;bottom:0;width:100%;padding:16px;background:var(--Layer0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loyalty-shop-item": `loyalty-shop-item_wyf3Z`,
	"loyalty-shop-item--wrapper": `loyalty-shop-item--wrapper_qRHeq`,
	"loyalty-shop-item__img": `loyalty-shop-item__img_HZN5N`,
	"loyalty-shop-item__title": `loyalty-shop-item__title_FN5yu`,
	"loyalty-shop-item__balance": `loyalty-shop-item__balance_gEIIW`,
	"loyalty-shop-item__des": `loyalty-shop-item__des_ViZg1`,
	"loyalty-shop-item__mark": `loyalty-shop-item__mark_srzhG`,
	"loyalty-shop-item__button-wrapper": `loyalty-shop-item__button-wrapper_kakQd`
};
export default ___CSS_LOADER_EXPORT___;
