// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-border-wrapper_Hyxo2{position:relative;border-radius:5px;transition:box-shadow .15s ease-in-out}.input-border-wrapper_Hyxo2,.input-border-wrapper--disabled_c7z4r{background-color:var(--Layer1)}@media(hover:hover)and (pointer:fine){.input-border-wrapper--hover_zwkVt{box-shadow:inset 0 0 0 1px var(--Blue);background-color:var(--Layer1)}}.input-border-wrapper--modal_N3Q2l,.input-border-wrapper--modal_N3Q2l.input-border-wrapper--disabled_c7z4r{background-color:var(--Layer2)}@media(hover:hover)and (pointer:fine){.input-border-wrapper--modal_N3Q2l.input-border-wrapper--hover_zwkVt{background-color:var(--Highlight)}}.input-border-wrapper--error_EaosJ{background-color:var(--ErrorField)}.input-border-wrapper--focus_qOjFz{box-shadow:inset 0 0 0 1px var(--Blue)}.input-border-wrapper--modal_N3Q2l.input-border-wrapper--focus_qOjFz{background-color:var(--Layer2)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-border-wrapper": `input-border-wrapper_Hyxo2`,
	"input-border-wrapper--disabled": `input-border-wrapper--disabled_c7z4r`,
	"input-border-wrapper--hover": `input-border-wrapper--hover_zwkVt`,
	"input-border-wrapper--modal": `input-border-wrapper--modal_N3Q2l`,
	"input-border-wrapper--error": `input-border-wrapper--error_EaosJ`,
	"input-border-wrapper--focus": `input-border-wrapper--focus_qOjFz`
};
export default ___CSS_LOADER_EXPORT___;
