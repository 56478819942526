// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .rules-container_h8tMF{flex-grow:1;padding:0;background-color:inherit;border-radius:0}html.app__layout--desktop .rules-container_h8tMF .inner_n_wDr{padding-bottom:48px;margin-top:0;background-color:inherit;border-radius:0}html.app__layout--desktop .rules-container__cover-content_CPFEA{padding:0 4px}html.app__layout--desktop .rules-container__default_A0car{padding:0 8px;margin-bottom:8px}html.app__layout--desktop .rules-container__title_Brg4x{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;padding:32px 0 16px;color:var(--TextDefault)}html.app__layout--desktop .rules-container__sub-header_W7cmZ{display:inline-block;color:var(--TextSecondary)}html.app__layout--desktop .rules-container__sub-header--wrapper_WnFmt{display:flex;gap:10px;align-items:center;justify-content:space-between;margin:28px 0 12px 8px}html.app__layout--desktop .rules-container__sub-header--wrapper-secondary_UPEuy{margin-top:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rules-container": `rules-container_h8tMF`,
	"inner": `inner_n_wDr`,
	"rules-container__cover-content": `rules-container__cover-content_CPFEA`,
	"rules-container__default": `rules-container__default_A0car`,
	"rules-container__title": `rules-container__title_Brg4x`,
	"rules-container__sub-header": `rules-container__sub-header_W7cmZ`,
	"rules-container__sub-header--wrapper": `rules-container__sub-header--wrapper_WnFmt`,
	"rules-container__sub-header--wrapper-secondary": `rules-container__sub-header--wrapper-secondary_UPEuy`
};
export default ___CSS_LOADER_EXPORT___;
