// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rg-bet-limit-edit_EmYTU{display:flex;flex-direction:column;row-gap:16px;width:100%;padding:16px 16px 42px}.rg-bet-limit-edit__info_dv0QN{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary);text-align:center}.rg-bet-limit-edit__buttons_MDAnL{row-gap:20px;display:flex;flex-direction:column}html.app__layout--desktop .rg-bet-limit-edit_EmYTU{padding:16px 16px 42px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rg-bet-limit-edit": `rg-bet-limit-edit_EmYTU`,
	"rg-bet-limit-edit__info": `rg-bet-limit-edit__info_dv0QN`,
	"rg-bet-limit-edit__buttons": `rg-bet-limit-edit__buttons_MDAnL`
};
export default ___CSS_LOADER_EXPORT___;
