// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.affiliate-list_LdnAL{display:flex;flex-flow:column nowrap;justify-content:flex-start;padding:0;margin:0;overflow:hidden;border-radius:5px}.affiliate-list_LdnAL .affiliate-list-item_kkWQT{margin-bottom:2px}.affiliate-list_LdnAL .affiliate-list-item_kkWQT:last-of-type,.affiliate-list_LdnAL .affiliate-list-item_kkWQT:only-child{margin-bottom:0}.affiliate-list--separate_cogj3 .history-list-item_ercVV{margin-bottom:8px;border-radius:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-list": `affiliate-list_LdnAL`,
	"affiliate-list-item": `affiliate-list-item_kkWQT`,
	"affiliate-list--separate": `affiliate-list--separate_cogj3`,
	"history-list-item": `history-list-item_ercVV`
};
export default ___CSS_LOADER_EXPORT___;
