// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-list-sport-headline_MbOAS{z-index:7;position:static;display:flex;align-items:center;justify-content:space-between;width:100%;height:56px;padding:0 0 0 8px}.sport-event-list-sport-headline__left_yTwYY{display:flex;flex-direction:row-reverse;align-items:center;justify-content:space-between;height:100%;overflow:hidden;white-space:nowrap}.sport-event-list-sport-headline__left--expandable_rxKoF{cursor:pointer}.sport-event-list-sport-headline__icon_tbaaD{display:flex;width:16px;height:16px;margin-right:8px;color:var(--White)}.sport-event-list-sport-headline__title_O7FdK{position:relative;display:flex;align-items:center}.sport-event-list-sport-headline__label_EJ3tx,.sport-event-list-sport-headline__title_O7FdK{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;overflow:hidden;white-space:nowrap}.sport-event-list-sport-headline__label_EJ3tx{width:fit-content;color:var(--TextDefault);text-overflow:ellipsis}.sport-event-list-sport-headline__right_nEx5f{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;flex-basis:auto;align-items:center;justify-content:flex-end;color:var(--TextSecondary);white-space:nowrap}.sport-event-list-sport-headline__expand-icon_j4xrv{min-width:24px;color:var(--TextDefault);cursor:pointer}html.app__layout--desktop .sport-event-list-sport-headline_MbOAS{padding:0 0 0 16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-list-sport-headline": `sport-event-list-sport-headline_MbOAS`,
	"sport-event-list-sport-headline__left": `sport-event-list-sport-headline__left_yTwYY`,
	"sport-event-list-sport-headline__left--expandable": `sport-event-list-sport-headline__left--expandable_rxKoF`,
	"sport-event-list-sport-headline__icon": `sport-event-list-sport-headline__icon_tbaaD`,
	"sport-event-list-sport-headline__title": `sport-event-list-sport-headline__title_O7FdK`,
	"sport-event-list-sport-headline__label": `sport-event-list-sport-headline__label_EJ3tx`,
	"sport-event-list-sport-headline__right": `sport-event-list-sport-headline__right_nEx5f`,
	"sport-event-list-sport-headline__expand-icon": `sport-event-list-sport-headline__expand-icon_j4xrv`
};
export default ___CSS_LOADER_EXPORT___;
