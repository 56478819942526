// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-select_zi9Nm{position:absolute;top:56px;right:0;bottom:0;left:0;display:flex;flex:1;flex-direction:column;padding:16px 0}.modal-select__foter_kL3dJ,.modal-select__search_Y0moA{flex:none}.modal-select__footer_kEFgN,.modal-select__search_Y0moA{padding:0 8px}.modal-select__search_Y0moA{margin:0 0 8px}.modal-select__content_Ivq4_{flex:1;overflow:hidden}.modal-select__footer_kEFgN{display:flex;margin:16px 0 0;overflow:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal-select": `modal-select_zi9Nm`,
	"modal-select__foter": `modal-select__foter_kL3dJ`,
	"modal-select__search": `modal-select__search_Y0moA`,
	"modal-select__footer": `modal-select__footer_kEFgN`,
	"modal-select__content": `modal-select__content_Ivq4_`
};
export default ___CSS_LOADER_EXPORT___;
