// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.countries-selector_Y4ppA{margin-top:-16px}.countries-selector__title_a0HiW{margin-top:16px;color:var(--TextSecondary)}.countries-selector__button_UCvVe,.countries-selector__list_ZnTnu{margin-bottom:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countries-selector": `countries-selector_Y4ppA`,
	"countries-selector__title": `countries-selector__title_a0HiW`,
	"countries-selector__button": `countries-selector__button_UCvVe`,
	"countries-selector__list": `countries-selector__list_ZnTnu`
};
export default ___CSS_LOADER_EXPORT___;
