import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createBlock as _createBlock, renderSlot as _renderSlot, Fragment as _Fragment, renderList as _renderList, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
    key: 0
};
import { ref, toRef, onActivated, onDeactivated, onMounted, onUnmounted, computed } from 'vue';
import { VSpinner } from '@components/spinner';
import { useSportlineLayoutSettings } from 'web/src/modules/sportline/composables/settings';
import { getMarketsSpecifiersKey } from 'web/src/modules/sportline/submodules/markets-grid/utils';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import MarketsListToolbar from 'web/src/modules/sportline/views/markets-list/components/MarketsListToolbar.vue';
import { unitTestElement } from './constants';
import { useMarketsList } from './composables/useMarketsList';
import MarketsListMarketGroup from './MarketGroup.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MarketsList',
    props: {
        sportEvent: {},
        representationSportFamily: {},
        isLoaded: {
            type: Boolean
        },
        isShowingLoadingIndicator: {
            type: Boolean
        },
        defaultLayoutType: {},
        isListItem: {
            type: Boolean
        },
        isMarketGroupsTabsEnabled: {
            type: Boolean
        },
        defaultMarketsLimit: {},
        hasStatistic: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const sportEvent = toRef(props, 'sportEvent', null);
        const representationSportFamily = toRef(props, 'representationSportFamily', null);
        const defaultMarketsLimit = toRef(props, 'defaultMarketsLimit', 0);
        const isLoaded = toRef(props, 'isLoaded', false);
        const isShowingLoadingIndicator = toRef(props, 'isShowingLoadingIndicator', false);
        const isListItem = toRef(props, 'isListItem', false);
        const isMarketGroupsTabsEnabled = toRef(props, 'isMarketGroupsTabsEnabled', false);
        const hasStatistic = toRef(props, 'hasStatistic', false);
        const defaultLayoutType = toRef(props, 'defaultLayoutType', null);
        const toolbar = ref(null);
        const marketHolder = ref();
        const columnOne = ref();
        const columnTwo = ref();
        const { isBetSwipeEventDetailsLayoutEnabled } = useSportlineLayoutSettings();
        const { isReadyToRender, hasAnyGroups, isDisplayOneColumnLayout, doShowTabs, doShowInlineStatisticSwitch, isStatisticSelected, isDisplayStatistic, isMoreMarketButtonVisible, gridFilters, displayedMarketGroupTabId, filteredGroups, oddGroups, evenGroups, onMarketTabClicked, onMoreMarketsClick, onStatisticSelectedChanged, onMarketGroupToggle, startWatchers, stopWatchers, setIsActivated } = useMarketsList({
            toolbar,
            marketHolder,
            columnOne,
            columnTwo,
            sportEvent,
            representationSportFamily,
            isLoaded,
            defaultLayoutType,
            isListItem,
            isMarketGroupsTabsEnabled,
            defaultMarketsLimit,
            hasStatistic,
            isPrimaryMarketFiltrationDisabled: computed(()=>false)
        });
        function getMarketsGridCellRenderKey(cell) {
            return `${cell.id}|${getMarketsSpecifiersKey(cell.markets)}`;
        }
        startWatchers();
        onActivated(()=>{
            setIsActivated(true);
        });
        onDeactivated(()=>{
            setIsActivated(false);
        });
        onMounted(()=>{
            setIsActivated(true);
        });
        onUnmounted(()=>{
            stopWatchers();
            setIsActivated(false);
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "marketHolder",
                ref: marketHolder,
                class: _normalizeClass({
                    [_ctx.$style['markets-list']]: true,
                    [_ctx.$style['markets-list--large-gaps']]: void 0
                })
            }, [
                _unref(isReadyToRender) ? isLoaded.value ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _unref(doShowTabs) ? _withDirectives((_openBlock(), _createBlock(MarketsListToolbar, {
                        key: 0,
                        ref_key: "toolbar",
                        ref: toolbar,
                        "grid-filters": _unref(gridFilters),
                        "active-market-group-id": _unref(displayedMarketGroupTabId),
                        "do-show-all-markets-tab": _unref(hasAnyGroups),
                        "do-show-inline-statistic-switch": _unref(doShowInlineStatisticSwitch),
                        "has-statistic": hasStatistic.value,
                        "is-statistic-selected": _unref(isStatisticSelected),
                        class: _normalizeClass({
                            [_ctx.$style['markets-list-toolbar']]: true,
                            [_ctx.$style['markets-list-toolbar--list-item']]: isListItem.value
                        }),
                        onMarketTabClick: _unref(onMarketTabClicked),
                        onChangeStatisticSelected: _unref(onStatisticSelectedChanged)
                    }, null, 8, [
                        "grid-filters",
                        "active-market-group-id",
                        "do-show-all-markets-tab",
                        "do-show-inline-statistic-switch",
                        "has-statistic",
                        "is-statistic-selected",
                        "class",
                        "onMarketTabClick",
                        "onChangeStatisticSelected"
                    ])), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: _unref(unitTestElement).toolbar
                            }
                        ]
                    ]) : _unref(isDisplayStatistic) ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['markets-list-separator'])
                    }, null, 2)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['markets-list-content__markets']]: true,
                            [_ctx.$style['hidden']]: !_unref(hasAnyGroups) && !hasStatistic.value,
                            [_ctx.$style['markets-list-content__markets--2-columns']]: !_unref(isDisplayOneColumnLayout)
                        })
                    }, [
                        _unref(isDisplayOneColumnLayout) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            ref_key: "columnOne",
                            ref: columnOne,
                            class: _normalizeClass(_ctx.$style['markets-list-content__column'])
                        }, [
                            _unref(isDisplayStatistic) ? (_openBlock(), _createElementBlock(_Fragment, {
                                key: 0
                            }, [
                                _renderSlot(_ctx.$slots, "statistic"),
                                _renderSlot(_ctx.$slots, "post-statistic")
                            ], 64)) : (_openBlock(true), _createElementBlock(_Fragment, {
                                key: 1
                            }, _renderList(_unref(filteredGroups), (group)=>(_openBlock(), _createBlock(MarketsListMarketGroup, {
                                    key: getMarketsGridCellRenderKey(group),
                                    group: group,
                                    "sportline-event": sportEvent.value,
                                    onMarketGroupToggle: _unref(onMarketGroupToggle)
                                }, null, 8, [
                                    "group",
                                    "sportline-event",
                                    "onMarketGroupToggle"
                                ]))), 128))
                        ], 2)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createElementVNode("div", {
                                ref_key: "columnOne",
                                ref: columnOne,
                                class: _normalizeClass(_ctx.$style['markets-list-content__column'])
                            }, [
                                _unref(isDisplayStatistic) ? (_openBlock(true), _createElementBlock(_Fragment, {
                                    key: 0
                                }, _renderList(_unref(filteredGroups), (group)=>(_openBlock(), _createBlock(MarketsListMarketGroup, {
                                        key: getMarketsGridCellRenderKey(group),
                                        group: group,
                                        "sportline-event": sportEvent.value,
                                        onMarketGroupToggle: _unref(onMarketGroupToggle)
                                    }, null, 8, [
                                        "group",
                                        "sportline-event",
                                        "onMarketGroupToggle"
                                    ]))), 128)) : (_openBlock(true), _createElementBlock(_Fragment, {
                                    key: 1
                                }, _renderList(_unref(oddGroups), (group)=>(_openBlock(), _createBlock(MarketsListMarketGroup, {
                                        key: getMarketsGridCellRenderKey(group),
                                        group: group,
                                        "sportline-event": sportEvent.value,
                                        onMarketGroupToggle: _unref(onMarketGroupToggle)
                                    }, null, 8, [
                                        "group",
                                        "sportline-event",
                                        "onMarketGroupToggle"
                                    ]))), 128))
                            ], 2),
                            _createElementVNode("div", {
                                ref_key: "columnTwo",
                                ref: columnTwo,
                                class: _normalizeClass(_ctx.$style['markets-list-content__column'])
                            }, [
                                _unref(isDisplayStatistic) ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    _renderSlot(_ctx.$slots, "statistic"),
                                    _renderSlot(_ctx.$slots, "post-statistic")
                                ], 64)) : (_openBlock(true), _createElementBlock(_Fragment, {
                                    key: 1
                                }, _renderList(_unref(evenGroups), (group)=>(_openBlock(), _createBlock(MarketsListMarketGroup, {
                                        key: getMarketsGridCellRenderKey(group),
                                        group: group,
                                        "sportline-event": sportEvent.value,
                                        onMarketGroupToggle: _unref(onMarketGroupToggle)
                                    }, null, 8, [
                                        "group",
                                        "sportline-event",
                                        "onMarketGroupToggle"
                                    ]))), 128))
                            ], 2)
                        ], 64))
                    ], 2),
                    isListItem.value ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        class: _normalizeClass({
                            [_ctx.$style['markets-list-content__show-more-markets']]: true,
                            [_ctx.$style['markets-list-content__show-more-markets--hidden']]: !_unref(isMoreMarketButtonVisible)
                        }),
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onMoreMarketsClick) && _unref(onMoreMarketsClick)(...args);
                        })
                    }, _toDisplayString(_ctx.$t('WEB2_SHOW_MORE')), 3)) : _createCommentVNode("", true)
                ], 64)) : isShowingLoadingIndicator.value ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['markets-list-content__loader'])
                }, [
                    _createVNode(_unref(VSpinner))
                ], 2)) : _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", _hoisted_1))
            ], 2)), [
                [
                    _directive_auto_id,
                    'MarketsList'
                ],
                [
                    _unref(vDataTestUnit),
                    {
                        el: _unref(unitTestElement).holder
                    }
                ]
            ]);
        };
    }
});
