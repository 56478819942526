import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "autofocus",
    "checked",
    "disabled",
    "readonly"
];
import { useSlots } from 'vue';
import { useAutofocus } from 'web/src/components/Input/composables';
import { useVSwitch } from './composables';
import { SwitchKind } from './enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VSwitch',
    props: {
        name: {},
        checked: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        readonly: {
            type: Boolean
        },
        label: {
            default: ''
        },
        hint: {},
        kind: {
            default: SwitchKind.DEFAULT
        },
        hidden: {
            type: Boolean
        },
        columnChange: {
            type: Boolean
        },
        autofocus: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "focus",
        "blur",
        "change",
        "input",
        "animation-completed"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const { showLabel, emitFocus, emitBlur, onClick, onChange, onInput, onTransitionEnd } = useVSwitch(props, emit, slots);
        const { focusable, focus } = useAutofocus(props);
        __expose({
            focus
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("label", {
                class: _normalizeClass({
                    [_ctx.$style['switch']]: true,
                    [_ctx.$style['switch--disabled']]: _ctx.disabled,
                    [_ctx.$style['switch--hidden']]: _ctx.hidden,
                    [_ctx.$style[`switch--${props.kind}`]]: true,
                    [_ctx.$style['switch--checked']]: _ctx.checked,
                    [_ctx.$style['switch--theme-dark']]: props.kind === _unref(SwitchKind).THEME,
                    [_ctx.$style['switch--column-change']]: _ctx.columnChange
                }),
                "data-test-id": "switch-root",
                onClick: _cache[5] || (_cache[5] = //@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onClick) && _unref(onClick)(...args);
                })
            }, [
                _unref(showLabel) ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['switch__text'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['switch__title']),
                        "data-test-id": "switch-label"
                    }, [
                        _renderSlot(_ctx.$slots, "default", {}, ()=>[
                                _createTextVNode(_toDisplayString(_ctx.label), 1)
                            ])
                    ], 2)
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['switch__icon']),
                    "data-test-id": "switch-icon"
                }, [
                    _createElementVNode("input", {
                        ref_key: "focusable",
                        ref: focusable,
                        "data-test-id": "switch-input",
                        autofocus: process.env.VUE_APP_OS_IOS ? void 0 : _ctx.autofocus,
                        checked: _ctx.checked,
                        disabled: _ctx.disabled,
                        readonly: _ctx.readonly,
                        class: _normalizeClass(_ctx.$style['switch__input']),
                        type: "checkbox",
                        onChange: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onChange) && _unref(onChange)(...args);
                        }, [
                            "stop"
                        ])),
                        onInput: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onInput) && _unref(onInput)(...args);
                        }, [
                            "stop"
                        ])),
                        onBlur: _cache[2] || (_cache[2] = _withModifiers(//@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(emitBlur) && _unref(emitBlur)(...args);
                        }, [
                            "stop"
                        ])),
                        onFocus: _cache[3] || (_cache[3] = _withModifiers(//@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(emitFocus) && _unref(emitFocus)(...args);
                        }, [
                            "stop"
                        ]))
                    }, null, 42, _hoisted_1),
                    _renderSlot(_ctx.$slots, "icon"),
                    _createElementVNode("span", {
                        class: _normalizeClass({
                            [_ctx.$style['switch__bg']]: true,
                            [_ctx.$style['switch__bg--inner']]: !_ctx.checked
                        })
                    }, null, 2),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['switch__switcher']),
                        onTransitionend: _cache[4] || (_cache[4] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(onTransitionEnd) && _unref(onTransitionEnd)(...args);
                        })
                    }, null, 34)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VSwitch'
                ]
            ]);
        };
    }
});
