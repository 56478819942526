import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { defineAsyncComponent, onBeforeMount, toRef } from 'vue';
import { TimeFormats } from 'web/src/utils/DateTime';
import { useTheme } from 'web/src/modules/theme/composables';
import { useI18nLocale } from 'web/src/modules/i18n/composables';
import { useVDatepicker } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VDatepicker',
    props: {
        value: {},
        clearable: {
            type: Boolean
        },
        showCalendarIcon: {
            type: Boolean
        },
        doDisablePastDates: {
            type: Boolean
        },
        roundTo: {},
        min: {},
        max: {}
    },
    emits: [
        "change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const VueDatePicker = defineAsyncComponent(()=>import('@vuepic/vue-datepicker'));
        const min = toRef(props, 'min');
        const max = toRef(props, 'max');
        const roundTo = toRef(props, 'roundTo');
        const format = TimeFormats.dayMonthYear;
        const { minDate, maxDate, onChange } = useVDatepicker({
            min,
            max,
            roundTo
        }, emit);
        const { isDark } = useTheme();
        const { lang } = useI18nLocale();
        onBeforeMount(()=>{
            import('@vuepic/vue-datepicker/dist/main.css');
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['date-picker'])
            }, [
                _createVNode(_unref(VueDatePicker), {
                    "model-value": _ctx.value,
                    format: _unref(format),
                    "enable-time-picker": false,
                    "min-date": _unref(minDate),
                    "max-date": _unref(maxDate),
                    dark: _unref(isDark),
                    locale: _unref(lang),
                    teleport: "",
                    "auto-apply": "",
                    transitions: false,
                    "onUpdate:modelValue": _unref(onChange)
                }, null, 8, [
                    "model-value",
                    "format",
                    "min-date",
                    "max-date",
                    "dark",
                    "locale",
                    "onUpdate:modelValue"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VDatepicker'
                ]
            ]);
        };
    }
});
