// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-balance_XBBtS{flex-direction:row-reverse;cursor:pointer;background-color:var(--OpacityBrandDefault)}.main-balance__icon_Q2zuP{color:var(--ColorBackground3)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main-balance": `main-balance_XBBtS`,
	"main-balance__icon": `main-balance__icon_Q2zuP`
};
export default ___CSS_LOADER_EXPORT___;
