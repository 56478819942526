import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ActionLink',
    props: {
        title: {}
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: "#",
                class: _normalizeClass(_ctx.$style['action-link']),
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event)=>emit('click'), [
                    "prevent"
                ]))
            }, [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'ActionLink'
                ],
                [
                    _unref(vDataTestUnit),
                    {
                        el: 'action-link'
                    }
                ]
            ]);
        };
    }
});
