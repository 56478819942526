// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .affiliate-list-item__wrapper_QMiJO{display:flex;flex-flow:row nowrap;align-items:center;justify-content:space-between;padding:12px;background-color:var(--Layer1);border-radius:5px}html.app__layout--desktop .affiliate-list-item__container_nbJUm{width:100%}html.app__layout--desktop .affiliate-list-item__row_mCQmv{display:flex;justify-content:space-between;width:100%}html.app__layout--desktop .affiliate-list-item__row--date_YmHlQ,html.app__layout--desktop .affiliate-list-item__row--description_j4gn4,html.app__layout--desktop .affiliate-list-item__row--meta_zuaWM{margin-bottom:8px}html.app__layout--desktop .affiliate-list-item__column--left_C88XF{flex:1 0 auto;max-width:calc(100% - 112px)}html.app__layout--desktop .affiliate-list-item__column--right_NYNBh{display:flex;flex:0 0 auto;flex-basis:94px;flex-direction:column;align-items:flex-end;text-align:right}html.app__layout--desktop .affiliate-list-item__column--block_TYTHy{display:flex;flex-direction:column}html.app__layout--desktop .affiliate-list-item_I73y7.list-item--paneled_B5gec{margin-bottom:8px}html.app__layout--desktop .affiliate-list-item_I73y7.list-item--paneled_B5gec:last-child{margin-bottom:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"affiliate-list-item__wrapper": `affiliate-list-item__wrapper_QMiJO`,
	"affiliate-list-item__container": `affiliate-list-item__container_nbJUm`,
	"affiliate-list-item__row": `affiliate-list-item__row_mCQmv`,
	"affiliate-list-item__row--date": `affiliate-list-item__row--date_YmHlQ`,
	"affiliate-list-item__row--description": `affiliate-list-item__row--description_j4gn4`,
	"affiliate-list-item__row--meta": `affiliate-list-item__row--meta_zuaWM`,
	"affiliate-list-item__column--left": `affiliate-list-item__column--left_C88XF`,
	"affiliate-list-item__column--right": `affiliate-list-item__column--right_NYNBh`,
	"affiliate-list-item__column--block": `affiliate-list-item__column--block_TYTHy`,
	"affiliate-list-item": `affiliate-list-item_I73y7`,
	"list-item--paneled": `list-item--paneled_B5gec`
};
export default ___CSS_LOADER_EXPORT___;
