// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_OKbsB{padding:0 16px 8px}.search__input_hc3Of{margin:0 8px}html.app__layout--desktop .search_OKbsB{padding:0 24px 24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `search_OKbsB`,
	"search__input": `search__input_hc3Of`
};
export default ___CSS_LOADER_EXPORT___;
