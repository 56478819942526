import { computed, toRef } from 'vue';
export default function useVTabs(props) {
    const items = toRef(()=>props.items);
    const activeId = toRef(()=>props.activeId);
    const containerRole = computed(()=>props.isNavigation ? 'navigation' : 'tablist');
    const isIconTabs = computed(()=>{
        if (!items.value?.length) return false;
        const tab = items.value[0];
        return tab && 'icon' in tab && !!tab.icon;
    });
    const activeTabIndex = computed(()=>items.value?.findIndex((item)=>item.id === activeId.value) ?? -1);
    const itemsList = computed(()=>items.value?.map((item)=>{
            const { isNavigation } = props;
            return {
                ...item,
                active: item.id === activeId.value,
                isNavigation
            };
        }) ?? []);
    return {
        activeTabIndex,
        containerRole,
        isIconTabs,
        itemsList
    };
}
