// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader_pceOd{z-index:110;position:absolute;top:0;right:0;bottom:0;left:0;display:flex;align-items:center;justify-content:center;background-color:hsla(var(--huecolordtlayer0),var(--saturationcolordtlayer0),var(--lightnesscolordtlayer0),.7)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preloader": `preloader_pceOd`
};
export default ___CSS_LOADER_EXPORT___;
