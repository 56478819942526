// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper_qV07J{position:relative;width:100%;height:100%;overflow:hidden}.swiper--outer-padding-8_CXTSV .swiper__wrapper_m29Xp{padding:0 8px}.swiper--outer-padding-16_qQ23S .swiper__wrapper_m29Xp{padding:16px}.swiper--outer-padding-0-12_SinSE .swiper__wrapper_m29Xp{padding:0 12px}.swiper--outer-padding-0-16_Oh_YL .swiper__wrapper_m29Xp{padding:0 16px}.swiper--overflow-visible_Gv0jK{overflow:visible}.swiper__wrapper_m29Xp{position:relative;display:flex;width:100%;transition-timing-function:ease-out;transition-property:transform;will-change:transform}.swiper--full-height_tbKtp{display:flex;flex-direction:column}.swiper--full-height_tbKtp .swiper__event-container_p2Pch,.swiper--full-height_tbKtp .swiper__wrapper_m29Xp{flex:1;height:100%}.swiper--top-margin-8_Yxz6o .swiper__wrapper_m29Xp{margin-top:8px}.swiper--block_uxpay .swiper__wrapper_m29Xp>.swiper-slide{width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper": `swiper_qV07J`,
	"swiper--outer-padding-8": `swiper--outer-padding-8_CXTSV`,
	"swiper__wrapper": `swiper__wrapper_m29Xp`,
	"swiper--outer-padding-16": `swiper--outer-padding-16_qQ23S`,
	"swiper--outer-padding-0-12": `swiper--outer-padding-0-12_SinSE`,
	"swiper--outer-padding-0-16": `swiper--outer-padding-0-16_Oh_YL`,
	"swiper--overflow-visible": `swiper--overflow-visible_Gv0jK`,
	"swiper--full-height": `swiper--full-height_tbKtp`,
	"swiper__event-container": `swiper__event-container_p2Pch`,
	"swiper--top-margin-8": `swiper--top-margin-8_Yxz6o`,
	"swiper--block": `swiper--block_uxpay`
};
export default ___CSS_LOADER_EXPORT___;
