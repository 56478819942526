import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useWebPushModal } from './composables';
import WebPushPhoneModal from '../WebPushPhoneModal/WebPushPhoneModal.vue';
import WebPushDesktopModal from '../WebPushDesktopModal/WebPushDesktopModal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WebPushModalRouteComponent',
    setup (__props) {
        const { modalProps, onClose, onButtonClick } = useWebPushModal();
        return (_ctx, _cache)=>(_openBlock(), _createBlock(WebPushDesktopModal, _mergeProps({
                key: 1
            }, _unref(modalProps), {
                onButtonClick: _unref(onButtonClick),
                onClose: _unref(onClose)
            }), null, 16, [
                "onButtonClick",
                "onClose"
            ]));
    }
});
