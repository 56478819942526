// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-title_THGS3{font-family:var(--landingTitleFontFamily);color:var(--landingTitleColor)}.landing-title_THGS3 span{color:#0000;background:var(--landingTitleStrongColor);-webkit-background-clip:text;background-clip:text}.landing-title--inverse_WRtwT{color:var(--landingTitleColorInverse)}.landing-title--primary_n6SXV{font-size:var(--landingTtilePrimaryFontSize);font-style:var(--landingTtilePrimaryTextStyle);font-weight:var(--landingTtilePrimaryFontWeight);line-height:var(--landingTtilePrimaryLineHeight)}@media(min-width:1024px){.landing-title--primary_n6SXV{font-size:var(--landingTtilePrimaryFontSizeDesk);line-height:var(--landingTtilePrimaryLineHeightDesk)}}.landing-title--secondary_Ijrmp{font-size:var(--landingTitleSecondaryFontSize);font-weight:var(--landingTtileSecondaryFontWeight);line-height:var(--landingTtileSecondaryLineHeight)}@media(min-width:1024px){.landing-title--secondary_Ijrmp{font-size:var(--landingTitleSecondaryFontSizeDesk);line-height:var(--landingTtileSecondaryLineHeightDesk)}}.landing-title--tertiary_qGp7D{font-size:var(--landingTitleTertiaryFontSize);font-weight:var(--landingTtileTertiaryFontWeight);line-height:var(--landingTtileTertiaryLineHeight);letter-spacing:var(--landingTtileTertiaryLetterSpacing)}@media(min-width:1024px){.landing-title--tertiary_qGp7D{font-size:var(--landingLabelTertiaryFontSizeDesk);line-height:var(--landingLabelTertiaryLineHeightDesk)}}.landing-title_THGS3 :deep(span){color:#0000;background:var(--landingTitleStrongColor);-webkit-background-clip:text;background-clip:text;-webkit-text-fill-color:#0000}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-title": `landing-title_THGS3`,
	"landing-title--inverse": `landing-title--inverse_WRtwT`,
	"landing-title--primary": `landing-title--primary_n6SXV`,
	"landing-title--secondary": `landing-title--secondary_Ijrmp`,
	"landing-title--tertiary": `landing-title--tertiary_qGp7D`
};
export default ___CSS_LOADER_EXPORT___;
