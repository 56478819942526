// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .tooltip_v0GEO{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;top:50%;display:flex;padding:16px;background:var(--TooltipBackground);border-radius:8px;box-shadow:var(--FloatingTopBarShadow);position:fixed;left:50%;z-index:72;margin:auto;white-space:nowrap;transform:translate(-50%)}html.app__layout--desktop .tooltip--profile_r5VBq{top:calc(50% - 24px);left:calc(50% + 126px)}html.app__layout--desktop .tooltip__text_DcMSG{color:var(--TextDefault);filter:invert(100%)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `tooltip_v0GEO`,
	"tooltip--profile": `tooltip--profile_r5VBq`,
	"tooltip__text": `tooltip__text_DcMSG`
};
export default ___CSS_LOADER_EXPORT___;
