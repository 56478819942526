import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { VPhotoPlaceholderDirection } from './enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VPhotoPlaceholder',
    props: {
        direction: {
            default: VPhotoPlaceholderDirection.Vertical
        },
        imageSrc: {
            default: ''
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['v-photo-placeholder']]: true,
                    [_ctx.$style['v-photo-placeholder--vertical']]: _ctx.direction === _unref(VPhotoPlaceholderDirection).Vertical,
                    [_ctx.$style['v-photo-placeholder--horizontal']]: _ctx.direction === _unref(VPhotoPlaceholderDirection).Horizontal
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass([
                        _ctx.$style['v-photo-placeholder__corner'],
                        _ctx.$style['v-photo-placeholder__corner--top']
                    ])
                }, null, 2),
                _createElementVNode("div", {
                    class: _normalizeClass([
                        _ctx.$style['v-photo-placeholder__corner'],
                        _ctx.$style['v-photo-placeholder__corner--top-right']
                    ])
                }, null, 2),
                _createElementVNode("div", {
                    class: _normalizeClass([
                        _ctx.$style['v-photo-placeholder__corner'],
                        _ctx.$style['v-photo-placeholder__corner--bottom']
                    ])
                }, null, 2),
                _createElementVNode("div", {
                    class: _normalizeClass([
                        _ctx.$style['v-photo-placeholder__corner'],
                        _ctx.$style['v-photo-placeholder__corner--bottom-right']
                    ])
                }, null, 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['v-photo-placeholder__frame-wrapper'])
                }, [
                    _ctx.imageSrc ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['v-photo-placeholder__frame']),
                        alt: "photo",
                        src: _ctx.imageSrc
                    }, null, 10, _hoisted_1)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['v-photo-placeholder__icon'])
                    }, [
                        _createVNode(_unref(VIcon), {
                            name: _unref(IconName).CAMERA,
                            size: _unref(IconSize).SIZE_24
                        }, null, 8, [
                            "name",
                            "size"
                        ])
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VPhotoPlaceholder'
                ]
            ]);
        };
    }
});
