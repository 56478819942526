import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = [
    "src",
    "alt"
];
const _hoisted_2 = {
    key: 1
};
import { ObserveVisibility } from 'vue3-observe-visibility';
import { IconSize } from '@leon-hub/icons';
import { SSpinner } from '@components/spinner';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import VLogoLoader from 'web/src/components/Loader/VLogoLoader/VLogoLoader.vue';
import DefaultFadeTransition from 'web/src/components/Transitions/DefaultFadeTransition/DefaultFadeTransition.vue';
import { useVLazyImage } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLazyImage',
    props: {
        src: {},
        alt: {},
        showLoader: {
            type: Boolean
        },
        lightLoader: {
            type: Boolean
        },
        transparentLoaderBg: {
            type: Boolean
        },
        objectFit: {
            default: ObjectFitOption.FILL
        },
        objectPosition: {},
        forceHideImage: {
            type: Boolean
        },
        isGradient: {
            type: Boolean
        },
        isRelative: {
            type: Boolean
        }
    },
    emits: [
        "error",
        "image-loaded",
        "click"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars, autofix/no-unused-vars
        const vObserveVisibility = ObserveVisibility;
        const { isNativeLazyMethod, imageLoadCompleted, visibilityChanged, forceShowImage, onImageLoad, showImage } = useVLazyImage(props, emit, {
            initial: false
        });
        function emitError() {
            emit('error');
        }
        function emitClick(event) {
            emit('click', event);
        }
        __expose({
            forceShowImage
        });
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['lazy-image']),
                onClick: emitClick
            }, [
                _unref(showImage) ? _withDirectives((_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.src,
                    alt: _ctx.alt,
                    class: _normalizeClass({
                        [_ctx.$style['lazy-image__img']]: true,
                        [_ctx.$style[`lazy-image__img--${_ctx.objectFit}`]]: _ctx.objectFit !== _unref(ObjectFitOption).FILL,
                        [_ctx.$style[`lazy-image__img-position--${_ctx.objectPosition}`]]: !!_ctx.objectPosition,
                        [_ctx.$style['lazy-image__img--is-relative']]: _ctx.isRelative
                    }),
                    loading: "lazy",
                    fetchpriority: "low",
                    onLoad: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onImageLoad) && _unref(onImageLoad)(...args);
                    }),
                    onError: emitError
                }, null, 42, _hoisted_1)), [
                    [
                        _directive_data_test,
                        {
                            el: 'lazy-image'
                        }
                    ]
                ]) : _createCommentVNode("", true),
                _unref(isNativeLazyMethod)() || _unref(imageLoadCompleted) ? _createCommentVNode("", true) : _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, null, 512)), [
                    [
                        _unref(vObserveVisibility),
                        {
                            callback: _unref(visibilityChanged),
                            once: true
                        }
                    ]
                ]),
                _createVNode(DefaultFadeTransition, {
                    "is-transition-disabled": _ctx.transparentLoaderBg
                }, {
                    default: _withCtx(()=>[
                            _unref(imageLoadCompleted) ? _createCommentVNode("", true) : _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass({
                                    [_ctx.$style['lazy-image__placeholder']]: true,
                                    [_ctx.$style['lazy-image__placeholder--loader']]: _ctx.showLoader,
                                    [_ctx.$style['lazy-image__placeholder--loader-transparent']]: _ctx.transparentLoaderBg
                                })
                            }, [
                                _ctx.showLoader ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 0
                                }, [
                                    (_openBlock(), _createBlock(VLogoLoader, {
                                        key: 1,
                                        class: _normalizeClass(_ctx.$style['lazy-image__loader-svg']),
                                        "is-fixed-light-logo": _ctx.lightLoader,
                                        "is-gradient": _ctx.isGradient,
                                        "is-small": ""
                                    }, null, 8, [
                                        "class",
                                        "is-fixed-light-logo",
                                        "is-gradient"
                                    ]))
                                ], 64)) : _createCommentVNode("", true)
                            ], 2)), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'lazy-image-placeholder'
                                    }
                                ]
                            ])
                        ]),
                    _: 1
                }, 8, [
                    "is-transition-disabled"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VLazyImage'
                ]
            ]);
        };
    }
});
