// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-input__wrapper_UL1EZ{display:flex}.phone-input__suffix_eZ4sW{flex:1}.phone-input__prefix_J8gFf{margin-right:8px}.phone-input__masked-placeholder_CgKF2{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;position:absolute;top:0;display:flex;align-items:center;color:var(--TextSecondary);white-space:nowrap;pointer-events:none}.phone-input__masked-placeholder_CgKF2,.phone-input__masked-placeholder--large_EoPHh{right:12px;left:12px;height:44px;padding:14px 0}.phone-input__masked-placeholder--hidden_KNoCG{z-index:-1;opacity:0}.phone-input__logo_wKIaN{width:56px;height:44px;margin-left:8px;border-radius:5px}.phone-input__clear-button_GxIWA{position:relative;right:65px}.phone-input__prefix-inner_sxdPg{display:flex;align-items:center;height:44px;padding:14px 12px;background:#0000;border:none;border-radius:5px;transition:box-shadow .15s ease-in-out}.phone-input__prefix-inner--with-icon_ZJ9kW:hover{box-shadow:inset 0 0 0 1px var(--Blue);cursor:pointer;background-color:var(--Layer1)}.phone-input__prefix-inner--large_S0JPu{height:44px;padding:14px 12px}.phone-input__prefix-text_k7PX8{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;min-width:16px;color:var(--TextDefault)}.phone-input__flag_BBipW{display:inline-flex;margin-right:8px}.phone-input__arrow_QzDU7{margin-left:8px;fill:var(--TextSecondary)}html.app__os--ios .phone-input__prefix-text_k7PX8{letter-spacing:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phone-input__wrapper": `phone-input__wrapper_UL1EZ`,
	"phone-input__suffix": `phone-input__suffix_eZ4sW`,
	"phone-input__prefix": `phone-input__prefix_J8gFf`,
	"phone-input__masked-placeholder": `phone-input__masked-placeholder_CgKF2`,
	"phone-input__masked-placeholder--large": `phone-input__masked-placeholder--large_EoPHh`,
	"phone-input__masked-placeholder--hidden": `phone-input__masked-placeholder--hidden_KNoCG`,
	"phone-input__logo": `phone-input__logo_wKIaN`,
	"phone-input__clear-button": `phone-input__clear-button_GxIWA`,
	"phone-input__prefix-inner": `phone-input__prefix-inner_sxdPg`,
	"phone-input__prefix-inner--with-icon": `phone-input__prefix-inner--with-icon_ZJ9kW`,
	"phone-input__prefix-inner--large": `phone-input__prefix-inner--large_S0JPu`,
	"phone-input__prefix-text": `phone-input__prefix-text_k7PX8`,
	"phone-input__flag": `phone-input__flag_BBipW`,
	"phone-input__arrow": `phone-input__arrow_QzDU7`
};
export default ___CSS_LOADER_EXPORT___;
