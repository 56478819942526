// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status_w4zQ9{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextSecondary);background-color:var(--Highlight);display:flex;align-items:center;justify-content:center;width:100%;min-height:40px;padding:14px 16px;text-align:center;border-radius:5px}.status--success_PCuTX{color:var(--BrandDefault);background-color:var(--OpacityBrandDefault)}.status--lowercase_DF257{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;padding:10px 16px;text-transform:none}.status__icon_I8Xjc{flex-shrink:0;margin-right:4px;fill:currentcolor}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": `status_w4zQ9`,
	"status--success": `status--success_PCuTX`,
	"status--lowercase": `status--lowercase_DF257`,
	"status__icon": `status__icon_I8Xjc`
};
export default ___CSS_LOADER_EXPORT___;
