// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiline-text-input__input_p1v0F{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;width:100%;min-height:44px;padding:0 12px;color:var(--TextDefault);background-color:#0000;caret-color:var(--Blue);border:none;padding:14px 12px;display:block;resize:none}.multiline-text-input__input_p1v0F::placeholder{color:#0000}.multiline-text-input__input_p1v0F:active,.multiline-text-input__input_p1v0F:focus{z-index:1;color:var(--TextDefault)}.multiline-text-input__input_p1v0F:disabled{color:var(--TextSecondary);-webkit-text-fill-color:var(--TextSecondary);opacity:1}html.app__os--windows .multiline-text-input__input_p1v0F{scrollbar-width:thin;scrollbar-color:var(--Highlight) var(--Layer0)}html.app__os--windows .multiline-text-input__input_p1v0F::-webkit-scrollbar{width:16px}html.app__os--windows .multiline-text-input__input_p1v0F::-webkit-scrollbar-thumb{min-height:30px;background-color:var(--Highlight);background-clip:content-box;border:4px solid #0000;border-radius:8px}.multiline-text-input__input_p1v0F::placeholder,.multiline-text-input__input_p1v0F:focus::placeholder{color:var(--TextSecondary)}.multiline-text-input--error_ISBYK:not(.multiline-text-input--focus_wcSiT) .multiline-text-input__input_p1v0F{color:var(--TextDefault)}.multiline-text-input--error_ISBYK:not(.multiline-text-input--focus_wcSiT) .multiline-text-input__input_p1v0F::placeholder{color:var(--ErrorText)}.multiline-text-input--error_ISBYK:not(.multiline-text-input--focus_wcSiT) .multiline-text-input__input_p1v0F:-webkit-autofill{-webkit-text-fill-color:var(--TextDefault)}.multiline-text-input__input--with-suffix_lQnfz{padding-right:40px}.multiline-text-input--large_eNw8V .multiline-text-input__input_p1v0F{min-height:44px;padding:0 12px;border-radius:5px;padding:14px 12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiline-text-input__input": `multiline-text-input__input_p1v0F`,
	"multiline-text-input--error": `multiline-text-input--error_ISBYK`,
	"multiline-text-input--focus": `multiline-text-input--focus_wcSiT`,
	"multiline-text-input__input--with-suffix": `multiline-text-input__input--with-suffix_lQnfz`,
	"multiline-text-input--large": `multiline-text-input--large_eNw8V`
};
export default ___CSS_LOADER_EXPORT___;
