// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sport-event-list-markets-dropdown_g8HJo{z-index:7;position:relative;height:100%;cursor:pointer;-webkit-user-select:none;user-select:none}.sport-event-list-markets-dropdown__list_h83L3{display:flex;flex-direction:column;padding:2px;margin:0;list-style:none;background-color:var(--Layer2);border-radius:5px;box-shadow:0 8px 16px #0000004d}.sport-event-list-markets-dropdown__list-item_zr83o{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;padding:12px;color:var(--TextDefault)}.sport-event-list-markets-dropdown__list-item--selected_U_RFL,.sport-event-list-markets-dropdown__list-item_zr83o:hover{color:var(--Layer2);cursor:pointer;background-color:var(--TextDefault);border-radius:3px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-list-markets-dropdown": `sport-event-list-markets-dropdown_g8HJo`,
	"sport-event-list-markets-dropdown__list": `sport-event-list-markets-dropdown__list_h83L3`,
	"sport-event-list-markets-dropdown__list-item": `sport-event-list-markets-dropdown__list-item_zr83o`,
	"sport-event-list-markets-dropdown__list-item--selected": `sport-event-list-markets-dropdown__list-item--selected_U_RFL`
};
export default ___CSS_LOADER_EXPORT___;
