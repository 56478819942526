// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-wrapper_bcGCl{position:relative;z-index:1}.dropdown-wrapper__button_K0oXc{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;width:100%;min-height:44px;padding:0 12px;color:var(--TextDefault);background-color:#0000;position:relative;text-align:left;cursor:pointer;caret-color:var(--Blue);-webkit-user-select:initial;user-select:auto;border:none}.dropdown-wrapper__button_K0oXc:disabled{color:var(--TextSecondary);cursor:default;opacity:1;-webkit-text-fill-color:var(--TextSecondary)}.dropdown-wrapper__button--empty_QQLqb{color:var(--TextSecondary)}.dropdown-wrapper__button--error_xHKNG{color:var(--TextDefault)}.dropdown-wrapper__button--icon-prefix_fFhON,.dropdown-wrapper__button--icon-prefix_fFhON.dropdown-wrapper__button--large_zw6uL{padding-left:36px}.dropdown-wrapper__button--icon-suffix_JIswz,.dropdown-wrapper__button--icon-suffix_JIswz.dropdown-wrapper__button--large_zw6uL{padding-right:36px}.dropdown-wrapper__button--empty_QQLqb.dropdown-wrapper__button--error_xHKNG .dropdown-wrapper__button_K0oXc{color:var(--ErrorText)}.dropdown-wrapper__button--hidden_wZEFw{visibility:hidden}.dropdown-wrapper__button--large_zw6uL{min-height:44px;padding:0 12px;border-radius:5px}.dropdown-wrapper__button-content_VqA3M{display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;pointer-events:none}.dropdown-wrapper__flag__cJsp{position:absolute;top:15px;left:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown-wrapper": `dropdown-wrapper_bcGCl`,
	"dropdown-wrapper__button": `dropdown-wrapper__button_K0oXc`,
	"dropdown-wrapper__button--empty": `dropdown-wrapper__button--empty_QQLqb`,
	"dropdown-wrapper__button--error": `dropdown-wrapper__button--error_xHKNG`,
	"dropdown-wrapper__button--icon-prefix": `dropdown-wrapper__button--icon-prefix_fFhON`,
	"dropdown-wrapper__button--large": `dropdown-wrapper__button--large_zw6uL`,
	"dropdown-wrapper__button--icon-suffix": `dropdown-wrapper__button--icon-suffix_JIswz`,
	"dropdown-wrapper__button--hidden": `dropdown-wrapper__button--hidden_wZEFw`,
	"dropdown-wrapper__button-content": `dropdown-wrapper__button-content_VqA3M`,
	"dropdown-wrapper__flag": `dropdown-wrapper__flag__cJsp`
};
export default ___CSS_LOADER_EXPORT___;
