import { defineStore } from 'pinia';
import { computed, ref, toRef } from 'vue';
import { CaptchaStatus, CaptchaType } from '@leon-hub/api-sdk/src/';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { CaptchaRequesterStrategy } from 'web/src/modules/captcha/store/enums';
import { reCaptchaThemeConfigMapper } from 'web/src/modules/captcha/store/utils';
import getDefaultCaptchaConfig from '../../core/store/utils/getDefaultCaptchaConfig';
const useCaptchaStore = defineStore('captcha', ()=>{
    const siteConfigStore = useSiteConfigStore();
    const captcha = toRef(()=>siteConfigStore.captcha);
    // State
    const captchaRequesterStrategy = ref(CaptchaRequesterStrategy.DEFAULT);
    const defaultCaptchaConfig = getDefaultCaptchaConfig();
    // Getters
    const loginCaptchaType = computed(()=>captcha.value?.loginCaptchaType ?? defaultCaptchaConfig.loginCaptchaType);
    const registrationCaptchaType = computed(()=>captcha.value?.registrationCaptchaType ?? defaultCaptchaConfig.registrationCaptchaType);
    const restorePasswordCaptchaType = computed(()=>captcha.value?.passwordRecoveryCaptchaType ?? defaultCaptchaConfig.passwordRecoveryCaptchaType);
    const reCaptchaTheme = computed(()=>reCaptchaThemeConfigMapper(captcha.value?.recaptcha.theme ?? defaultCaptchaConfig.recaptcha.theme));
    const isLoginEnforced = computed(()=>(captcha.value?.loginCaptchaStatus ?? defaultCaptchaConfig.loginCaptchaStatus) === CaptchaStatus.ENFORCED);
    const isRestorePasswordEnforced = computed(()=>(captcha.value?.passwordRecoveryCaptchaStatus ?? defaultCaptchaConfig.passwordRecoveryCaptchaStatus) === CaptchaStatus.ENFORCED);
    const isRegistrationEnforced = computed(()=>(captcha.value?.registrationCaptchaStatus ?? defaultCaptchaConfig.registrationCaptchaStatus) === CaptchaStatus.ENFORCED);
    const useIframeForCaptcha = computed(()=>captcha.value?.useIframeForCaptcha ?? defaultCaptchaConfig.useIframeForCaptcha);
    const captchaIframeUrl = computed(()=>captcha.value?.captchaIframeUrl ?? defaultCaptchaConfig.captchaIframeUrl);
    const apiUrl = computed(()=>captcha.value?.captchaApiUrl ?? defaultCaptchaConfig.captchaApiUrl);
    // Actions
    function getSiteKey(strategy) {
        return computed(()=>{
            const reCaptchaKey = (captcha.value?.recaptcha.siteKeys ?? []).find((sk)=>sk.captchaType === captchaType(strategy).value);
            return reCaptchaKey?.key || '';
        });
    }
    function getSiteKeyByCaptchaType(captchaT) {
        return computed(()=>{
            const reCaptchaKey = (captcha.value?.recaptcha.siteKeys ?? []).find((sk)=>sk.captchaType === captchaT);
            return reCaptchaKey?.key || '';
        });
    }
    function isEnforced(strategy) {
        switch(strategy){
            case CaptchaRequesterStrategy.LOGIN:
                return isLoginEnforced;
            case CaptchaRequesterStrategy.REGISTRATION:
                return isRegistrationEnforced;
            case CaptchaRequesterStrategy.RESTORE_PASSWORD:
                return isRestorePasswordEnforced;
            case CaptchaRequesterStrategy.DEFAULT:
                return ref(true);
            default:
                return ref(false);
        }
    }
    function captchaType(strategy) {
        switch(strategy){
            case CaptchaRequesterStrategy.LOGIN:
                return loginCaptchaType;
            case CaptchaRequesterStrategy.REGISTRATION:
                return registrationCaptchaType;
            case CaptchaRequesterStrategy.RESTORE_PASSWORD:
                return restorePasswordCaptchaType;
            default:
                return ref(CaptchaType.RECAPTCHA);
        }
    }
    function captchaSettingsForStrategy(strategy, ct) {
        return computed(()=>({
                isEnforced: isEnforced(strategy).value,
                isEnabledToShow: true,
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                captchaType: ct || captchaType(strategy).value,
                siteKey: ct ? getSiteKeyByCaptchaType(ct).value : getSiteKey(strategy).value,
                theme: reCaptchaTheme.value,
                captchaRequesterStrategy: captchaRequesterStrategy.value
            }));
    }
    return {
        useIframeForCaptcha,
        captchaIframeUrl,
        apiUrl,
        registrationCaptchaType,
        getSiteKeyByCaptchaType,
        captchaSettingsForStrategy
    };
});
export default useCaptchaStore;
