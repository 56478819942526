// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.leader-board-wrapper_VpTS8{margin:24px 8px 20px}html.app__layout--desktop .leader-board-wrapper_VpTS8{margin-bottom:24px}.leader-board-wrapper__header_ckGKR{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;margin:0 16px 12px;color:var(--TextDefault);text-align:center}.leader-board-wrapper__sub-header_LkLQD{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;margin:0 16px 8px;color:var(--TextSecondary);text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leader-board-wrapper": `leader-board-wrapper_VpTS8`,
	"leader-board-wrapper__header": `leader-board-wrapper__header_ckGKR`,
	"leader-board-wrapper__sub-header": `leader-board-wrapper__sub-header_LkLQD`
};
export default ___CSS_LOADER_EXPORT___;
