import { runnerTagAway, runnerTagBoth, runnerTagCompetitor, runnerTagDraw, runnerTagDrawaway, runnerTagEven, runnerTagHome, runnerTagHomeaway, runnerTagHomedraw, runnerTagNeither, runnerTagNo, runnerTagOdd, runnerTagOther, runnerTagOver, runnerTagRange, runnerTagScore, runnerTagUnder, runnerTagYes } from '@leon-hub/api-sdk';
import { SelectionTag } from '../../../enums';
const runnerTagMap = {
    [SelectionTag.AWAY]: runnerTagAway,
    [SelectionTag.BOTH]: runnerTagBoth,
    [SelectionTag.COMPETITOR]: runnerTagCompetitor,
    [SelectionTag.DRAWAWAY]: runnerTagDrawaway,
    [SelectionTag.DRAW]: runnerTagDraw,
    [SelectionTag.EVEN]: runnerTagEven,
    [SelectionTag.HOMEAWAY]: runnerTagHomeaway,
    [SelectionTag.HOMEDRAW]: runnerTagHomedraw,
    [SelectionTag.HOME]: runnerTagHome,
    [SelectionTag.NEITHER]: runnerTagNeither,
    [SelectionTag.NO]: runnerTagNo,
    [SelectionTag.ODD]: runnerTagOdd,
    [SelectionTag.OTHER]: runnerTagOther,
    [SelectionTag.OVER]: runnerTagOver,
    [SelectionTag.RANGE]: runnerTagRange,
    [SelectionTag.SCORE]: runnerTagScore,
    [SelectionTag.UNDER]: runnerTagUnder,
    [SelectionTag.YES]: runnerTagYes
};
export function selectionTagsToRunnerTags() {
    let input = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
    const result = [];
    if (!input) return result;
    for (const tag of input){
        const mappedtag = runnerTagMap[tag];
        if (mappedtag) result.push(mappedtag);
    }
    return result;
}
