// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.egs-exclusive-badge_EKybX{font-size:10px;font-weight:400;line-height:12px;letter-spacing:normal;display:flex;align-items:center;justify-content:center;height:20px;padding:4px 8px;color:var(--DBrandText);background-color:#00000080;border-radius:20px}.egs-exclusive-badge--preview_MAcH7{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;height:32px;padding:8px;border-radius:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"egs-exclusive-badge": `egs-exclusive-badge_EKybX`,
	"egs-exclusive-badge--preview": `egs-exclusive-badge--preview_MAcH7`
};
export default ___CSS_LOADER_EXPORT___;
