// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login__title_knBgM{font-size:24px;font-weight:600;line-height:32px;padding:0 16px;margin:16px 0;color:var(--ColorContent1)}html.app__layout--desktop .login__title_knBgM{padding:0 32px}.login__tabs_gX4kp{padding:0 8px}html.app__layout--desktop .login__tabs_gX4kp{padding:0 24px}.login__content__UOn9{padding:0 16px 16px}html.app__layout--desktop .login__content__UOn9{padding:0 32px 32px}.login_gcUma .tabs_MzDUE{padding:0 8px}html.app__layout--desktop .login_gcUma .tabs_MzDUE{padding:0 24px}.login__buttons-block_ZMro3{margin-top:20px}.login__form_rwLfd{margin-top:16px}.login__button_VieIK{margin-bottom:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login__title": `login__title_knBgM`,
	"login__tabs": `login__tabs_gX4kp`,
	"login__content": `login__content__UOn9`,
	"login": `login_gcUma`,
	"tabs": `tabs_MzDUE`,
	"login__buttons-block": `login__buttons-block_ZMro3`,
	"login__form": `login__form_rwLfd`,
	"login__button": `login__button_VieIK`
};
export default ___CSS_LOADER_EXPORT___;
