// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-icon_ws9B2{position:relative;display:flex;flex-shrink:0;align-items:center;justify-content:center;width:24px;height:24px;border:2px solid var(--TextSecondary);border-radius:5px;transition:.4s cubic-bezier(.25,.8,.25,1)}.checkbox-icon--error_VdVPB{border-color:var(--ErrorText)}.checkbox-icon--disabled_efNGH{color:var(--Layer2);border-color:var(--Highlight);opacity:.6}.checkbox-icon--hover_OlGy_{border-color:var(--BrandDefault)}.checkbox-icon--checked_NQVsb{color:var(--BrandText);background:var(--BrandDefault);border-color:#0000}.checkbox-icon--checked_NQVsb.checkbox-icon--disabled_efNGH{background:var(--Highlight)}.checkbox-icon--checked_NQVsb.checkbox-icon--hover_OlGy_{background:var(--BrandHighlight);border-color:#0000}.checkbox-icon__check_Pxp8B{opacity:0}.checkbox-icon--checked_NQVsb .checkbox-icon__check_Pxp8B{opacity:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-icon": `checkbox-icon_ws9B2`,
	"checkbox-icon--error": `checkbox-icon--error_VdVPB`,
	"checkbox-icon--disabled": `checkbox-icon--disabled_efNGH`,
	"checkbox-icon--hover": `checkbox-icon--hover_OlGy_`,
	"checkbox-icon--checked": `checkbox-icon--checked_NQVsb`,
	"checkbox-icon__check": `checkbox-icon__check_Pxp8B`
};
export default ___CSS_LOADER_EXPORT___;
