import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useVLoadMoreObserver } from 'web/src/components/LoadMoreObserver/VLoadMoreObserver/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VLoadMoreObserver',
    props: {
        fallbackButtonText: {
            default: ''
        }
    },
    emits: [
        "load-more"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { collision, buttonText, showFallbackButton } = useVLoadMoreObserver(props, emit);
        function onButtonClick() {
            emit('load-more');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                ref_key: "collision",
                ref: collision,
                class: _normalizeClass(_ctx.$style['load-more-observer'])
            }, [
                _unref(showFallbackButton) ? (_openBlock(), _createBlock(VButton, {
                    key: 0,
                    label: _unref(buttonText),
                    onClick: onButtonClick
                }, null, 8, [
                    "label"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VLoadMoreObserver'
                ]
            ]);
        };
    }
});
