import { computed, toRef } from 'vue';
import { useRouterStore } from 'web/src/modules/core/store';
import useHomePageType from 'web/src/modules/core/store/composables/useHomePageType';
import { getLobbyTypeByRouteName } from './utils';
export default function useLobbyType() {
    const routerStore = useRouterStore();
    const currentRouteName = toRef(()=>routerStore.currentRouteName);
    const nextRouteName = toRef(()=>routerStore.nextRouteName);
    const { homePageType } = useHomePageType();
    const lobbyType = computed(()=>getLobbyTypeByRouteName({
            routeName: nextRouteName.value || currentRouteName.value,
            homePageType: homePageType.value
        }));
    return {
        lobbyType
    };
}
