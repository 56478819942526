// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-number-input_oCvF3{position:relative;width:100%}.card-number-input__logo_bJevm{align-self:center;width:32px;height:20px;margin-right:12px}.card-number-input__logo-wrapper_q8olO{position:absolute;top:18px;right:0;z-index:2;display:inline-flex;width:32px;height:44px;margin-right:39px}.card-number-input__logo-image_OR94K{align-self:center;width:32px;height:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-number-input": `card-number-input_oCvF3`,
	"card-number-input__logo": `card-number-input__logo_bJevm`,
	"card-number-input__logo-wrapper": `card-number-input__logo-wrapper_q8olO`,
	"card-number-input__logo-image": `card-number-input__logo-image_OR94K`
};
export default ___CSS_LOADER_EXPORT___;
