import { computed, onMounted, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { Events as AnalyticsEvent } from '@leon-hub/yandex-metrika';
import { logger } from '@leon-hub/logging';
import RouteName from '@leon-hub/routing-config';
import useBonusLoyaltyStore from 'web/src/modules/bonuses/store/useBonusLoyaltyStore';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import useLoyaltyShopStore from 'web/src/modules/bonuses/store/useLoyaltyShopStore';
export default function useBonusLoyaltyProgramRoutePage() {
    const bonusLoyaltyStore = useBonusLoyaltyStore();
    const siteConfigStore = useSiteConfigStore();
    const router = useRouter();
    const analytics = useAnalytics();
    const { isLoggedIn } = useIsLoggedIn();
    const loyaltyShopStore = useLoyaltyShopStore();
    const isShopOffersLoaded = ref(false);
    const isLeonShopEnabled = toRef(siteConfigStore, 'isLeonShopEnabled');
    const showExchangeTitle = toRef(()=>bonusLoyaltyStore.showExchangeTitle ?? false);
    const offers = toRef(loyaltyShopStore, 'offers');
    const bonusLoaded = computed(()=>bonusLoyaltyStore.bonusLoaded);
    const isLeonShopEnabledWithOffers = computed(()=>isLeonShopEnabled.value && offers.value.length > 0);
    const amountNumber = computed(()=>bonusLoyaltyStore.amountNumber);
    const remainingAmountNumber = computed(()=>bonusLoyaltyStore.remainingAmountNumber);
    const progress = computed(()=>bonusLoyaltyStore.progress);
    const progressPercentString = computed(()=>bonusLoyaltyStore.progressPercentString);
    const bonusLinkLoyalty = computed(()=>siteConfigStore.bonusLinkLoyalty);
    const bonusLinkEgsLoyalty = computed(()=>siteConfigStore.bonusLinkEgsLoyalty);
    const isParticipating = computed(()=>bonusLoyaltyStore.isParticipating);
    const handleExchange = ()=>{
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                leonshop: 'exchange'
            }
        });
        router.push({
            name: RouteName.BONUS_WITHDRAWALS
        });
    };
    const handleParticipate = async ()=>{
        try {
            await bonusLoyaltyStore.confirmBonus();
            bonusLoyaltyStore.setIsParticipating(true);
        } catch  {
            logger.warn('something went wrong with bonus confirmation');
        }
    };
    const onShopItemClick = (id)=>{
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                leonshop: 'item'
            }
        });
        router.push({
            name: RouteName.SHOP_ITEM_DETAILS,
            params: {
                itemId: `${id}`
            }
        });
    };
    onMounted(async ()=>{
        if (isLoggedIn.value) await bonusLoyaltyStore.fetchBonusLoyalty();
        if (isLeonShopEnabled.value) await loyaltyShopStore.fetchCustomerOffers();
        isShopOffersLoaded.value = true;
    });
    return {
        offers,
        bonusLoaded,
        isLeonShopEnabledWithOffers,
        amountNumber,
        remainingAmountNumber,
        progress,
        progressPercentString,
        bonusLinkLoyalty,
        bonusLinkEgsLoyalty,
        isParticipating,
        handleExchange,
        handleParticipate,
        onShopItemClick,
        isShopOffersLoaded,
        showExchangeTitle
    };
}
