import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import { defineAsyncComponent } from 'vue';
import { useVScrollbar } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VScrollbar',
    props: {
        flexFill: {
            type: Boolean
        },
        isDropdown: {
            type: Boolean
        },
        useScrollListener: {
            type: Boolean
        },
        testEl: {},
        handleResizeChange: {
            type: Boolean
        }
    },
    emits: [
        "reached-bottom"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const emit = __emit;
        const { element, scrollTop, scrollDown, scrollTo, scrollRight, scrollToElement, scrollUpdate, recompose } = useVScrollbar();
        const Scrollbar = process.env.VUE_APP_OS_IOS || process.env.VUE_APP_OS_ANDROID ? defineAsyncComponent(()=>import('../VScrollbarPhone/VScrollbarPhone.vue')) : defineAsyncComponent(()=>import('../VScrollbarDesktop/VScrollbarDesktop.vue'));
        __expose({
            scrollTop,
            scrollDown,
            scrollTo,
            scrollRight,
            scrollToElement,
            scrollUpdate,
            recompose
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(Scrollbar), {
                ref_key: "element",
                ref: element,
                "flex-fill": _ctx.flexFill,
                "is-dropdown": _ctx.isDropdown,
                "use-scroll-listener": _ctx.useScrollListener,
                "test-el": _ctx.testEl,
                "handle-resize-change": _ctx.handleResizeChange,
                onReachedBottom: _cache[0] || (_cache[0] = ($event)=>emit('reached-bottom'))
            }, {
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 3
            }, 8, [
                "flex-fill",
                "is-dropdown",
                "use-scroll-listener",
                "test-el",
                "handle-resize-change"
            ])), [
                [
                    _directive_auto_id,
                    'VScrollbar'
                ]
            ]);
        };
    }
});
