// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .more-indicator_NL5ro{display:flex;flex-direction:column;align-items:flex-end;justify-content:center;width:72px;height:100%;padding-right:8px}html.app__layout--desktop .sportline-event-markets-counter__EJWv{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;display:flex;flex-direction:row;align-items:flex-end;justify-content:center;color:var(--TextDefault)}html.app__layout--desktop .sportline-event-markets-counter__chevron_CytWp{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextPrimary);-webkit-text-decoration:none;text-decoration:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"more-indicator": `more-indicator_NL5ro`,
	"sportline-event-markets-counter": `sportline-event-markets-counter__EJWv`,
	"sportline-event-markets-counter__chevron": `sportline-event-markets-counter__chevron_CytWp`
};
export default ___CSS_LOADER_EXPORT___;
