// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cashout-button_upMzN{flex:1;justify-content:space-between;overflow:hidden}.cashout-button__label_CO2Ef{flex:0 1 auto;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.cashout-button__suffix_vzOJ8{display:flex;flex:none;align-items:center}.cashout-button__icon_uvRYL{display:flex;align-items:center;justify-content:center;width:20px;height:20px;margin-right:4px;background:var(--OpacityBrandDefault);border-radius:4px}.cashout-button--hover_i4Y8E .cashout-button__icon_uvRYL{background:var(--OpacityLayer2)}.cashout-button__amount_cGaQU{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cashout-button": `cashout-button_upMzN`,
	"cashout-button__label": `cashout-button__label_CO2Ef`,
	"cashout-button__suffix": `cashout-button__suffix_vzOJ8`,
	"cashout-button__icon": `cashout-button__icon_uvRYL`,
	"cashout-button--hover": `cashout-button--hover_i4Y8E`,
	"cashout-button__amount": `cashout-button__amount_cGaQU`
};
export default ___CSS_LOADER_EXPORT___;
