import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { HistoryFilter } from '@leon-hub/api-sdk';
import { NativeSelect } from 'web/src/components/Select';
import { useCustomerHistoryFilterSelect } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryFilterSelect',
    props: {
        selectedHistoryFilter: {
            default: HistoryFilter.ALL
        },
        historyFilterOptions: {}
    },
    emits: [
        "filter-change"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { filterSelectOptions, selectProperties, onFilterChange } = useCustomerHistoryFilterSelect(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _unref(filterSelectOptions).length ? _withDirectives((_openBlock(), _createBlock(_unref(NativeSelect), _mergeProps({
                key: 0,
                class: _ctx.$style['history-filter-select']
            }, _unref(selectProperties), {
                "theme-bg-color": "",
                onChange: _unref(onFilterChange)
            }), null, 16, [
                "class",
                "onChange"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerHistoryFilterSelect'
                ]
            ]) : _createCommentVNode("", true);
        };
    }
});
