// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.balance-card_X3h7e{position:relative;display:flex;flex-grow:1;align-items:center;padding:12px;margin-bottom:8px;border-radius:5px;box-shadow:none}.balance-card--multi_Vv7Ve{display:grid;grid-template-rows:36px 1fr;gap:24px;align-items:flex-start}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance-card": `balance-card_X3h7e`,
	"balance-card--multi": `balance-card--multi_Vv7Ve`
};
export default ___CSS_LOADER_EXPORT___;
