// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.egs-list-item__type-kind_FgdtL{font-weight:500;display:block;width:100%;height:16px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.egs-list-item__date_AHLHV,.egs-list-item__type-kind_FgdtL{font-size:12px;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}.egs-list-item__date_AHLHV{font-weight:400}.egs-list-item__event-description_yMKuh{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:block;overflow:hidden;color:var(--TextDefault);text-overflow:ellipsis;white-space:nowrap}.egs-list-item__debit_a54Xl{font-weight:400;line-height:20px}.egs-list-item__credit_bJyuA,.egs-list-item__debit_a54Xl{font-size:14px;letter-spacing:.25px;color:var(--TextPrimary)}.egs-list-item__credit_bJyuA{font-weight:500;line-height:16px}.egs-list-item__credit--positive_dbndb{color:var(--BrandDefault)}.egs-list-item__id_oA_Pu{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"egs-list-item__type-kind": `egs-list-item__type-kind_FgdtL`,
	"egs-list-item__date": `egs-list-item__date_AHLHV`,
	"egs-list-item__event-description": `egs-list-item__event-description_yMKuh`,
	"egs-list-item__debit": `egs-list-item__debit_a54Xl`,
	"egs-list-item__credit": `egs-list-item__credit_bJyuA`,
	"egs-list-item__credit--positive": `egs-list-item__credit--positive_dbndb`,
	"egs-list-item__id": `egs-list-item__id_oA_Pu`
};
export default ___CSS_LOADER_EXPORT___;
