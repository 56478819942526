// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landing-button_jJzco{box-sizing:border-box;display:var(--landingButtonDisplay);gap:var(--landingButtonGap);align-items:center;justify-content:center;width:var(--landingButtonWidth);height:var(--landingButtonHeight);padding:var(--landingButtonPadding);color:var(--landingButtonColor);-webkit-text-decoration:none;text-decoration:none;background:var(--landingButtonBackground);border:none;border-radius:var(--landingButtonRadius);transition:var(--animation-button-transition);transform:var(--animation-button-transform)}@media(hover:hover)and (pointer:fine){.landing-button_jJzco:hover:not([disabled]){cursor:pointer;background:var(--landingButtonBackgroundHover)}}.landing-button__label_hrbQm{display:flex;flex-shrink:0;justify-content:center;font-family:var(--landingButtonFontFamily);font-size:var(--landingButtonFontSize);font-weight:var(--landingButtonFontWeight);line-height:var(--landingButtonLineHeight)}@media(min-width:1024px){.landing-button__label_hrbQm{font-size:var(--landingButtonFontSizeDesk);line-height:var(--landingButtonLineHeightDesk)}}.landing-button--full_nxJNQ{width:100%}.landing-button__icon_epzH_{position:relative;width:24px;height:24px}.landing-button_jJzco:active:not([disabled]),.landing-button_jJzco:focus-visible{box-shadow:var(--landingButtonActiveBoxShadow)}.landing-button_jJzco:disabled{color:var(--landingButtonDisabledColor);cursor:auto;background:var(--landingButtonDisabledBackground);opacity:var(--landingButtonDisabledOpacity)}@media(min-width:1024px){.landing-button_jJzco{width:var(--landingButtonWidthDesk);height:var(--landingButtonHeightDesk);padding:var(--landingButtonPaddingDesk)}}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landing-button": `landing-button_jJzco`,
	"landing-button__label": `landing-button__label_hrbQm`,
	"landing-button--full": `landing-button--full_nxJNQ`,
	"landing-button__icon": `landing-button__icon_epzH_`
};
export default ___CSS_LOADER_EXPORT___;
