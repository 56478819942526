// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-email_dP41Y{padding:0}.confirm-email--success_XVMD3{color:var(--BrandDefault)}.confirm-email__form_p3uuT{margin-top:8px}.confirm-email__label_JChIU{display:inline-block;max-width:100%;overflow:hidden}.confirm-email__button-container_pcZtS{white-space:nowrap}.confirm-email__inline-button_SnJD4{display:inline;padding:0;color:var(--TextPrimary);-webkit-text-decoration:underline;text-decoration:underline;cursor:pointer;background:#0000;border:0}@media(hover:hover)and (pointer:fine){.confirm-email__inline-button_SnJD4:hover{color:var(--TextDefault)}}.confirm-email__bright_iTeT6{color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirm-email": `confirm-email_dP41Y`,
	"confirm-email--success": `confirm-email--success_XVMD3`,
	"confirm-email__form": `confirm-email__form_p3uuT`,
	"confirm-email__label": `confirm-email__label_JChIU`,
	"confirm-email__button-container": `confirm-email__button-container_pcZtS`,
	"confirm-email__inline-button": `confirm-email__inline-button_SnJD4`,
	"confirm-email__bright": `confirm-email__bright_iTeT6`
};
export default ___CSS_LOADER_EXPORT___;
