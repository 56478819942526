// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .payments-bonuses_V1W5E{width:100%}html.app__layout--desktop .payments-bonuses--modal_z4pib{padding:0}html.app__layout--desktop .payments-bonuses_V1W5E>button{margin-bottom:12px}html.app__layout--desktop .payments-bonuses__title_poSqS{font-size:13px;font-weight:500;line-height:16px;letter-spacing:normal;padding:16px 32px;color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-bonuses": `payments-bonuses_V1W5E`,
	"payments-bonuses--modal": `payments-bonuses--modal_z4pib`,
	"payments-bonuses__title": `payments-bonuses__title_poSqS`
};
export default ___CSS_LOADER_EXPORT___;
