// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb_dCP4Q{-webkit-user-select:none;user-select:none}.breadcrumb__content_fD39W{position:absolute;top:8px;right:0;bottom:0;left:0;width:100%;height:calc(100% + 40px);background-color:var(--OpacityLayer0)}.breadcrumb__content--sub-menu_K9Dcu{font-size:13px;font-weight:400;line-height:16px;letter-spacing:normal;z-index:5;position:absolute;top:0;right:0;bottom:auto;left:0;width:200px;height:auto;margin-top:4px;overflow:hidden;color:var(--Layer1);background-color:var(--TextDefault);border-radius:0 5px 5px}.breadcrumb--type-menu_SwdA7{position:relative}.breadcrumb--type-menu_SwdA7 .breadcrumb__content_fD39W{z-index:5;position:absolute;top:0;bottom:auto;left:0;width:200px;height:auto;margin-top:4px;overflow:hidden;background-color:var(--TextDefault);border-radius:0 5px 5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": `breadcrumb_dCP4Q`,
	"breadcrumb__content": `breadcrumb__content_fD39W`,
	"breadcrumb__content--sub-menu": `breadcrumb__content--sub-menu_K9Dcu`,
	"breadcrumb--type-menu": `breadcrumb--type-menu_SwdA7`
};
export default ___CSS_LOADER_EXPORT___;
