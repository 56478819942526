import { isArray, isObject } from '@leon-hub/guards';
import { FormSubmitMode, FormControlType } from 'web/src/components/Form/enums';
import { getSubmitButtonProperties, getUiSchemaField } from 'web/src/components/Form/utils/uiSchema';
import { isFormTypeObject } from './formSchemaUtils';
export const isMultipleFields = (uiSchema)=>{
    const list = uiSchema.order ? uiSchema.order : Object.keys(uiSchema.fields ?? {});
    if (!list.length) return false;
    const count = list.reduce((accumulator, field)=>{
        const fieldProps = uiSchema?.fields?.[field];
        isObject(fieldProps);
        // https://jira.leoncorp.net/browse/LEONWEB-1336
        if (fieldProps.hidden || fieldProps.disabled || fieldProps.isIgnoredByDisableCounter || fieldProps.widget === FormControlType.Hidden) return accumulator;
        return accumulator + 1;
    }, 0);
    return count > 1;
};
export const getSubmitMode = (uiSchema)=>isMultipleFields(uiSchema) ? FormSubmitMode.AlwaysEnabled : FormSubmitMode.DisabledByErrors;
export const isSubmitButtonDisabled = (uiSchema)=>Boolean(getSubmitButtonProperties(uiSchema).disabled);
export const haveEmptyRequiredFields = (schema, uiSchema, formDataMap)=>{
    const { required, properties } = schema;
    if (!required || !isArray(required)) return false;
    const flatRequired = [];
    for (const fieldName of required){
        const matchedField = properties[fieldName];
        const uiField = getUiSchemaField(uiSchema, fieldName);
        if (!uiField.hidden) {
            if (isFormTypeObject(matchedField) && isArray(matchedField.required)) for (const subfield of matchedField.required)flatRequired.push(`${fieldName}/${subfield}`);
            else flatRequired.push(fieldName);
        }
    }
    return flatRequired.some((fieldName)=>!formDataMap.get(fieldName));
};
