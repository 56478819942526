// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.standings-team-promotion_eG443{width:8px;min-width:8px;height:8px;min-height:8px;border:1px solid var(--TextSecondary);border-radius:4px}.standings-team-promotion_eG443[title]{cursor:pointer}.standings-team-promotion--filled_b6qj6{background:var(--TextSecondary);border-color:var(--TextSecondary)}.standings-team-promotion--continental-league_pELW_{background:#1a237e;border-color:#1a237e}.standings-team-promotion--continental-league-playoff_zacsh{background:#2196f3;border-color:#2196f3}.standings-team-promotion--second-continental-league_XCzIw{background:#7e8392;border-color:#7e8392}.standings-team-promotion--second-continental-league-playoff_ST_kN{background:#cbcdd3;border-color:#cbcdd3}.standings-team-promotion--relegation-play-off_ubglS{background:#ff9800;border-color:#ff9800}.standings-team-promotion--relegation-direct__gAm3{background:#f14c45;border-color:#f14c45}.standings-team-promotion--promotion-direct_htEHy{background:#2e7d32;border-color:#2e7d32}.standings-team-promotion--promotion-playoff_JfpF_{background:#00c853;border-color:#00c853}.standings-team-promotion--next-round-play-off_b5h7B{background:#ffed00;border-color:#ffed00}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"standings-team-promotion": `standings-team-promotion_eG443`,
	"standings-team-promotion--filled": `standings-team-promotion--filled_b6qj6`,
	"standings-team-promotion--continental-league": `standings-team-promotion--continental-league_pELW_`,
	"standings-team-promotion--continental-league-playoff": `standings-team-promotion--continental-league-playoff_zacsh`,
	"standings-team-promotion--second-continental-league": `standings-team-promotion--second-continental-league_XCzIw`,
	"standings-team-promotion--second-continental-league-playoff": `standings-team-promotion--second-continental-league-playoff_ST_kN`,
	"standings-team-promotion--relegation-play-off": `standings-team-promotion--relegation-play-off_ubglS`,
	"standings-team-promotion--relegation-direct": `standings-team-promotion--relegation-direct__gAm3`,
	"standings-team-promotion--promotion-direct": `standings-team-promotion--promotion-direct_htEHy`,
	"standings-team-promotion--promotion-playoff": `standings-team-promotion--promotion-playoff_JfpF_`,
	"standings-team-promotion--next-round-play-off": `standings-team-promotion--next-round-play-off_b5h7B`
};
export default ___CSS_LOADER_EXPORT___;
