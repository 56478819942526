import { ref, computed, nextTick } from 'vue';
import { Timer } from '@leon-hub/utils';
import { useWindowResize } from '@leon-hub/browser-composables';
import { isElementInsideParent } from '@leon-hub/html-utils';
import { getContainerStyleProps, getSettingsForOpenDirection } from '../utils';
import { useExternalScrollWatcher } from './useExternalScrollWatcher';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function useVCoreDropdown(props, emit) {
    const dropToTop = ref(false);
    const stylesIsReady = ref(false);
    const maxHeight = ref(null);
    const dropdownContainer = ref();
    const styleProps = ref(null);
    const style = computed(()=>{
        if (!styleProps.value) return {};
        const { translateTop, translateLeft, ...css } = styleProps.value;
        return {
            ...css,
            transform: `translate3d(${translateLeft}px, ${translateTop}px, 0)`
        };
    });
    const emitUpdatePosition = ()=>{
        emit('update-position');
    };
    function calculateStyles() {
        styleProps.value = getContainerStyleProps(props, dropToTop, maxHeight, dropdownContainer);
        stylesIsReady.value = true;
    }
    function handleOutSideClick(event) {
        (event.target instanceof HTMLElement || event.target instanceof SVGElement) && dropdownContainer.value;
        if (props.ignoreActiveElementClick && document.activeElement !== document.body && event.target === document.activeElement) return;
        if (!isElementInsideParent(event.target, dropdownContainer.value)) emit('close', event);
    }
    function checkForFreeSpace() {
        const directionSettings = getSettingsForOpenDirection(props, dropdownContainer);
        dropToTop.value = directionSettings.betterOpenToTop;
        maxHeight.value = directionSettings.maxHeight;
    }
    function redraw() {
        checkForFreeSpace();
        calculateStyles();
        emitUpdatePosition();
    }
    let resizeTimeout = 0;
    function handleResize() {
        Timer.clearTimeout(resizeTimeout);
        resizeTimeout = Timer.setTimeout(()=>{
            redraw();
        }, 50);
    }
    let scrollTimer = 0;
    let initialScrollTop = null;
    let initialTranslateTop = null;
    async function onScrollEnd() {
        initialScrollTop = null;
        initialTranslateTop = null;
        maxHeight.value = null;
        styleProps.value = null;
        stylesIsReady.value = false;
        await nextTick();
        redraw();
    }
    function handleScroll(scrollTop) {
        if (null === initialScrollTop) initialScrollTop = scrollTop;
        if (null === initialTranslateTop) initialTranslateTop = styleProps.value?.translateTop || 0;
        window.requestAnimationFrame(()=>{
            if (null === initialScrollTop || null === initialTranslateTop) return;
            const offset = scrollTop - initialScrollTop;
            if (styleProps.value) {
                const newOffsetTop = initialTranslateTop - offset;
                styleProps.value = {
                    ...styleProps.value,
                    translateTop: newOffsetTop
                };
            }
        });
        Timer.clearTimeout(scrollTimer);
        scrollTimer = Timer.setTimeout(onScrollEnd, 100);
    }
    function handleContentScroll(busEvent) {
        handleScroll(busEvent.scrollTop);
    }
    function onAppear() {
        document.addEventListener('mousedown', handleOutSideClick);
        redraw();
    }
    function onDisappear() {
        document.removeEventListener('mousedown', handleOutSideClick);
        Timer.clearTimeout(scrollTimer);
        Timer.clearTimeout(resizeTimeout);
    }
    async function onContentRedraw() {
        await nextTick();
        calculateStyles();
    }
    useWindowResize(handleResize);
    if (!props.ignoreScroll) useExternalScrollWatcher(handleScroll, handleContentScroll);
    return {
        dropdownContainer,
        style,
        stylesIsReady,
        onAppear,
        onDisappear,
        onContentRedraw,
        maxHeight,
        dropToTop
    };
}
