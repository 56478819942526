import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onDeactivated } from 'vue';
import useFilterTransition from './composables/useFilterTransition';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FilterTransition',
    props: {
        disabled: {
            type: Boolean
        },
        fadeInDuration: {
            default: 250
        },
        fadeOutDuration: {
            default: 200
        }
    },
    emits: [
        "change-id"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { transitionDuration, transitionName, isFadeOutTransition, transitionStyles, transitionStart, fadeOutComplete, beforeFadeIn, transitionComplete, getActiveTransitionId, isActive, enable, disable } = useFilterTransition(props, {
            setActiveTransitionId (id) {
                emit('change-id', id);
            }
        });
        onDeactivated(()=>{
            disable();
        });
        __expose({
            getActiveTransitionId,
            isActive,
            enable,
            disable
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", null, [
                _renderSlot(_ctx.$slots, "filter", {
                    duration: _unref(transitionDuration),
                    beforeLeave: _unref(transitionStart),
                    afterLeave: _unref(fadeOutComplete),
                    beforeEnter: _unref(beforeFadeIn),
                    afterEnter: _unref(transitionComplete)
                }),
                _renderSlot(_ctx.$slots, "before-content"),
                _createVNode(_Transition, {
                    name: _unref(transitionName),
                    "enter-active-class": _ctx.$style[`${_unref(transitionName)}-enter-active`],
                    "enter-from-class": _ctx.$style[`${_unref(transitionName)}-enter-from`],
                    "enter-to-class": _ctx.$style[`${_unref(transitionName)}-enter-to`],
                    "leave-active-class": _ctx.$style[`${_unref(transitionName)}-leave-active`],
                    "leave-to-class": _ctx.$style[`${_unref(transitionName)}-leave-to`],
                    "leave-from-class": _ctx.$style[`${_unref(transitionName)}-leave-from`]
                }, {
                    default: _withCtx(()=>[
                            _renderSlot(_ctx.$slots, "content", {
                                isFadeOut: _unref(isFadeOutTransition),
                                extraStyle: _unref(transitionStyles)
                            })
                        ]),
                    _: 3
                }, 8, [
                    "name",
                    "enter-active-class",
                    "enter-from-class",
                    "enter-to-class",
                    "leave-active-class",
                    "leave-to-class",
                    "leave-from-class"
                ])
            ])), [
                [
                    _directive_auto_id,
                    'FilterTransition'
                ]
            ]);
        };
    }
});
