// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.promotion-countdown__inner_eDmKF{display:flex;flex-direction:column;gap:4px;padding:0;background-color:#0000;border-radius:0}.promotion-countdown__title_jw1wR{font-size:16px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault);text-align:center}.promotion-countdown__counters_K99sM{display:flex;justify-content:center}.promotion-countdown__counters-item__kLW7{display:flex;flex-direction:column;gap:0;text-align:center}.promotion-countdown__counters-item-value_F93lu{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;color:var(--TextDefault)}.promotion-countdown__counters-item-label_LfpzN{font-size:10px;font-weight:400;line-height:12px;letter-spacing:normal;color:var(--TextSecondary);text-transform:uppercase;letter-spacing:.2px}.promotion-countdown__counters-separator_WeH4g{font-size:24px;font-weight:500;line-height:28px;letter-spacing:normal;margin:0 2px;color:var(--TextSecondary)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"promotion-countdown__inner": `promotion-countdown__inner_eDmKF`,
	"promotion-countdown__title": `promotion-countdown__title_jw1wR`,
	"promotion-countdown__counters": `promotion-countdown__counters_K99sM`,
	"promotion-countdown__counters-item": `promotion-countdown__counters-item__kLW7`,
	"promotion-countdown__counters-item-value": `promotion-countdown__counters-item-value_F93lu`,
	"promotion-countdown__counters-item-label": `promotion-countdown__counters-item-label_LfpzN`,
	"promotion-countdown__counters-separator": `promotion-countdown__counters-separator_WeH4g`
};
export default ___CSS_LOADER_EXPORT___;
