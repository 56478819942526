// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-container_P6MNr{padding:10px 0 0 0}.checkbox-container--no-padding_BIHhm{padding:0}.checkbox_FA9oL{display:flex;cursor:pointer}.checkbox--disabled_isa6c{cursor:default}.checkbox--disabled_isa6c .checkbox__hint-error_INB7K,.checkbox--disabled_isa6c .checkbox__text_WHq0O{opacity:.3}.checkbox__input_HmtyP{position:absolute;top:-10px;left:-10px;z-index:1;width:0;height:0;opacity:0}.checkbox__text_WHq0O{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;display:block;flex:1;padding-top:2px;margin-left:12px;color:var(--TextDefault);-webkit-user-select:none;user-select:none;transition:color .4s cubic-bezier(.25,.8,.25,1)}.checkbox__hint-error_INB7K{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;display:block;flex-basis:100%;flex-shrink:0;padding:8px 0 0;color:var(--ErrorText)}.checkbox__hint-error_INB7K:first-letter{text-transform:uppercase}.checkbox__hint-link_pqreq{margin-left:12px}.checkbox--error_eoxoW.checkbox__text_WHq0O{display:flex;align-items:center}.checkbox__text-container_TCYDA{display:block;min-height:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox-container": `checkbox-container_P6MNr`,
	"checkbox-container--no-padding": `checkbox-container--no-padding_BIHhm`,
	"checkbox": `checkbox_FA9oL`,
	"checkbox--disabled": `checkbox--disabled_isa6c`,
	"checkbox__hint-error": `checkbox__hint-error_INB7K`,
	"checkbox__text": `checkbox__text_WHq0O`,
	"checkbox__input": `checkbox__input_HmtyP`,
	"checkbox__hint-link": `checkbox__hint-link_pqreq`,
	"checkbox--error": `checkbox--error_eoxoW`,
	"checkbox__text-container": `checkbox__text-container_TCYDA`
};
export default ___CSS_LOADER_EXPORT___;
