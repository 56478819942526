import { ref, watch, computed, nextTick } from 'vue';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { useSportlineEventMarketsPriority } from 'web/src/modules/sportline/composables/markets';
import { getNonEmptyMarketsGridFilters } from 'web/src/modules/sportline/submodules/markets-grid/utils';
import useMarketsListResizableLayout from './useMarketsListResizableLayout';
import useMarketsListAnalytics from './useMarketsListAnalytics';
import useMarketsListOpenStateStorage from './useMarketsListOpenStateStorage';
import { useMarketsListTabsControl } from './useMarketsListTabsControl';
import { useMarketsListGroups } from './useMarketsListGroups';
export function useMarketsList(props) {
    const { toolbar, marketHolder, columnOne, columnTwo, sportEvent, representationSportFamily, isLoaded, defaultLayoutType, isListItem, isMarketGroupsTabsEnabled, defaultMarketsLimit, hasStatistic, isPrimaryMarketFiltrationDisabled } = props;
    const { markets, secondaryMarkets } = useSportlineEventMarketsPriority({
        sportEvent,
        isPrimaryMarketFiltrationDisabled
    });
    const { closedMarketGroups, toggleMarketGroupClosedStateInTheStorage } = useMarketsListOpenStateStorage({
        representationSportFamily
    });
    let marketGroupsWatcher = null;
    let doShowTabsWatcher = null;
    let isLoadedWatcher = null;
    const isActivated = ref(false);
    const tabsHasBeenShown = ref(false);
    const isOutright = computed(()=>!!sportEvent.value?.isOutright);
    const allMarketsList = computed(()=>isListItem?.value ? markets.value : secondaryMarkets.value);
    const isEmptyMarketsList = computed(()=>0 === allMarketsList.value.length);
    const isOnlyOneMarket = computed(()=>allMarketsList.value.length <= 1);
    const gridFilters = computed(()=>{
        if (!isMarketGroupsTabsEnabled?.value) return [];
        return getNonEmptyMarketsGridFilters(sportEvent.value?.marketGroups, allMarketsList.value);
    });
    const { allowedToSelectLayoutTypes, isDisplayOneColumnLayout, recalculateColumnsLayout } = useMarketsListResizableLayout({
        isListItem,
        isOutright,
        isEmptyMarketsList,
        defaultLayoutType,
        isOnlyOneMarket,
        marketHolder,
        columnOne,
        columnTwo
    });
    const { isAllTabSelected, isStatisticSelected, activeGridFilter, isSelectedTabExistInMarketGroups, doShowInlineStatisticSwitch, displayedMarketGroupTabId, resetSelectedTabId, recalculateActiveTabId, setSelectedTabId } = useMarketsListTabsControl({
        gridFilters,
        hasStatistic,
        allowedToSelectLayoutTypes
    });
    const isReadyToRender = ref(false);
    useIntersectionObserver(marketHolder, (isIntersecting)=>{
        if (isIntersecting) {
            isReadyToRender.value = true;
            nextTick().then(recalculateColumnsLayout);
        }
    }, {
        rootMargin: '0px',
        threshold: 0.1
    }, true);
    const { isMoreMarketsClicked, isMoreMarketButtonVisible, filteredGroups, oddGroups, evenGroups, hasAnyGroups } = useMarketsListGroups({
        allMarketsList,
        isAllTabSelected,
        activeGridFilter,
        closedMarketGroups,
        defaultMarketsLimit
    });
    const isDisplayStatistic = computed(()=>!!hasStatistic?.value && (isStatisticSelected.value || isEmptyMarketsList.value));
    const doShowTabs = computed(()=>{
        if (isEmptyMarketsList.value) return false;
        if (tabsHasBeenShown.value) return true;
        if (hasStatistic?.value) return true;
        return !isEmptyMarketsList.value || gridFilters.value.length > 0;
    });
    const { startAnalyticsWatchers, stopAnalyticsWatchers } = useMarketsListAnalytics({
        isDisplayStatistic
    });
    async function onMarketTabClicked(value) {
        setSelectedTabId(value);
        await nextTick();
        recalculateColumnsLayout();
    }
    async function onMoreMarketsClick() {
        isMoreMarketsClicked.value = true;
        await nextTick();
        recalculateColumnsLayout();
    }
    function onStatisticSelectedChanged(value) {
        isStatisticSelected.value = value;
    }
    function onMarketGroupToggle(groupKey) {
        toggleMarketGroupClosedStateInTheStorage(groupKey);
    }
    function startWatchers() {
        // @TODO check watch isSelectedTabExists
        marketGroupsWatcher = watch(gridFilters, async ()=>{
            if (isSelectedTabExistInMarketGroups.value) return;
            resetSelectedTabId();
            await nextTick();
            toolbar.value?.slideToActiveTab();
        }, {
            deep: true,
            immediate: true
        });
        doShowTabsWatcher = watch(doShowTabs, (newValue)=>{
            if (newValue) tabsHasBeenShown.value = true;
        }, {
            immediate: true
        });
        isLoadedWatcher = watch(isLoaded, async (newValue)=>{
            if (newValue) recalculateActiveTabId();
            await nextTick();
            recalculateColumnsLayout();
        }, {
            immediate: true
        });
        startAnalyticsWatchers();
    }
    function stopWatchers() {
        marketGroupsWatcher?.();
        marketGroupsWatcher = null;
        doShowTabsWatcher?.();
        doShowTabsWatcher = null;
        isLoadedWatcher?.();
        isLoadedWatcher = null;
        stopAnalyticsWatchers();
    }
    function setIsActivated(value) {
        isActivated.value = value;
        nextTick().then(recalculateColumnsLayout);
    }
    return {
        isReadyToRender,
        hasAnyGroups,
        isDisplayOneColumnLayout,
        doShowTabs,
        doShowInlineStatisticSwitch,
        isStatisticSelected,
        isDisplayStatistic,
        isMoreMarketButtonVisible,
        gridFilters,
        displayedMarketGroupTabId,
        filteredGroups,
        oddGroups,
        evenGroups,
        onMarketTabClicked,
        onMoreMarketsClick,
        onStatisticSelectedChanged,
        onMarketGroupToggle,
        startWatchers,
        stopWatchers,
        setIsActivated
    };
}
