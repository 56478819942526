import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
import { onMounted, onUpdated } from 'vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { MultilineTextInput, TextInput } from 'web/src/components/Input';
import useVFeedBackComposition from 'web/src/components/FeedbackComposition/VFeedbackComposition/composables/useVFeedbackComposition';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VFeedbackComposition',
    props: {
        description: {},
        feedbackFormMaxChars: {},
        haveValidEmailValue: {
            type: Boolean
        },
        submitting: {
            type: Boolean
        },
        isClearTextarea: {
            type: Boolean
        },
        isApiErrorEmailMessage: {},
        savedErrorDuplicateEmail: {}
    },
    emits: [
        "submit"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { comment, commentTextarea, emailValue, emailFieldError, submitButtonProperties, rowsMaxValue, rowsMinValue, onComponentMounted, onComponentUpdated, onEmailBlur, onEmailChange, onTextareaChange, getSubmitFormData } = useVFeedBackComposition(props);
        onMounted(onComponentMounted);
        onUpdated(onComponentUpdated);
        function submit() {
            emit('submit', getSubmitFormData());
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['feedback-legacy'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['feedback-legacy__description'])
                }, [
                    _renderSlot(_ctx.$slots, "default", {}, ()=>[
                            _createTextVNode(_toDisplayString(_ctx.description), 1)
                        ])
                ], 2),
                _createElementVNode("div", null, [
                    _createVNode(_unref(MultilineTextInput), {
                        ref_key: "commentTextarea",
                        ref: commentTextarea,
                        name: "feedback",
                        placeholder: _ctx.$t('WEB2_YOUR_FEEDBACK_PLACEHOLDER'),
                        "rows-min": _unref(rowsMinValue),
                        "rows-max": _unref(rowsMaxValue),
                        "max-length": _ctx.feedbackFormMaxChars,
                        value: _unref(comment),
                        onInput: _unref(onTextareaChange)
                    }, null, 8, [
                        "placeholder",
                        "rows-min",
                        "rows-max",
                        "max-length",
                        "value",
                        "onInput"
                    ]),
                    _ctx.haveValidEmailValue ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(TextInput), {
                        key: 0,
                        value: _unref(emailValue),
                        name: "emailInput",
                        placeholder: _ctx.$t('WEB2_YOUR_FEEDBACK_EMAIL_PLACEHOLDER'),
                        label: _ctx.$t('WEB2_EMAIL_INPUT_LABEL'),
                        error: _unref(emailFieldError),
                        onBlur: _unref(onEmailBlur),
                        onInput: _unref(onEmailChange)
                    }, null, 8, [
                        "value",
                        "placeholder",
                        "label",
                        "error",
                        "onBlur",
                        "onInput"
                    ])),
                    _withDirectives(_createVNode(VButton, _mergeProps({
                        class: _ctx.$style['feedback-legacy__submit']
                    }, _unref(submitButtonProperties), {
                        onClick: submit
                    }), null, 16, [
                        "class"
                    ]), [
                        [
                            _directive_data_test,
                            {
                                el: 'feedback-legacy-button'
                            }
                        ]
                    ])
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'VFeedbackComposition'
                ]
            ]);
        };
    }
});
