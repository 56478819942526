import { ref } from 'vue';
import { defineStatefulComposable } from 'web/src/shared/store';
const useIsLoggedIn = defineStatefulComposable(()=>{
    const isLoggedIn = ref(false);
    return {
        isLoggedIn
    };
});
export default useIsLoggedIn;
export { useIsLoggedIn };
