// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-single-input__input_OgqkU{position:absolute;width:100%;height:100%;padding:0;margin:0;overflow:hidden;clip:rect(0 0 0 0);clip-path:inset(50%);border:0;opacity:0}.file-single-input__input_OgqkU:active,.file-single-input__input_OgqkU:focus{position:absolute}.file-single-input__input_OgqkU:disabled{opacity:0}.file-single-input__input-border-wrapper_Gogtr{position:relative;height:44px}.file-single-input--large_zJR4V .file-single-input__input-border-wrapper_Gogtr{height:44px}.file-single-input__file-label_sVz0X{z-index:1;font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;width:100%;min-height:44px;padding:0 12px;color:var(--TextDefault);background-color:#0000;caret-color:var(--Blue);border:none;position:absolute;display:flex;align-items:center;height:100%;overflow:hidden}.file-single-input__file-label_sVz0X::placeholder{color:#0000}.file-single-input__file-label_sVz0X:active,.file-single-input__file-label_sVz0X:focus{z-index:1;color:var(--TextDefault)}.file-single-input__file-label_sVz0X:disabled{color:var(--TextSecondary);-webkit-text-fill-color:var(--TextSecondary);opacity:1}.file-single-input--large_zJR4V .file-single-input__file-label--icon-suffix_KKVov,.file-single-input__file-label--icon-suffix_KKVov{padding-right:36px}.file-single-input--error_YuwgP:not(.file-single-input--focus_KJvvF) .file-single-input__file-label_sVz0X{color:var(--TextDefault)}.file-single-input--empty_rEj4i .file-single-input__file-label_sVz0X{color:var(--TextSecondary)}.file-single-input--empty_rEj4i.file-single-input--error_YuwgP:not(.file-single-input--focus_KJvvF) .file-single-input__file-label_sVz0X{color:var(--ErrorText)}.file-single-input--large_zJR4V .file-single-input__file-label_sVz0X{min-height:44px;padding:0 12px;border-radius:5px}.file-single-input__file-name_kW40m{flex:0 1 auto;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.file-single-input__file-extension_uX6Tr{flex:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file-single-input__input": `file-single-input__input_OgqkU`,
	"file-single-input__input-border-wrapper": `file-single-input__input-border-wrapper_Gogtr`,
	"file-single-input--large": `file-single-input--large_zJR4V`,
	"file-single-input__file-label": `file-single-input__file-label_sVz0X`,
	"file-single-input__file-label--icon-suffix": `file-single-input__file-label--icon-suffix_KKVov`,
	"file-single-input--error": `file-single-input--error_YuwgP`,
	"file-single-input--focus": `file-single-input--focus_KJvvF`,
	"file-single-input--empty": `file-single-input--empty_rEj4i`,
	"file-single-input__file-name": `file-single-input__file-name_kW40m`,
	"file-single-input__file-extension": `file-single-input__file-extension_uX6Tr`
};
export default ___CSS_LOADER_EXPORT___;
