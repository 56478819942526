// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .coming_nYJ1J{display:flex;align-items:center;height:56px;padding:0 16px;margin-top:8px;background-color:var(--Layer0)}html.app__layout--desktop .coming__title_aNUVK{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;flex:1;color:var(--TextDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"coming": `coming_nYJ1J`,
	"coming__title": `coming__title_aNUVK`
};
export default ___CSS_LOADER_EXPORT___;
