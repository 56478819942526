import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { AvatarSize } from './enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VAvatar',
    props: {
        size: {
            default: AvatarSize.SIZE_44
        },
        imageSrc: {},
        isLoyaltyEnabled: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        function onClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['avatar']]: true,
                    [_ctx.$style[`avatar--${props.size}`]]: _ctx.size && _ctx.size !== _unref(AvatarSize).SIZE_44
                })
            }, [
                _ctx.isLoyaltyEnabled ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    class: _normalizeClass(_ctx.$style['avatar__button']),
                    onClick: onClick
                }, [
                    _ctx.imageSrc ? _renderSlot(_ctx.$slots, "egs-loyalty-program", {
                        key: 0
                    }) : (_openBlock(), _createElementBlock("img", {
                        key: 1,
                        src: require('web/src/assets/images/beginner.svg'),
                        alt: ""
                    }, null, 8, _hoisted_1))
                ], 2)) : (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: _normalizeClass(_ctx.$style['avatar__button']),
                    onClick: onClick
                }, [
                    _createVNode(_unref(VIcon), {
                        size: _ctx.size === _unref(AvatarSize).SIZE_68 ? _unref(IconSize).SIZE_28 : _unref(IconSize).SIZE_24,
                        name: _unref(IconName).PERSON_FILL
                    }, null, 8, [
                        "size",
                        "name"
                    ])
                ], 2))
            ], 2)), [
                [
                    _directive_auto_id,
                    'VAvatar'
                ]
            ]);
        };
    }
});
