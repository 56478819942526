// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.v-photo-placeholder_krJ0g{position:relative;padding-top:5px}.v-photo-placeholder--vertical_vY5So{width:164px;height:168px}.v-photo-placeholder--horizontal_9Pj7X{width:160px;height:122px}.v-photo-placeholder__frame-wrapper_Ajqhk{position:relative;display:flex;align-items:center;justify-content:center;margin:auto;border:1px solid var(--Highlight);border-radius:8px}.v-photo-placeholder--vertical_vY5So .v-photo-placeholder__frame-wrapper_Ajqhk{width:122px;height:160px}.v-photo-placeholder--horizontal_9Pj7X .v-photo-placeholder__frame-wrapper_Ajqhk{width:160px;height:122px}.v-photo-placeholder__frame_xhybu{object-fit:contain}.v-photo-placeholder--vertical_vY5So .v-photo-placeholder__frame_xhybu{width:110px;height:148px}.v-photo-placeholder--horizontal_9Pj7X .v-photo-placeholder__frame_xhybu{width:148px;height:110px}.v-photo-placeholder__icon_VKGvi{position:absolute;display:flex;align-items:center;justify-content:center;width:40px;height:40px;color:var(--Black);opacity:.4}.v-photo-placeholder__icon_VKGvi:before{position:absolute;top:0;right:0;bottom:0;left:0;z-index:-1;content:"";background-color:var(--White);border-radius:50%}.v-photo-placeholder__corner_EaHCC{position:absolute;width:9px;height:9px}.v-photo-placeholder--horizontal_9Pj7X .v-photo-placeholder__corner_EaHCC{display:none}.v-photo-placeholder__corner_EaHCC:before{position:absolute;top:0;left:2px;width:7px;content:"";border-top:1px solid var(--TextSecondary)}.v-photo-placeholder__corner_EaHCC:after{position:absolute;top:2px;left:0;height:7px;content:"";border-left:1px solid var(--TextSecondary)}.v-photo-placeholder__corner--top__P0hD{top:0;left:0}.v-photo-placeholder__corner--top-right_g_7dd{top:0;right:0;transform:rotate(90deg)}.v-photo-placeholder__corner--bottom_67SdM{bottom:0;left:0;transform:rotate(270deg)}.v-photo-placeholder__corner--bottom-right_Z1aZF{right:0;bottom:0;transform:rotate(180deg)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"v-photo-placeholder": `v-photo-placeholder_krJ0g`,
	"v-photo-placeholder--vertical": `v-photo-placeholder--vertical_vY5So`,
	"v-photo-placeholder--horizontal": `v-photo-placeholder--horizontal_9Pj7X`,
	"v-photo-placeholder__frame-wrapper": `v-photo-placeholder__frame-wrapper_Ajqhk`,
	"v-photo-placeholder__frame": `v-photo-placeholder__frame_xhybu`,
	"v-photo-placeholder__icon": `v-photo-placeholder__icon_VKGvi`,
	"v-photo-placeholder__corner": `v-photo-placeholder__corner_EaHCC`,
	"v-photo-placeholder__corner--top": `v-photo-placeholder__corner--top__P0hD`,
	"v-photo-placeholder__corner--top-right": `v-photo-placeholder__corner--top-right_g_7dd`,
	"v-photo-placeholder__corner--bottom": `v-photo-placeholder__corner--bottom_67SdM`,
	"v-photo-placeholder__corner--bottom-right": `v-photo-placeholder__corner--bottom-right_Z1aZF`
};
export default ___CSS_LOADER_EXPORT___;
