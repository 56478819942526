// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-input__placeholder_LxH7e{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;position:absolute;top:14px;left:12px;color:var(--TextDefault);pointer-events:none}.payment-input__label_qW2bm,.payment-input__placeholder--error_BOxkj:not(.payment-input__placeholder--focus_Fs2cN){color:var(--TextDefault)}.payment-input__label_qW2bm{font-weight:400;height:16px;padding-left:0;margin-bottom:2px;text-align:left;font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payment-input__placeholder": `payment-input__placeholder_LxH7e`,
	"payment-input__label": `payment-input__label_qW2bm`,
	"payment-input__placeholder--error": `payment-input__placeholder--error_BOxkj`,
	"payment-input__placeholder--focus": `payment-input__placeholder--focus_Fs2cN`
};
export default ___CSS_LOADER_EXPORT___;
