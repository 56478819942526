// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .groups-list_PeZi0{display:grid;grid-template-columns:repeat(2,1fr);gap:16px;grid-template-columns:repeat(4,1fr)}@media only screen and (min-width:500px){html.app__layout--desktop .groups-list_PeZi0{grid-template-columns:repeat(3,1fr)}}@media only screen and (min-width:650px){html.app__layout--desktop .groups-list_PeZi0{grid-template-columns:repeat(4,1fr)}}html.app__layout--desktop .groups-list--search_u5qiB{grid-template-columns:repeat(3,1fr)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"groups-list": `groups-list_PeZi0`,
	"groups-list--search": `groups-list--search_u5qiB`
};
export default ___CSS_LOADER_EXPORT___;
