// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bet-list-item__status_r9Glx{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;display:block;width:100%;overflow:hidden;color:var(--TextSecondary);text-overflow:ellipsis;white-space:nowrap}.bet-list-item__status--positive_F0f9U{color:var(--BrandDefault)}.bet-list-item__date_y0xDE{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;color:var(--TextSecondary)}.bet-list-item__event-description_w8ZIN{display:block;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.bet-list-item__coefficient_YoZHr,.bet-list-item__event-description_w8ZIN{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.bet-list-item__credit_Qjl9e,.bet-list-item__debit_pHOgs{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;color:var(--TextPrimary)}.bet-list-item__credit--positive_m1m1R,.bet-list-item__debit--positive_IxzFR{color:var(--BrandDefault)}.bet-list-item__credit_Qjl9e{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px}.bet-list-item__prediction_nHzO_,.bet-list-item__prediction-label_GpCku{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px}.bet-list-item__prediction-label_GpCku{color:var(--TextSecondary)}.bet-list-item__prediction-choice_OGq1P{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;color:var(--BrandDefault);white-space:nowrap}.bet-list-item__cash-out_h5PEp{position:relative;color:var(--AlertDefault)}.bet-list-item__cash-out_h5PEp:after{position:absolute;top:-14px;right:-14px;bottom:-14px;left:-14px;display:block;content:""}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bet-list-item__status": `bet-list-item__status_r9Glx`,
	"bet-list-item__status--positive": `bet-list-item__status--positive_F0f9U`,
	"bet-list-item__date": `bet-list-item__date_y0xDE`,
	"bet-list-item__event-description": `bet-list-item__event-description_w8ZIN`,
	"bet-list-item__coefficient": `bet-list-item__coefficient_YoZHr`,
	"bet-list-item__credit": `bet-list-item__credit_Qjl9e`,
	"bet-list-item__debit": `bet-list-item__debit_pHOgs`,
	"bet-list-item__credit--positive": `bet-list-item__credit--positive_m1m1R`,
	"bet-list-item__debit--positive": `bet-list-item__debit--positive_IxzFR`,
	"bet-list-item__prediction": `bet-list-item__prediction_nHzO_`,
	"bet-list-item__prediction-label": `bet-list-item__prediction-label_GpCku`,
	"bet-list-item__prediction-choice": `bet-list-item__prediction-choice_OGq1P`,
	"bet-list-item__cash-out": `bet-list-item__cash-out_h5PEp`
};
export default ___CSS_LOADER_EXPORT___;
