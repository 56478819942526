// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feedback-legacy__description_w_Hbh{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:16px;color:var(--TextPrimary)}.feedback-legacy__submit_zNA6I{margin-top:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"feedback-legacy__description": `feedback-legacy__description_w_Hbh`,
	"feedback-legacy__submit": `feedback-legacy__submit_zNA6I`
};
export default ___CSS_LOADER_EXPORT___;
