// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .bonus-codes-overview_LEVlp{padding:16px;background:var(--Layer1);border-radius:8px}html.app__layout--desktop .bonus-codes-overview__row_pDQ19{display:flex;align-items:center;justify-content:space-between;overflow:hidden}html.app__layout--desktop .bonus-codes-overview__counter-label_XBMfD{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;display:block;margin-bottom:4px;color:var(--TextSecondary)}html.app__layout--desktop .bonus-codes-overview__counter-value_MNNmK{font-size:18px;font-weight:500;line-height:24px;letter-spacing:normal;display:block;color:var(--TextDefault)}html.app__layout--desktop .bonus-codes-overview__footer__SdKa{margin-top:16px}html.app__layout--desktop .bonus-codes-overview__help_xNzz3{display:inline-flex;align-items:center;margin:-10px;color:var(--TextSecondary);cursor:pointer;background:none;border:none}html.app__layout--desktop .bonus-codes-overview__help-icon_jILGo{margin-right:5px}html.app__layout--desktop .bonus-codes-overview__help-icon_jILGo svg{fill:var(--TextSecondary)}html.app__layout--desktop .bonus-codes-overview__help-text_thTzK{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;-webkit-text-decoration:underline;text-decoration:underline}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bonus-codes-overview": `bonus-codes-overview_LEVlp`,
	"bonus-codes-overview__row": `bonus-codes-overview__row_pDQ19`,
	"bonus-codes-overview__counter-label": `bonus-codes-overview__counter-label_XBMfD`,
	"bonus-codes-overview__counter-value": `bonus-codes-overview__counter-value_MNNmK`,
	"bonus-codes-overview__footer": `bonus-codes-overview__footer__SdKa`,
	"bonus-codes-overview__help": `bonus-codes-overview__help_xNzz3`,
	"bonus-codes-overview__help-icon": `bonus-codes-overview__help-icon_jILGo`,
	"bonus-codes-overview__help-text": `bonus-codes-overview__help-text_thTzK`
};
export default ___CSS_LOADER_EXPORT___;
