import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { computed } from 'vue';
import { Tag } from '@leon-hub/tags';
import { IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VAnimationStarter from 'web/src/components/AnimationStarter/VAnimationStarter/VAnimationStarter.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LandingButton',
    props: {
        isDisabled: {
            type: Boolean
        },
        fullWidth: {
            type: Boolean
        },
        href: {},
        id: {},
        label: {},
        role: {},
        tag: {
            default: Tag.BUTTON
        },
        target: {},
        type: {},
        dataAttributeName: {},
        dataAttributeValue: {},
        iconName: {}
    },
    emits: [
        "click",
        "keydown",
        "mousedown",
        "focus",
        "blur"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const computedAttributes = computed(()=>({
                ...props.dataAttributeName && props.dataAttributeValue ? {
                    [props.dataAttributeName]: props.dataAttributeValue
                } : {}
            }));
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VAnimationStarter, {
                "root-margin": "0px 0px -50px 0px",
                threshold: 0.8
            }, {
                default: _withCtx(()=>[
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), _mergeProps(computedAttributes.value, {
                            id: _ctx.id,
                            class: {
                                [_ctx.$style['landing-button']]: true,
                                [_ctx.$style['landing-button--full']]: _ctx.fullWidth
                            },
                            href: _ctx.href,
                            disabled: _ctx.isDisabled,
                            onClick: _cache[0] || (_cache[0] = ($event)=>emit('click', $event)),
                            onKeydown: _cache[1] || (_cache[1] = ($event)=>emit('keydown', $event)),
                            onMousedown: _cache[2] || (_cache[2] = ($event)=>emit('mousedown', $event))
                        }), {
                            default: _withCtx(()=>[
                                    _renderSlot(_ctx.$slots, "default", {}, ()=>[
                                            _ctx.iconName ? (_openBlock(), _createElementBlock("span", {
                                                key: 0,
                                                class: _normalizeClass(_ctx.$style['landing-button__icon'])
                                            }, [
                                                _createVNode(_unref(VIcon), {
                                                    name: _ctx.iconName,
                                                    size: _unref(IconSize).SIZE_24
                                                }, null, 8, [
                                                    "name",
                                                    "size"
                                                ])
                                            ], 2)) : _createCommentVNode("", true),
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['landing-button__label'])
                                            }, _toDisplayString(_ctx.label), 3)
                                        ])
                                ]),
                            _: 3
                        }, 16, [
                            "id",
                            "class",
                            "href",
                            "disabled"
                        ]))
                    ]),
                _: 3
            })), [
                [
                    _directive_auto_id,
                    'LandingButton'
                ]
            ]);
        };
    }
});
