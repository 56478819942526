// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .odds-selector_YOlu_{display:flex;flex-direction:column;gap:4px;padding:0;margin:0;list-style:none}html.app__layout--desktop .odds-selector__item_jLHoF{position:relative;display:flex;flex-direction:row;gap:0;align-items:center;justify-content:space-between;width:100%;height:44px;padding:0 12px;color:var(--TextPrimary);cursor:pointer;background-color:var(--Layer2);border:none;border-radius:5px}html.app__layout--desktop .odds-selector__item_jLHoF:hover:not(html.app__layout--desktop .odds-selector__item-active_i9a7n){color:var(--TextDefault);background-color:var(--LanguageItemHoverColor)}html.app__layout--desktop .odds-selector__item-active_i9a7n{color:var(--TextDefault);cursor:default;background-color:var(--Highlight);border:1px solid #0000}html.app__layout--desktop .odds-selector__item-text_KftG4{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:inline-flex;flex-grow:1;padding:0 14px 0 8px;-webkit-user-select:none;user-select:none}html.app__layout--desktop .odds-selector__icon-selected_tQBiJ{color:var(--TextDefault)}html.app__layout--desktop .odds-selector__icon-container_uUObf{position:relative}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"odds-selector": `odds-selector_YOlu_`,
	"odds-selector__item": `odds-selector__item_jLHoF`,
	"odds-selector__item-active": `odds-selector__item-active_i9a7n`,
	"odds-selector__item-text": `odds-selector__item-text_KftG4`,
	"odds-selector__icon-selected": `odds-selector__icon-selected_tQBiJ`,
	"odds-selector__icon-container": `odds-selector__icon-container_uUObf`
};
export default ___CSS_LOADER_EXPORT___;
