// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.casino-loyalty-program-level-done-modal_SCecn{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;position:fixed;top:16px;right:16px;z-index:1;display:flex;flex-direction:row;align-items:center;width:280px;height:62px;padding:6px;color:var(--DButton);background:#fffffff2;border-radius:100px 12px 12px 100px;box-shadow:0 8px 16px #0000004d}.casino-loyalty-program-level-done-modal__icon_chyku{display:flex;align-items:center;justify-content:center;width:50px;height:50px;background:#0000001a;border-radius:50%}.casino-loyalty-program-level-done-modal__content_Uehdk{padding-left:10px}.casino-loyalty-program-level-done-modal__title_hzH7K{margin-bottom:4px;font-weight:500}html.app__layout--desktop .casino-loyalty-program-level-done-modal_SCecn{top:80px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-loyalty-program-level-done-modal": `casino-loyalty-program-level-done-modal_SCecn`,
	"casino-loyalty-program-level-done-modal__icon": `casino-loyalty-program-level-done-modal__icon_chyku`,
	"casino-loyalty-program-level-done-modal__content": `casino-loyalty-program-level-done-modal__content_Uehdk`,
	"casino-loyalty-program-level-done-modal__title": `casino-loyalty-program-level-done-modal__title_hzH7K`
};
export default ___CSS_LOADER_EXPORT___;
