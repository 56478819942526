// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-wrapper_RPpa1{position:relative;display:flex;flex-flow:column wrap;flex-grow:1}.input-wrapper_RPpa1 .input-wrapper__icon--prefix_SFWIV{color:var(--TextDefault)}.input-wrapper_RPpa1 .input-wrapper__icon--suffix_SBb28{color:var(--TextSecondary)}.input-wrapper_RPpa1:last-child{margin-bottom:0}.input-wrapper--disabled_NkItI .input-wrapper__icon--prefix_SFWIV{color:var(--TextSecondary)}.input-wrapper--disabled_NkItI .input-wrapper__icon--suffix_SBb28{color:var(--Highlight)}.input-wrapper--error_u0VFO:not(.input-wrapper--focus__ryoA) .input-wrapper__icon--prefix_SFWIV,.input-wrapper--error_u0VFO:not(.input-wrapper--focus__ryoA) .input-wrapper__icon--suffix_SBb28{color:var(--ErrorText)}.input-wrapper--empty_XGtsh:not(.input-wrapper--error_u0VFO):not(.input-wrapper--focus__ryoA):not(.input-wrapper--disabled_NkItI) .input-wrapper__icon--prefix_SFWIV,.input-wrapper--empty_XGtsh:not(.input-wrapper--error_u0VFO):not(.input-wrapper--focus__ryoA):not(.input-wrapper--disabled_NkItI) .input-wrapper__icon--suffix_SBb28{color:var(--TextSecondary)}.input-wrapper__field-container_uJWdd{position:relative;width:100%}.input-wrapper__field_pZ8uj{z-index:2;position:relative;transform:translateZ(0)}.input-wrapper__label_OVbbe{font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal;height:16px;padding-left:0;margin-bottom:2px;color:var(--TextDefault);text-align:left}.input-wrapper__icon_eacxe{z-index:5;position:absolute;top:0;display:flex;align-items:center;justify-content:center;min-width:36px;height:44px}.input-wrapper__icon_eacxe:empty{display:none}.input-wrapper__icon--suffix_SBb28{right:0}.input-wrapper__icon--prefix_SFWIV{left:0}.input-wrapper__icon--inert_C4PRS{pointer-events:none}.input-wrapper--large_c8Eif .input-wrapper__icon_eacxe{min-width:36px;height:44px}.input-wrapper__lock_V_WN1{color:var(--Highlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-wrapper": `input-wrapper_RPpa1`,
	"input-wrapper__icon--prefix": `input-wrapper__icon--prefix_SFWIV`,
	"input-wrapper__icon--suffix": `input-wrapper__icon--suffix_SBb28`,
	"input-wrapper--disabled": `input-wrapper--disabled_NkItI`,
	"input-wrapper--error": `input-wrapper--error_u0VFO`,
	"input-wrapper--focus": `input-wrapper--focus__ryoA`,
	"input-wrapper--empty": `input-wrapper--empty_XGtsh`,
	"input-wrapper__field-container": `input-wrapper__field-container_uJWdd`,
	"input-wrapper__field": `input-wrapper__field_pZ8uj`,
	"input-wrapper__label": `input-wrapper__label_OVbbe`,
	"input-wrapper__icon": `input-wrapper__icon_eacxe`,
	"input-wrapper__icon--inert": `input-wrapper__icon--inert_C4PRS`,
	"input-wrapper--large": `input-wrapper--large_c8Eif`,
	"input-wrapper__lock": `input-wrapper__lock_V_WN1`
};
export default ___CSS_LOADER_EXPORT___;
