// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lazy-image_shZNO{position:relative;display:inline-block;width:100%;max-width:100%;height:100%}.lazy-image__placeholder_ZN3M4{position:absolute;top:0;left:0;display:flex;align-items:center;justify-content:center;width:100%;height:100%;padding:4px}.lazy-image__placeholder--loader_xjntF{background-color:var(--Layer1)}.lazy-image__placeholder--loader-transparent_UsdFO{background-color:#0000}.lazy-image__loader-svg_ESB6c{max-width:72px;margin:auto}.lazy-image__img_mRAwS{position:absolute;top:0;left:0;width:100%;height:100%;object-position:inherit;border-radius:inherit}.lazy-image__img-position--top_WjaoZ{object-position:top}.lazy-image__img-position--bottom_qVQjk{object-position:bottom}.lazy-image__img-position--right_oj8MC{object-position:right}.lazy-image__img-position--left_dwJvt{object-position:left}.lazy-image__img--cover_ecAJ9{object-fit:cover}.lazy-image__img--contain_AmkOi{object-fit:contain}.lazy-image__img--scale-down_J_Ixt{object-fit:scale-down}.lazy-image__img--none_OSCAy{object-fit:none}.lazy-image__img--is-relative_czg5G{position:relative}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lazy-image": `lazy-image_shZNO`,
	"lazy-image__placeholder": `lazy-image__placeholder_ZN3M4`,
	"lazy-image__placeholder--loader": `lazy-image__placeholder--loader_xjntF`,
	"lazy-image__placeholder--loader-transparent": `lazy-image__placeholder--loader-transparent_UsdFO`,
	"lazy-image__loader-svg": `lazy-image__loader-svg_ESB6c`,
	"lazy-image__img": `lazy-image__img_mRAwS`,
	"lazy-image__img-position--top": `lazy-image__img-position--top_WjaoZ`,
	"lazy-image__img-position--bottom": `lazy-image__img-position--bottom_qVQjk`,
	"lazy-image__img-position--right": `lazy-image__img-position--right_oj8MC`,
	"lazy-image__img-position--left": `lazy-image__img-position--left_dwJvt`,
	"lazy-image__img--cover": `lazy-image__img--cover_ecAJ9`,
	"lazy-image__img--contain": `lazy-image__img--contain_AmkOi`,
	"lazy-image__img--scale-down": `lazy-image__img--scale-down_J_Ixt`,
	"lazy-image__img--none": `lazy-image__img--none_OSCAy`,
	"lazy-image__img--is-relative": `lazy-image__img--is-relative_czg5G`
};
export default ___CSS_LOADER_EXPORT___;
