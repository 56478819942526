import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { Tag } from '@leon-hub/tags';
import { JumbotronFooterButton, VJumbotron } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useCustomerHistoryListEmpty } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'CustomerHistoryListEmpty',
    props: {
        filter: {},
        isButtonHidden: {
            type: Boolean
        }
    },
    setup (__props) {
        const props = __props;
        const { buttonLabel, emptyText, doShowActionButton, buttonClick } = useCustomerHistoryListEmpty(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(VJumbotron), {
                heading: _ctx.$t('WEB2_NOTHING_FOUND'),
                text: _unref(emptyText),
                class: "history-list__jumbotron"
            }, {
                footer: _withCtx(()=>[
                        !_ctx.isButtonHidden && _unref(doShowActionButton) ? (_openBlock(), _createBlock(_unref(JumbotronFooterButton), {
                            key: 0,
                            class: "history-list__button",
                            tag: _unref(Tag).BUTTON,
                            label: _unref(buttonLabel),
                            kind: _unref(ButtonKind).BASE,
                            "full-width": false,
                            "is-uppercase": false,
                            rounded: "",
                            onClick: _unref(buttonClick)
                        }, null, 8, [
                            "tag",
                            "label",
                            "kind",
                            "full-width",
                            "onClick"
                        ])) : _createCommentVNode("", true)
                    ]),
                _: 1
            }, 8, [
                "heading",
                "text"
            ])), [
                [
                    _directive_auto_id,
                    'CustomerHistoryListEmpty'
                ]
            ]);
        };
    }
});
