// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-navigation-buttons_A2Ur8{z-index:1;position:absolute;top:0;right:0;bottom:0;left:0;pointer-events:none;opacity:0;transition:opacity .25s ease-in-out}.swiper:hover .swiper-navigation-buttons_A2Ur8{opacity:1}.swiper-navigation-buttons__btn_SjL5f{position:absolute;top:0;bottom:0;display:flex;flex-direction:column;align-items:center;pointer-events:all;-webkit-user-select:none;user-select:none}.swiper-navigation-buttons__btn--prev_t9Mnd{left:0}.swiper-navigation-buttons__btn--next_D_9id{right:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"swiper-navigation-buttons": `swiper-navigation-buttons_A2Ur8`,
	"swiper-navigation-buttons__btn": `swiper-navigation-buttons__btn_SjL5f`,
	"swiper-navigation-buttons__btn--prev": `swiper-navigation-buttons__btn--prev_t9Mnd`,
	"swiper-navigation-buttons__btn--next": `swiper-navigation-buttons__btn--next_D_9id`
};
export default ___CSS_LOADER_EXPORT___;
