import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue";
import { toRef } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { ButtonHeight, ButtonKind } from '@leon-hub/module-buttons';
import { VIcon } from '@components/v-icon';
import LButton from 'web/src/components/Button/LButton/LButton.vue';
import { ObjectFitOption } from 'web/src/components/Image/VImage/enums';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'LSnackbarContent',
    props: {
        type: {
            default: 'default'
        },
        title: {},
        text: {},
        buttonLabel: {},
        isSecondaryButton: {
            type: Boolean
        },
        hasClose: {
            type: Boolean
        },
        isTabBarHidden: {
            type: Boolean
        },
        isTooltipZIndex: {
            type: Boolean
        },
        iconName: {},
        image: {},
        duration: {},
        isDone: {
            type: Boolean
        },
        primaryButton: {},
        secondaryButton: {},
        linkButton: {},
        isPrimaryButtonDone: {
            type: Boolean
        },
        isSecondaryButtonDone: {
            type: Boolean
        },
        isMaxZIndex: {
            type: Boolean
        }
    },
    emits: [
        "click",
        "click-close",
        "click-secondary",
        "click-link"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const imageSrc = toRef(()=>{
            if (props.image) return props.image;
        });
        const isSingleRow = toRef(()=>!!props.title && !props.text || !!props.text && !props.title);
        const hasButtons = toRef(()=>!!props.buttonLabel || !!props.primaryButton?.label || !!props.secondaryButton?.label || !!props.linkButton?.label);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['snack-bar']]: true,
                    [_ctx.$style['snack-bar--multiline']]: !isSingleRow.value,
                    [_ctx.$style['snack-bar--with-close-button']]: _ctx.hasClose,
                    [_ctx.$style['snack-bar--with-graphics']]: _ctx.iconName || imageSrc.value,
                    [_ctx.$style['snack-bar--with-progress']]: _ctx.duration && _ctx.duration > 0
                })
            }, [
                _ctx.iconName || _ctx.image ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['snack-bar__graphics'])
                }, [
                    imageSrc.value ? _withDirectives((_openBlock(), _createBlock(VImage, _mergeProps({
                        key: 0
                    }, imageSrc.value, {
                        class: _ctx.$style['snack-bar__image'],
                        "object-fit": _unref(ObjectFitOption).CONTAIN
                    }), null, 16, [
                        "class",
                        "object-fit"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'snack-bar__image'
                            }
                        ]
                    ]) : _createCommentVNode("", true),
                    _ctx.iconName ? _withDirectives((_openBlock(), _createBlock(_unref(VIcon), {
                        key: 1,
                        name: _ctx.iconName,
                        class: _normalizeClass([
                            _ctx.$style['snack-bar__icon'],
                            _ctx.$style[`snack-bar__icon--type-${_ctx.type}`]
                        ]),
                        size: _unref(IconSize).SIZE_24
                    }, null, 8, [
                        "name",
                        "class",
                        "size"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'snack-bar__icon'
                            }
                        ]
                    ]) : _createCommentVNode("", true)
                ], 2)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['snack-bar__content'])
                }, [
                    isSingleRow.value ? _withDirectives((_openBlock(), _createElementBlock("p", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['snack-bar__single-label'])
                    }, [
                        _createTextVNode(_toDisplayString(_ctx.title || _ctx.text), 1)
                    ], 2)), [
                        [
                            _directive_data_test,
                            {
                                el: 'snack-bar__single-label'
                            }
                        ]
                    ]) : _createCommentVNode("", true),
                    isSingleRow.value ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['snack-bar__labels'])
                    }, [
                        _withDirectives((_openBlock(), _createElementBlock("h3", {
                            class: _normalizeClass(_ctx.$style['snack-bar__title'])
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'snack-bar__title'
                                }
                            ]
                        ]),
                        _withDirectives((_openBlock(), _createElementBlock("p", {
                            class: _normalizeClass({
                                [_ctx.$style['snack-bar__text']]: true,
                                [_ctx.$style['snack-bar__text--with-padding']]: !!_ctx.buttonLabel
                            })
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.text), 1)
                        ], 2)), [
                            [
                                _directive_data_test,
                                {
                                    el: 'snack-bar__text'
                                }
                            ]
                        ])
                    ], 2)),
                    _ctx.hasClose && (isSingleRow.value && !hasButtons.value || !isSingleRow.value && _ctx.hasClose) ? _withDirectives((_openBlock(), _createBlock(LButton, {
                        key: 2,
                        class: _normalizeClass(_ctx.$style['snack-bar__close-button']),
                        "icon-name": _unref(IconName).CROSS,
                        "icon-size": _unref(IconSize).SIZE_24,
                        onClick: _cache[0] || (_cache[0] = ($event)=>emit('click-close', $event))
                    }, null, 8, [
                        "class",
                        "icon-name",
                        "icon-size"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'snack-bar__close-button'
                            }
                        ]
                    ]) : _createCommentVNode("", true)
                ], 2),
                hasButtons.value ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['snack-bar__buttons-container'])
                }, [
                    _ctx.buttonLabel || _ctx.primaryButton?.label ? _withDirectives((_openBlock(), _createBlock(LButton, _mergeProps({
                        key: 0
                    }, _ctx.primaryButton, {
                        class: _ctx.$style['snack-bar__button'],
                        height: _unref(ButtonHeight).TINY,
                        label: _ctx.buttonLabel || _ctx.primaryButton?.label,
                        kind: _unref(ButtonKind).PRIMARY,
                        "icon-name": _ctx.isPrimaryButtonDone ? _unref(IconName).CHECK_FILLED : _ctx.primaryButton?.iconName,
                        rounded: "",
                        "is-uppercase": false,
                        onClick: _cache[1] || (_cache[1] = ($event)=>emit('click', $event))
                    }), null, 16, [
                        "class",
                        "height",
                        "label",
                        "kind",
                        "icon-name"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'snack-bar__button'
                            }
                        ]
                    ]) : _createCommentVNode("", true),
                    _ctx.secondaryButton?.label ? _withDirectives((_openBlock(), _createBlock(LButton, _mergeProps({
                        key: 1
                    }, _ctx.secondaryButton, {
                        class: _ctx.$style['snack-bar__button'],
                        height: _unref(ButtonHeight).TINY,
                        label: _ctx.secondaryButton?.label,
                        kind: _unref(ButtonKind).SECONDARY,
                        "icon-name": _ctx.isSecondaryButtonDone ? _unref(IconName).CHECK_FILLED : _ctx.secondaryButton?.iconName,
                        rounded: "",
                        "is-uppercase": false,
                        onClick: _cache[2] || (_cache[2] = ($event)=>emit('click-secondary', $event))
                    }), null, 16, [
                        "class",
                        "height",
                        "label",
                        "kind",
                        "icon-name"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'snack-bar__button'
                            }
                        ]
                    ]) : _createCommentVNode("", true),
                    _ctx.linkButton?.label ? _withDirectives((_openBlock(), _createBlock(LButton, _mergeProps({
                        key: 2
                    }, _ctx.linkButton, {
                        class: {
                            [_ctx.$style['snack-bar__button']]: true,
                            [_ctx.$style['snack-bar__button--link']]: true
                        },
                        height: _unref(ButtonHeight).TINY,
                        label: _ctx.linkButton?.label,
                        kind: _unref(ButtonKind).TRANSPARENT,
                        rounded: "",
                        "is-uppercase": false,
                        onClick: _cache[3] || (_cache[3] = ($event)=>emit('click-link', $event))
                    }), null, 16, [
                        "class",
                        "height",
                        "label",
                        "kind"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'snack-bar__button'
                            }
                        ]
                    ]) : _createCommentVNode("", true)
                ], 2)) : _createCommentVNode("", true),
                _ctx.duration && _ctx.duration > 0 ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(_ctx.$style['snack-bar__progress-container'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['snack-bar__progress-bar']),
                        style: _normalizeStyle({
                            animationDuration: `${_ctx.duration}s`
                        })
                    }, null, 6)
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SnackbarContent'
                ]
            ]);
        };
    }
});
