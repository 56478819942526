// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.restore_Hhd_0{padding:0 16px 16px;margin-top:16px}.restore-password__tabs-container_wFMx6{margin:0 8px}html.app__layout--desktop .restore-password__tabs-container_wFMx6{margin:0 24px}html.app__layout--desktop .restore_Hhd_0{padding:0 32px 32px}.restore-captcha-badge_AeAbj{padding:0 0 44px;margin:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"restore": `restore_Hhd_0`,
	"restore-password__tabs-container": `restore-password__tabs-container_wFMx6`,
	"restore-captcha-badge": `restore-captcha-badge_AeAbj`
};
export default ___CSS_LOADER_EXPORT___;
