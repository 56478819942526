// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-list-item_ynS_p:active,.payments-list-item_ynS_p:focus{transform:scaleX(.99) scaleY(.96)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-list-item": `payments-list-item_ynS_p`
};
export default ___CSS_LOADER_EXPORT___;
