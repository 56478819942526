// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .sport-event-details__controls_zsWku{position:relative;display:flex;align-items:center;justify-content:space-between;height:44px}html.app__layout--desktop .sport-event-details__switch-block_pYqSG{background-color:var(--OpacityLayer1);border-radius:5px}html.app__layout--desktop .sport-event-details__switch_VOGc_{padding:10px 8px 10px 12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sport-event-details__controls": `sport-event-details__controls_zsWku`,
	"sport-event-details__switch-block": `sport-event-details__switch-block_pYqSG`,
	"sport-event-details__switch": `sport-event-details__switch_VOGc_`
};
export default ___CSS_LOADER_EXPORT___;
