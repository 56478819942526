// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .casino-provider-select_Ft4FR{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;display:flex;flex:1;align-items:center;justify-content:space-between;height:auto;-webkit-user-select:none;user-select:none;background-color:var(--Layer1);border-radius:5px;padding:4px 0 4px 16px;white-space:nowrap;cursor:pointer}html.app__layout--desktop .casino-provider-select_Ft4FR:hover{color:var(--TextDefault);background-color:var(--Highlight)}html.app__layout--desktop .casino-provider-select__button_aOcQn{display:flex;align-items:center;justify-content:center;width:44px;height:44px;margin-right:0;margin-left:4px;border-radius:0}html.app__layout--desktop .casino-provider-select__button--selected_mu3_c{width:44px;height:44px;color:var(--BrandDefault);background-color:none}html.app__layout--desktop .casino-provider-select--in-sidebar_WhaxN{flex-direction:row-reverse;gap:8px;justify-content:flex-end;padding:0;color:var(--TextPrimary)}html.app__layout--desktop .casino-provider-select--in-sidebar_WhaxN .casino-provider-select__button_aOcQn{margin:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"casino-provider-select": `casino-provider-select_Ft4FR`,
	"casino-provider-select__button": `casino-provider-select__button_aOcQn`,
	"casino-provider-select__button--selected": `casino-provider-select__button--selected_mu3_c`,
	"casino-provider-select--in-sidebar": `casino-provider-select--in-sidebar_WhaxN`
};
export default ___CSS_LOADER_EXPORT___;
