// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statistic-chart-bar_J7zP5{font-size:10px;font-weight:500;line-height:12px;letter-spacing:normal;height:100%;padding:0 4px;border-radius:2px}.statistic-chart-bar__container_Ar5N9{display:flex;gap:4px;justify-content:space-between;width:100%}.statistic-chart-bar__container--labeled_YsUdz{height:12px}.statistic-chart-bar__container--narrow_cvPM2{height:4px}.statistic-chart-bar--host_RYK2O{color:var(--BrandText);background:var(--StatsA)}.statistic-chart-bar--draws_sXLSz{color:var(--BrandText);background:var(--StatsDraw)}.statistic-chart-bar--guest_R7Xml{color:var(--BrandText);background:var(--StatsB)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic-chart-bar": `statistic-chart-bar_J7zP5`,
	"statistic-chart-bar__container": `statistic-chart-bar__container_Ar5N9`,
	"statistic-chart-bar__container--labeled": `statistic-chart-bar__container--labeled_YsUdz`,
	"statistic-chart-bar__container--narrow": `statistic-chart-bar__container--narrow_cvPM2`,
	"statistic-chart-bar--host": `statistic-chart-bar--host_RYK2O`,
	"statistic-chart-bar--draws": `statistic-chart-bar--draws_sXLSz`,
	"statistic-chart-bar--guest": `statistic-chart-bar--guest_R7Xml`
};
export default ___CSS_LOADER_EXPORT___;
