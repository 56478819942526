// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-picker_fmM8E .dp__main{display:inline-flex;width:auto}.date-picker_fmM8E .dp__input{font-size:14px;font-weight:400;line-height:16px;letter-spacing:.25px;width:74px;padding:0;margin:0;font-family:var(--MainFontFamily);color:var(--TextDefault);background-color:#0000;border:none;border-radius:0;transition:none}.date-picker_fmM8E .dp--clear-btn{display:none}.date-picker_fmM8E .dp__input_icon{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"date-picker": `date-picker_fmM8E`
};
export default ___CSS_LOADER_EXPORT___;
