// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sms-code-input_g5dij{display:flex;padding:18px 0 0;margin:0;border:0}.sms-code-input__inner_Eiike{display:flex;justify-content:center;padding-bottom:0}.sms-code-input__wrapper_Mm996{margin:0 auto}.sms-code-input__raw-wrapper_opTYx{display:flex;align-items:center;justify-content:center}.sms-code-input__border_uZoXB{width:36px;padding:0;margin-right:12px}.sms-code-input__border_uZoXB:last-child{margin-right:0}.sms-code-input__number_hYRlx{text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sms-code-input": `sms-code-input_g5dij`,
	"sms-code-input__inner": `sms-code-input__inner_Eiike`,
	"sms-code-input__wrapper": `sms-code-input__wrapper_Mm996`,
	"sms-code-input__raw-wrapper": `sms-code-input__raw-wrapper_opTYx`,
	"sms-code-input__border": `sms-code-input__border_uZoXB`,
	"sms-code-input__number": `sms-code-input__number_hYRlx`
};
export default ___CSS_LOADER_EXPORT___;
