// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.push-info_PmCy_{margin-top:8px;text-align:center}.push-info-bonus_WZuvL{max-width:200px;margin:0 auto}.push-info-bonus__item_BcgSX{display:flex;align-items:center;margin-bottom:18px;color:var(--TextDefault);text-align:left}.push-info-bonus__item-icon_ySCuL{flex-shrink:0;margin-right:8px;color:var(--BrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"push-info": `push-info_PmCy_`,
	"push-info-bonus": `push-info-bonus_WZuvL`,
	"push-info-bonus__item": `push-info-bonus__item_BcgSX`,
	"push-info-bonus__item-icon": `push-info-bonus__item-icon_ySCuL`
};
export default ___CSS_LOADER_EXPORT___;
