// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments-depositok_FgtYD{display:flex;flex-direction:column;align-items:center;width:100%;max-width:400px;padding:16px 16px 20px;margin:0 auto;text-align:center}.payments-depositok--margin-top_hHQpR{margin:80px 20px}.payments-depositok--separate-page_gZIl5{min-height:calc(100vh - 60px)}html.app__layout--desktop .payments-depositok_FgtYD{max-width:311px;padding:0 0 32px;margin-right:auto;margin-left:auto}html.app__layout--desktop .payments-depositok--separate-page_gZIl5{justify-content:center;min-height:100vh;padding-top:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"payments-depositok": `payments-depositok_FgtYD`,
	"payments-depositok--margin-top": `payments-depositok--margin-top_hHQpR`,
	"payments-depositok--separate-page": `payments-depositok--separate-page_gZIl5`
};
export default ___CSS_LOADER_EXPORT___;
