import { toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { CustomCustomerFieldType } from 'web/src/modules/registration/enums';
import { FormControlType } from 'web/src/components/Form/enums';
export function useRecaptchaV3Props(props) {
    "1";
    {
        const siteConfigStore = useSiteConfigStore();
        const isRegistrationCaptchaV3Enabled = toRef(()=>siteConfigStore.captcha?.recaptchaV3?.registrationEnabled ?? false);
        return {
            isRegistrationCaptchaV3Enabled,
            configuredCaptchaV3UiSchema: toRef(()=>({
                    [CustomCustomerFieldType.CAPTCHA_TOKEN_V3]: {
                        options: {
                            ...props ?? {}
                        },
                        hidden: true,
                        widget: FormControlType.CaptchaV3
                    }
                }))
        };
    }
}
