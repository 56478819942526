import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
export default /*@__PURE__*/ _defineComponent({
    __name: 'VStatus',
    props: {
        text: {},
        isSuccess: {
            type: Boolean
        },
        isLowerCase: {
            type: Boolean
        },
        isIcon: {
            type: Boolean
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        function onClick(event) {
            emit('click', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['status']]: true,
                    [_ctx.$style['status--success']]: _ctx.isSuccess,
                    [_ctx.$style['status--lowercase']]: _ctx.isLowerCase
                }),
                onClick: onClick
            }, [
                _ctx.isIcon ? (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 0,
                    name: _unref(IconName).CHECK_FILLED,
                    size: _unref(IconSize).SIZE_16,
                    class: _normalizeClass(_ctx.$style['status__icon'])
                }, null, 8, [
                    "name",
                    "size",
                    "class"
                ])) : _createCommentVNode("", true),
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['status__text'])
                }, _toDisplayString(_ctx.text), 3)
            ], 2)), [
                [
                    _directive_auto_id,
                    'VStatus'
                ]
            ]);
        };
    }
});
